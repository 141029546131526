import { useState } from 'react';

export const useMultiSelectMethods = (
	initialMethodId: string,
) => {
	const [selectedMethodId, setSelectedMethodId] = useState<string>(initialMethodId);

	//This commented out Code allows for multiple methods selection
	// const [selectedMethodIds, setSelectedMethodIds] = useState<string[]>([initialMethodId]);
	// const handleSelectMethod = (pmId: string) => {
	// 	const isSelected = selectedMethodIds.some((selectedId: any) => selectedId === pmId);
    //     let currentSelected = selectedMethodIds;
	// 	if (isSelected) {
    //         currentSelected = currentSelected.filter((selectedId: any) => selectedId !== pmId);
	// 	} else {
    //         currentSelected = [...currentSelected, pmId];
	// 	}
    //     setSelectedMethodIds(currentSelected);
	// };

	const handleSelectMethod = (pmId: string) => {
        setSelectedMethodId(pmId);
	};

	return {
		handleSelectMethod,
		selectedMethodId,
	};
};
