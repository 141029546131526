/** @jsx jsx */

import React from 'react';
import { EDateFormats, ETypography, ETypographyColor, SuspenseWrapper, Typography } from '@bondsports/utils';
import { jsx, css } from '@emotion/react';
import { ItemsPerPageCalculator, Table } from '../../shared/NewTable';
import { ColumnType } from '../../shared/NewTable/HeaderRow';
import { AlignType } from '../../shared/NewTable/types';
import dayjs from 'dayjs';
import { FutureInstallment } from '@bondsports/types';
import { TranslationEn } from '@assets/i18n/en';
import { isEmpty } from 'lodash';

const tableCss = css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 350px;
`;
const { labels } = TranslationEn.payments.installmentsTable;

const columns = [
	{
		id: 'originalPlannedDate',
		label: labels.date,
		type: 'custom',
		styling: { align: AlignType.LEFT },
		component: (state: string) => {
			return (
				<Typography type={ETypography.body2} color={ETypographyColor.primary}>
					{dayjs.utc(state, EDateFormats.YYYY_MM_DD).format(EDateFormats.DAY_FORMAT)}
				</Typography>
			);
		},
	},
	{
		id: 'price',
		label: labels.amount,
		type: 'currency',
		styling: { align: AlignType.LEFT },
	},
	{
		id: 'method',
		label: labels.method,
		type: 'paymentMethodWithIcon',
		styling: { align: AlignType.LEFT },
	},
	{
		id: 'status',
		label: labels.status,
		type: 'status',
		styling: { align: AlignType.LEFT },
	},
];

interface ISelectScheduleInstallmentsProps {
	subject: string;
	isLoading: boolean;
	scheduledPayments: FutureInstallment[];
	meta: any;
	selectedIds: number[];
	isSelectAll: boolean;
	handleSelect: (id: number) => void;
	page: number;
	tableRef?: React.RefObject<HTMLDivElement>;
	handleSelectAll: () => void;
}
const SelectScheduleInstallments = ({
	subject,
	isLoading,
	scheduledPayments,
	meta,
	tableRef,
	selectedIds,
	isSelectAll,
	handleSelect,
	handleSelectAll,
	page,
}: ISelectScheduleInstallmentsProps) => {
	const isSelectIndeterminate = (totalItems: number): boolean => {
		if (!isSelectAll && totalItems === selectedIds.length) {
			return true;
		}
		if (isSelectAll && isEmpty(selectedIds)) {
			return false;
		}
		return selectedIds.length > 0;
	};
	const { maxHeight } = ItemsPerPageCalculator(tableRef, true);

	return (
		<div css={tableCss}>
			<SuspenseWrapper loading={isLoading} width={30}>
				<Table
					isSelectRow
					isVirtualized
					meta={meta}
					columns={columns as ColumnType[]}
					rows={scheduledPayments}
					maxHeight={maxHeight}
					customEmptySpace
					onSelect={handleSelect}
					onSelectAll={handleSelectAll}
					isAllSelected={isSelectAll}
					selectedArr={selectedIds}
					isLoading={isLoading}
					pagination
					ssr
					removeShadows
					footerOrder={['pagination', 'labels', 'footer']}
					subject={subject}
					isSelectIndeterminate={isSelectIndeterminate(meta.totalItems)}
					page={page}
				/>
			</SuspenseWrapper>
		</div>
	);
};

export default SelectScheduleInstallments;
