/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import { flexBetweenCss, fullWidthCss } from '../../../styles/utils';
import { BackButton } from '../../BackButtton';
import { EBrandingColorsOptions, EPickerTheme, ETypography, ETypographyColor } from '../../../types';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Button } from '../../../components/Button';
import { Typography } from '../../../atoms';
import { SizeEnum } from '../../../lib/constants';
import { backButtonCss, buttonContainerCss, flex, footerCss } from './style';

export const PaymentFlowFooter = ({
	labels,
	remainingAmount,
	isNextDisabled,
	isLoading,
	handleBack,
	handleNext,
}: {
	labels: any;
	paymentAmountInput?: ReactNode;
	isNextDisabled?: boolean;
	isLoading?: boolean;
	handleNext: () => void;
	handleBack: () => void;
	remainingAmount: string;
}) => {
	const { isMobile } = useWindowSize();
	return (
		<div data-aid="paymentFlowFooter-container" css={footerCss}>
			{isMobile && (
				<div data-aid="paymentFlowFooter-container-mobile" css={flex}>
					<Typography type={ETypography.body1} color={ETypographyColor.primary}>
						{labels.leftToPay}
					</Typography>
					{!!remainingAmount && (
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{remainingAmount}
						</Typography>
					)}
				</div>
			)}
			<div data-aid="paymentFlowFooter-button-container" css={[flexBetweenCss, fullWidthCss]}>
				<div data-aid="button-paymentFlowFooter-back" css={backButtonCss}>
					<BackButton handleBack={handleBack} sizer={SizeEnum.MEDIUM} />
				</div>
				<div data-aid="paymentFlowFooter-button-container" css={buttonContainerCss}>
					{isMobile && (
						<Button
							data-aid="button-mobile-paymentFlowFooter-cancel"
							sizer={SizeEnum.MEDIUM}
							theme={EPickerTheme.BASIC}
							onClick={handleBack}
						>
							{labels.cancel}
						</Button>
					)}
					<Button
						data-aid="button-paymentFlowFooter-next"
						sizer={SizeEnum.MEDIUM}
						isProcessing={isLoading}
						disabledColor={EBrandingColorsOptions.tx_text_disabled}
						theme={!isNextDisabled ? EPickerTheme.PRIMARY : EPickerTheme.BASIC}
						disabled={isNextDisabled}
						onClick={handleNext}
					>
						{labels.next}
					</Button>
				</div>
			</div>
		</div>
	);
};
