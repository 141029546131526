import { IPaymentMethod } from "..";
import { PaymentMethodTypeEnum } from "../../..";
import { typesWithoutRegularLimit } from "./consts";


export const getAmountsByMethods = (usedPaymentMethods: IPaymentMethod[], types: PaymentMethodTypeEnum[]) => {
    const result: number[] = [];
    for (const singleType of types) {
        const amount = usedPaymentMethods.find(pm => pm.type === singleType)?.amount ?? 0;
        result.push(amount);
    };
    return result;
};

export const getErrorDisplayed = (pm: IPaymentMethod, userId: number, limit: number, remainingPrice: number, labels: Record<string, string>): {disableErrorAmount?: number, errorMessage: string} | undefined => {
    if (pm.refundAmount) {
        if (!typesWithoutRegularLimit.includes(pm.type)) {
                if (!pm.amount || pm.refundAmount > pm.amount) {
                    return { errorMessage: labels.refundLarger };
                }
        }
        if (pm.payingUserId === userId && (!limit || (remainingPrice < 0))) {
            return { disableErrorAmount: limit, errorMessage: `${labels.refundLargerThanUser}` };
        }
    }
    return undefined;
}