import { useCallback, useState } from 'react';
import { IUseMultipleSelectedValueReturn } from '../components/Select_v2/types';

/**
 * Custom hook to manage multiple selection logic.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Function} [params.onSelectCallback] - Optional callback function to be called when items are selected.
 * @param {string[]} [params.initialValues] - Initial values for the selection.
 * @param {Function} params.resetSearch - Function to reset the search.
 *
 * @returns {Object} - The selected values, a function to handle selection, and a function to clear the selection.
 */
export const useMultipleSelectedValue = ({
	onSelectCallback,
	initialValues,
	resetSearch,
}: {
	onSelectCallback?: (value: string[]) => void;
	initialValues?: string[];
	resetSearch: () => void;
}): IUseMultipleSelectedValueReturn => {
	const [values, setValues] = useState<string[]>(initialValues || []);

	const onSelect = useCallback(
		(selectedValues: string[]) => {
			setValues(selectedValues);
			if (onSelectCallback) onSelectCallback(selectedValues);
		},
		[onSelectCallback]
	);

	const handleClear = () => {
		onSelect([]);
		resetSearch();
	};

	return {
		values,
		onSelect,
		handleClear,
		selectedLabel: values.length,
	};
};
