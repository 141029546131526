/** @jsx jsx */

import { FC, ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import * as dayjs from 'dayjs';
import { BnIcon, ETypography, ETypographyColor, Icons, Typography } from '@bondsports/utils';
import { DateTimeFormats, formatDateTime } from '@bondsports/date-time';
import { colors } from '../../../styles/theme';
import { EDateTimeFormats } from '../../../types/times';
import { TranslationEn } from '@assets/i18n/en';
import { flex, marginRightCss } from '../../../styles/utils';

const clockIconCss = (unsetHeight: boolean) => css`
	${unsetHeight ? null : 'height: 30px;'};
	display: flex;
	align-items: center;
`;

const dateCss = css`
	margin-left: 12px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.lightText};
	display: flex;
	align-items: center;
	gap: 2px;
	svg {
		width: 16px;
		height: 16px;
	}
`;

interface Props {
	unsetHeight?: boolean;
	icon?: Icons;
	children?: ReactNode;
}

export const ClockLabel: FC<Props> = ({ unsetHeight = false, icon, children }: Props) => {
	return (
		<div css={dateCss}>
			<div css={clockIconCss(unsetHeight)}>
				<BnIcon icon={icon ?? Icons.clock} />
			</div>
			{children}
		</div>
	);
};

interface ILastActivityProps {
	time: string;
}

export const LastActivity = ({ time }: ILastActivityProps) => {
	const labels = TranslationEn.customers.customerHeader.lastActivity;
	return (
		<ClockLabel>
			{labels} {dayjs(time).format(EDateTimeFormats.DAY_FORMAT)}
		</ClockLabel>
	);
};

export const LastCheckin = ({ time }: ILastActivityProps) => {
	const labels = TranslationEn.customers.customerHeader.lastCheckin;
	return (
		<ClockLabel icon={Icons.check_in}>
			{labels} {formatDateTime(dayjs(time), DateTimeFormats.DAY_FORMAT)} -{' '}
			{formatDateTime(dayjs(time), DateTimeFormats.H12_AMPM)}
		</ClockLabel>
	);
};

export const AccountActivity = ({
	id,
	lastActivityDate,
	lastCheckinDate,
}: {
	id: number;
	lastActivityDate: string;
	lastCheckinDate?: string;
}) => {
	return (
		<div css={flex}>
			<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
				{`#${id}`}
			</Typography>
			<LastActivity time={lastActivityDate} />
			{lastCheckinDate && <LastCheckin time={lastCheckinDate} />}
		</div>
	);
};
