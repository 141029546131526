import { Address } from '@bondsports/types';
import { RCAddress } from '../../types/entities/coreEntites/Address';

/**
 * Formats a given address object into a string.
 *
 * @param {Address} address - The customer's address.
 * @returns {string} - The formatted address string.
 *
 * @example
 * const sampleAddress: RCAddress = {
 *   streetNum: '123',
 *   street: 'William Street',
 *   city: 'New York',
 *   state: 'NY',
 *   zip: '10001',
 *   country: 'USA'
 * };
 *
 * const formattedString = formatAddress(sampleAddress);
 * Output: '123 William Street, New York, NY 10001, USA'
 */
export function formatAddress(address: Address | RCAddress): string {
	const { streetNum = '', street = '', city = '', state = '', zip = '', country = '' } = address;

	const formattedStreet = [streetNum, street].filter(Boolean).join(' ');
	const formattedStateAndZip = [state, zip].filter(Boolean).join(' ');

	return [formattedStreet, city, formattedStateAndZip, country].filter(Boolean).join(', ');
}

/**
 * Retrieves the address string from the given RCAddress object.
 * @param address - The RCAddress object containing city, street, state, and zip information.
 * @returns The formatted address string.
 * @example
 * ```
 * const address: RCAddress = {
 *     city: 'New York',
 *     street: '123 Main St',
 *     state: 'NY',
 *     zip: '10001'
 * };
 * const formattedAddress = getAddressString(address);
 * console.log(formattedAddress); // Output: "New York, 123 Main St, NY, 10001"
 * ```
 */
export const getAddressString = (address?: RCAddress) => {
	const { city, street, state, zip } = address ?? {};
	return [city, street, state, zip].filter(Boolean).join(', ');
};

/**
 * Retrieves the street number, street, and city from the given RCAddress object and joins them into a string.
 * @param address - The RCAddress object containing street number, street, and city information.
 * @returns The formatted street and city string.
 * @example
 * ```
 * const address: RCAddress = {
 *     streetNum: '123',
 *     street: 'Main St',
 *     city: 'New York'
 * };
 * const streetAndCity = getAddressStreetAndCity(address);
 * console.log(streetAndCity); // Output: "123 Main St New York"
 * ```
 */
export const getAddressStreetAndCity = (address?: RCAddress) => {
	return [address?.streetNum, address?.street, address?.city].filter(Boolean).join(' ');
};

/**
 * Retrieves the zip code and state from the given RCAddress object and joins them into a string.
 * @param address - The RCAddress object containing zip code and state information.
 * @returns The formatted zip code and state string.
 * @example
 * ```
 * const address: RCAddress = {
 *     zip: '10001',
 *     state: 'NY'
 * };
 * const zipAndState = getAddressZipAndState(address);
 * console.log(zipAndState); // Output: "10001 NY"
 * ```
 */
export const getAddressZipAndState = (address?: RCAddress) => {
	return [address?.zip, address?.state].filter(Boolean).join(' ');
};
