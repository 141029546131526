import { css } from '@emotion/react';

export const containerCss = (heightMultiple: number = 1) => css`
	.childClass {
		height: ${140 * heightMultiple}px;
	}
`;

export const iconCss = (color: string) => css`
	color: ${color};
	height: 24px;
	width: 24px;
`;
