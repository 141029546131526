import { useNavigation } from "./useNavigation";
import { ERoutePaths } from "../types/navigation";


export function useNavigatoToStation () {
	const { ngNavigate } = useNavigation();
	const handleStationRedirection = () => {
		ngNavigate(ERoutePaths.STATION, '');
	}

    return {
        handleStationRedirection
    }
}
