import React from 'react';
import { Popup, SelectOptions } from '@bondsports/utils';
import { MoreButton } from './MoreBotton';
import { useRowActionsPopup } from '../../hooks/useRowActionsPopup';
import { RowMangePaymenMethodActionsEnum, TRowActionsPopup } from '../../types';

export const RowActionsPopup = ({ handleReplace, id }: TRowActionsPopup) => {
	const { handleMoreMenu, options } = useRowActionsPopup({ handleReplace, id });

	return (
		<Popup body={<SelectOptions onSelect={(v: RowMangePaymenMethodActionsEnum) => handleMoreMenu(v)} options={options} />}>
			<MoreButton />
		</Popup>
	);
};
