import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { ISlot } from '../../organisms/bo_calendar_slot/types';
import {
	ECalendarMode,
	IEventsGroup,
	IOptions,
	IPartialRanges,
	ISlotEventsToSpaces,
	ISlotsEvents,
	ISlotsEventsAndEventsWithSpaces,
	TOnChangeInCalender,
} from '../types';
import {
	calculateMarginFromHours,
	calculateMarginFromMinutes,
	calculateMarginLeftFromHours,
	calculateMarginLeftFromMinutes,
	oneHourHeight,
	oneHourWidth,
	setNewHourHeight,
	setNewHourWidth,
} from '../utils/timeUtils';
import { usePrevious } from './usePrevious';

dayjs.extend(isoWeek);

export type DateRange = {
	[key: number]: {
		startDate: string;
		endDate: string;
	};
};

export type MiddlewareContent = {
	datesRange: DateRange;
	updateDatesRange: (id: number, startDate: string, endDate: string, onChangeData?: any) => void;
	hasCollision: (event: ISlot, list: ISlot[]) => boolean;
	day: number;
	incrementDay: () => void;
	updateEventsToSpaceByDatesRange: (
		spaces: ISlotEventsToSpaces,
		onChange?: TOnChangeInCalender
	) => {
		[x: number]: ISlot[];
	};
	draggableDatesRange: DateRange;
	setDraggableRange: (id: number, startDate: string, endDate: string) => void;
	options: IOptions;
	setOptions: (options: IOptions) => void;
	partialRanges: IPartialRanges;
	setPartialRanges: (range: IPartialRanges) => void;
	getPartialEvents: (events: ISlot[], from: number, to: number) => ISlot[];
	getTopAndHeight: (
		startDate: number,
		endDate: number,
		horizontal?: boolean,
		debug?: boolean
	) => { top: number; height: number };
	getEventsGroups: (events: ISlot[], horizontal?: boolean) => IEventsGroup[];
	isResizing: boolean;
	setIsResizing: (isResizing: boolean) => void;
	initiateEventsToSpaces: (slots: ISlotsEventsAndEventsWithSpaces) => void;
	updateEventsData: () => void;
	handleDrop: (
		eventId: number | string,
		parentId: number | string,
		spaceId: number | string,
		newDate: string,
		newEndDate?: string
	) => void;
	eventsToSpaces: ISlotEventsToSpaces;
	events: ISlotsEvents;
};

const MiddlewareContext = createContext<MiddlewareContent>({
	datesRange: {},
	updateDatesRange: (id: number, startDate: string, endDate: string, onChangeData?: any) => {},
	hasCollision: (event: any, list: any[]) => false,
	day: 1,
	incrementDay: () => {},
	updateEventsToSpaceByDatesRange: (spaces: ISlotEventsToSpaces, onChange?: TOnChangeInCalender) => ({}),
	draggableDatesRange: {},
	setDraggableRange: (id: number, startDate: string, endDate: string) => {},
	options: {},
	setOptions: (options: IOptions) => ({}),
	partialRanges: {
		vertical: {
			from: 0,
			to: 0,
		},
		horizontal: {
			from: 0,
			to: 0,
		},
	},
	setPartialRanges: (ranges: IPartialRanges) => ({}),
	getPartialEvents: (events: ISlot[], from: number, to: number) => [],
	getTopAndHeight: (startDate: number, endDate: number, horizontal?: boolean) => ({ top: 0, height: 0 }),
	getEventsGroups: (events: ISlot[], horizontal?: boolean) => [],
	isResizing: false,
	setIsResizing: (isResizing: boolean) => {},
	initiateEventsToSpaces: (slots: ISlotsEventsAndEventsWithSpaces) => {},
	updateEventsData: () => {},
	handleDrop: (
		eventId: number | string,
		parentId: number | string,
		spaceId: number | string,
		newDate: string,
		newEndDate?: string
	) => {},
	eventsToSpaces: {},
	events: {},
});

export const MiddlewareContextProvider = ({
	children,
	onChange,
}: {
	children: ReactNode;
	onChange?: TOnChangeInCalender;
}) => {
	const [eventsToSpaces, setEventsToSpaces] = useState<ISlotEventsToSpaces>({});
	const [events, setEvents] = useState<ISlotsEvents>({});

	const initiateEventsToSpaces = useCallback((slots: ISlotsEventsAndEventsWithSpaces) => {
		setEventsToSpaces(slots.eventsToSpaces);
		setEvents(slots.events);
	}, []);

	const handleDrop = useCallback(
		(
			eventId: number | string,
			parentId: number | string,
			spaceId: number | string,
			newDate: string,
			newEndDate?: string
		) => {
			const newEvents = { ...eventsToSpaces };
			const fullEvents = { ...events };

			const dataEvent = newEvents[spaceId]?.find((item: ISlot) => item.id === Number(eventId)) || fullEvents[eventId];

			let event = { ...dataEvent, spaceId };

			if (newDate) {
				const date = dayjs(newDate);
				let startDate = dayjs(event.startDate)
					.set('date', date.get('date'))
					.set('month', date.get('month'))
					.set('year', date.get('year'))
					.format();

				let endDate = dayjs(event.endDate)
					.set('date', date.get('date'))
					.set('month', date.get('month'))
					.set('year', date.get('year'))
					.format();

				// Drag'n'drop slot on daily view
				if (newEndDate) {
					startDate = newDate;
					endDate = newEndDate;
				}

				event = {
					...event,
					startDate,
					endDate,
					startTime: dayjs.utc(startDate).format('HH:mm:ss'),
					endTime: dayjs.utc(endDate).format('HH:mm:ss'),
				};
			}

			// remove event from the previous space
			if (newEvents[parentId]) {
				newEvents[parentId] = newEvents[parentId].filter((item: ISlot) => Number(item.id) !== Number(eventId));
			}

			// add event to the new space
			if (newEvents[spaceId]) {
				newEvents[spaceId] = [...newEvents[spaceId], event];
			} else {
				newEvents[spaceId] = [event];
			}

			setEventsToSpaces(newEvents);

			// update the full events object
			const newEvent = { ...fullEvents[eventId], spaceId };
			fullEvents[eventId] = newEvent;
			setEvents(fullEvents);
		},
		[events, eventsToSpaces]
	);

	const [options, setOptions] = useState<IOptions>({});
	const previousOptions = usePrevious(options);

	useEffect(() => {
		setNewHourHeight(options?.hourSize?.vertical);
		setNewHourWidth(options?.hourSize?.horizontal);
		setDatesRange({});
	}, [options?.hourSize?.vertical, options?.hourSize?.horizontal]);

	useEffect(() => {
		if (options && previousOptions && Object.keys(previousOptions).length > 0 && onChange) {
			const params = {} as {
				startDate?: string;
				endDate?: string;
			};
			const dayShift = options.isSundayFirstDay ? 1 : 0;

			if (options.mode === ECalendarMode.DAILY) {
				params.startDate = dayjs(options.date).format('DD/MM/YYYY');
				params.endDate = dayjs(options.date).format('DD/MM/YYYY');
			} else if (options.mode === ECalendarMode.WEEKLY) {
				params.startDate = dayjs(options.date)
					.isoWeekday(1 - dayShift)
					.format('DD/MM/YYYY');
				params.endDate = dayjs(options.date)
					.isoWeekday(7 - dayShift)
					.format('DD/MM/YYYY');
			} else if (options.mode === ECalendarMode.MONTHLY) {
				const firstDay = dayjs(options.date).date(1);
				let lastDay = dayjs(options.date).date(31);
				while (lastDay.format('MM') !== firstDay.format('MM')) lastDay = lastDay.subtract(1, 'day');

				if (options?.monthlySpecificMonth) {
					params.startDate = firstDay.format('DD/MM/YYYY');
					params.endDate = lastDay.format('DD/MM/YYYY');
				} else {
					params.startDate = dayjs(firstDay)
						.isoWeek(firstDay.isoWeek())
						.isoWeekday(1 - dayShift)
						.format('DD/MM/YYYY');
					params.endDate = dayjs(lastDay)
						.isoWeek(lastDay.isoWeek())
						.isoWeekday(7 - dayShift)
						.format('DD/MM/YYYY');
				}
			}

			onChange?.({
				type: 'NEW_MODE',
				data: {
					mode: options.mode,
					...params,
				},
			});
		}
	}, [options, previousOptions]);

	const [partialRanges, setPartialRanges] = useState<IPartialRanges>({
		vertical: {
			from: 0,
			to: 0,
		},
		horizontal: {
			from: 0,
			to: 0,
		},
	});

	const [datesRange, setDatesRange] = useState<DateRange>({});
	const [isResizing, setIsResizing] = useState(false);

	const updateDatesRange = useCallback(
		(id: number, startDate: string, endDate: string, onChangeData?: any) => {
			if (onChange) {
				onChange(onChangeData);
			}
			setDatesRange({ [id]: { startDate, endDate } });
		},
		[onChange]
	);

	const [draggableDatesRange, setDraggableDatesRange] = useState<DateRange>({});

	const setDraggableRange = useCallback((id: number, startDate: string, endDate: string) => {
		setDraggableDatesRange({ [id]: { startDate, endDate } });
	}, []);

	const hasCollision = (event: ISlot, list: ISlot[] = []) => {
		if (!event) {
			return true;
		}
		if (list.length === 0 || !Array.isArray(list)) return false;
		const slotWithOverallTime = handleOverallTimeForBundledSlots(event);
		const listWithOverallTimes = list.map(slot => handleOverallTimeForBundledSlots(slot));

		const eventStartDate = dayjs(slotWithOverallTime.startTime, 'HH:mm:ss').valueOf();
		const eventEndDate = dayjs(slotWithOverallTime.endTime, 'HH:mm:ss').valueOf();

		return Boolean(
			listWithOverallTimes.find(item => {
				if (slotWithOverallTime.id === item.id) return false;

				const itemStartDate = dayjs(item.startTime, 'HH:mm:ss').valueOf();
				const itemEndDate = dayjs(item.endTime, 'HH:mm:ss').valueOf();

				if (eventEndDate <= itemStartDate || eventStartDate >= itemEndDate) {
					return false;
				}

				return true;
			})
		);
	};

	const handleOverallTimeForBundledSlots = (slot: ISlot): ISlot => {
		const { startTime, endTime, overallStartTime, overallEndTime } = slot;
		const updatedSlot = {
			...slot,
			startTime: overallStartTime ?? startTime,
			endTime: overallEndTime ?? endTime,
		};
		return updatedSlot;
	};

	/** Update events object after change datetime */
	const updateEventsToSpaceByDatesRange = useCallback(
		(prevSpaces: ISlotEventsToSpaces) => {
			const newSpaces = { ...prevSpaces };

			for (const eventId in datesRange) {
				for (const spaceId in newSpaces) {
					const events = newSpaces[spaceId];

					const others = events.filter(item => item.id !== Number(eventId));
					const changeItem = events.find(item => item.id === Number(eventId));

					if (changeItem) {
						const updatedItem = {
							...changeItem,
							startDate: datesRange[eventId].startDate,
							endDate: datesRange[eventId].endDate,
							startTime: dayjs.utc(datesRange[eventId].startDate).format('HH:mm:ss'),
							endTime: dayjs.utc(datesRange[eventId].endDate).format('HH:mm:ss'),
						};

						others.push(updatedItem);
					}

					newSpaces[spaceId] = others;
				}
			}

			return newSpaces;
		},
		[datesRange]
	);

	const [day, setDay] = useState(1);

	const incrementDay = useCallback(() => {
		setDay(currentDay => {
			onChange?.({
				type: 'SCROLL_TO_NEXT_DAY',
				data: {
					date: dayjs(options?.date).add(currentDay, 'day').format('DD/MM/YYYY'),
				},
			});

			return currentDay + 1;
		});
	}, [onChange]);

	useEffect(() => {
		if (options.infiniteScrolling && options.mode === ECalendarMode.DAILY) {
			setDay(1);
			incrementDay();
		} else setDay(1);
	}, [options.mode, options.view, options.date, options.infiniteScrolling]);

	const getPartialEvents = useCallback(
		(events: ISlot[], from: number, to: number) =>
			events.filter(event => {
				const date = dayjs(options?.date);

				const difference = dayjs(event.startDate).diff(date, 'hour');

				return difference >= from && difference <= to;
			}),
		[options?.date]
	);

	/** Calculating top/left position and height/width of events for daily mode */
	const getTopAndHeight = useCallback(
		(startDate: number, endDate: number, horizontal?: boolean, debug?: boolean) => {
			const hour = Number(dayjs(startDate).format('HH'));
			const minutes = Number(dayjs(startDate).format('mm'));

			const diff = dayjs(endDate).diff(dayjs(startDate), 'minutes');

			const h = horizontal ? calculateMarginLeftFromMinutes(diff) : calculateMarginFromMinutes(diff);
			const marginTop = horizontal ? calculateMarginLeftFromHours(hour) : calculateMarginFromHours(hour);
			const marginTopMin = horizontal ? calculateMarginLeftFromMinutes(minutes) : calculateMarginFromMinutes(minutes);

			const total = marginTop + marginTopMin;

			const today = dayjs(options?.date);
			const date = dayjs(startDate);

			const difference = date.diff(today, 'hour');
			const differenceAtDays = Math.abs(Math.floor(difference / 24));

			const size = horizontal ? oneHourWidth : oneHourHeight;

			let shift = size * 24 * differenceAtDays;
			if (difference < 0) shift *= -1;

			return { height: h || 0, top: total + shift || 0 + shift };
		},
		[options?.date]
	);

	/** Making events groups with collision */
	const getEventsGroups = (events: ISlot[], horizontal?: boolean): IEventsGroup[] => {
		let groups = [] as ISlot[][];

		for (let i = 0; i < events?.length; i++) {
			const group = [
				events[i],
				...events.slice(i + 1).filter(event => {
					return hasCollision(events[i], [event]);
				}),
			];
			groups.push(group);
		}

		for (let i = 0; i < groups.length; i++) {
			for (let j = i + 1; j < groups.length; j++) {
				if (groups[i] && groups[j] && groups[i].find(item => groups[j].find(event => item.id === event.id))) {
					groups[i] = Array.from(new Set([...groups[i], ...groups[j]]));
					delete groups[j];
				}
			}
		}

		groups = groups.filter(group => !!group);

		const result: IEventsGroup[] = [];

		groups.forEach(events => {
			if (events.length > 1) {
				const startDates = events.map(event => {
					const splittedStartTime = event.startTime.split(':');

					return dayjs(dayjs.utc(event.startDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')
						.hour(+splittedStartTime[0])
						.minute(+splittedStartTime[1])
						.toDate()
						.getTime();
				});
				const endDates = events.map(event => {
					const splittedEndTime = event.endTime.split(':');
					return dayjs(dayjs.utc(event.endDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')
						.hour(+splittedEndTime[0])
						.minute(+splittedEndTime[1])
						.toDate()
						.getTime();
				});

				const minStart = Math.min(...startDates);
				const maxEnd = Math.max(...endDates);

				const { top, height } = getTopAndHeight(minStart, maxEnd, horizontal);

				const groups =
					events.length > 2 ? getSubGroups(events) : [[events[0]], [events[1]]].sort((a, b) => a[0].id - b[0].id);

				result.push({
					items: events.sort((a, b) => a.id - b.id),
					groups,
					top,
					height,
				});
			} else if (events.length === 1) result.push({ items: events, item: events[0] });
		});

		return result;
	};

	/** Making groups inside group of events with collision */
	const getSubGroups = useCallback((events: ISlot[]) => {
		const groups = [] as ISlot[][];

		events.forEach(event => {
			let added = false;
			for (let i = 0; i < groups.length; i++) {
				if (!hasCollision(event, groups[i])) {
					groups[i].push(event);
					added = true;
					break;
				}
			}
			if (!added) groups.push([event]);
		});

		for (let i = 0; i < groups.length; i++) {
			groups[i] = groups[i].sort((a, b) => a.id - b.id);
		}

		return groups.sort((a, b) => a[0].id - b[0].id);
	}, []);

	const updateEventsData = useCallback(() => {
		setEvents(prevEvents => {
			const newEvents = { ...prevEvents };

			for (const eventId in datesRange) {
				newEvents[eventId] = {
					...newEvents[eventId],
					startDate: datesRange[eventId].startDate,
					endDate: datesRange[eventId].endDate,
				};
			}

			return newEvents;
		});

		setEventsToSpaces(prevSpaces => updateEventsToSpaceByDatesRange(prevSpaces));
	}, [updateEventsToSpaceByDatesRange]);

	useEffect(() => {
		updateEventsData();
	}, [datesRange]);

	return (
		<MiddlewareContext.Provider
			value={{
				datesRange,
				updateDatesRange,
				hasCollision,
				day,
				incrementDay,
				updateEventsToSpaceByDatesRange,
				draggableDatesRange,
				setDraggableRange,
				options,
				setOptions,
				partialRanges,
				setPartialRanges,
				getPartialEvents,
				getTopAndHeight,
				getEventsGroups,
				isResizing,
				setIsResizing,
				initiateEventsToSpaces,
				updateEventsData,
				handleDrop,
				events,
				eventsToSpaces,
			}}
		>
			{children}
		</MiddlewareContext.Provider>
	);
};

export const useMiddlewareContext = () => useContext(MiddlewareContext);
