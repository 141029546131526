/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor } from '../../../types';
import { Typography } from '../../../atoms';
import { Fragment, useMemo } from 'react';
import { useColors } from '../../../hooks/useColors';
import { formatDateTimeTz } from '../utils/date-formatting.util';
import { Status } from '../../table/cellTypes/Status';
import { CellMapper } from '../../table/row';
import { PaymentMethodMapper } from '../payments/PaymentMethodMapper';
import { AlignType } from '../../table/types';
import { installmentCss, statusCss } from './style';
import { Checkbox } from '../../../components/Checkbox';

const flex = css`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const spanCss = (disabled = false) => css`
	height: 1px;
	opacity: ${disabled ? 0.35 : 1};
`;

interface InstallmentProps {
	state: any;
	isChecked?: boolean;
	disabled?: boolean;
	handleSelect: (installment: any) => void;
}

export const InstallmentRadio = (props: InstallmentProps) => {
	const { colors } = useColors();
	const failedAndDisabled = props.state?.status === 'future' && props.disabled;
	const Method = useMemo(() => {
		return <InstallmentLine {...props} disabled={failedAndDisabled} />;
	}, [props.state.type, props.isChecked, props.disabled]);

	return (
		<div
			data-aid="installment-Line"
			css={installmentCss(colors, failedAndDisabled)}
			onClick={() => props.handleSelect(props.state)}
		>
			{Method}
		</div>
	);
};

const InstallmentLine = ({ state, isChecked, disabled }: InstallmentProps) => {
	const color = disabled ? ETypographyColor.disabled : ETypographyColor.primary;

	return (
		<Fragment>
			<div css={flex}>
				<span css={spanCss(disabled)}>
					<Checkbox key={state?.id} color={color} checked={isChecked} functionDisable />
				</span>
				<Typography type={ETypography.body2} color={color}>
					{formatDateTimeTz().formatDate(state?.originalPlannedDate)}
				</Typography>
			</div>
			<Typography type={ETypography.body2} css={statusCss} color={color}>
				<CellMapper column={{ id: 'price', type: 'currency', styling: { color }, label: '' }} state={state} />
			</Typography>
			<Typography type={ETypography.body1} color={color}>
				<PaymentMethodMapper color={color} state={state} />
			</Typography>
			<Typography type={ETypography.body2} css={statusCss} color={color}>
				<Status
					column={{
						id: 'status',
						type: 'status',
						align: AlignType.LEFT,
					}}
					row={state}
				/>
			</Typography>
		</Fragment>
	);
};
