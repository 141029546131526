import React from 'react';
import { PromoCodeSelectProps } from '../../types/promoCodes';
import { AddPublicPrivateNotes } from '../../../../molecules/add-public-private-notes';
import { PromoCodeSelect } from './promo-code-select';
import { useNotes } from '../../hooks/useNotes';
import { Column } from '../../../../atoms/layoutPrimitives';
import { columnCss } from '../../styles';

export const PromoCodeContent: React.FC<PromoCodeSelectProps> = ({ onChange, initialState, fetchData }) => {
	const { handleNotesChange } = useNotes(
		{ privateNote: initialState?.privateNote, publicNote: initialState?.publicNote },
		onChange
	);

	return (
		<Column fullWidth gap={'2.5rem'} styles={columnCss}>
			<PromoCodeSelect onChange={onChange} initialState={initialState} fetchData={fetchData} />
			<AddPublicPrivateNotes
				onChange={handleNotesChange}
				initialPublicNote={initialState?.publicNote}
				initialPrivateNote={initialState?.privateNote}
			/>
		</Column>
	);
};
