import React, { Fragment, useState } from 'react';
import { ArchiveSlotInvoiceActionEnum } from '../../../molecules/ActionSelect';
import { IPayingUser, IPaymentMethod } from '../../../molecules/PaymentMethodPicker';
import { PaymentMethodStep } from './PaymentMethodStep';
import { SummaryStep } from './SummaryStep';

interface RefundStepsProps {
	step?: number;
	amountToRefund: number;
	usedPaymentMethods: IPaymentMethod[];
	lineItemCount?: number;
	refundResponse?: IPaymentMethod[];
	goToResourceType?: 'program' | 'membership';
	handleSelectedMethods: (val: IPaymentMethod[]) => void;
	initialMail?: string;
	handleSelectAction: (val: ArchiveSlotInvoiceActionEnum) => void;
	sendReceiptAction: (type: 'email' | 'sms', toAddress: string) => void;
	handleDisableButton?: (val: boolean) => void;
	goToResource?: () => void;
	disableActions?: boolean;
	isPublicInvoice?: boolean;
	invoiceOwner?: IPayingUser;
}

export const RefundSteps = ({
	step,
	amountToRefund,
	usedPaymentMethods,
	lineItemCount,
	refundResponse,
	goToResourceType,
	handleSelectedMethods,
	handleDisableButton,
	initialMail,
	handleSelectAction,
	sendReceiptAction,
	goToResource,
	disableActions = false,
	isPublicInvoice = false,
	invoiceOwner,
}: RefundStepsProps) => {
	const [action, setAction] = useState<ArchiveSlotInvoiceActionEnum>();
	const handleActionSelect = (val: ArchiveSlotInvoiceActionEnum) => {
		setAction(val);
		handleSelectAction(val);
	};

	switch (step) {
		case 0:
			return (
				<PaymentMethodStep
					amountToRefund={amountToRefund}
					usedPaymentMethods={usedPaymentMethods}
					handleSelectAction={handleActionSelect}
					handleSelectedMethods={handleSelectedMethods}
					disableActions={disableActions}
					invoiceOwner={invoiceOwner}
					handleDisable={handleDisableButton}
					isPublicInvoice={isPublicInvoice}
				/>
			);
		case 1:
			return (
				<SummaryStep
					lineItemCount={lineItemCount}
					refundResponse={refundResponse}
					onChangeSendReceipt={sendReceiptAction}
					goToResource={goToResource}
					initialMail={initialMail}
					action={action}
					goToResourceType={goToResourceType}
					paymentMethods={usedPaymentMethods}
					invoiceOwner={invoiceOwner}
				/>
			);
		default:
			return <Fragment />;
	}
};
