import { useState } from 'react';
import { paymentApi } from '@app/react/lib/api/paymentApi';
import { FutureInstallmentDto, PaginationMetaDto } from '@bondsports/types';
import { useInstallmentsHookReturn } from '../../types/chargeInstallment.types';

const DEFAULT_PRICE = 0;

export const useInstallments = (): useInstallmentsHookReturn => {
	const [scheduledPayments, setScheduledPayments] = useState<FutureInstallmentDto[]>([]);
	const [selectedInstallmentsPrice, setSelectInstallmentsPrice] = useState<number>(DEFAULT_PRICE);
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

	const [meta, setMeta] = useState<PaginationMetaDto>({
		totalItems: 1,
		itemsPerPage: 1,
		totalPages: 1,
		currentPage: 1,
	});
	const handleSelectAll = () => {
		setSelectedIds([]);
		setSelectInstallmentsPrice(DEFAULT_PRICE);
		setIsSelectAll(!isSelectAll);
	};
	
	const getScheduledPayments = async (organizationId, invoiceId, userId, page) => {
		const futurePayments = await fetchScheduledPayments(organizationId, invoiceId, userId, page);
		setScheduledPayments(futurePayments.data.sort((a: any, b: any) => new Date(a?.originalPlannedDate).getTime() - new Date(b?.originalPlannedDate).getTime()));
		setMeta(futurePayments.meta);
	};
	const handlePriceOnSelect = (id: number, add = false) => {
		const currentInstallment = scheduledPayments.find((installment) => installment.id === id);
			if (add){
				setSelectInstallmentsPrice(selectedInstallmentsPrice + currentInstallment.price);
			} else {
				setSelectInstallmentsPrice(selectedInstallmentsPrice - currentInstallment.price);
			}
	};

	const fetchScheduledPayments = async (organizationId: number, invoiceId: number, userId: number, page: number): Promise<{meta: PaginationMetaDto, data: FutureInstallmentDto[]}> => {
		return await paymentApi.getFutureScheduledPayments(
			organizationId,
			invoiceId,
			userId,
			page
		);
	};
	
	const handleSelect = (id: number) => {
		const isSelected = selectedIds.some((selectedId: any) => selectedId === id);
		if (isSelected) {
			handlePriceOnSelect(id);
			setSelectedIds(selectedIds.filter((selectedId: any) => selectedId !== id));
		} else {
			handlePriceOnSelect(id, true);
			setSelectedIds([...selectedIds, id]);
		}
	};

	const isApplyPmDisabled = meta.totalItems === selectedIds.length || (isSelectAll && !selectedIds.length);

	return {
		getScheduledPayments,
		meta,
		isApplyPmDisabled,
		scheduledPayments,
		handleSelect,
		isSelectAll,
		selectedInstallmentsPrice,
		handleSelectAll,
		selectedIds,
	};
};
