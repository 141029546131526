/** @jsx jsx */
/** @jsxFrag React.Fragment */
import * as React from 'react';
import { jsx } from '@emotion/react';
import { Button } from 'ui';
import { useEffect, useState } from 'react';
import { AttendanceDto, AttendanceEntityTypeEnum } from '@bondsports/types';
import { useAttendance } from '@app/react/hooks/useAttendance';
import { flex, gapCss, uppercaseCss } from '@app/react/styles/utils';
import { BnIcon, ENotificationType, Icons } from '@bondsports/utils';
import { colors_v2 } from '@app/react/styles/theme';
import { useNotification } from '@app/react/hooks/useNotification';
import { getFullName } from '@app/react/lib/utils';
import { EventWithAttendanceDto } from '@bondsports/types';
import { TCustomer } from '@app/react/types/customers';
import { TranslationEn } from '@assets/i18n/en';
import { addToDateHours, convertTimestampFromTimezoneToTimezone, DateTimeFormats, DEFAULT_TIMEZONE, isEarlier, isLater } from '@bondsports/date-time';

const ALLOW_CHECK_IN_HOURS_BEFORE = 12;

const labels = TranslationEn.activities.attendance;

interface AttendancePanelProps {
	event: EventWithAttendanceDto;
	customerState: TCustomer;
}

/**
 * The AttendancePanel consists of an affordance to check-in or undo check-in for a user,
 * and an indicator of whether the user is checked-in or not.
 *
 * There are four hierarchical states:
 * - User NOT checked-in...
 *   - they can check-in (show CHECK IN button)
 *   - they cannot check-in (show nothing)
 * - User IS checked-in...
 *   - they can undo check-in (show ✅ and UNDO button)
 *   - they cannot undo check-in (show ✅ without button)
 */
export const AttendancePanel: React.FC<AttendancePanelProps> = ({ event, customerState }) => {
	const [attendanceForCustomer, setAttendanceForCustomer] = useState<AttendanceDto | undefined>(null);
	const [canCheckIn, setCanCheckIn] = useState<boolean>(false);
	const [canUndoCheckIn, setCanUndoCheckIn] = useState<boolean>(false);
	const { createAttendance, createAttendanceSuccess, deleteAttendance, deleteAttendanceSuccess } = useAttendance();
	const { setToastNotification } = useNotification();

	// from the initial attendance list, find the attendance matching the current customer
	// The API should only return the attendance for the current customer, but this is still good practice.
	useEffect(() => {
		const attendance = event.attendance.find(attendance => attendance.userId === customerState.entityId);
		setAttendanceForCustomer(attendance);
	}, [event, customerState]);

	// after deleting an attendance, reset the attendance for the customer (updating the UI)
	useEffect(() => {
		if (deleteAttendanceSuccess) {
			setAttendanceForCustomer(null);
			setToastNotification(
				labels.eventUndoCheckinSuccess(event.title, getFullName(customerState)),
				ENotificationType.success
			);
		}
	}, [deleteAttendanceSuccess, event.title]);

	// after creating an attendance, set this as the new attendance for the customer (updating the UI)
	useEffect(() => {
		if (createAttendanceSuccess) {
			setAttendanceForCustomer(createAttendanceSuccess);
			setToastNotification(
				labels.eventCheckinSuccess(event.title, getFullName(customerState)),
				ENotificationType.success
			);
		}
	}, [createAttendanceSuccess, event.title]);

	// Update state to affect the affordance shown to the user.
	useEffect(() => {
		const eventStartDate = convertTimestampFromTimezoneToTimezone(
			event.startDate,
			DEFAULT_TIMEZONE,
			event.timezone,
			DateTimeFormats.YYYY_MM_DD_T_HH_MM_SS
		);

		const startDate = new Date(eventStartDate);


		const eventEndDate = convertTimestampFromTimezoneToTimezone(
			event.endDate,
			DEFAULT_TIMEZONE,
			event.timezone,
			DateTimeFormats.YYYY_MM_DD_T_HH_MM_SS
		);

		const endDate = new Date(eventEndDate);

		const nowDate = new Date();
	
		if (attendanceForCustomer) {
			setCanCheckIn(false);
			// Can only UNDO check-in when event end time > now (ie. event not over)
			setCanUndoCheckIn(isLater(endDate, nowDate));
		} else {
			// Can only check in when event start time is < 12 hours from now AND event end time is > now (ie. event not over)
			const checkingOpenWindow = addToDateHours(nowDate, ALLOW_CHECK_IN_HOURS_BEFORE);
			const allowToCheckIn = isEarlier(startDate, checkingOpenWindow) && isLater(endDate, nowDate);
			setCanCheckIn(allowToCheckIn);
			setCanUndoCheckIn(false);
		}
	}, [attendanceForCustomer, event, customerState]);

	const handleCheckin = (eventId: number, userId: number, organizationId: number) => {
		createAttendance(customerState, organizationId, userId, { entityId: eventId, entityType: AttendanceEntityTypeEnum.EVENT });
	};

	const handleUndoCheckin = (attendance: AttendanceDto) => {
		deleteAttendance(attendance.organizationId, attendance.id);
	};

	return (
		<>
			{attendanceForCustomer ? (
				<div css={[flex, gapCss(4)]}>
					{canUndoCheckIn && <UndoCheckinButton handleUndoCheckin={() => handleUndoCheckin(attendanceForCustomer)} />}
					<BnIcon icon={Icons.check_in} color={colors_v2.t_green} />
				</div>
			) : (
				canCheckIn && (
					<div>
						<CheckinButton
							handleCheckin={() => handleCheckin(event.id, customerState.entityId, customerState.organizationId)}
						/>
					</div>
				)
			)}
		</>
	);
};

const CheckinButton = ({ handleCheckin }) => {
	return (
		<Button size="sm" variant="secondary" onClick={handleCheckin}>
			<span css={uppercaseCss}>{labels.checkIn}</span>
		</Button>
	);
};

const UndoCheckinButton = ({ handleUndoCheckin }) => {
	return (
		<Button size="sm" variant="icon" onClick={handleUndoCheckin}>
			<span css={uppercaseCss}>{labels.undo}</span>
		</Button>
	);
};
