/** @jsxRuntime classic */
/** @jsx jsx */
import { useTheme, jsx, SerializedStyles } from '@emotion/react';
import React, { useMemo, Fragment, ReactNode } from 'react';
import { Popup } from '../Popup';
import { StyledSelect, StyledValue, selectedTagsCss } from './style';
import { BnArrowDown, BnArrowUp } from '../../icons';
import { ISelectOptions, SelectOptions } from '../Options_v2';
import { capitalize } from '../../functions';
import { Tooltip } from '../Tooltip';
import { getIcon } from '../../icons';
import { InputContainer } from '../Input/style';
import cx from 'classnames';
import { ErrorMessage } from '../ErrorMessage';
import { Icons } from '../../types/theme';
import { BnIcon } from '../icons_v2';
import { IGroup } from '../Options_v2/types';
import { i18n } from '../../lib/i18n';
import { SelectedTags } from './SelectedTags';
import { LabelTag } from '../../molecules/LabelTag';
import { flexCss, fullWidthCss } from '../../styles/utils';
import { SearchInput } from './SearchInput';
import { Typography } from '../../atoms';

export interface IOption_V2 {
	value: string | number;
	label: string;
	icon?: Icons;
	key?: string;
	isDisabled?: boolean;
}

export interface ISelect_V2 {
	options: IOption_V2[] | IGroup[];
	placeholder?: string;
	value?: string;
	selectedLabel?: string | string[] | number;
	onSelecte?: (val: string | string[] | number[] | (string | number)[]) => void;
	isMultiple?: boolean;
	sizer?: TSizeEnum;
	insideLeftLabel?: string;
	label?: string;
	disabled?: boolean;
	info?: string;
	error?: string;
	helper?: string;
	helperContent?: string;
	clear?: () => void;
	noLabel?: boolean;
	hideError?: boolean;
	onChange?: (v: unknown) => void;
	css?: SerializedStyles;
	enableSelectAll?: boolean;
	disableSelectAll?: boolean;
	selectAllLabel?: string;
	unsetOptionsWidth?: boolean;
	isTopLayer?: boolean;
	isDisplayCounter?: boolean;
	isWithTags?: boolean;
	handleType?: (val: string) => void;
	maxSelections?: number;
	isGroups: boolean;
	lastOptionElementRef?: (node: Element) => void;
	isLoading?: boolean;
	maxHeight?: number;
	isError?: boolean;
	onClick?: () => void;
	isAllSelected?: boolean;
	handleSelectAll?: () => void;
	isDisplayValue?: boolean;
	marginTop?: number;
	NoResultsComponent?: JSX.Element;
	tags?: ReactNode;
}
import { useSelect } from './useSelect';
import { SizeEnum, TSizeEnum } from '../../lib/constants';

const MAX_HEIGHT = 400;

export const Select_v2 = ({
	options,
	onSelecte = val => {},
	value = '',
	placeholder = '',
	sizer = SizeEnum.SMALL,
	insideLeftLabel = '',
	label = '',
	noLabel = false,
	disabled = false,
	isMultiple = false,
	info = '',
	helper = '',
	hideError = false,
	error = '',
	helperContent = '',
	enableSelectAll = false,
	disableSelectAll,
	selectAllLabel = '',
	handleSelectAll,
	unsetOptionsWidth = false,
	CustomSelectOptionsComponent = undefined,
	clear,
	isTopLayer = false,
	isDisplayCounter = false,
	isWithTags = false,
	handleType,
	maxSelections,
	isGroups,
	lastOptionElementRef,
	isLoading,
	isError,
	maxHeight = MAX_HEIGHT,
	selectedLabel,
	isDisplayValue = true,
	onClick,
	isAllSelected,
	marginTop,
	tags,
	...rest
}: ISelect_V2 &
	React.SelectHTMLAttributes<HTMLSelectElement> & {
		CustomSelectOptionsComponent?: ({
			options,
			onSelect,
			value,
			autoFocus,
			isMultiple,
			enableSelectAll,
			selectAllLabel,
			onSelectAll,
		}: ISelectOptions) => JSX.Element;
	}) => {
	const {
		handleOnChange,
		isOpen,
		visited,
		ref,
		selectedValues,
		isMaxError,
		handleSetOpen,
		toggleIsOpen,
		handleRemoveOption,
	} = useSelect({ value, maxSelections, onSelecte, isWithTags, options, isGroups });

	const colors = useTheme();

	const isDispalyError = (visited && !maxSelections) || (maxSelections && isMaxError);

	const newOptions = isGroups
		? options
		: (options as IOption_V2[])?.map(opt => {
				return {
					...opt,
					value: String(opt.value),
				};
		  });

	const style = rest.style;

	const chosen = isGroups
		? []
		: (options as IOption_V2[]).filter(option => String(option.value) === String(value)).map(option => option.label);

	let multipleChosen: string[];
	if (isMultiple) {
		try {
			multipleChosen = isGroups
				? []
				: (options as IOption_V2[])
						.filter(option => (value as unknown as any[]).map(v => String(v)).includes(String(option.value)))
						.map(option => option.label);
		} catch {
			(err: any) => {
				console.log(err);
			};
		}
	}
	const onSelectAll = () => {
		if (handleSelectAll) {
			handleSelectAll();
		} else {
			defaultHandleSelectAll();
		}
	};
	const defaultHandleSelectAll = () => {
		onSelecte(
			isGroups
				? []
				: (options as IOption_V2[]).map(item => {
						return item.value;
				  })
		);
	};

	const handleRemoveAll = () => {
		onSelecte([]);
	};

	const onChange = (val: string) => {
		handleOnChange(val, isMultiple);
	};

	const basicSelectProps = {
		options: newOptions,
		onSelect: onChange,
		value,
		isGroups,
	};

	const [SelectOptionsComponent, selectProps] = CustomSelectOptionsComponent
		? [CustomSelectOptionsComponent, { ...basicSelectProps }]
		: [
				SelectOptions,
				{
					...basicSelectProps,
					onSelectAll,
					handleRemoveAll,
					isMultiple,
					enableSelectAll,
					disableSelectAll,
					selectAllLabel,
					autoFocus: !handleType,
					lastOptionElementRef,
					isLoading,
					isError,
					isAllSelected,
					NoResultsComponent: rest.NoResultsComponent,
				},
		  ];

	const selectedValue = useMemo(() => {
		if (!String(value)) {
			return handleType ? '' : placeholder;
		}
		if (isMultiple) {
			return isDisplayCounter ? (
				<LabelTag state={{ label: `${value.length} ${i18n.selcted}`, value }} size="XS" />
			) : (
				multipleChosen?.join(', ')
			);
		}
		return capitalize(chosen[0]);
	}, [value]);

	const selectedLabelToDisplay = useMemo(() => {
		if (!selectedLabel) {
			return handleType ? '' : placeholder;
		}
		if (isMultiple) {
			return isDisplayCounter ? (
				<LabelTag
					handleRemove={clear}
					state={{ label: `${selectedLabel} ${i18n.selcted}`, value }}
					size={SizeEnum.EXTRA_SMALL}
				/>
			) : (
				multipleChosen?.join(', ')
			);
		}
		return selectedLabel;
	}, [selectedLabel]);

	const memoizedArrow = useMemo(() => (isOpen ? <BnArrowUp /> : <BnArrowDown />), [isOpen]);

	const displayText = useMemo(
		() => (isDisplayValue && selectedValue ? selectedValue : selectedLabelToDisplay),
		[isDisplayValue, selectedValue, selectedLabelToDisplay]
	);

	const handleOnClck = () => {
		toggleIsOpen();
		if (onClick) {
			if (onClick) {
				onClick();
			}
		}
	};

	return (
		<Fragment>
			<InputContainer
				className={cx(
					{
						M: sizer === 'M',
						S: sizer === 'S',
					},
					rest.className
				)}
				style={style}
				theme={{ ...colors }}
				disabled={disabled}
				marginTop={marginTop}
			>
				{(label || noLabel) && (
					<div className="label" data-aid="index-Select_v2">
						<div>
							{capitalize(label)}
							{rest.required && !noLabel && ' *'}
						</div>
						{info && (
							<Tooltip isTopLayer content={info}>
								{getIcon('info', 'l')}
							</Tooltip>
						)}
					</div>
				)}
				<div data-aid="Select_v2-select-container" className="select-container">
					<Popup
						isTopLayer={isTopLayer}
						fullWidth={!unsetOptionsWidth}
						disableAutoTrigger
						body={disabled ? undefined : <SelectOptionsComponent {...selectProps} />}
						unsetOptionsWidth={unsetOptionsWidth}
						onOpenChange={handleSetOpen}
						padding={'0'}
						borderRadius={0}
						trigger={isOpen}
						maxHeight={maxHeight}
					>
						<StyledSelect
							className={cx({
								placeholder: !String(value),
								disabled: disabled,
							})}
							theme={{ ...colors }}
							tabIndex={0}
							ref={ref}
							onClick={() => {
								if (ref.current === document.activeElement) {
									handleOnClck();
								}
							}}
							onFocus={e => {
								handleOnClck();
							}}
						>
							{insideLeftLabel && (
								<Typography color="secondary" type="body2" className="insideLeftLabel">
									{insideLeftLabel}
								</Typography>
							)}
							<div css={[flexCss, fullWidthCss]}>
								{displayText && (
									<StyledValue
										className={cx(isDisplayCounter ? 'tagValue' : 'value', {
											error: error && isDispalyError,
											disabled,
										})}
										theme={{ ...colors }}
									>
										{displayText}
									</StyledValue>
								)}
								{handleType && ((!selectedValue && clear && !isMultiple) || isMultiple) && (
									<SearchInput
										handleOpenPopup={handleSetOpen}
										onType={handleType}
										placeholder={!String(value) && !selectedLabel ? placeholder : ''}
										disabled={disabled}
									/>
								)}
							</div>
							<div data-aid="Select_v2-button" className="button">
								{memoizedArrow}
							</div>
						</StyledSelect>
					</Popup>
					{clear && !isMultiple && (
						<div data-aid="Select_v2-remove" className="remove" css={flexCss} onClick={disabled ? undefined : clear}>
							<BnIcon icon={Icons.remove} />
						</div>
					)}
				</div>
				{!hideError && <div style={{ minHeight: '15px' }}>{isDispalyError && <ErrorMessage error={error} />}</div>}
				{helper && (
					<div data-aid="Select_v2-helper" className="helper">
						{helperContent && <Tooltip content={helperContent}>{getIcon('info', 'r')}</Tooltip>}
						<div>{capitalize(helper)}</div>
					</div>
				)}
			</InputContainer>
			{isWithTags && (
				<div data-aid="Select_v2-selected" css={selectedTagsCss}>
					{tags ? (
						tags
					) : (
						<SelectedTags selectedValues={selectedValues} handleRemove={!disabled ? handleRemoveOption : undefined} />
					)}
				</div>
			)}
		</Fragment>
	);
};
