/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { UserPaymentMethodOption } from '@bondsports/types';
import { ICustomer } from '../../../../../types/customers';
import { wrapContainerCss } from '../../../../payments/Charge/CashOrDocument/styles';
import { PlusButton } from '@app/react/components/shared/PlusButton';
import { ETypography, ETypographyColor, ModalWindow, Typography } from '@bondsports/utils';
import { Charge } from '@app/react/components/payments/Charge';
import { TranslationEn } from '@assets/i18n/en';
import { EChargeInnerStep } from '@app/react/types/payment';

interface IProps {
	paymentMethodsOptions: UserPaymentMethodOption[];
	userId: number;
	isOpen?: boolean;
	setIsOpen?: (isOpen: boolean) => void;
	customer: ICustomer;
	handleToggleClose: () => void;
}

const addMethodLineCss = css`
	display: flex;
	cursor: pointer;
	align-items: center;
	underline: 1px solid #e0e0e0;
`;

const AddMethodWrapper = ({ customer, userId, isOpen, setIsOpen, paymentMethodsOptions, handleToggleClose }: IProps) => {
	const closeModal = () => {
		setIsOpen(false);
	}
	const openModal = () => {
		setIsOpen(true);
	}
	return (
        <div data-aid="add-method-container" css={wrapContainerCss}>
			<ModalWindow isShowing={isOpen} toggle={closeModal}>
				<Charge
					organizationId={customer?.organizationId}
					toggle={handleToggleClose}
					userId={userId}
					totalAmount={0}
					customer={customer}
					paymentMethods={paymentMethodsOptions}
					isScheduled={false}
					initialStep={EChargeInnerStep.NEW_CARD}
					showFeeWarning={false}
				/>
			</ModalWindow>
			<div css={addMethodLineCss} onClick={openModal} data-aid="add-method-button">
				<PlusButton onAdd={() => {}} />
				<Typography color={ETypographyColor.primary} type={ETypography.body2Link}>
					{TranslationEn.customers.managePaymentMethods.addNewMethod}
				</Typography>
			</div>
    	</div>
	);
};

export { AddMethodWrapper };
