import { BACKOFFICE_DATA_ADDED, COGNITO_USERNAME, CONSUMER_DATA_ADDED } from "./constants"

export interface VerificationResult {
    email: string,
    exp: number,
    id?: string,
    role?: string,
    firstName?: string,
    lastName?: string,
    [COGNITO_USERNAME]: string,
    [CONSUMER_DATA_ADDED]: boolean,
    [BACKOFFICE_DATA_ADDED]: boolean,
    isAdmin?: boolean
}