import * as React from 'react';

export const CheckIn = (props: any) => (
	<svg
		data-aid="svgCheckIn"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M19 18V12C19 11.448 19.447 11 20 11C20.553 11 21 11.448 21 12V18C21 19.654 19.654 21 18 21H6C4.346 21 3 19.654 3 18V6C3 4.346 4.346 3 6 3H12C12.553 3 13 3.448 13 4C13 4.552 12.553 5 12 5H6C5.448 5 5 5.449 5 6V18C5 18.551 5.448 19 6 19H18C18.552 19 19 18.551 19 18Z"
			fill="currentColor"
		/>
		<path
			d="M19 18V12C19 11.448 19.447 11 20 11C20.553 11 21 11.448 21 12V18C21 19.654 19.654 21 18 21H6C4.346 21 3 19.654 3 18V6C3 4.346 4.346 3 6 3H12C12.553 3 13 3.448 13 4C13 4.552 12.553 5 12 5H6C5.448 5 5 5.449 5 6V18C5 18.551 5.448 19 6 19H18C18.552 19 19 18.551 19 18Z"
			fill="currentColor"
		/>
		<path
			d="M20.905 3.40337C20.4505 2.91126 19.6673 2.86267 19.1556 3.29483L11.8732 12.1735L10.1658 10.325C9.71141 9.83283 8.92813 9.78423 8.41643 10.2165C7.90466 10.6487 7.85817 11.398 8.31259 11.8902L10.8428 14.6299C11.07 14.876 11.3795 15.0112 11.696 15.0308C12.0126 15.0505 12.3364 14.9546 12.5923 14.7384L20.8011 5.0771C21.3129 4.64488 21.3594 3.89554 20.905 3.40337Z"
			fill="currentColor"
		/>
	</svg>
);
