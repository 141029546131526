import { ColorInput, TinyColor } from '@ctrl/tinycolor';
import { css } from '@emotion/react';

export function setOpacity(
	// Any valid color that TinyColor accepts
	color: ColorInput,
	// Opacity level (from 0 to 1)
	opacity: number
) {
	return new TinyColor(color).setAlpha(opacity).toRgbString();
}

export const breakpoints = {
	// Smallest mobile screen we support
	// Anything below that is unsupported
	mobile: 360,
	mobilePx: '360px',
	// Smallest tablet screen we support
	tablet: 768,
	tabletPx: '768px',
	// Smallest desktop screen we support
	desktop: 1280,
	desktopPx: '1280px',
};

export const mediaUp = (screenSize: string) => `@media (min-width: ${screenSize})`;
export const mediaDown = (screenSize: string) => `@media (max-width: ${screenSize})`;
export const mobileOnly = mediaDown(`${breakpoints.tablet - 1}px`);
export const notMobile = mediaUp(`${breakpoints.tablet}px`);
export const printOnly = `@media print`;

export const printFont = css`
	* {
		font-family: sans-serif !important;
		color: black !important;
	}
`;

export const flexColumn = css`
	display: flex;
	flex-direction: column;
`;
export const flex = css`
	display: flex;
	align-items: center;
`;

export const flexCss = css`
	display: flex;
`;

export const flexBetweenCss = css`
	${flexCss};
	justify-content: space-between;
`;

export const fullWidthCss = css`
	width: 100%;
`;

export const gapCss = (gap: number) => css`
	gap: ${gap}px;
`;

export const marginTopCss = (marginTop: number) => css`
	margin-top: ${marginTop}px;
`;

export const marginBottomCss = (margin: number) => css`
	margin-bottom: ${margin}px;
`;

export const marginRightCss = (marginTop: number) => css`
	margin-right: ${marginTop}px;
`;

export const marginLeftCss = (margin: number) => css`
	margin-left: ${margin}px;
`;

export const svgCss = (width?: string, height?: string, color?: string) => css`
	${color && `color: ${color}`};
	svg {
		${width && !height ? `width: ${width}; height: auto;` : height && !width ? `height: ${height}; width: auto;` : ``};
		${width && height && `width: ${width}; height: ${height};`};
	}
`;

export const flexCenterCss = css`
	${flexCss};
	align-items: center;
`;

export const flexWrapCss = css`
	display: flex;
	flex-wrap: wrap;
`;

export const paddingCss = (padding: number | string) => css`
	padding: ${padding}px;
`;

export const fitContentCss = css`
	width: fit-content;
`;

export const flexColCss = css`
	${flexCss};
	flex-direction: column;
`;

export const flexRowCss = css`
	${flexCss};
	flex-direction: row;
`;

export const alignItemsCss = (how: string) => css`
	align-items: ${how};
`;

export const justifyContentCss = (how: string) => css`
	justify-content: ${how};
`;

export const flexGrowCss = (howMuch: number) => css`
	flex-grow: ${howMuch};
`;

export const centerTextCss = css`
	text-align: center;
`;

export const paddingBottomCss = (padding: number) => css`
	padding-bottom: ${padding}px;
`;

export const paddingTopCss = (padding: number) => css`
	padding-top: ${padding}px;
`;

export const paddingLeftCss = (padding: number) => css`
	padding-left: ${padding}px;
`;

export const paddingRightCss = (padding: number) => css`
	padding-right: ${padding}px;
`;

export const widthCss = (width: number) => css`
	width: ${width}%;
`;

export const fullHeightCss = css`
	height: 100%;
`;

export const heightPxCss = (pixels: number) => css`
	height: ${pixels}px;
`;

export const widthPxCss = (pixels: number) => css`
	width: ${pixels}px;
`;

export const maxHeightCss = (howMuch: string = 'fit-content') => css`
	max-height: ${howMuch};
`;

export const maxWidthCss = (howMuch: string) => css`
	max-width: ${howMuch};
`;

// Refer to https://stackoverflow.com/questions/10743763/word-wrap-break-word-does-not-work-in-this-example/58685854#58685854 to understand the solution
export const textEllipsisOverflowCss = css`
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
`;

export const separatorCss = (where: string, color: string, width: number = 1) => css`
	border-${where}: ${width}px solid ${color};
`;

export const displayCss = (display: string) => css`
	display: ${display};
`;

export const minHeightCss = (height: string = 'fit-content') => css`
	min-height: ${height};
`;

export const centeredContainerCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;
