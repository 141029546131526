import { add, isEarlier, isSameDate, sortByStartDate, subtract } from '../../../../lib/dates';
import { OrderByEnum } from '@bondsports/types';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { first, last } from 'lodash';
import { FetchFunc, MustHaveStartAndEndDates, Page, PagesState } from '../types/types';
import { getStartOfDay } from '@bondsports/date-time';

const ITEMS_PER_PAGE = 5;

enum ReducerActionsEnum {
	INSERT = 'insert',
	PREVIOUS_PAGE = 'previous',
	NEXT_PAGE = 'next',
}

const pagesReducer = <T extends MustHaveStartAndEndDates>(
	state: PagesState<T>,
	action: { type: ReducerActionsEnum; previous?: T[]; current?: T[]; next?: T[] }
): PagesState<T> => {
	switch (action.type) {
		case ReducerActionsEnum.INSERT: {
			const page: Page<T> = action.current ? new Page(action.current) : state.current;

			if (action.previous) {
				page.previous = new Page(action.previous);
				page.previous.next = page;
			}

			if (action.next) {
				page.next = new Page(action.next);
				page.next.previous = page;
			}

			state.current = page;
			break;
		}
		case ReducerActionsEnum.NEXT_PAGE:
			state.current = state.current.next;
			break;
		case ReducerActionsEnum.PREVIOUS_PAGE:
			state.current = state.current.previous;
			break;
	}

	return {
		...state,
	};
};

function getLatestDate<T extends MustHaveStartAndEndDates>(items: T[]): Date {
	if (!items.length) return null;

	const sortedItems = sortByStartDate(items);
	const latestStartDate = first(sortedItems).endDate;

	return subtract(latestStartDate, 1, 'second');
}

function getEarliestDate<T extends MustHaveStartAndEndDates>(items: T[]): Date {
	if (!items.length) return null;

	const sortedItems = sortByStartDate(items);
	const earliestStartDate = last(sortedItems).startDate;

	return earliestStartDate;
}

export const useDynamicList = <T extends MustHaveStartAndEndDates>(
	rangeStartDate: Date,
	rangeEndDate: Date,
	fetcher: FetchFunc<T>
) => {
	const [isLoading, setLoading] = useState(true);
	const [state, dispatch] = useReducer(pagesReducer<T>, {});
	const controllerRef = useRef<AbortController>();
	const [error, setError] = useState();

	const handleError = useCallback(err => {
		setError(err.err || err.message);
	}, []);

	const fetchPrevious = useCallback(
		(date: Date, itemsPerPage = ITEMS_PER_PAGE, options?: { signal: AbortSignal }) =>
			fetcher(itemsPerPage, subtract(rangeStartDate, 1, 'day'), date, OrderByEnum.DESC, options),
		[fetcher, rangeStartDate]
	);

	const fetchNext = useCallback(
		(date: Date, itemsPerPage = ITEMS_PER_PAGE, options?: { signal: AbortSignal }) =>
			fetcher(itemsPerPage, date, add(rangeEndDate, 1, 'day'), OrderByEnum.ASC, options),
		[fetcher, rangeEndDate]
	);

	const fetchMorePastItems = useCallback(
		async (items: T[], slice: T[], missingCount: number): Promise<void> => {
			const earliestDate: Date = getEarliestDate(slice);
			const response: T[] = await fetchPrevious(earliestDate, missingCount, {
				signal: controllerRef.current.signal,
			});

			dispatch({
				type: ReducerActionsEnum.INSERT,
				previous: [...items.slice(missingCount, items.length), ...response],
			});
		},
		[fetchPrevious]
	);

	const filterNextSessions = (current, next) => {
		const currentIds = new Set(current.map(item => item.id ?? 0));
		const filteredNext = next.filter(item => !currentIds.has(item.id));
		return filteredNext;
	};
	const removeDuplicatesMaintainOrder = inputArray => {
		const uniqueIds = new Set();
		const resultArray = [];
		return inputArray.filter(item => {
			if (!uniqueIds.has(item.id)) {
				uniqueIds.add(item.id);
				resultArray.push(item.id);
				return true;
			}
			return false;
		});
	};

	const initialFetch = useCallback(async () => {
		controllerRef.current = new AbortController();

		try {
			const now: Date = getStartOfDay(new Date(), true) as Date;
			let current: T[] = [];
			if (!isSameDate(rangeEndDate, now) && isEarlier(now, rangeEndDate)) {
				current = await fetcher(ITEMS_PER_PAGE, now, rangeEndDate, OrderByEnum.ASC, {
					signal: controllerRef.current.signal,
				});
				current.reverse();
			}

			let missingCount: number = ITEMS_PER_PAGE - current.length;
			const nextRecsNeeded = ITEMS_PER_PAGE + missingCount;
			const latestDate: Date = getLatestDate(current) ?? rangeEndDate;

			let next: T[] = [];
			if (!isSameDate(latestDate, now) && isEarlier(now, latestDate)) {
				next = await fetchNext(latestDate, nextRecsNeeded, {
					signal: controllerRef.current.signal,
				});
				next.reverse();

				// Taking the corresponding amount of future items from the previous page
				// and push it to the current page.
				const nextSlice: T[] = next.splice(next.length * -1, next.length < missingCount ? next.length : missingCount);
				current = [...nextSlice, ...current];
				next = filterNextSessions(current, next);
				current = removeDuplicatesMaintainOrder(current);
			}

			missingCount = ITEMS_PER_PAGE - current.length;
			const prevRecsNeeded = ITEMS_PER_PAGE + missingCount;
			const erliestDay: Date = getEarliestDate(current) ?? now;
			let previous: T[] = await fetchPrevious(erliestDay, prevRecsNeeded, {
				signal: controllerRef.current.signal,
			});

			// Taking the corresponding amount of past items from the previous page
			// and push it to the current page.
			const prevSlice: T[] = previous.splice(0, missingCount);
			current = [...current, ...prevSlice];
			current = removeDuplicatesMaintainOrder(current);
			previous = filterNextSessions(current, previous);

			dispatch({ type: ReducerActionsEnum.INSERT, next, current, previous });
			setLoading(false);
		} catch (err) {
			handleError(err);
			setLoading(false);
		}
	}, [fetcher, rangeEndDate, fetchPrevious, fetchNext, handleError]);

	useEffect(() => {
		initialFetch().then();

		return () => controllerRef.current?.abort();
	}, [initialFetch]);

	const onPrevious = () => {
		const previous: Page<T> = state.current?.previous;
		const hasMorePreviousPages = !!previous?.previous?.page?.length;
		dispatch({ type: ReducerActionsEnum.PREVIOUS_PAGE });
		// Only fetch the new previous page if there is a chance to have more past items.
		if (previous.page.length === ITEMS_PER_PAGE && !hasMorePreviousPages) {
			controllerRef.current = new AbortController();

			const earliestDate: Date = getEarliestDate(previous.page);
			fetchPrevious(earliestDate, ITEMS_PER_PAGE, { signal: controllerRef.current.signal })
				.then(res =>
					dispatch({
						type: ReducerActionsEnum.INSERT,
						previous: res,
					})
				)
				.catch(err => handleError(err));
		}
	};

	const onNext = () => {
		const next: Page<T> = state.current.next;
		const isNeedNextPage = !!next?.next?.page?.length; //next?.page?.length <= ITEMS_PER_PAGE ?? false;
		const latestDate: Date = getLatestDate(next.page);
		dispatch({ type: ReducerActionsEnum.NEXT_PAGE });
		// Only fetch the new next page if there is a chance to have more future items.
		if (next.page.length === ITEMS_PER_PAGE && !isNeedNextPage) {
			controllerRef.current = new AbortController();

			fetchNext(latestDate, ITEMS_PER_PAGE, { signal: controllerRef.current.signal })
				.then(res => dispatch({ type: ReducerActionsEnum.INSERT, next: res.reverse() }))
				.catch(err => handleError(err));
		}
	};

	return {
		previousPage: state.current?.previous?.page,
		currentPage: state.current?.page,
		nextPage: state.current?.next?.page,
		onPrevious,
		onNext,
		isLoading,
		error,
	};
};
