import { AlertsStatusEnum, TCustomer } from '@app/react/types/customers';
import { useEffect, useState } from 'react';
import {
	generateMembershipsState,
	generatePaymentsAlerts,
	getMemberships,
	getPaymentsAlerts,
	membershipInitialState,
} from './utils';
import { useCustomer } from '../../hooks/useCustomer';
import { PaymentAlert } from '../../../Identification/sections/Payments/types';
import { IExpandedMembershipMemberData, Invoice, NoteDto, PaginationResultDto } from '@bondsports/types';
import { IMeta } from '@app/react/lib/api/membershipApi';
import { IErrorArr } from '@app/react/types/errors';
import { IMemershipsData } from './types';
import { customersApi } from '@app/react/lib/api/customersApi';
import { TGetMembershipsResposnse } from '@app/react/types/membershipMember';
import { useFetch } from '@app/react/hooks/useFetch';
import { EventWithAttendanceDto } from '@bondsports/types';
import { DEFAULT_TIMEZONE, getStartOfDay } from '@bondsports/date-time';
import { addDays } from 'date-fns';

export const EVENTS_UNTIL_DAYS_FROM_NOW = 7;

export const useOverview = (customerState: TCustomer) => {
	const { navigateToMembershipById, navigateInvoiceById } = useCustomer();
	const [paymentAlerts, setPaymentAlerts] = useState<PaymentAlert[]>([]);
	const [isLoadingAlerts, setIsLoadingAlerts] = useState(true);
	const [isFetchingMembershipsFaild, setIsFetchingMembershipsFaild] = useState(false);
	const [memershipsDataState, setMemershipsDataState] = useState<IMemershipsData>(membershipInitialState);
	const [isFetchingPaymentsFaild, setIsFetchingPaymentsFaild] = useState(false);
	const [totalPaymentsAlerts, setTotalPaymentsAlerts] = useState(0);
	const [isExistingInvoices, setIsExistingInvoices] = useState(false);
	const [isLoadingMemberships, setIsLoadingMemberships] = useState(false);
	const [isLoadingEvents, setIsLoadingEvents] = useState(false);
	const [events, setEvents] = useState<EventWithAttendanceDto[]>([]);
	const [isLoadingEventsFailed, setIsLoadingEventsFailed] = useState(false);
	const [alertNotes, setAlertNotes] = useState<NoteDto[]>();

	const alertStatus =
		!customerState.waiverSignedDate || totalPaymentsAlerts > 0
			? AlertsStatusEnum.ISSUES
			: AlertsStatusEnum.GOOD_STANGING;

	const handleMbershipSuccess = (memberships: IExpandedMembershipMemberData[]) => {
		initiateMemberShipsState(memberships);
	};

	const handlePaymentsSuccess = async (paymentsResponse: { data: Invoice[]; meta: IMeta }) => {
		const totalItems = paymentsResponse.meta.totalItems;
		if (!totalItems) {
			const isExistingInvoicesResponse = await customersApi.getInvoicesByCustomerId({
				customerId: customerState.id,
				itemsPerPage: 1,
			});
			if ((isExistingInvoicesResponse as IErrorArr).err) {
				handleFetchingInvoicesError();
			} else {
				setIsExistingInvoices(isExistingInvoicesResponse.meta.totalItems > 0);
			}
		} else {
			setIsExistingInvoices(true);
			await initiatePaymentsState(paymentsResponse.data, totalItems);
		}
	};

	const handleMembershipsError = () => {
		setIsFetchingMembershipsFaild(true);
	};

	const handleFetchingInvoicesError = () => {
		setIsFetchingPaymentsFaild(true);
	};

	const initiateMemberShipsState = (memberships: IExpandedMembershipMemberData[]) => {
		const membershipState = generateMembershipsState(memberships, navigateToMembershipById);
		setMemershipsDataState(membershipState);
	};

	const initiatePaymentsState = async (payments: Invoice[], totalItems: number) => {
		const paymentAlerts = await generatePaymentsAlerts(customerState.organizationId, payments, navigateInvoiceById);
		setPaymentAlerts(paymentAlerts);
		setTotalPaymentsAlerts(totalItems);
	};

	const handleGetMembershipResponse = (membershipsResposnse: TGetMembershipsResposnse) => {
		if ((membershipsResposnse as IErrorArr).err) {
			handleMembershipsError();
		} else {
			handleMbershipSuccess(
				(
					membershipsResposnse as {
						data: IExpandedMembershipMemberData[];
					}
				).data
			);
		}
	};

	const handleGetPaymentsAlertsResponse = async (paymentsResponse: { data: Invoice[]; meta: IMeta } | IErrorArr) => {
		if ((paymentsResponse as IErrorArr).err) {
			handleFetchingInvoicesError();
		} else {
			await handlePaymentsSuccess(paymentsResponse as { data: Invoice[]; meta: IMeta });
		}
	};

	const handleGetEventsResponse = (eventsResponse: PaginationResultDto<EventWithAttendanceDto>) => {
		setEvents(eventsResponse.data);
	}

	const handleFetchData = async () => {
		setIsLoadingAlerts(true);
		setIsLoadingMemberships(true);
		getMemberships(customerState.id, customerState.organizationId).then(membershipsResposnse => {
			handleGetMembershipResponse(membershipsResposnse);
			setIsLoadingMemberships(false);
		});
		getPaymentsAlerts(customerState.id).then(async paymentsAlertsResponse => {
			await handleGetPaymentsAlertsResponse(paymentsAlertsResponse);
			setIsLoadingAlerts(false);
		});
		setIsLoadingEvents(true);
		const eventsFrom = getStartOfDay(new Date(), true, DEFAULT_TIMEZONE) as Date;
		const eventsUntil = addDays(eventsFrom, EVENTS_UNTIL_DAYS_FROM_NOW);
		customersApi.getCustomersEvents({
			customerId: customerState.id,
			organizationId: customerState.organizationId,
			startDateAfter: eventsFrom,
			startDateBefore: eventsUntil
		}).then(eventsResponse => {
			handleGetEventsResponse(eventsResponse);
		}).catch(() => {
			setIsLoadingEventsFailed(true);
		}).finally(() => {
			setIsLoadingEvents(false);
		});
	};

	useEffect(() => {
		handleFetchData();
	}, [customerState.id, customerState.organizationId]);

	useFetch(
		async ({ signal }) => {
			const data = await customersApi.getCustomerNotes(customerState.organizationId, customerState.id, true, 3, 1, {
				signal,
			});

			return data.data;
		},
		[customerState.organizationId, customerState.id],
		{
			onSuccess: alerts => setAlertNotes(alerts),
		}
	);

	return {
		paymentAlerts,
		isExistingInvoices,
		isLoadingAlerts,
		isFetchingMembershipsFaild,
		memershipsDataState,
		alertStatus,
		isFetchingPaymentsFaild,
		totalPaymentsAlerts,
		isLoadingMemberships,
		isLoadingEvents,
		isLoadingEventsFailed,
		events,
		alertNotes,
	};
};
