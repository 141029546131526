import { useCallback, useState } from 'react';
import { IOption } from '../types';
import { IUseSelectedValueReturn } from '../components/Select_v2/types';

/**
 * Custom hook to manage selection logic.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Function} [params.onSelectCallback] - Optional callback function to be called when an item is selected.
 * @param {IOption[]} params.items - Array of options available for selection.
 * @param {IOption} [params.initialValue] - Initial value for the selection.
 * @param {Function} params.resetSearch - Function to reset the search.
 *
 * @returns {Object} - The value of the selected item, a function to handle selection, and a function to clear the selection.
 */
export const useSelectedValue = ({
	onSelectCallback,
	items,
	initialValue,
	resetSearch,
}: {
	onSelectCallback?: (value: IOption) => void;
	items: IOption[];
	initialValue?: IOption;
	resetSearch: () => void;
}): IUseSelectedValueReturn => {
	const [value, setValue] = useState<IOption>(initialValue || { label: '', value: '' });

	const onSelect = useCallback(
		(selectedValue: string) => {
			const label = items?.find(option => String(option.value) === String(selectedValue))?.label || '';
			const newValue = { label, value: selectedValue };
			setValue(newValue);
			if (onSelectCallback) onSelectCallback(newValue);
		},
		[onSelectCallback, items]
	);

	const handleClear = () => {
		onSelect('');
		resetSearch();
	};

	return {
		value,
		onSelect,
		handleClear,
	};
};
