/** @jsx jsx */

import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { CheckboxButton } from '../utils';
import { flexCol, flex } from '../../../styles/utils';
import { colors } from '../../../styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import { BnIcon, Icons } from '@bondsports/utils';
import { UserPaymentMethod } from '@bondsports/types';

const ContainerCss = css`
	${flexCol};
	padding: 2rem 10rem;
	justify-content: center;
	align-items: center;
`;

const wrapContainerCss = css`
	${flex};
	max-width: 300px;
	flex-wrap: wrap;
	justify-content: center;
`;

const topPartCss = css`
	${flexCol};
	margin: 0;
`;

const iconCss = css`
	width: 14px;
	height: 14px;
	margin-right: 0.5rem;
`;

const primeryTitle = css`
	font-size: 1.4rem;
	line-height: 130%;
	text-align: center;
	color: ${colors.brandPrimary};
`;

interface Props {
	ACHMethods: UserPaymentMethod[];
	selectedCard: string;
	setSelectedCard: Dispatch<SetStateAction<string>>;
	setFullSelectedCard?: (val) => void;
}

export const ACH = ({ ACHMethods, selectedCard, setSelectedCard, setFullSelectedCard }: Props) => {
	const ACHMethodsDisplay = useMemo(() => {
		return ACHMethods?.filter(Boolean).map(ACH => {
			return {
				...ACH,
				last4: ACH.ccLast4,
			};
		});
	}, [ACHMethods]);

	const handlePickACH = (ACH: UserPaymentMethod) => {
		setSelectedCard(ACH.paymentMethodId);
		if (setFullSelectedCard) {
			setFullSelectedCard(ACH);
		}
	};

	return (
		<div data-aid="ach" css={ContainerCss}>
			{ACHMethodsDisplay?.length ? (
				<React.Fragment>
					<div css={topPartCss}>
						<div css={primeryTitle}>{TranslationEn.payments.useExisting}</div>
						<div css={wrapContainerCss}>
							{ACHMethodsDisplay.map((ACH, index) => {
								return (
									<CheckboxButton key={index} onClick={() => handlePickACH(ACH as unknown as UserPaymentMethod)} active={selectedCard === ACH.paymentMethodId}>
										<BnIcon icon={Icons.bank} css={iconCss} />
										{ACH.last4}
									</CheckboxButton>
								);
							})}
						</div>
					</div>
				</React.Fragment>
			) : (
				<React.Fragment />
			)}
		</div>
	);
};
