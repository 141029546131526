/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { Conflict } from 'ui';
import { flexCol } from '@app/react/styles/utils';
import { StationSetupLink } from '../../shared/StationSetupLink';
import { FAQLink } from '../../shared/FAQLink';

const errMessage = css`
	font-weight: 500;
	font-size: 1.4rem;
	font-family: Montserrat;
	line-height: 1.7rem;
`;

const errorContainer = css`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const iconCss = css`
	color: ${ETypographyColor.red};
	margin-right: 0.5rem;
`;

interface IFailedSwipeConnection {
    labels: Record<string, string>;
}

export const FailedSwipeConnection = ({
	labels
}: IFailedSwipeConnection) => {
	return (
        <div css={errorContainer}>
        <Conflict className="h-5 w-5 text-error" css={iconCss} />
        <div css={flexCol}>
			<Typography type={ETypography.caption} color={ETypographyColor.red} css={errMessage}>{labels.title}</Typography>
			<Typography type={ETypography.caption} color={ETypographyColor.primary} css={errMessage}>{labels.faqPrefix}<FAQLink /></Typography>
			<Typography type={ETypography.caption} color={ETypographyColor.primary} css={errMessage}>{labels.faqSuffix}<StationSetupLink /></Typography>
        </div>
    </div>
	);
};
