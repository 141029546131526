/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { Button, ETypography, ETypographyColor, Typography, SizeEnum } from '@bondsports/utils';
import { flexCol, flexEndCss, marginTop, paddingCss } from 'app/react/styles/utils';
import { colors } from 'app/react/styles/theme';
import { ReactNode } from 'react';

const flexColContainer = css`
	${flexCol};
	align-items: center;
`;
const headerCss = css`
	text-align: center;
	padding-top: 36px;
	padding-bottom: 24px;
	border-bottom: 1px solid ${colors.borderSeperator};
	${flexColContainer};
`;

const footerCss = css`
	padding: 16px 40px;
	border-top: 1px solid ${colors.borderSeperator};
	${flexEndCss};
`;

const subButtonCss = css`
	margin-right: 16px;
`;

const mainCss = css`
	padding: 24px 40px;
	flex-grow: 1;
	overflow-y: scroll;
	height: 200px;
`;

const containCss = (height?: number) => css`
	max-width: 500px;
	display: flex;
	flex-direction: column;
	height: ${height ?? 600}px;
`;

const secondButtonCss = css`
	text-transform: uppercase !important;
`;

interface Props {
	mainButton: string;
	title?: string;
	subTitle?: string;
	htmlSubTitle?: ReactNode;
	main: ReactNode;
	subButton?: string;
	mainAction: () => void;
	secondButton?: string;
	secondAction?: () => void;
	subAction?: () => void;
	customIsProcessing?: boolean;
	disableMainAction?: boolean;
	height?: number;
	showHeader?: boolean;
	showFooter?: boolean;
}
export const CustomModalBody = ({
	mainButton,
	title,
	subTitle,
	htmlSubTitle,
	main,
	subButton,
	mainAction,
	subAction,
	secondButton,
	secondAction,
	disableMainAction = false,
	height,
	showHeader = true,
	showFooter = true,
}: Props) => {
	return (
		<div data-aid="CustomModalBody" css={containCss(height)}>
			{showHeader && (
				<div css={headerCss}>
					{title && (
						<Typography color={ETypographyColor.primary} type={ETypography.h4}>
							{title}
						</Typography>
					)}
					{subTitle && (
						<div css={marginTop(8)}>
							<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
								{subTitle}
							</Typography>
						</div>
					)}
					{htmlSubTitle && (
						<div css={[marginTop(8), paddingCss('0px 20px 0 20')]}>
							<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
								{htmlSubTitle}
							</Typography>
						</div>
					)}
				</div>
			)}

			<div css={mainCss}>{main}</div>

			<div css={footerCss}>
				{secondButton && (
					<Button
						data-aid="button-CustomModalBody-second"
						theme="basic"
						sizer={SizeEnum.SMALL}
						type="button"
						onClick={secondAction}
						css={secondButtonCss}
					>
						{secondButton}
					</Button>
				)}
				<Button
					data-aid="button-CustomModalBody-sub"
					theme="secondary"
					sizer={SizeEnum.SMALL}
					type="button"
					onClick={subAction}
					css={subButtonCss}
				>
					{subButton}
				</Button>
				<Button
					data-aid="button-CustomModalBody-main"
					theme="primary"
					sizer={SizeEnum.SMALL}
					type="button"
					onClick={mainAction}
					disabled={disableMainAction}
				>
					{mainButton}
				</Button>
			</div>
		</div>
	);
};
