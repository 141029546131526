import * as Cookies from 'js-cookie';
import jwtDecode from "jwt-decode";
import { USER_DETAILS_COOKIE_NAME, BACKOFFICE_DATA_ADDED, VerificationResult } from './types';

const SECONDS_TO_MILISECONDS_MULTIPLIER = 1000;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CookiesWorker {
    public static hasBondUserDetails(): boolean {
        return !!CookiesWorker.getBondUserDetails();
    }

    public static getBondUserEmail(): string | undefined {
        const userDetails = CookiesWorker.getBondUserDetails();
        console.log('User email', userDetails?.email);

        return userDetails?.email;
    }

    public static getBondUserDetails(): VerificationResult | undefined {
        const token = CookiesWorker.getBondUserDetailsCookie();
        console.log('JWT token', token);

        if (!token) {
            return;
        }

        try {
            const decodedToken: any = jwtDecode(token);
            console.log('JWT decoded token', decodedToken);

            if (!CookiesWorker.validateUserDetails(decodedToken)) {
                return;
            }

            return decodedToken;
        } catch (e) {
            console.info('Error while decoding token', e);
            return;
        }
    }

    private static validateUserDetails(userDetails: VerificationResult): boolean {
        const nowDate = new Date();
        const tokenExpirationDate = new Date(userDetails.exp * SECONDS_TO_MILISECONDS_MULTIPLIER);

        return tokenExpirationDate >= nowDate;
    }

	public static hasBondUserDetailsSubmitted(): boolean {
        const userDetails = CookiesWorker.getBondUserDetails();
        console.log(`User ${!!userDetails?.[BACKOFFICE_DATA_ADDED] ? 'has' : 'has not'} submitted additional backoffice data`);

        return !!userDetails?.[BACKOFFICE_DATA_ADDED];
    }

    private static getBondUserDetailsCookie(): string | undefined {
        return Cookies.get(USER_DETAILS_COOKIE_NAME);
    }
}