import { CustomDurationTypeEnum } from './rentalProduct';

export enum DurationMinutesEnum {
	HalfHour = 30,
	OneHour = 60,
	OneAndHalfHour = 90,
	TwoHours = 120,
	Custom = -1,
	FullDay = 1440,
}

export interface DurationPicker {
	duration?: DurationMinutesEnum;
	customDurationType?: CustomDurationTypeEnum;
	customDurationQuantity?: number;
}
