import { css } from '@emotion/react';
import { ThemeColors as colors_v2 } from '../../../styles/theme';

export const errorRowCss = (isShow: boolean) => css`
	max-height: ${isShow ? '50px' : '0'};
	opacity: ${isShow ? '1' : '0'};
	margin: 0 0 ${isShow ? '1rem' : 0} 0;
	transition: max-height 0.5s ease-in-out, opacity 0.4s ease-in-out, margin 0.5s ease-in-out;

	svg {
		height: 24px;
		width: auto;
		color: ${colors_v2.bg_error};
	}
`;

export const removeMarginsCss = css`
	.S {
		margin-top: 0;
	}
`;

export const otherContentStyles = css`
	justify-content: center;
	min-height: 280px;
`;

export const columnCss = css`
	${removeMarginsCss};
	align-self: flex-start;
`;
