export enum ResourceNameTypeEnum {
	EVENT = 'event',
	VENUE = 'venue',
	TEAM = 'team',
	LEAGUE = 'league',
	USER = 'user',
	ORGANIZATION = 'organization',
	APP = 'app',
	FEED = 'feed',
	MATCH = 'match',
	ROUND = 'round',
	PORTAL = 'portal',
	LEAGUE_SEASON = 'season', // TODO: rename to league_season
	TOURNAMENT = 'tournament',
	MEMBERSHIP = 'membership',
	DIVISION = 'division',
	GAMESLOT = 'gameslot',
	SPACE = 'space',
	RESERVATION = 'reservation',
	INVOICE = 'invoice',
	CUSTOMER = 'customer',
	PACKAGE = 'package',
	FACILITY = 'facility',
	PROGRAM = 'program',
	PROGRAM_SEASON = 'program_season',
	PRODUCT = 'product',
	GROUP = 'group',
	VARIANT = 'variant',
	SLOT = 'slot',
	ADDON = 'addon',
	GOODS = 'goods',
	FEE = 'fee',
	PAYMENT_METHOD = 'payment_method',
	DISCOUNT = 'discount',
	ACTIVITY = 'activity',
	PROGRAM_TYPE = 'program_type',
	TAX = 'tax',
}

export enum DELIVERY_METHOD {
	EMAIL = 'email',
	SMS = 'sms'
}

export type DeliveryMethodOptions = DELIVERY_METHOD.EMAIL | DELIVERY_METHOD.SMS;


export enum NotificationDeliveryChannel {
	EMAIL = 'EMAIL',
	// SMS is not yet implemented.  Error will be returned if attempted.
	SMS = 'SMS',
  }
