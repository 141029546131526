import * as React from 'react';
import { useRecoilState } from 'recoil';
import { FormValues, MainDetailsFormValues } from '../forms/settings/facility/types';
import { facilityApi } from '../lib/api/facilityApi';
import { facilityStore } from '../stores/facilityStore';
import { FacilityUpdateStatusEnum, ICreateFacilityObj } from '../types/facility';
import { getLocationByLatLng, getServerFormatAddress, getTimezone } from '../components/shared/GoogleMap/utils';
import { useHistory, useParams } from 'react-router-dom';
import { useNotification } from './useNotification';
import { isEmptyObject } from '../lib/utils';
import { ENotificationType } from '../stores/baseStore';
import { GoogleAddress } from '../types/address';

export const useFacility = () => {
	const [facilityState, setFacilityState] = useRecoilState(facilityStore.facilityState);
	const [facilityId, setFacilityId] = useRecoilState(facilityStore.facilityId);
	const [isFetchingFacility, setFetchingFacilityState] = useRecoilState(facilityStore.isFacilityFetching);
	const [isRefreshFacilitiesNeeded, setIsRefreshFacilitiesNeeded] = useRecoilState(
		facilityStore.isRefreshFacilitiesNeeded
	);
	const [resources, setResources] = useRecoilState(facilityStore.facilityResources);
	const { setPopupNotification } = useNotification();
	const history = useHistory();
	const [location, setLocation] = useRecoilState(facilityStore.facilityLocation);

	const initiateFacilityData = (data: any) => {
		setFacilityState(data);
		setFacilityId(data.id);
		setFetchingFacilityState(false);
		setLocation(undefined);
	};

	const fetchFacilityData = (
		organizationId: number,
		facilityId: number,
		handleError: (message: string, turnOffProcessing?: boolean) => void
	) => {
		setFetchingFacilityState(true);
		facilityApi
			.getFacilityById(organizationId, facilityId)
			.then(res => {
				if (res.data) {
					initiateFacilityData(res.data);
				} else if (res.message) {
					setFetchingFacilityState(false);
					handleError(String(res.message), false);
				}
			})
			.catch(err => {
				setFetchingFacilityState(false);
				handleError(err.message ? String(err.message) : String(err), false);
			});
	};

	const fetchFacilityDataIfEmpty = (
		organizationId: number,
		facilityID: number,
		handleError: (message: string, turnOffProcessing?: boolean) => void
	) => {
		if (isEmptyObject(facilityState) || facilityID !== facilityState.id) {
			fetchFacilityData(organizationId, facilityID, handleError);
		}
	};

	const fetchFacilityResources = (
		organizationId: number,
		facilityId: number,
		handleError: (message: string, turnOffProcessing?: boolean) => void
	) => {
		facilityApi
			.getResourcesByFacilityID(organizationId, facilityId)
			.then(res => {
				if (res.data) {
					setResources(res.data);
				} else if (res.message) {
					setFetchingFacilityState(false);
					handleError(String(res.message), false);
				}
			})
			.catch(err => {
				setFetchingFacilityState(false);
				handleError(err.message ? String(err.message) : String(err), false);
			});
	};

	const initLocationIfEmpty = () => {
		if (facilityState.address && !location) {
			getLocationByLatLng(
				facilityState.address.geo.coordinates[1],
				facilityState.address.geo.coordinates[0],
				facilityState.address
			).then(res => {
				if (res) {
					setLocation(res);
				}
			});
		}
	};

	const navigateToFacility = () => {
		history.push(`/facilities/${facilityId}`);
	};

	const navigateToFacilityById = (id: number) => {
		history.push(`/facilities/${id}`);
	};

	const successCallback = (
		organizationID: number,
		facilityId: number,
		successMessage: string,
		handleError: (message: string, turnOffProcessing?: boolean) => void,
		withNavigation = true
	) => {
		fetchFacilityData(organizationID, facilityId, handleError);
		setIsRefreshFacilitiesNeeded(true);
		if (withNavigation) {
			navigateToFacilityById(facilityId);
		}
		setPopupNotification(successMessage, ENotificationType.action_confirmed);
	};

	const updateFacilityStatus = (
		organizationId: number,
		statue: FacilityUpdateStatusEnum,
		handleError: (message: string, turnOffProcessing?: boolean) => void,
		successMessage: string,
		withNavigation = true
	) => {
		setFetchingFacilityState(true);
		facilityApi
			.updateFacilityStatus(organizationId, facilityId, statue)
			.then(response => {
				if (response.data) {
					successCallback(organizationId, facilityId, successMessage, handleError, withNavigation);
				} else if (response.message) {
					setFetchingFacilityState(false);
					handleError(String(response.message), false);
				}
			})
			.catch(err => {
				setFetchingFacilityState(false);
				handleError(err.message ? String(err.message) : String(err), false);
			});
	};

	const prepareData = async (values: FormValues, organizationId: number, isSendAddress = true) => {
		const resourcesIds = values.resourcesIds?.map(resourcesId => Number(resourcesId));
		let address = {};
		let openingTimes = [];
		let timezone;

		values.timeSlots.forEach((timeSlot, index) => {
			const on = timeSlot.on || [];
			if (index === 0 && on.length === 0) {
				return [];
			}
			const slotOpeningTimes = on.map(day => {
				return { dayOfWeek: day, open: timeSlot.startTime, close: timeSlot.endTime };
			});
			openingTimes = [...openingTimes, ...slotOpeningTimes];
		});

		if (isSendAddress) {
			address = await getServerFormatAddress(values.location);
			timezone = await getTimezone(values.location.value.description);
			if (!timezone) {
				return null;
			}
			return {
				name: values.name,
				sports: values.sports,
				description: values.shortDescription,
				longDescription: values.longDescription,
				info: values.rulesInfo,
				address: address,
				openingTimes,
				organizationId: organizationId,
				timezone,
				amenities: values.amenities,
				resourcesIds,
			} as ICreateFacilityObj;
		}

		return {
			name: values.name,
			sports: values.sports,
			description: values.shortDescription,
			longDescription: values.longDescription,
			info: values.rulesInfo,
			openingTimes,
			organizationId: organizationId,
			timezone,
			amenities: values.amenities,
			resourcesIds,
		} as ICreateFacilityObj;
	};

	const prepareDetailsData = async (
		values: MainDetailsFormValues,
		organizationId: number,
		initialLocation: GoogleAddress
	) => {
		let address = {};
		let timezone: string;

		if (values.location !== initialLocation) {
			address = await getServerFormatAddress(values.location);
			timezone = await getTimezone(values.location.value.description);
			if (!timezone) {
				return null;
			}
			return {
				name: values.name,
				description: values.shortDescription || '',
				longDescription: values.longDescription || '',
				info: values.rulesInfo || '',
				address: address,
				organizationId: organizationId,
				timezone,
			};
		}

		return {
			name: values.name,
			description: values.shortDescription || '',
			longDescription: values.longDescription || '',
			info: values.rulesInfo || '',
			organizationId: organizationId,
		};
	};

	return {
		fetchFacilityData,
		facilityState,
		facilityId,
		setFacilityId,
		isFetchingFacility,
		prepareData,
		fetchFacilityResources,
		resources,
		setResources,
		navigateToFacility,
		navigateToFacilityById,
		successCallback,
		fetchFacilityDataIfEmpty,
		initLocationIfEmpty,
		location,
		prepareDetailsData,
		updateFacilityStatus,
		isRefreshFacilitiesNeeded,
		setIsRefreshFacilitiesNeeded,
	};
};
