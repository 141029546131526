import { FeeDto, UserPaymentMethodOption, PaymentStatusEnum, UserPaymentMethod } from '@bondsports/types';
import { PaymentMethodTypeEnum } from '@bondsports/utils';

export function convertToPaymentStatus(paymentStatus: PaymentStatusEnum, isVoided: boolean, isRefunded: boolean) {
	if (isRefunded) {
		return PaymentStatusEnum.REFUNDED;
	}

	if (isVoided) {
		return PaymentStatusEnum.VOID;
	}

	return paymentStatus;
}

/**
 * This function is used to find the fee associated with the selected Stripe payment method.
 *
 * @param {UserPaymentMethodOption[]} paymentMethods - An array of available payment methods.
 * @param {UserPaymentMethod} selectedPaymentMethod - The payment method selected by the user.
 *
 * @returns {FeeDto | undefined} - Returns the fee associated with the selected payment method. If no fee is found, it
 *     returns undefined.
 */
export const findFeeSelectedStripePaymentMethod = (
	paymentMethods: UserPaymentMethodOption[],
	selectedPaymentMethod: UserPaymentMethod
): FeeDto | undefined => {
	const relevantMethods = paymentMethods.filter(pm => pm.paymentMethodType === selectedPaymentMethod.paymentMethodType);
	if (selectedPaymentMethod.paymentMethodType !== PaymentMethodTypeEnum.CARD) return relevantMethods?.[0].fee;

	if (relevantMethods.length === 1) return relevantMethods?.[0]?.fee;

	return relevantMethods.find(pm => pm.subPaymentMethodType === selectedPaymentMethod?.subPaymentMethodType)?.fee;
};
