import { type } from 'os';
import { Answer } from './answer';
import { rcEnums } from './rc.enums';
import { BondBaseEntity } from '../base';

export enum PaymentStatus {
	SENT_TO_CLIENT = 1,
	SENT_FOR_PAYMENT = 2,
	ACCEPTED = 3,
	REJECTED = 4,
	CANCELLED = 5,
	FRAUD = 6,
	NOT_RELEVANT = 7,
	PENDING = 8,
}

export interface ProductResource {
	type: string;
	id: number;
}
export interface IPaymentProduct {
	id: number;
	ordinal: number;
	paidAmount: number;
	resources?: ProductResource[];
	userId?: number;
}
export interface IPaymentData {
	installmentsAmount: number;
	isPartialPayment: boolean;
	products?: IPaymentProduct[];
	paymentData: {
		token: string;
		type: 'ach' | 'card';
	};
	answers: Answer[] | { userId: number; answers: Answer[] }[];
	amountToPay: number | undefined;
	userId: number;
	signType: rcEnums.league.SignTypeEnum;
	entityId?: number;
}

export interface PaymentMethod {
	id: string;
	billing_details: {
		address: {
			city: string | null;
			country: string | null;
			line1: string | null;
			line2: string | null;
			postalCode: string | null;
			state: string | null;
		};
		email: string;
		name: string;
		phone: string;
		account_number?: string;
	};
	card: {
		brand: string;
		country?: string;
		exp_month: string;
		exp_year: string;
		fingerprint?: string;
		funding?: string;
		generated_from?: string | null;
		last4: string;
		isDefault: boolean;
	};
	type: SelectablePaymentMethodType;
}

export interface UserPaymentMethod extends BondBaseEntity {
    userId: number;
    failCount: number;
    isDefault?: boolean;
    paymentMethodType: PaymentMethodTypeEnum;
    subPaymentMethodType?: SubPaymentMethodTypeEnum;
    paymentMethodId: string;
    nextAllowedChargeDate?: Date;
    expMonth?: number;
    expYear?: number;
    ccLast4?: string;
    ccBrand?: string;
}

export interface PurchaseProduct {
	id: number;
	ordinal: number;
	parentOrdinal: number | undefined;

	resources: ProductResource[];
	userId: number | undefined;

	// ----- remove this - it's not needed anymore --------
	paidAmount: number;
}
export type SelectablePaymentMethodType = PaymentMethodTypeEnum.CARD | PaymentMethodTypeEnum.ACH;

export enum SubPaymentMethodTypeEnum {
    CREDIT = "credit",
    DEBIT = "debit",
    PREPAID = "prepaid",
    UNKNOWN = "unknown"
}

export enum PaymentMethodTypeEnum {
	CARD = 'card',
	ACH = 'us_bank_account',
	CASH = 'cash',
	CHECK = 'check',
	BALANCE = 'balance',
	CARD_ON_TERMINAL = 'card-on-terminal',
	OTHER = 'other',
	LOST_DEBT = 'lost-debt',
	MIGRATED = 'migrated',
	VOID = 'void',
	GIFT_CARD = 'gift-card',
}
