import { paymentApi } from '@app/react/lib/api/paymentApi';
import { IPricesOfProductsResults, UserPaymentMethodOption } from '@bondsports/types';
import { roundPriceCents } from 'app/react/lib/pricing';
import { TranslationEn } from 'assets/i18n/en';
import { delay, isEqual } from 'lodash';
import * as moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useErrorModalDepricated } from '../components/shared/ErrorDepricated';
import { calculateTotalPriceWithTax } from '../forms/activities/pricing/utils/price';
import { customersApi } from '../lib/api/customersApi';
import { organizationApi } from '../lib/api/organizationApi';
import { productApi } from '../lib/api/productsApi';
import { Mixpanel, MixpanelEvents, mixpanelNull } from '../lib/mixpanel';
import { cartStore } from '../stores/cartStore';
import { BookingDto, ReservationDto } from '../types/NewReservation';
import { ICustomer } from '../types/customers';
import { PlatformsEnum } from '../types/orders';
import { EPaymentMethod } from '../types/payment';
import { ICartObj, IRecalc, TPurchaseData } from './purchase/types';
import { useOrganization } from './useOrganization';
import { useStation } from './useStation';

export const useCart = () => {
	const [anonymousCustomer, setAnonymousCustomer] = useRecoilState(cartStore.anonymousCustomer);
	const [isCartOpen, setCartOpen] = useRecoilState(cartStore.isCartOpen);
	const [isRentalCart, setRentalCart] = useRecoilState(cartStore.isRentalCart);
	const [isCartInline, setCartInline] = useRecoilState(cartStore.isCartInline);
	const [closeCartAfterPurchase, setCloseCartAfterPurchase] = useRecoilState(cartStore.closeCartAfterPurchase);
	const [itemsForPurchase, setRawItems] = useRecoilState(cartStore.itemsForPurchase);
	const paidAmount = useRecoilValue(cartStore.paidAmount);
	const [invoiceId, setInvoiceId] = useRecoilState(cartStore.invoiceId);
	const [payments, setPayments] = useRecoilState(cartStore.payments);
	const [originalItems, setOriginalItems] = useRecoilState(cartStore.originalItems);
	const [selectedItems, setSelectedItems] = useRecoilState(cartStore.selectedItems);
	const [selectedCustomer, setSelectedCustomerToStore] = useRecoilState(cartStore.selectedCustomer);
	const [anonymousId, setAnonymousId] = useRecoilState(cartStore.anonymousId);
	const [additionalData, setAdditionalData] = useRecoilState(cartStore.additionalData);

	const [checkoutState, setCheckoutStateRecoil] = useRecoilState(cartStore.checkoutState);
	const sumLine = useRecoilValue(cartStore.sumLine);

	const invoice = (originalItems as any)?.invoice;

	const [cartSubTotal, cartTotalAmount, cartTaxAmount, cartTaxPercentage] = isRentalCart
		? [invoice?.totalWithoutTax, invoice?.price, invoice?.totalTax, 10]
		: [sumLine.subTotal, sumLine.total, sumLine.tax, sumLine.taxPrecentage];

	const { shiftId } = useStation();
	const { organizationId } = useOrganization();
	const [skipFirst, setSkipFirst] = useState(true);

	const [isRecalcLoading, setIsRecalcLoading] = useState(false);
	const [isPaymentMethodsLoading, setIsPaymentMethodsLoading] = useState(false);

	const { ErrorToggle, ErrorModalDepricated } = useErrorModalDepricated();
	const [error, setError] = useRecoilState(cartStore.cartError);
	const maxQuantity = 80;

	const addFacilityToCart = (facilityId: number) => {
		setAdditionalData({ ...additionalData, facilityId });
	};

	const isSessionOnGoing = useMemo(() => {
		return payments.length > 0;
	}, [payments]);

	const addPayment = val => {
		setPayments([...payments, val]);
	};

	const turnOnRentalCart = () => {
		setRentalCart(true);
	};

	useEffect(() => {
		if (organizationId && (!anonymousCustomer || anonymousCustomer.organizationId !== organizationId)) {
			organizationApi.getAnonymousCustomer(organizationId).then(({ data }) => {
				setAnonymousCustomer(data as any);
			});
		}
	}, [organizationId]);

	useEffect(() => {
		if (!skipFirst) {
			setSelectedItems({});
			setSelectedCustomerToStore({} as ICustomer);
		}
		setSkipFirst(false);
	}, [isRentalCart]);

	const setSelectedCustomer = val => {
		if (JSON.stringify(val) !== JSON.stringify(selectedCustomer)) {
			if (val.id) {
				// add customer
				Mixpanel.track(MixpanelEvents.ADD_CUSTOMER_TO_CART, {
					customer_id: val?.id,
					customer_fullname: val?.name,
					cart_total_price: cartTotalAmount,
				});
				setSelectedCustomerToStore(val);
			} else {
				// remove customer
				Mixpanel.track(MixpanelEvents.REMOVE_CUSTOMER, {
					customer_id: selectedCustomer?.id,
					customer_fullname: selectedCustomer?.name,
					cart_total_price: cartTotalAmount,
				});
				setSelectedCustomerToStore(val);
			}
		}
	};

	const setCheckoutState = (values: ICartObj) => {
		setSelectedItems(values.selectedItems);
		setSelectedCustomer(values.selectedCustomer);
		setRawItems(values.itemsForPurchase);
		setOriginalItems(values.originalItems);
		setPayments(values.payments);
		setInvoiceId(values.invoiceId ? values.invoiceId : null);
		setCheckoutStateRecoil(values);
	};

	const handleAttachToInvoice = (id: number) => {
		setInvoiceId(id ? id : null);
	};
	const cartToggle = () => {
		setCartOpen(!isCartOpen);
	};

	const initiateProgramsCart = () => {
		setCloseCartAfterPurchase(true);
	};

	useEffect(() => {
		if (error.message) {
			ErrorToggle();
		}
	}, [error.timedate]);

	const addToCartByQuantity = (obj: any, quantity: number) => {
		if (!isSessionOnGoing) {
			if (totalProductsInCart() + quantity > maxQuantity) {
				handleQuantityExeded();
			} else {
				const newProductToPurchase = [...itemsForPurchase];
				const newItems = { ...checkoutState.selectedItems };
				for (let i = 0; i < quantity; i++) {
					newProductToPurchase.push({ ...obj, subProducts: obj.subProducts || [] });

					if (newItems[obj.id]) {
						const quantity = newItems[obj.id].quantity;
						newItems[obj.id] = { ...newItems[obj.id], quantity: quantity + (obj.quantity || 1) };
					} else {
						const newItem = {
							quantity: obj.quantity || 1,
							product: { ...obj, subProducts: obj.subProducts || [] },
							// subProducts: [],
						};
						newItems[obj.id] = newItem;
					}
				}
				setRawItems(newProductToPurchase);

				setSelectedItems(newItems);
			}
		}
	};

	const initiateCart = () => {
		if (JSON.parse(localStorage.getItem('bn_cart'))) {
			const cartData = JSON.parse(localStorage.getItem('bn_cart'));
			let isResetRequired = false;
			Object.keys(cartData.selectedItems).map(key => {
				if (cartData?.selectedItems?.[key]?.product?.type === 'reservation') {
					isResetRequired = true;
				}
			});

			// reset cart data if the localStorage data is in old format
			if (!isResetRequired) {
				setCheckoutState(JSON.parse(localStorage.getItem('bn_cart')));
			} else {
				resetCart();
			}
		} else {
			resetCart();
		}
	};

	const saveTheCart = () => {
		localStorage.setItem('bn_cart', JSON.stringify(checkoutState));
	};

	const setOrganizationId = (organizationId: number) => {
		setCheckoutState({ ...checkoutState, organizationId });
	};

	const handleQuantityExeded = () => {
		setError({ message: TranslationEn.payments.cart.tooManyItems, timedate: moment().valueOf() });
	};

	/**
	 * @description this function used for adding products in Kiosk and memberships.
	 */
	const addToCartSingle = (obj: any, customer?: ICustomer) => {
		if (!isSessionOnGoing) {
			if (totalProductsInCart() + obj.quantity > maxQuantity) {
				handleQuantityExeded();
			} else {
				const newProductToPurchase = [...itemsForPurchase];
				newProductToPurchase.push({ ...obj, subProducts: obj.subProducts || [] });
				const newItems = { ...checkoutState.selectedItems };
				if (newItems[obj.id]) {
					const quantity = newItems[obj.id].quantity;
					newItems[obj.id] = { ...newItems[obj.id], quantity: quantity + (obj.quantity || 1) };
				} else {
					const newItem = {
						quantity: obj.quantity || 1,
						product: { ...obj, subProducts: obj.subProducts || [] },
						// subProducts: [],
					};
					newItems[obj.id] = newItem;
				}
				setRawItems(newProductToPurchase);

				setSelectedItems(newItems);
				if (customer && !selectedCustomer?.id) {
					setSelectedCustomer(customer);
				}

				const isMembership = obj.type === 'meembership';
				let membershipId;
				if (isMembership) {
					if (obj.resources && obj.resources[0]) {
						membershipId = obj.resources[0].id;
					}
				}

				Mixpanel.track(MixpanelEvents.ADD_PRODUCT, {
					customer_id: customer?.id || mixpanelNull,
					customer_fullname: customer?.name || mixpanelNull,
					cart_total_price: cartTotalAmount,
					participant_id: mixpanelNull,
					participant_fullname: mixpanelNull,
					product_id: obj.id,
					product_name: isMembership ? obj.productName : obj.name,
					product_type: obj.type,
					product_sub_type: obj.productSubType,
					product_price: obj.price,
					product_pre_tax: '',
					product_tax_type: obj.tax ? (obj.isTaxInclusive ? 'Inclusive' : 'Exclusive') : null,
					product_tax_price: obj.tax,
					program_id: mixpanelNull,
					program_name: mixpanelNull,
					program_activities_id: mixpanelNull,
					program_activities_name: mixpanelNull,
					program_gender: mixpanelNull,
					program_levelofplay_ids: mixpanelNull,
					program_levelofplay_names: mixpanelNull,
					program_min_age: mixpanelNull,
					program_max_age: mixpanelNull,
					program_age_type: mixpanelNull,
					session_id: mixpanelNull,
					session_name: mixpanelNull,
					session_activities_id: mixpanelNull,
					session_activities_name: mixpanelNull,
					session_gender: mixpanelNull,
					session_levelofplay_ids: mixpanelNull,
					session_levelofplay_names: mixpanelNull,
					session_min_age: mixpanelNull,
					session_max_age: mixpanelNull,
					session_age_type: mixpanelNull,
					membership_id: isMembership ? membershipId : mixpanelNull,
					membership_name: isMembership ? obj.productName : mixpanelNull,
				});

				// setCheckoutState({ ...checkoutState, selectedItems: { ...newItems } });
			}
		}
	};

	/**
	 * @description this function used for adding product in programs
	 * @mixpanel added in the before firing this function
	 */
	const addSessionToCartWithCustomer = (data: any[], customer: any, participant?: ICustomer) => {
		if (!isSessionOnGoing) {
			const newItems = { ...checkoutState.selectedItems };
			data.forEach(obj => {
				if (totalProductsInCart() + obj.quantity > maxQuantity) {
					handleQuantityExeded();
				} else {
					if (newItems[obj.id]) {
						const quantity = newItems[obj.id].quantity;
						newItems[obj.id] = { ...newItems[obj.id], quantity: quantity + (obj.quantity || 1) };
					} else {
						const newItem = {
							quantity: obj.quantity || 1,
							product: { ...obj, subProducts: obj.subProducts || [] },
							// subProducts: obj.subProducts || [],
							participant,
						};
						newItems[obj.id] = newItem;
					}
				}
			});
			if (!selectedCustomer?.id) {
				setSelectedCustomer(customer);
			}

			setSelectedItems(newItems);
		}
	};

	/**
	 * TODO: Add types
	 * Add rental items to cart
	 * @param val {Array} - array of items to add to cart
	 * @param originalItems {Array} - array of original items
	 * @param isUpdate {Boolean} - if the item is being updated
	 * @param customer {ICustomer} - customer object
	 */
	const addToRentalCart = (val: any[], originalItems?: any[], isUpdate = false, customer?: ICustomer) => {
		const newItems = isUpdate ? {} : { ...checkoutState.selectedItems };
		for (const obj of val) {
			// if (newItems[obj.id]) {
			// 	const quantity = newItems[obj.id].quantity;
			// 	const rentalResources = newItems[obj.id].product.rentalResources;
			// 	const newSubProducts = [...newItems[obj.id].product.subProducts];
			//
			// 	obj?.subProducts.forEach(newSubProduct => {
			// 		const subProductsInCart = newSubProducts.find(
			// 			subProduct => subProduct.product.id === newSubProduct.product.id
			// 		);
			// 		if (subProductsInCart) {
			// 			const subProductsQuantity = subProductsInCart.quantity;
			// 			newSubProducts[newSubProducts.indexOf(subProductsInCart)] = {
			// 				product: { ...newSubProducts[newSubProducts.indexOf(subProductsInCart)].product },
			// 				quantity: subProductsQuantity + (newSubProduct.quantity || 1),
			// 			};
			// 		} else {
			// 			newSubProducts.push(newSubProduct);
			// 		}
			// 	});
			//
			// 	if (!rentalResources.includes(obj.spaceName)) {
			// 		rentalResources.push(obj.spaceName);
			// 	}
			//
			// 	newItems[obj.id] = {
			// 		product: { ...newItems[obj.id].product, rentalResources: rentalResources, subProducts: newSubProducts },
			// 		quantity: quantity + (obj.quantity || 1),
			// 	};
			// continue;
			//}
			const newSubProducts = [];
			for (const newSubProduct of obj?.subProducts ?? []) {
				const subProductsInCart = newSubProducts.find(subProduct => subProduct.product.id === newSubProduct.product.id);
				if (subProductsInCart) {
					const subProductsQuantity = subProductsInCart.quantity;
					newSubProducts[newSubProducts.indexOf(subProductsInCart)] = {
						product: { ...newSubProducts[newSubProducts.indexOf(subProductsInCart)].product },
						quantity: subProductsQuantity + (newSubProduct.quantity || 1),
					};

					continue;
				}
				newSubProducts.push(newSubProduct);
			}

			newItems[obj.id] = {
				quantity: obj.quantity || 1,
				product: { ...obj, subProducts: newSubProducts, rentalResources: [obj.spaceName] },
			};
		}

		setOriginalItems(originalItems);
		setSelectedItems(newItems);
		setSelectedCustomer(customer);
	};

	/**
	 * TODO: Add types
	 * Add items to cart
	 * @param val {Array} - array of items to add to cart
	 * @param customer {ICustomer} - customer object
	 */
	const addToCart = (val: any[], customer?: ICustomer) => {
		const newItems = { ...checkoutState.selectedItems };

		for (const obj of val) {
			if (newItems[obj.id]) {
				const quantity = newItems[obj.id].quantity;
				newItems[obj.id] = {
					product: {
						...newItems[obj.id].product,
						subProducts: [...newItems[obj.id].product.subProducts, obj.subProducts],
					},
					quantity: quantity + (obj.quantity || 1),
				};

				continue;
			}

			const newItem = {
				quantity: obj.quantity || 1,
				product: obj,
			};
			newItems[obj.id] = newItem;
		}

		setCheckoutState({ ...checkoutState, selectedItems: newItems, selectedCustomer: customer ?? undefined });
	};

	/**
	 * TODO: Add types
	 * @description this function used to add product from rentals
	 */
	const addToCartMultiple = (val: any[], originalItems?: any[], isUpdate = false, customer?: ICustomer) => {
		if (!isSessionOnGoing) {
			if (isRentalCart) {
				addToRentalCart(val, originalItems, isUpdate, customer);
				return;
			}

			addToCart(val, customer);
		}
	};

	const removeFromCartSingle = (id: number) => {
		if (!isSessionOnGoing) {
			const newItems = { ...checkoutState.selectedItems };
			delete newItems[id];
			let newRawItems = [...checkoutState.itemsForPurchase];
			newRawItems = newRawItems.filter(item => item.id !== id);
			setCheckoutState({ ...checkoutState, selectedItems: { ...newItems }, itemsForPurchase: newRawItems });
		}
	};

	const removeSubProductFromCart = (id: number, parentId: number) => {
		if (!isSessionOnGoing) {
			const newItems = { ...checkoutState.selectedItems };
			const newSubProducts = newItems[parentId].product.subProducts.filter(subProduct => {
				return subProduct.product.id !== id;
			});
			newItems[parentId] = {
				...newItems[parentId],
				product: { ...newItems[parentId].product, subProducts: newSubProducts },
			};
			setCheckoutState({ ...checkoutState, selectedItems: { ...newItems } });
		}
	};

	const addCustomer = async (val: ICustomer) => {
		if (!isSessionOnGoing) {
			const { admin } = await customersApi.getCustomersFamilyAdminAsCustomer(val);
			setSelectedCustomer(admin || val);
		}
	};

	const removeCustomer = () => {
		if (!isSessionOnGoing) {
			setCheckoutState({ ...checkoutState, selectedCustomer: {} });
		}
	};

	const handleClearingCart = () => {
		const cartState = {
			organizationId: checkoutState.organizationId,
			originalItems: [],
			selectedItems: {},
			itemsForPurchase: [],
			payments: [],
			invoiceId: null,
			selectedCustomer: {},
		} as ICartObj;
		localStorage.removeItem('bn_cart');
		setCheckoutState(cartState);
		setInvoiceId(undefined);
		setSelectedItems({});
		setSelectedCustomer({});
	};

	const clearCart = () => {
		if (!isSessionOnGoing) {
			Mixpanel.track(MixpanelEvents.CLEAR_CART, {
				customer_id: selectedCustomer.id || mixpanelNull,
				customer_fullname: selectedCustomer.name || mixpanelNull,
				cart_total_price: cartTotalAmount,
			});
			handleClearingCart();
		}
	};

	const resetCart = () => {
		handleClearingCart();
	};

	const totalProductsInCart = () => {
		let total = 0;
		Object.keys(checkoutState.selectedItems).forEach(key => {
			const item = checkoutState.selectedItems[key];
			total += item.quantity;
			if (item.product.subProducts && item.product.subProducts.length > 0) {
				item.product.subProducts.forEach(sub => {
					total += sub.quantity || 1;
				});
			}
		});
		return total;
	};

	const getAndSetAnonymousCustomer = () => {
		organizationApi.getAnonymousCustomer(organizationId).then(res => {
			setAnonymousId(res.data?.entityId);
			// setSelectedCustomerToStore(res.data as unknown as ICustomer);
			return res.data?.entityId;
		});
	};

	const updateQuantity = (id: number, value: number, previousValue: number) => {
		if (!isSessionOnGoing) {
			const total = totalProductsInCart();
			if (total - previousValue + value > maxQuantity) {
				handleQuantityExeded();
			} else {
				const newItems = { ...checkoutState.selectedItems };
				newItems[id] = { ...newItems[id], quantity: value };
				let rawItems = [...checkoutState.itemsForPurchase];
				const relaventItem = rawItems.find(item => item.id === id);
				rawItems = rawItems.filter(item => item.id !== id);
				for (let i = 0; i < value; i++) {
					rawItems.push(relaventItem);
				}

				setCheckoutState({ ...checkoutState, selectedItems: { ...newItems }, itemsForPurchase: rawItems });
			}
		}
	};

	const quantityOfProduct = (id: number) => {
		return checkoutState.selectedItems[id] ? checkoutState.selectedItems[id].quantity : 0;
	};

	const quantityOfSubProducts = (arr: number[]) => {
		let counter = 0;
		arr.forEach(productId => {
			if (checkoutState.selectedItems[productId]) {
				counter += checkoutState.selectedItems[productId].quantity;
			}
		});
		return counter;
	};

	const prepareDataForPurchase = async (
		token: string,
		type: string,
		isNewCard?: boolean,
		amount?: number
	): Promise<Partial<TPurchaseData>> => {
		if (organizationId) {
			let userId;
			if (!checkoutState.selectedCustomer.id) {
				if (anonymousId) userId = anonymousId;
				//solution for loading issue-trying to fetch again the anonymousId
				else {
					userId = await getAndSetAnonymousCustomer();
					if (!userId) return;
				}
			} else {
				userId = checkoutState.selectedCustomer.entityId;
			}
			if (isRentalCart) {
				// should be used : BookingDto
				const bookingData: BookingDto = {
					reservation: originalItems as unknown as ReservationDto,
					platform: PlatformsEnum.BO,
					shiftId: shiftId,
					paymentData: {
						token,
						type: type as EPaymentMethod,
					},
					amountToPay: amount,
					total: cartTotalAmount,
					purchasingUserId: userId,
					invoiceId,
				};
				return bookingData as Partial<TPurchaseData>;
			} else {
				const products = [];
				checkoutState.itemsForPurchase.forEach((product, index) => {
					if (product.excluded) {
						return;
					}

					const discountedProduct = selectedItems[product.id]?.product;
					const productToChargeFor = discountedProduct ?? product;
					const isChargeablePrice = discountedProduct?.price > 0; // TODO: change the price to send the unitPrice always

					products.push({
						id: productToChargeFor?.id,
						ordinal: index + 1,
						unitPrice: productToChargeFor?.price ?? product?.price,
						resources: productToChargeFor?.resources || [],
						userId: productToChargeFor?.userId || userId,
						isChargeablePrice,
					});

					const subProducts = productToChargeFor?.subProducts ?? [];

					// const excludedProducts = checkoutState.itemsForPurchase.find(p => p.id === product.id && p.excluded);
					// product.quantity = excludedProducts.length ? excludedProducts.length : undefined;

					for (const sub of subProducts) {
						if (sub?.product) {
							products.push({
								id: sub.product.id,
								ordinal: sub.product.ordinal,
								unitPrice: sub.product.price,
								quantity: sub.product.quantity || 1,
								resources: sub.product.resources || [],
								userId: sub.product.userId || userId,
								parentProductId: product.id,
								isChargeablePrice,
							});
						}
					}
				});

				const obj = {
					isPartialPayment: amount !== cartTotalAmount,
					purchasingUserId: userId,
					products,
					paymentData: {
						token,
						type,
					},
					answers: [
						{
							userId: userId,
							answers: [],
						},
					],
					amountToPay: roundPriceCents(amount || amount > -1 ? amount : cartTotalAmount),
					platform: PlatformsEnum.BO,
					invoiceId,
					shiftId,
				};

				return obj;
			}
		}
	};

	const CartError = () => {
		return <ErrorModalDepricated message={error.message} />;
	};

	const updateDiscounts = (arr: IPricesOfProductsResults[]) => {
		const newSelectedItems = { ...checkoutState.selectedItems };

		const newPricesMapper = {};

		arr.forEach(newPrice => {
			newPricesMapper[newPrice.productId] = newPrice;
		});

		Object.keys(newSelectedItems).forEach(itemId => {
			let newProduct = { ...newSelectedItems[itemId].product };
			const newPrice = newPricesMapper[itemId];
			if (newPrice) {
				if (newProduct.userId === newPricesMapper[itemId].userId || !newProduct.userId) {
					// update prices
					newProduct.price = calculateTotalPriceWithTax(newPrice.unitPrice, newPrice.isTaxInclusive, newProduct.tax);
					newProduct.taxPrice = roundPriceCents(newPrice.tax / (newProduct.quantity ?? 1));
					newProduct.isTaxInclusive = newPrice.isTaxInclusive;
				}
			}
			let newSubProducts = [];
			newSubProducts = newSelectedItems[itemId].product.subProducts.map(subProduct => {
				const newSubProduct = { ...subProduct.product };
				const newPriceSub = newPricesMapper[subProduct.product.id];
				if (newPriceSub) {
					// update prices
					newSubProduct.price = calculateTotalPriceWithTax(
						newPriceSub.unitPrice,
						newPriceSub.isTaxInclusive,
						newSubProduct.tax
					);
					newSubProduct.taxPrice = roundPriceCents(newPriceSub.tax / (newSubProduct.quantity ?? 1));
					newSubProduct.isTaxInclusive = newPriceSub.isTaxInclusive;
				}
				return { ...subProduct, product: newSubProduct };
			});
			newProduct = { ...newProduct, subProducts: newSubProducts };
			newSelectedItems[itemId] = { ...newSelectedItems[itemId], product: newProduct };
		});
		if (!isEqual(newSelectedItems, checkoutState.selectedItems)) {
			setSelectedItems(newSelectedItems);
		}
	};

	const recalcPrices = useCallback(async () => {
		try {
			setIsRecalcLoading(true);
			if (!isRentalCart) {
				const data = await prepareDataForPurchase('', '', false);

				if (!data) {
					return;
				}

				if ((data as IRecalc)?.products?.length) {
					(data as IRecalc).products = (data as IRecalc).products.map(product => ({
						...product,
						productId: product.id,
						id: undefined,
					}));
				}

				const res = await productApi.recalcPricing(organizationId, { ...data });
				if (res) {
					updateDiscounts(res);
					return;
				}
			}
			return;
		} catch (e) {
			setError(e);
		} finally {
			setIsRecalcLoading(false);
		}
	}, [checkoutState, anonymousId]);

	const getPaymentMethods = async (orgId?: number, uId?: number): Promise<UserPaymentMethodOption[]> => {
		try {
			setIsPaymentMethodsLoading(true);
			const res = await paymentApi.getCustomerUserPaymentMethods(
				orgId ?? organizationId,
				uId ?? Number(selectedCustomer?.entityId ? selectedCustomer.entityId : anonymousId)
			);

			if (res?.err) {
				return [];
			} else {
				return res;
			}
		} catch (e) {
			setError(e);
		} finally {
			delay(() => setIsPaymentMethodsLoading(false), 100);
		}
	};

	return {
		CartError,
		addCustomer,
		addFacilityToCart,
		addPayment,
		addSessionToCartWithCustomer,
		addToCartByQuantity,
		addToCartMultiple,
		addToCartSingle,
		additionalData,
		anonymousCustomer,
		anonymousId,
		cartSubTotal,
		cartTaxAmount,
		cartTaxPercentage,
		cartToggle,
		cartTotalAmount,
		clearCart,
		closeCartAfterPurchase,
		getAndSetAnonymousCustomer,
		getPaymentMethods,
		handleAttachToInvoice,
		initiateCart,
		initiateProgramsCart,
		invoiceId,
		isCartInline,
		isCartOpen,
		isLoading: isRecalcLoading || isPaymentMethodsLoading,
		isRentalCart,
		isSessionOnGoing,
		itemsForPurchase,
		organizationId,
		paidAmount,
		payments,
		prepareDataForPurchase,
		quantityOfProduct,
		quantityOfSubProducts,
		recalcPrices,
		removeCustomer,
		removeFromCartSingle,
		removeSubProductFromCart,
		resetCart,
		saveTheCart,
		selectedCustomer,
		selectedItems,
		setInvoiceId,
		setOrganizationId,
		setRawItems, //temp solution
		setSelectedCustomer,
		totalProductsInCart,
		turnOnRentalCart,
		updateQuantity,
	};
};
