/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { AnswerTemplateCustomerDto } from '@bondsports/types';
import { DateTimeFormats,  convertUtcTimestampToTimezone } from '@bondsports/date-time';
import { ETypography, ETypographyColor } from '../../../types';
import { Typography } from '../../../atoms';
import { CustomerDetailLine } from './CustomerDetailLine';
import { sectionCss } from '../styles';
import { i18n } from '../../../lib/i18n';

interface Props {
	customer: AnswerTemplateCustomerDto;
	dateSigned: string | Date;
	timezone: string;
}

export const CustomerDetails: FC<Props> = (props: Props) => {
	const { customer, dateSigned, timezone } = props;
	const labels = i18n.applicationTemplate.customerDetails;

	const formattedDate = `${convertUtcTimestampToTimezone(dateSigned, timezone, DateTimeFormats.DAY_FORMAT)} ${
		labels.at
	} ${convertUtcTimestampToTimezone(dateSigned, timezone, DateTimeFormats.H12_AMPM_UPPERCASE)}`;
	const customerDetails = { dateSigned: formattedDate, ...customer };

	return (
		<div css={sectionCss}>
			<Typography color={ETypographyColor.primary} type={ETypography.h4}>
				{labels.title}
			</Typography>
			<div className="customer-details-container">
				{Object.entries(customerDetails).map(([detail, data]) => (
					<CustomerDetailLine key={detail} title={labels.details[detail]} data={data} />
				))}
			</div>
		</div>
	);
};
