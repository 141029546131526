/** @jsx jsx */
/** @jsxRuntime classic */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { TranslationEn } from '../../../../assets/i18n/en';
import { underlineCss } from '@app/react/styles/utils';
import { useNavigatoToStation } from '@app/react/hooks/useNavigateToStation';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';

const labels = TranslationEn.terminal;

export const StationSetupLink = () => {
	const { handleStationRedirection } = useNavigatoToStation();
	return (
		<Typography color={ETypographyColor.primary} type={ETypography.captionLink}>
			<a onClick={handleStationRedirection} css={underlineCss} target="">
				{labels.setupLink}
			</a>
		</Typography>
	);
};
