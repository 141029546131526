/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { FC, useRef, ReactNode, useLayoutEffect, useEffect } from 'react';

import { useColors } from '../../hooks/useColors';
import { GenericContainerCss } from './style';
import { ColorCode, TMaintAtEndHeight, TPxHeightSetter } from './types';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

interface IProps {
	colorCode?: ColorCode;
	isDaily?: boolean;
	isShort?: boolean;
	isDraft?: boolean;
	isTopBorderFlat?: boolean;
	isBottomBorderFlat?: boolean;
	isNestedEvent?: boolean;
	isTrailing?: boolean;
	isNesting?: boolean;
	height?: number;
	maintAtEndHeight?: TMaintAtEndHeight;
	setHeightFromRef?: TPxHeightSetter;
	children: ReactNode;
}

export const GenericContainer = ({
	children,
	colorCode,
	isDaily = false,
	isShort = false,
	isDraft = false,
	isTopBorderFlat = false,
	isBottomBorderFlat = false,
	isNestedEvent = false,
	isTrailing = false,
	isNesting = false,
	height,
	maintAtEndHeight = null,
	setHeightFromRef,
}: IProps) => {
	const { colors } = useColors();
	const maintAfterRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		const domHeightPx = maintAfterRef.current?.clientHeight || 0;
		setHeightFromRef && setHeightFromRef(domHeightPx, isTrailing);
	}, [maintAfterRef.current, setHeightFromRef, isTrailing]);

	return (
		<div
			ref={maintAfterRef}
			css={GenericContainerCss({
				colors,
				isDiagonal: false,
				colorCode,
				isDaily,
				isShort,
				isDraft,
				top: isTopBorderFlat,
				bottom: isBottomBorderFlat,
				isNestedEvent,
				isTrailing,
				isNesting,
				height,
				maintAtEndHeight,
			})}
			data-aid="GenericContainer-bo_calendar_slot"
		>
			{children}
		</div>
	);
};
