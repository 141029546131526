/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { Button } from '../../components/Button';
import { Icons, TSize } from '../../types';
import { BnIcon } from '../../components/icons_v2';
import { i18n } from '../../lib/i18n';
import { SizeEnum } from '../../lib/constants';

interface IProps {
	handleBack: () => void;
	sizer?: TSize;
}

export const BackButton = ({ handleBack, sizer = SizeEnum.SMALL }: IProps) => {
	const { back } = i18n.buttons;
	return (
		<Button data-aid="button-BackButtton-left" theme="basic" sizer={sizer} onClick={handleBack}>
			<BnIcon icon={Icons.arrow_left} />
			{back}
		</Button>
	);
};
