export const BASE_ORGANIZATION_PATH = '/organization';
export const BASE_INSTRUCTORS_PATH = `${BASE_ORGANIZATION_PATH}/instructors`;

export const INSTRUCTOR_PROFILE_SEGMENT = 'instructor-profile';
export const GENERAL_SETTINGS_SEGMENT = 'general-settings';

export const INSTRUCTORS_PAGE = `${BASE_INSTRUCTORS_PATH}`;

export const INSTRUCTOR_PROFILE_PATH = `${BASE_INSTRUCTORS_PATH}/${INSTRUCTOR_PROFILE_SEGMENT}/${GENERAL_SETTINGS_SEGMENT}`;

export const editInstructorPathById = (instructorId: number) =>
	`${BASE_INSTRUCTORS_PATH}/${instructorId}/${INSTRUCTOR_PROFILE_SEGMENT}/${GENERAL_SETTINGS_SEGMENT}`;
