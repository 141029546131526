/** @jsx jsx */
import * as React from 'react';
import { jsx } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { EventWithAttendanceDto } from '@bondsports/types';
import { AlertsStatusEnum, TCustomer } from '@app/react/types/customers';
import { scrollYCss } from '@app/react/styles/utils';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { EVENTS_UNTIL_DAYS_FROM_NOW } from '../../../customerPage/CustomerPageBodyMapper/Overview/usOverview';
import { AlertsCard } from '../AlertsCard';
import { EventRow } from './EventRow';

interface Props {
	customerState: TCustomer;
	isLoading: boolean;
	loadingFailed: boolean;
	events: EventWithAttendanceDto[];
}

export const Events = ({ customerState, isLoading, loadingFailed, events }: Props) => {
	const labels = TranslationEn.customers.overview.events;
	return (
		<AlertsCard
			heightMultiple={2.72}
			title={labels.title}
			status={loadingFailed ? AlertsStatusEnum.ERROR : AlertsStatusEnum.N_A}
			footer={
				events.length === 0 &&
				!loadingFailed && (
					<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
						{labels.noEvents(EVENTS_UNTIL_DAYS_FROM_NOW)}
					</Typography>
				)
			}
			main={
				<div css={scrollYCss}>
					{events.map((event: EventWithAttendanceDto ) => (
						<EventRow
							key={event.id}
							event={event}
							customerState={customerState}
							isFirstItem={events[0]?.id == event.id}
							isLastItem={events[events.length - 1]?.id == event.id}
						/>
					))}
				</div>
			}
			errorMessage={labels.errorMessage}
			isLoading={isLoading}
		/>
	);
};
