import * as dayjs from 'dayjs';
import { TranslationEn } from '../../../assets/i18n/en';
import { EDateTimeFormats } from '../types/times';
import { Nullable } from '../types/core';
import { getYearsDiff } from './dates';
import { MAX_AGE_LIMIT } from '../forms/activities/utils/consts';

enum AgeType {
	yearChar = 'y',
	year = 'years',
	month = 'months',
}

function getAgeNumber(str: string) {
	return str.substring(0, str.length - 2);
}

export const getAgeRangeTag = (minAge = '', maxAge = '') => {
	const ageTypeChar = minAge.slice(-1);
	const ageType = ageTypeChar === AgeType.yearChar ? AgeType.year : AgeType.month;
	return `${getAgeNumber(minAge)} - ${getAgeNumber(maxAge)} ${ageType}`;
};

export const formatAgeRange = (minAge: string, maxAge: string, ageCalcDate?: string): string => {
	if (!minAge || !maxAge) return '';
	const labels = TranslationEn.activities.program.info;
	const ageLabels = labels.basicInfo.ageDesc;
	const [min, minUnit] = minAge.split(' ');
	const [max, maxUnit] = maxAge.split(' ');

	const ageDesc =
		max === MAX_AGE_LIMIT
			? `${ageLabels.from} ${min} ${labels.basicInfo[minUnit](+min)} ${ageLabels.andUp}`
			: `${min}${minUnit === maxUnit ? '' : ` ${labels.basicInfo[minUnit](min)}`} - ${max} ${labels.basicInfo[maxUnit](
					max
			  )} ${ageLabels.old}`;

	const ageCalcDesc = ageCalcDate
		? `${ageLabels.eligibility} ${dayjs(ageCalcDate).format(EDateTimeFormats.DAY_FORMAT)}`
		: null;

	return `${ageDesc}${ageCalcDesc ? `\n${ageCalcDesc}` : ''}`;
};

export function getCustomerAge(birthDate: string): number {
	return getYearsDiff(birthDate);
}

export function getCustomerAgeWithSuffix(birthDate?: string): Nullable<string> {
	const suffix = TranslationEn.customers.ageSuffix;
	return birthDate ? `${getCustomerAge(birthDate)}${suffix}` : null;
}
