/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { isValidElement, ReactElement, useEffect, useRef } from 'react';
import { colors } from '../../../styles/theme';

interface FormPartProps {
	label: string;
	value: string | number | string[] | ReactElement;
	width?: number;
	margin?: number;
	labelMargin?: number;
	valueFontSize?: number;
	isDisabled?: boolean;
}

const formPartCss = (
	width: number,
	margin: number,
	labelMargin: number,
	valueFontSize: number,
	isDisabled: boolean
) => css`
	display: flex;
	flex-direction: column;
	margin: ${margin}rem;
	padding: 1rem;
	width: ${width}%;
	label {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 1.2rem;
		line-height: 15px;
		text-transform: uppercase;
		color: ${isDisabled ? '#dae6f0' : colors.formInputBg};
		margin-bottom: ${labelMargin}px;
	}
	div {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: ${valueFontSize}rem;
		line-height: 130%;
		color: ${isDisabled ? '#dae6f0' : colors.brandPrimary};
	}
`;

const arrayCss = css`
	margin-bottom: 10px;
`;

export const FormPart = ({
	label,
	value,
	labelMargin = 8,
	width = 25,
	margin = 2,
	valueFontSize = 1.4,
	isDisabled,
}: FormPartProps) => {
	const aRef = useRef(null);
	const content = `${
		!Array.isArray(value) ? `<div>${value}</div>` : value.map(value => `<div css=${arrayCss}>${value}</div>`)
	}`;

	const element = isValidElement(value);

	useEffect(() => {
		if (!aRef?.current) return;
		aRef.current.innerHTML = content;
	}, [aRef, content]);

	return (
		<React.Fragment>
			<div data-aid="formPart" css={formPartCss(width, margin, labelMargin, valueFontSize, isDisabled)}>
				{label && <label data-aid="formPart-label">{label}</label>}
				{element ? value : <div data-aid="formPart-value" style={{ whiteSpace: 'pre-line' }} ref={aRef} />}
			</div>
		</React.Fragment>
	);
};
