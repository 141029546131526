import { attendanceApi } from '@app/react/lib/api/attendanceApi';
import { AttendanceCreateDto, AttendanceDto, PaginationMetaDto } from '@bondsports/types';
import { useEffect, useState } from 'react';
import useAudio, { SoundEffect } from './useAudio';
import { ICustomer } from '../types/customers';

export const useAttendance = () => {
	const [attendance, setAttendance] = useState<AttendanceDto[]>([]);
	const [attendanceMeta, setAttendanceMeta] = useState<PaginationMetaDto>();
	const [isFetchingAttendance, setIsFetchingAttendance] = useState<boolean>(false);
	const [isCreatingAttendance, setIsCreatingAttendance] = useState<boolean>(false);
	const [createAttendanceError, setCreateAttendanceError] = useState<string | null>(null);
	const [createAttendanceSuccess, setCreateAttendanceSuccess] = useState<AttendanceDto | null>(null);
	const [isDeletingAttendance, setIsDeletingAttendance] = useState<boolean>(false);
	const [deleteAttendanceError, setDeleteAttendanceError] = useState<string | null>(null);
	const [deleteAttendanceSuccess, setDeleteAttendanceSuccess] = useState<boolean>(false);
	const [customerState, setCustomerState] = useState<ICustomer | null>(null);
	const { playSoundEffect } = useAudio();

	const fetchAttendance = async (organizationId: number, userId: number, itemsPerPage: number, page = 1) => {
		setIsFetchingAttendance(true);
		try {
			const res = await attendanceApi.fetchAttendance(organizationId, userId, itemsPerPage, page);
			setIsFetchingAttendance(false);
			setAttendance(res.data);
			setAttendanceMeta(res.meta);
		} catch (err) {
			console.error(err);
		}
		setIsFetchingAttendance(false);
	};

	const createAttendance = async (customerState: ICustomer, organizationId: number, userId: number, data: AttendanceCreateDto) => {
		setCustomerState(customerState);
		setCreateAttendanceSuccess(null);
		setCreateAttendanceError(null);
		setIsCreatingAttendance(true);

		try {
			const res = await attendanceApi.createAttendance(organizationId, userId, data);

			if (!res.succeeded) {
				setCreateAttendanceError(res.message ?? (res as any).err);
			} else {
				setCreateAttendanceSuccess(res.data);
			}
		} catch (err) {
			setCreateAttendanceError(err.message);
		}
		setIsCreatingAttendance(false);
	};

	// Play success and warn sound effects on successful attendance creation
	// NB: Warning is only about WAIVER, does not capture PAYMENT issues, they have their own flow to fetch...
	useEffect(() => {
    if (createAttendanceSuccess) {
        const { waiverSignedDate } = customerState || {};
        const soundEffect = waiverSignedDate ? SoundEffect.CheckInWarn : SoundEffect.CheckInSuccess;
        playSoundEffect(soundEffect); 
    }
}, [createAttendanceSuccess, customerState?.waiverSignedDate]); 

	// Play error sounds on failed attendance creation
	useEffect(() => {
		if (createAttendanceError) {
			playSoundEffect(SoundEffect.CheckInError);
		}
	}, [createAttendanceError]);

	const deleteAttendance = async (organizationId: number, attendanceId: number) => {
		setDeleteAttendanceSuccess(false);
		setDeleteAttendanceError(null);
		setIsDeletingAttendance(true);

		try {
			const res = await attendanceApi.deleteAttendance(organizationId, attendanceId);

			if (!res.succeeded) {
				setDeleteAttendanceError(res.message ?? (res as any).err);
				return;
			}
			setDeleteAttendanceSuccess(true);
		} catch (err) {
			setDeleteAttendanceError(err.message);
		} finally {
			setIsDeletingAttendance(false);
		}
	};

	return {
		attendance,
		attendanceMeta,
		isFetchingAttendance,
		createAttendance,
		createAttendanceError,
		isCreatingAttendance,
		createAttendanceSuccess,
		fetchAttendance,
		deleteAttendance,
		isDeletingAttendance,
		deleteAttendanceError,
		deleteAttendanceSuccess,
	};
};
