import { useDebounce } from '@app/react/hooks/useDebouncedSearch';
import { useState } from 'react';
import { IGenericFilters, IUseFilters } from '../types/filters';

export const useFilters = <T extends IGenericFilters>(initialFilters: T = {} as T): IUseFilters<T> => {
	const [selectedFilters, setSelectedFilters] = useState<T | null>(initialFilters);
	const debouncedSelectedFilters = useDebounce(selectedFilters);

	const onSelectFilter = (value: boolean, valueOfChecked: number, sectionClicked: keyof T) => {
		setSelectedFilters(prevState => {
			const updatedFilters = { ...prevState };
			const selectedSection = updatedFilters[sectionClicked] || [];

			updatedFilters[sectionClicked] = !selectedSection.includes(valueOfChecked)
				? ([...selectedSection, valueOfChecked] as T[keyof T])
				: (selectedSection.filter(id => id !== valueOfChecked) as T[keyof T]);

			return updatedFilters;
		});
	};

	return {
		debouncedSelectedFilters,
		flatSelectedFilters: Object.values(selectedFilters).flat(),
		onSelectFilter,
	};
};
