/** @jsx jsx */

import { customersApi } from 'app/react/lib/api/customersApi';
import { membershipApi } from 'app/react/lib/api/membershipApi';
import { getDateStringWithYear, getMonthDayRange, getTimeRangeDisplay } from 'app/react/lib/dates';
import { currentPageType, IBreadcrumbStep } from 'app/react/types/navigation';
import { TranslationEn } from '@assets/i18n/en';
import { ITaxType } from 'app/react/components/customers/singleInvoice/types';
import { Capitalize } from 'app/react/lib/form';
import { MembershipTypeEnum } from 'app/react/types/membership';
import {
	AppendixAddonItem,
	IPayment,
	IPaymentDetailValues,
	ILineItem,
	PaymentStatusEnum,
	ProductTypesEnum,
	PurchasedResourceStatusEnum,
	AppendixItem,
} from 'app/react/types/orders';
import * as dayjs from 'dayjs';
import { SlotDto, SlotTypeEnum } from 'app/react/types/NewReservation';
import { useOrganization } from 'app/react/hooks/useOrganization';
import {
	LineItems,
	Invoice,
	Resource,
	Segment,
	Series,
	Slot,
	Reservation,
	ResourceNameTypeEnum,
} from '@bondsports/types';
import { EPaymentMethod } from '../../../types/payment';
import { roundWithDecimal } from '@bondsports/utils';

const URL_SEPARATOR = '/';
const VOID_URL_SUFFIX = '/action/void';
const REFUND_URL_SUFFIX = '/action/refund';
const PAYMENT_URL_SUFFIX = '/payments';
const INDEX_NOT_FOUND = -1;
const START_SPLIT_INDEX = 0;
const ADD_TO_SPLIT_INDEX = 2;

enum ERedirectUrlType {
	CUSTOMER = 'customer',
	FAMILY = 'family',
	INVOICES = 'invoices',
}

export enum EDocType {
	INVOICE = 'invoice',
	PAYMENT = 'payment',
}

interface RedirectUrls {
	invoiceUrl: string;
	voidUrl: string;
	refundUrl: string;
	customerUrl: string;
	customerListUrl: string;
	paymentUrl: string;
}

const typeMapper = {
	event: ResourceNameTypeEnum.EVENT,
	venue: ResourceNameTypeEnum.VENUE,
	team: ResourceNameTypeEnum.TEAM,
	league: ResourceNameTypeEnum.LEAGUE,
	user: ResourceNameTypeEnum.USER,
	organization: ResourceNameTypeEnum.ORGANIZATION,
	app: ResourceNameTypeEnum.APP,
	feed: ResourceNameTypeEnum.FEED,
	match: ResourceNameTypeEnum.MATCH,
	round: ResourceNameTypeEnum.ROUND,
	portal: ResourceNameTypeEnum.PORTAL,
	season: ResourceNameTypeEnum.LEAGUE_SEASON,
	tournament: ResourceNameTypeEnum.TOURNAMENT,
	membership: ResourceNameTypeEnum.MEMBERSHIP,
	division: ResourceNameTypeEnum.DIVISION,
	gameslot: ResourceNameTypeEnum.GAMESLOT,
	space: ResourceNameTypeEnum.SPACE,
	reservation: ResourceNameTypeEnum.RESERVATION,
	invoice: ResourceNameTypeEnum.INVOICE,
	customer: ResourceNameTypeEnum.CUSTOMER,
	package: ResourceNameTypeEnum.PACKAGE,
	facility: ResourceNameTypeEnum.FACILITY,
	program: ResourceNameTypeEnum.PROGRAM,
	program_season: ResourceNameTypeEnum.PROGRAM_SEASON,
	product: ResourceNameTypeEnum.PRODUCT,
	group: ResourceNameTypeEnum.GROUP,
	variant: ResourceNameTypeEnum.VARIANT,
};

export const useCustomers = () => {
	const { organizationId } = useOrganization();

	/**
	 * Process the relational data of the line item and return a line item object for display on an invoice or payment
	 * @param {ILineItem} lineItem - Line item and its extended data relations
	 * @returns {IDisplayLineItem} - Display line item object
	 */
	const expandLineItemData = (lineItem: ILineItem, slots?: SlotDto[], includeTaxInPrice = false) => {
		const qty = lineItem.displayFullQuantityWithVoid;
		const purchasedResource = lineItem.purchasedResources?.[0];
		const productType: ResourceNameTypeEnum = typeMapper[lineItem.productType];
		const item = {
			name: lineItem.product.name,
			description:
				lineItem.paymentStatus === PaymentStatusEnum.VOID ? TranslationEn.itemVoided : lineItem.product.description,
			secondDescription: null,
			img: lineItem.img || null,
			productType: lineItem.productType,
			isVoid: lineItem.paymentStatus === PaymentStatusEnum.VOID || lineItem.isVoided,
			parentLineItemId: lineItem.parentLineItemId,
		};

		let timePeriod: string;
		const name = Capitalize(lineItem.customerFirstName);
		switch (productType) {
			case ResourceNameTypeEnum.MEMBERSHIP:
				if (purchasedResource?.membership) {
					if (purchasedResource?.membership?.membershipType === MembershipTypeEnum.FIXED) {
						// TODO awaiting answers on display differences
						const years = Math.floor(purchasedResource?.membership?.durationMonths / 12);
						const months = purchasedResource?.membership?.durationMonths % 12;
						timePeriod = `${years > 0 ? `${years} year${years > 1 ? `s` : ``}` : ``} ${months > 0 ? months : ``} ${
							months > 1 ? `months` : months > 0 ? 'month' : ''
						}`.trim();
					}
					if (purchasedResource?.membership?.membershipType === MembershipTypeEnum.ROLLING) {
						// TODO awaiting answers on display differences
						const years = Math.floor(purchasedResource?.membership?.durationMonths / 12);
						const months = purchasedResource?.membership?.durationMonths % 12;
						timePeriod = `${years > 0 ? `${years} year${years > 1 ? `s` : ``}` : ``} ${months > 0 ? months : ``} ${
							months > 1 ? `months` : months > 0 ? 'month' : ''
						}`.trim();
					}
					item.description = Capitalize(`${purchasedResource?.membership?.customerTypes?.[0] || ''}`);
					item.secondDescription = `${timePeriod || ''} \u2022 ${name || ''}`;
				}
				break;
			case ResourceNameTypeEnum.PROGRAM_SEASON:
				if (purchasedResource?.programSeason) {
					item.description = Capitalize(`${purchasedResource?.programSeason?.program?.name || ''}`);
					item.secondDescription = createSecondDescription(purchasedResource?.programSeason?.name, name);
				}
				break;
			case ResourceNameTypeEnum.LEAGUE_SEASON:
				if (purchasedResource?.leagueSeason) {
					item.description = Capitalize(`${purchasedResource?.leagueSeason?.league?.name || ''}`);
					item.secondDescription = createSecondDescription(purchasedResource?.leagueSeason?.name, name);
				}
				break;
			case ResourceNameTypeEnum.EVENT:
				if (purchasedResource?.event) {
					item.description = Capitalize(`${purchasedResource?.event?.parentSession?.program?.name || ''}`);
					item.secondDescription = createSecondDescription(purchasedResource?.event?.parentSession?.name, name);
				}
				break;
			case ResourceNameTypeEnum.SPACE:
			case ResourceNameTypeEnum.RESERVATION:
				if (slots?.[0]?.reservation || purchasedResource?.space)
					item.description = Capitalize(`${slots?.[0]?.reservation?.name || purchasedResource?.space?.name}`);
				break;
			case ResourceNameTypeEnum.PRODUCT:
			default:
				break;
		}

		return {
			item,
			quantity: qty, // temp solution -> we need to figure out how to handle qty with events
			displayQuantity: lineItem.displayQuantity,
			price: lineItem.displayUnitPrice || lineItem.unitPrice || 0,
			paidAmount: lineItem?.displayTotalPaid || lineItem?.totalPaid,
			status: lineItem.paymentStatus,
			tax: lineItem?.taxPrecent ? `${lineItem?.taxPrecent}% ${lineItem?.isTaxInclusive ? 'inc' : ''}` : '-',
			total: includeTaxInPrice ? lineItem.displayTotalPriceWithTax : lineItem.displayFullPriceWithVoid,
			displayFullPrice: lineItem.displayFullPrice,
		};
	};

	/**
	 * Update line item with tax details
	 * @param {ILineItem} lineItem - Line item
	 * @param {{taxesExcl: ITaxType, taxesInc: ITaxType}} totalTax - Tax details
	 * @param type Called from invoice or payment
	 */
	const handleTaxesOnLineItem = (
		lineItem: ILineItem,
		totalTax: { taxesExcl: ITaxType; taxesInc: ITaxType },
		type: EDocType
	) => {
		const taxPercent = lineItem?.tax?.taxPrecent ?? lineItem?.taxPrecent;
		if (taxPercent) {
			const isInclusive = lineItem.tax?.isTaxInclusive ?? lineItem.isTaxInclusive;
			const price =
				type === EDocType.PAYMENT
					? lineItem?.tax?.paidAmount ?? lineItem?.paidAmount
					: lineItem?.tax?.price ?? lineItem?.price;
			if (isInclusive) {
				const tax = totalTax.taxesInc[taxPercent] ?? 0;
				totalTax.taxesInc[taxPercent] = roundWithDecimal(tax + price);
			} else {
				const taxPrecent = totalTax.taxesExcl[taxPercent] ?? 0;
				totalTax.taxesExcl[taxPercent] = roundWithDecimal(taxPrecent + price);
			}
		}
		return totalTax;
	};

	/**
	 * Create second description line for line item on invoice or payment display
	 * @param {string} desc - Product description (will be truncated)
	 * @param {string} customerName - Customer name (will NOT be truncated)
	 * @returns {string} - Description \u2022 Customer name with the description being truncated if needed
	 */
	const createSecondDescription = (desc: string, customerName: string): string => {
		const name = Capitalize(customerName);
		return `${Capitalize(desc || '').slice(0, 30 - name.length)}${
			name.length + (desc.length || 0) + 3 > 30 ? '...' : ''
		} \u2022 ${name}`;
	};

	/**
	 * Process all line items in an invoice and return schedules for the appendix of the invoice
	 * @param invoice Invoice object to be used to build the appendix schedule
	 * @returns Process line items into schedules (where relevant) for appendix of invoice
	 */
	const buildAppendixData = (invoice: Invoice): any[] => {
		const lineItems = invoice.lineItems ?? [];
		const appendixItems: any[] = [];
		const registrationLineItems: LineItems[] = lineItems.filter(
			li => li.productType === ProductTypesEnum.REGISTRATION && !li.isRefunded
		);
		const reservationLineItems: LineItems[] = lineItems.filter(
			li => li.productType === ProductTypesEnum.RESERVATION && !li.isRefunded
		);
		const goodsLineItems: LineItems[] = lineItems.filter(
			li => li.productType === ProductTypesEnum.GOODS && !li.isRefunded
		);
		const usedGoods: number[] = [];

		// Reservation line items are for rentals
		if (reservationLineItems?.length > 0) {
			const reservations: Reservation[] = [...new Set(invoice.slots?.map(s => JSON.stringify(s.reservation)))].map(
				str => JSON.parse(str)
			);
			const items = [];

			for (const reservation of reservations) {
				if (reservation) {
					if (reservation.addons?.length > 0) {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						usedGoods.push(reservation.addons.map(ao => ao.id));
					}

					let seriesArray: Series[] = [];
					reservation.segments?.forEach(segment => {
						if (segment?.series?.filter(ser => ser.slots?.some(sl => sl.invoiceId === invoice.id)).length) {
							seriesArray = [
								...seriesArray,
								// eslint-disable-next-line no-unsafe-optional-chaining
								...segment?.series
									?.filter(ser => ser.slots?.some(sl => sl.invoiceId === invoice.id))
									.map(series => {
										series.segment = segment;
										const resources = [];
										series.slots.forEach(slot => {
											if (segment.resourceIds.includes(slot.resource.id)) {
												resources.push(slot.resource);
											}
										});
										series.resources = [...new Set(resources)];
										return series;
									}),
							];
						}
					});

					for (const series of seriesArray) {
						const slots = series?.slots
							?.filter(sl => sl.invoiceId === invoice.id && sl.slotType !== SlotTypeEnum.MAINTENANCE)
							.sort((a, b) => String(a.startDate).localeCompare(String(b.startDate))) as Slot[];
						const spaces = series?.resources as Resource[];
						const spacesText = Capitalize(spaces?.[0]?.name ?? '').trim();
						const spacesTooltipContent =
							spaces?.length > 1 ? spaces.map(sp => Capitalize(sp.name?.trim() ?? '')).join(', ') : '';
						const slotsAddonCount = slots?.reduce(
							(acc, slot) =>
								acc +
								(slot.addons?.filter(addon =>
									goodsLineItems.map(addon => addon.productUserId).includes(addon.productUserId)
								)?.length || 0),
							0
						);
						const item = {
							description: Capitalize(series.segment?.title ?? '').trim(),
							addonCount: {
								text: slotsAddonCount > 0 ? `${slotsAddonCount} item${slotsAddonCount > 1 ? 's' : ''}` : '',
								tooltipContent: '',
								moreCount: 0,
							},
							space: { text: spacesText, tooltipContent: spacesTooltipContent, moreCount: spaces?.length - 1 ?? 0 },
							daysTimes: {
								dateRange: '',
								timeRange: [''],
							},
							addons: [],
							startDate: new Date(),
							slots: slots.map(s => {
								const eventDesc =
									(s.title ?? '').toLowerCase().trim() !== (series.segment?.title ?? '').toLowerCase().trim()
										? (s.title ?? '').toLowerCase().trim()
										: '';
								const addons =
									goodsLineItems?.filter(gli =>
										s.addons?.map(addon => addon.productUserId)?.includes(gli.productUserId as number)
									) ?? [];
								if (addons.length > 0) {
									usedGoods.push(...addons.map(a => a.id));
								}

								const addonCountText = Capitalize(addons[0]?.product?.name.trim()) ?? '';
								const addonTooltipContent =
									addons?.map(addon => Capitalize(addon.product?.name.trim())).join(', ') ?? '';
								return {
									description: eventDesc,
									subTitles: [],
									daysTimes: {
										dateRange: getDateStringWithYear(dayjs(s.startDate, 'YYYY-MM-DD').toDate()),
										timeRange: [getTimeRangeDisplay(s.startTime, s.endTime)],
									},
									addonCount: {
										text: addonCountText ?? '',
										tooltipContent: addonTooltipContent,
										moreCount: addons && addons.length > 0 ? addons?.length - 1 : 0,
									},
									startDate: s.startDate,
									space: {
										text: Capitalize(series.resources?.find(r => r.id === s.spaceId)?.name ?? '').trim(),
										tooltipContent: '',
										moreCount: 0,
									},
									approvalStatus: s.approvalStatus,
								};
							}),
						};

						item.startDate = dayjs(series.startDate).toDate();
						if ((reservation.segments as Segment[])?.[0].series?.[0].slots?.length) {
							item.daysTimes.dateRange = getMonthDayRange(
								String(slots[0].startDate),
								String(slots[slots.length - 1].endDate)
							);
							item.daysTimes.timeRange = [getTimeRangeDisplay(slots[0].startTime, slots[0].endTime)];
						}
						items.push(item);
					}
				}
			}
			appendixItems.push(...items);
		}

		// Registration line items are for program sessions
		if (registrationLineItems?.length > 0) {
			for (const li of registrationLineItems) {
				const items = [];
				for (const pr of li.purchasedResources) {
					if (Number(pr.status) !== PurchasedResourceStatusEnum.CANCELED) {
						const addons: AppendixAddonItem[] = [];
						const item: AppendixItem = {
							userId: li.userId,
							description: pr.event?.parentSession?.name ?? pr.programSeason?.name ?? '',
							addonCount: { text: '', tooltipContent: '', moreCount: 0 },
							daysTimes: {
								dateRange: '',
								timeRange: [],
							},
							addons: [],
							startDate: new Date(),
						};

						switch (pr.resourceType) {
							case ResourceNameTypeEnum.EVENT: {
								//  Get addons for this event
								const eventAttendee = pr.event?.eventAttendees?.find(a => a.attendeeId === li.userId);
								item.startDate = dayjs(pr?.event?.startDateString || '').toDate();
								if (eventAttendee) {
									const timeRange = [getTimeRangeDisplay(pr.event.startTime, pr.event.endTime)];
									item.daysTimes.dateRange = dayjs(pr.event.startDateString, 'YYYY/MM/DD').format('MMM D, YYYY');
									item.daysTimes.timeRange = timeRange;
									getTimeRangeDisplay(pr.event.startTime || '', pr?.event?.endTime || '');
									if (eventAttendee.addonProductUserIds && eventAttendee.addonProductUserIds?.length > 0) {
										// eslint-disable-next-line no-unsafe-optional-chaining
										for (const apr of eventAttendee.addonProductUserIds) {
											const addon = goodsLineItems.find(gli => gli.productUserId === apr);
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											usedGoods.push(addon!.id);
											if (addon) {
												const dateRange = getDateStringWithYear(dayjs(pr.event.startDate).toDate());
												item.daysTimes.dateRange = dateRange;
												item.daysTimes.timeRange = timeRange;

												const existingAddon = addons.find(ao => ao.daysTimes.dateRange === dateRange);
												if (existingAddon) {
													existingAddon.daysTimes.timeRange.push(
														getTimeRangeDisplay(pr.event.startTime, pr.event.endTime)
													);
												} else {
													addons.push({
														description: '',
														subTitles: [],
														daysTimes: {
															dateRange,
															timeRange,
														},
														addonCount: addon.product?.name,
														startDate: dayjs(pr.event.startDateString).toDate(),
													});
												}
											}
										}
									}
								}
								break;
							}
							case ResourceNameTypeEnum.PROGRAM_SEASON:
								item.startDate = dayjs((pr.programSeason as any)?.startDateString).toDate();
								item.daysTimes.dateRange = getMonthDayRange(
									String(pr.programSeason?.startDate),
									String(pr.programSeason?.endDate)
								);
								break;
							default:
								break;
						}
						item.addonCount = {
							text: addons.length > 0 ? `${addons.length} item${addons.length > 1 ? 's' : ''}` : ``,
							tooltipContent: '',
							moreCount: 0,
						};
						item.addons = [...addons].sort((a, b) => {
							return a.startDate.getTime() - b.startDate.getTime();
						});
						items.push(item);
					}
				}
				appendixItems.push(...items);
			}
		}

		const sortedAppendixItems = [...appendixItems].sort((a, b) => {
			return a.startDate.getTime() - b.startDate.getTime();
		});

		//  Add all non connected line items of type GOODS as a line item

		if (!reservationLineItems) {
			const remainingGoods = goodsLineItems.filter(gli => !usedGoods.includes(gli.id));
			if (remainingGoods?.length > 0) {
				for (const gli of remainingGoods) {
					let qty = gli.quantity || 1;
					while (qty--) {
						sortedAppendixItems.push({
							description: '',
							addonCount: { text: gli.product.name, tooltipContent: '' },
							daysTimes: {
								dateRange: '',
								timeRange: [],
							},
							addons: [],
							startDate: dayjs().toDate(),
						});
					}
				}
			}
		}

		return sortedAppendixItems;
	};

	/**
	 * Build the redirect links to various parts of the invoice flow
	 * @param {string} currentUrl - Pathname of the current url
	 * @returns {RedirectUrls} - All the redirect links for invoices
	 */
	const buildRedirectUrls = (currentUrl: string): RedirectUrls => {
		// Customer list is located at the root of customers
		const customerListUrl = URL_SEPARATOR;

		const splitUrl = currentUrl.split(URL_SEPARATOR).filter(Boolean);

		let invoiceUrl = buildUrlNavUrl(ERedirectUrlType.INVOICES, splitUrl);
		invoiceUrl = invoiceUrl ? `/${invoiceUrl}` : null;

		const voidUrl = `${invoiceUrl}${VOID_URL_SUFFIX}`;

		const refundUrl = `${invoiceUrl}${REFUND_URL_SUFFIX}`;

		const customerUrl = buildUrlNavUrl(ERedirectUrlType.CUSTOMER, splitUrl);
		const familyUrl = buildUrlNavUrl(ERedirectUrlType.FAMILY, splitUrl);
		const paymentUrl = `${customerUrl || familyUrl}${PAYMENT_URL_SUFFIX}`;

		return { invoiceUrl, voidUrl, refundUrl, customerUrl, customerListUrl, paymentUrl };
	};

	/**
	 * Build the redirect link to the specified page
	 * @param type - The type of page to redirect to
	 * @param splitUrl - The url of the current page split into an array
	 */
	const buildUrlNavUrl = (type: ERedirectUrlType, splitUrl: string[]) => {
		const typeIdx = splitUrl.findIndex(urlPart => urlPart === type);
		return typeIdx > INDEX_NOT_FOUND
			? `${splitUrl.slice(START_SPLIT_INDEX, typeIdx + ADD_TO_SPLIT_INDEX).join(URL_SEPARATOR)}`
			: null;
	};

	/**
	 * Build steps for breadcrumb navigation
	 * @param {string} currentUrl - Pathname of the current url
	 * @param {currentPageType} currentPage - Where the breadcrumb navigation will be used
	 */
	const buildBreadCrumbData = (currentUrl: string, currentPage: currentPageType): IBreadcrumbStep[] => {
		const breadcrumbLabels = TranslationEn.customers.paymentsInvoices.voidPage.breadcrumbs;
		const { invoiceUrl, customerUrl, customerListUrl, voidUrl, refundUrl } = buildRedirectUrls(currentUrl);
		const steps: IBreadcrumbStep[] = [];

		steps.push({ label: breadcrumbLabels.customerList, to: customerListUrl });

		switch (currentPage) {
			case 'refund':
				steps.push(
					{ label: breadcrumbLabels.customer, to: customerUrl },
					{ label: breadcrumbLabels.invoice, to: invoiceUrl },
					{ label: breadcrumbLabels.refund, to: refundUrl }
				);
				break;
			case 'void':
				steps.push(
					{ label: breadcrumbLabels.customer, to: customerUrl },
					{ label: breadcrumbLabels.invoice, to: invoiceUrl },
					{ label: breadcrumbLabels.void, to: voidUrl }
				);
				break;
			default:
				break;
		}

		return steps;
	};

	/**
	 * Get the invoice by invoice id from the api
	 * @param {number} customerId Customer attached to the requested invoice
	 * @param {number} invoiceId  ID of the requested invoice
	 * @param {boolean} extended  Request the purchased resource data
	 * @returns {Promise<any>} Requested invoice
	 */
	const fetchInvoiceById = async (customerId: number, invoiceId: number, extended = false, orgId?: number) => {
		return await customersApi.getInvoiceById_DEPRICATED({
			customerId: Number(customerId) || 1,
			invoiceId: Number(invoiceId),
			extended,
			organizationId: orgId || organizationId,
		});
	};

	/**
	 * Get a customer by user id and organization id
	 * @param {number} organizationId Organization id of the requested customer
	 * @param {number} userId Paying user id from an invoice
	 * @returns {Promise<any>}  Customer from the requested organization + user
	 */
	const fetchCustomerByUserId = async (organizationId: number, userId: number) => {
		return await customersApi.getCustomerByUserId(organizationId, userId);
	};

	/**
	 * Get entitlement groups for an organization by ids
	 * @param {number} organizationId Requested organization id
	 * @param {string} entitlementGroups  Comma delimited string of entitlement group ids
	 * @returns {Promise<any>}  Entitlement group records
	 */
	const fetchEntitlementGroupsByOrg = async (organizationId: number, entitlementGroups: string) => {
		return await membershipApi.getEntitlementGroupsByOrganiztionId(organizationId, entitlementGroups);
	};

	/**
	 * Builds the data for refund/void payment page relating to items/line items
	 * @param {number} organizationId Requested organization id
	 * @param {IPayment} payment  The payment to process
	 * @returns {Promise<IPaymentDetailValues>}  The values needed to display the payment
	 */
	const buildPaymentDetailValues = async (organizationId: number, payment: IPayment): Promise<IPaymentDetailValues> => {
		const lineItems = {};
		let calculatedOriginalPrice = 0;
		const taxesInc = {};
		const taxesExcl = {};
		let creditCardCounter = 0;

		if (!payment.lineItems) {
			return {} as IPaymentDetailValues;
		}

		payment.lineItems
			.filter(
				li =>
					!li.isRefunded &&
					!li.isVoided &&
					[PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.PARTIAL_PAYMENT].includes(li.paymentStatus)
			)
			.sort((a, b) => {
				return a.id - b.id;
			})
			.forEach(line => {
				if (line.productType === ProductTypesEnum.TAX) {
					if (lineItems[line.previousPurchaseLineId]) {
						lineItems[line.previousPurchaseLineId].tax = line;
					}
				} else {
					lineItems[line.id] = line;
				}
			});

		const parentLineItems: ILineItem[] = Object.values(lineItems).filter(
			li => !(li as ILineItem).parentLineItemId
		) as ILineItem[];

		const items = Object.keys(lineItems)
			.filter(itemId => !lineItems[itemId].isVoided && lineItems[itemId].paymentStatus !== 'voided')
			.filter(itemId => {
				const item = lineItems[itemId];
				return item.price >= 0;
			})
			.map(itemId => {
				const item = lineItems[itemId];

				handleTaxesOnLineItem(item, { taxesExcl, taxesInc }, EDocType.PAYMENT);
				const obj = expandLineItemData(item, payment?.slots);

				calculatedOriginalPrice += item.unitPrice;
				let priceToRefund = item.unitPrice;
				if (item.tax && !item.tax.isTaxInclusive) {
					priceToRefund = item.unitPrice + item.tax.price;
				}

				return {
					...obj,
					id: item.id,
					priceToRefund,
					isDisabled: !!parentLineItems.find(li => li.id === obj.item.parentLineItemId),
					priceToVoid: item.unitPrice,
					totalPriceToVoid: item.totalPrice + Math.abs(item.discountAmount),
				};
			});

		const payments = payment.invoiceToPayments.map(invoice => {
			if (
				[EPaymentMethod.CARD, EPaymentMethod.CARD_ON_TERMINAL].includes(invoice?.payment?.paymentType) &&
				invoice.paidAmount > 0
			) {
				creditCardCounter++;
			}
			const paymentObj = {
				date: invoice.updatedAt,
				id: invoice.payment.id,
				method: invoice.payment.paymentType,
				amount: invoice.payment.price,
			};
			return paymentObj;
		});

		return {
			totalTax: { taxesExcl, taxesInc },
			itemRows: items,
			paymentRows: payments,
			creditCardCounter,
			originalPrice: calculatedOriginalPrice,
		};
	};

	/**
	 * Request to void entire invoice. Will only work if the invoice is completely unpaid
	 * @param {number} organizationId Requested organization id
	 * @param {IPayment} invoice  The invoice to void
	 * @returns {Promise<IPayment>} Voided invoice
	 */
	const postVoid = async (organizationId: number, invoiceId: number) => {
		return await customersApi.voidInvoice(organizationId, invoiceId);
	};

	/**
	 * Request to void individual line items from an invoice
	 * @param {number} organizationId Requested organization id
	 * @param {number} invoiceId  The invoice to void the line items on
	 * @param {number[]} lineItems  Array of line item
	 * @returns {Promise<IPayment>} Updated invoice after voiding line items
	 */
	const postVoidItems = async (organizationId: number, invoiceId: number, lineItems: any[], body?: any) => {
		return await customersApi.voidInvoiceItems(organizationId, invoiceId, lineItems, body);
	};

	interface IResponse {
		appendixData: any;
		discounts: any;
		totalDiscountAmount: any;
		totalTax: any;
		originalPrice: any;
		items: any;
		payments: any;
	}

	const generateLineItemsRows = async ({
		data,
		organizationId,
		totalTax,
		includeTaxInPrice = false,
	}: {
		data: any;
		organizationId: number;
		totalTax: any;
		includeTaxInPrice?: boolean;
		// eslint-disable-next-line require-await
	}): Promise<IResponse> => {
		const response: any = {
			discounts: [],
		};

		let entitlementGroups = '';
		const entitlementObj: any = {};
		let totalDiscountAmount = 0;
		const discounts: any[] = [];
		let calculatedOriginalPrice = 0;
		const lineItems: any = {};
		data.lineItems
			.sort((a: any, b: any) => {
				return a.id - b.id;
			})
			.forEach((line: any) => {
				if (line.productType === ProductTypesEnum.TAX) {
					if (lineItems[line.parentLineItemId]) {
						lineItems[line.parentLineItemId].tax = line;
						if (includeTaxInPrice) {
							lineItems[line.parentLineItemId].price = lineItems[line.parentLineItemId].price + line.price;
							lineItems[line.parentLineItemId].totalPrice = lineItems[line.parentLineItemId].price + line.price;
						}
					}
				} else {
					lineItems[line.id] = line;
				}
			});

		const appendixData = buildAppendixData(data);
		response.appendixData = appendixData;

		const items = Object.keys(lineItems).map(key => {
			const item = lineItems[key];
			const entitlementId = item.entitlementGroupId;
			calculatedOriginalPrice += item.price;
			if (entitlementId && entitlementId !== -1) {
				if (entitlementObj[entitlementId]) {
					entitlementObj[entitlementId].amount =
						entitlementObj[entitlementId].amount - (item.price - item.originalPrice);
				} else {
					if (entitlementGroups === '') {
						entitlementGroups = `${entitlementId}`;
					} else {
						entitlementGroups += `,${entitlementId}`;
					}
					entitlementObj[entitlementId] = {
						amount: item.price - item.originalPrice,
						percentage: Number(100 - (item.price / item.originalPrice) * 100).toFixed(2),
					};
				}
				totalDiscountAmount += item.price - item.originalPrice;
			}

			if (entitlementId === -1) {
				totalDiscountAmount += item.price - item.originalPrice;
				const DiscountObj = {
					createdAt: '',
					id: `${item.id}-1`,
					name: item.discountDescription,
					organizationId: organizationId,
					updatedAt: '',
					amount: item.price - item.originalPrice,
					percentage: Number(100 - (item.price / item.originalPrice) * 100).toFixed(2),
				};
				discounts.push(DiscountObj);
			}

			// TBD - refactor requierd
			membershipApi.getEntitlementGroupsByOrganiztionId(organizationId, entitlementGroups).then(response => {
				const relevantEntitlement = entitlementGroups.split(',');
				response.forEach((entitlement: any) => {
					if (relevantEntitlement.includes(String(entitlement.id))) {
						discounts.push({ ...entitlement, ...entitlementObj[entitlement.id] });
					}
				});
				response.discounts = discounts;
				response.totalDiscountAmount = totalDiscountAmount;
			});
			// ------------------------

			const totalTaxValue = handleTaxesOnLineItem(item, totalTax, EDocType.INVOICE);
			response.totalTax = totalTaxValue;
			return expandLineItemData(item, data.slots, includeTaxInPrice);
		});

		response.originalPrice = calculatedOriginalPrice;
		response.items = items;
		// handle payments rows
		const payments = data?.invoiceToPayments?.map((invoice: any) => {
			const paymentObj = {
				date: invoice.payment.createdAt,
				id: invoice.payment.id,
				paymentType: invoice.payment.paymentType,
				ccLast4: invoice.payment.ccLast4,
				ccBrand: invoice.payment.ccBrand,
				amount: invoice.payment.price,
				stripePaymentMethodId: invoice.payment.stripePaymentMethodId,
				paymentFees: invoice.payment.paymentFees ?? [],
				status: invoice.payment.status,
			};
			return paymentObj;
		});
		response.payments = payments ?? [];

		return response;
	};

	return {
		expandLineItemData,
		handleTaxesOnLineItem,
		buildAppendixData,
		buildRedirectUrls,
		buildBreadCrumbData,
		buildPaymentDetailValues,
		fetchInvoiceById,
		fetchCustomerByUserId,
		fetchEntitlementGroupsByOrg,
		postVoid,
		generateLineItemsRows,
		postVoidItems,
	};
};
