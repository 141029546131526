// organizationId
import { atom } from 'recoil';
import { Facility } from '../types/facility';
import { Organization } from '../types/organization';
import { Resource } from '../types/resource';
import { Addon } from 'app/react/types/addon';
import { ICustomer } from 'app/react/types/customers';
import { Membership } from 'app/react/types/membership';
import { Questionnaires } from 'app/react/types/organization';

// should add session interface
const orgnanizationId = atom<number>({
	key: 'orgnanizationId',
	default: 0,
});

export interface IPermission {
	id: number;
	createdAt: string;
	updatedAt: string;
	name: string;
	deletedAt?: string;
}

const permissions = atom<IPermission[]>({
	key: 'organizationPermissions',
	default: [],
});

const organizationActivities = atom<[]>({
	key: 'organizationActivities',
	default: [],
});

const customers = atom<ICustomer[]>({
	key: 'customers',
	default: [],
});

const memberships = atom<Membership[]>({
	key: 'memberships',
	default: [],
});

const forms = atom<Questionnaires[]>({
	key: 'forms',
	default: [],
});

const resources = atom<Resource[]>({
	key: 'resources',
	default: [],
});

const addons = atom<Addon[]>({
	key: 'addons',
	default: [],
});

const isFetchingAddons = atom<boolean>({
	key: 'isFetchingAddons',
	default: true,
});

const sports = atom<number[]>({
	key: 'sports',
	default: [],
});

const organizationData = atom<Organization>({
	key: 'organizationData',
	default: {},
});

const isOrganizationFetching = atom<boolean>({
	key: 'isOrganizationFetching',
	default: false,
});

const isOrganizationProfileCompleted = atom<boolean>({
	key: 'isOrganizationProfileCompleted',
	default: false,
});

const isFacilitiesNotYetFetched = atom<boolean>({
	key: 'isFetchingFacilities',
	default: true,
});

const refreshResourcesNeeded = atom<boolean>({
	key: 'refreshResourcesNeeded',
	default: true,
});

const organizationSports = atom<number[]>({
	key: 'organizationSports',
	default: [],
});

const facilities = atom<Facility[]>({
	key: 'facilities',
	default: [],
});

const organizationLocation = atom<any>({
	key: 'organizationLocation',
	default: undefined,
});

const organizationTimezone = atom<string>({
	key: 'organizationTimezone',
	default: null,
});

const isFetchingfacilities = atom<boolean>({
	key: 'isFetchingfacilities',
	default: true,
});

export const organizationStore = {
	orgnanizationId,
	permissions,
	organizationData,
	isOrganizationFetching,
	isOrganizationProfileCompleted,
	isFacilitiesNotYetFetched,
	refreshResourcesNeeded,
	organizationSports,
	facilities,
	organizationLocation,
	resources,
	organizationActivities,
	customers,
	memberships,
	forms,
	addons,
	isFetchingAddons,
	sports,
	organizationTimezone,
	isFetchingfacilities,
};
