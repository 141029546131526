export enum MoreMenuEnumEmployee {
	DELETE = 'delete',
	EDIT = 'edit',
	ARCHIVED = 'archived',
	STATUS_CHANGE = 'statusChange',
	UNARCHIVED = 'unarchived',
	VIEW = 'view',
}

export enum SortColumnsEnum {
	EMAIL = 'email',
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	LAST_LOGIN = 'lastLogin',
	STATUS = 'status',
	EMPLOYMENT_START_DATE = 'employmentStartDate',
	EMPLOYMENT_END_DATE = 'employmentEndDate',
	REFERENCE_ID = 'referenceId'
}


export enum EmployeeFiltersEnum {
	FACILITIESIDS = 'facilitiesIds',
	STATUSES = 'statuses',
	ROLE = 'roles',
}