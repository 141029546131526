/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { BnIcon } from '../../../components/icons_v2';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { paymentMethodButtonCss } from './style';

interface Props {
	icon: Icons;
	label: string;
	onClick: () => void;
}
export const AddPaymentMethodBtton = ({ icon, label, onClick }: Props) => {
	return (
		<button data-aid="addPaymentMethodBtton" css={paymentMethodButtonCss} onClick={onClick}>
			<BnIcon icon={icon} />
			<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
				{label}
			</Typography>
		</button>
	);
};
