/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import { ETypography, ETypographyColor } from '../../../types';
import { Typography } from '../../../atoms';

export const PaymentFlowHeader = ({ labels }: { labels: any }) => {
	return (
		<header>
			<Typography type={ETypography.h5} color={ETypographyColor.primary}>
				{labels.title}
			</Typography>
			<Typography type={ETypography.body1} color={ETypographyColor.primary}>
				{labels.subTitle}
			</Typography>
		</header>
	);
};
