/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

export const noContentCellCss = css`
	margin: auto;
	width: 50%;
	text-align: center;
`;

export const noContentCellTextCss = css`
	font-size: 1.5rem;
	display: inline-block;
	text-align: center;
	-webkit-transform: scale(2, 1); /* Safari and Chrome */
	-moz-transform: scale(2, 1); /* Firefox */
	-ms-transform: scale(2, 1); /* IE 9 */
	-o-transform: scale(2, 1); /* Opera */
	transform: scale(2, 1); /* W3C */
`;
