import { useState, useEffect } from 'react';

export enum SoundEffect {
  CheckInSuccess = 'checkin-success.wav',
  CheckInWarn = 'checkin-warn.wav',
  CheckInError = 'checkin-error.wav',
}

/**
 * Play sound effects.
 * 
 * To use, simply `playSoundEffect(SoundEffect.CheckInSuccess)`
 * 
 * One more thing... you can override the default sounds effects with LocalStorage.
 * To override: `overrideSoundEffect(SoundEffect.CheckInSuccess, file)`
 * To undo the override: 'overrideSoundEffect(SoundEffect.CheckInSuccess)` with no file.
 * NB: As we're using LocalStorage, this will only affect the current browser.
 *  
 */
const useAudio = () => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  /**
   * Play the sound effect.
   * Will play the user's uploaded sound effect if it exists in LocalStorage, otherwise will play the default sound effect.
   * 
   * @param effectName The pre-defined Effect to play.
   */
  const playSoundEffect = async (effectName: SoundEffect) => {
    const playedFromLocalStorage = await playAudioFromLocalStorage(effectName);
    if (!playedFromLocalStorage) {
      playAudioFromFilename(effectName);
    }
  };

  /**
   * Override's a default SoundEffect in the current browser via LocalStorage.
   * NB: LocalStorage persists when the browser is closed and when the cache is cleared.
   * 
   * Call with a null file to remove the override.
   * 
   * @param effectName The pre-defined Effect to override.
   * @param file The sound file (wav, mp3, etc) to use for the override.  Null to reset to default.
   * @returns The filename of the overriden sound effect, if any.
   */
  const overrideSoundEffect = (effectName: SoundEffect, file?: File): string | null => {
    if (file) {
      storeAudioInLocalStorage(effectName, file);
      localStorage.setItem(filenameStorageKey(effectName), file.name);
      return file.name;
    } else {
      localStorage.removeItem(effectName);
      localStorage.removeItem(filenameStorageKey(effectName));
      return null;
    }
  };

  /**
   * If a SoundEffect has been overridden, returns the filename uploaded by the user.
   * 
   * @param effectName The pre-defined Effect for which to get the filename.
   * @returns The filename of the overriden sound effect, if any.
   */
  const filenameForOverridenSoundEffect = (effectName: SoundEffect) => {
    const name = localStorage.getItem(filenameStorageKey(effectName));
    return name;
  }

  // private methods
  const storeAudioInLocalStorage = (key: string, file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
        try {
            try {  
            localStorage.setItem(key, reader.result as string);  
        } catch (error) {
            console.error(`Failed to store ${key} in localStorage`, error);
        }
        } catch (error) {  
            console.error(`Failed to store ${key} in localStorage`, error);  
        }
    };
    reader.readAsDataURL(file);
  };

  const playAudioFromFilename = (filename: string) => {
    if (!audio || audio.paused) {
        const url = `assets/media/audio/${filename}`;
        const audio = new Audio(url);
        setAudio(audio);
        audio.play();
    }
  };

  const playAudioFromLocalStorage = async (key: string) => {
    try {
      const base64Audio = localStorage.getItem(key);
      if (base64Audio) {
        const response = await fetch(base64Audio);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const audio = new Audio(url);
        setAudio(audio);
        audio.play();
        return true;
      }
    } catch (error) {
      console.error(`Failed to load ${key} from localStorage`, error);
    }
    return false;
  };

  const filenameStorageKey = (key: string) => `${key}-filename`;

  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
        audio.src = '';
      }
    };
  }, [audio]);

  return {
    playSoundEffect,
    overrideSoundEffect,
    filenameForOverridenSoundEffect
  };
};

export default useAudio;