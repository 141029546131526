/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode, useCallback } from 'react';
import { jsx, css } from '@emotion/react';
import {BnIcon, Button, ETypography, ETypographyColor, Icons, Typography, TSize, TButton} from '@bondsports/utils';
import { colors_v2 } from '../../../styles/theme';
import { flexCol, flexRowCss } from '../../../styles/utils';
import { fullWidthCss } from '../../../styles/utils';
import { BackButton } from './BackButton';

type TPosition = 'left' | 'center' | 'right';

interface ContainerCssProps {
	isExpanded: boolean;
	isThreeButtons: boolean;
	isTwoButtons: boolean;
	singleButtonPosition: TPosition;
	hideSeparator?: boolean;
	height?: string;
	width?: string;
	verticalFooter?: boolean;
	footerExtraExists?: boolean;
	footerPaddingBottom?: string;
}
interface IProps {
	children: ReactNode;
	icon?: Icons;
	iconColor?: string;
	title: string;
	description?: string;
	descriptionComponent?: ReactNode;
	handleBack?: () => void;
	actionButtonText?: string;
	actionButtonDisabled?: boolean;
	actionButtonInProgress?: boolean;
	singleButtonPosition?: TPosition;
	handleActionButton?: (v?: unknown) => void;
	subButtonText?: string;
	handleSubButton?: (e?: unknown) => void;
	thirdButtonText?: string;
	handleThirdButton?: () => void;
	isExpanded?: boolean;
	subButtonInProgress?: boolean;
	footerExtra?: ReactNode;
	subButtonTheme?: TButton;
	centralizedFooter?: boolean;
	height?: string;
	width?: string;
	hideSeparator?: boolean;
	verticalFooterBtns?: boolean;
	footerPaddingBottom?: string;
	actionButtonsSize?: TSize;
	footerButtonsGap?: number;
}

const iconCss = (color: string) => css`
	color: ${color || 'unset'};
`;

const containerCss = ({
	isExpanded,
	isThreeButtons,
	isTwoButtons,
	singleButtonPosition = 'center',
	hideSeparator = false,
	height,
	width,
	verticalFooter = false,
	footerExtraExists = false,
	footerPaddingBottom,
}: ContainerCssProps) => css`
	display: flex;
	flex-direction: column;
	width: ${width ? width : isExpanded ? '520px' : '600px'};
	${height && `height: ${height}`};

	header {
		padding: 36px 0 24px 0;
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: center;
		border-bottom: 1px solid ${colors_v2.bg_border_seperator};

		.h4 {
			text-align: center;
		}
	}

	main {
		flex-grow: 1;
		overflow-y: auto;
	}

	footer {
		display: flex;
		${!hideSeparator && `border-top: 1px solid ${colors_v2.bg_border_seperator};`};
		padding: ${verticalFooter || isThreeButtons
			? '24px 0 4px 0'
			: `24px 40px ${footerPaddingBottom || (footerExtraExists ? '0' : 'calc(24px - 1rem)')} 24px`};

		${isThreeButtons
			? `
            align-items: center;
            justify-content: center;
            justify-self: center;
            flex-wrap: wrap;
            gap:16px;
                `
			: isTwoButtons || singleButtonPosition === 'right'
			? `
            justify-content: flex-end;
            gap: 16px;
            `
			: `
            justify-content:center;
            `}

		${isThreeButtons &&
		!isTwoButtons &&
		`
			display: flex;
			flex-direction: column;
			gap: 8px;
		`}
		.frame-wrapper {
			display: flex;
			gap: 8px;
			width: 100%;
			justify-content: center;
			align-items: center;
		}
	}
`;

const footerContainerCss = (extraExists: boolean, isThreeButtons: boolean) => css`
	${flexRowCss};
	flex-grow: 1;
	justify-content: ${extraExists ? 'space-between' : isThreeButtons ? 'center' : 'flex-end'};
`;

const footerExtraCss = css``;

const footerButtonsCss = (verticalLayout = false, footerButtonsGap = 8) => css`
	${!verticalLayout ? flexRowCss : flexCol};
	${verticalLayout && 'flex-direction: column-reverse !important;'}
	gap: ${footerButtonsGap}px;
`;

/**
 *
 * @param children -> would be rendered in main tag as the body of the modal
 * @param icon -> type Icons
 * @param iconColor -> string of the color for the icon
 * @param title -> the title in the header
 * @param descripition -> the description in the header
 * @param handleBack -> the function of clicking "back" - if exist the button would be rendered
 * @param actionButtonText -> the label of the main button
 * @param actionButtonDisabled -> the disable of the main button
 * @param actionButtonInProgress -> the isProcessing of the main button (loading spinner)
 * @param handleActionButton -> the onClick of the main button
 * @param subButtonText -> the label of the secondary button
 * @param handleSubButton -> the onClick of the secondary button
 * @param thirdButtonText -> the label of the basic button
 * @param handleThirdButton -> the onClick of the basic button
 * @param isExpanded -> switching width between 520 and 600 px, by default its false ( 520px )
 * @param subButtonInProgress -> the processing indication for the subButton
 * @returns
 */
export const FlowLayout = ({
	children,
	icon,
	iconColor = '',
	title,
	description,
	descriptionComponent,
	handleBack,
	actionButtonText,
	actionButtonDisabled,
	actionButtonInProgress,
	singleButtonPosition,
	handleActionButton,
	subButtonText,
	isExpanded = false,
	handleSubButton,
	thirdButtonText,
	handleThirdButton,
	subButtonInProgress,
	footerExtra,
	subButtonTheme,
	centralizedFooter = false,
	height,
	width,
	hideSeparator,
	verticalFooterBtns = false,
	footerPaddingBottom,
	actionButtonsSize = 'S',
	footerButtonsGap,
}: IProps) => {
	const isThreeButtons = Boolean(thirdButtonText);
	const isTwoButtons = Boolean(subButtonText);
	const Frames = useCallback(({ children }: { children: ReactNode }) => {
		return <div className="frame-wrapper">{children}</div>;
	}, []);

	const Frame = subButtonText && actionButtonText && thirdButtonText ? Frames : Fragment;

	return (
		<div
		    data-aid="FlowLayout"
			css={containerCss({
				isExpanded,
				isThreeButtons,
				isTwoButtons,
				singleButtonPosition,
				hideSeparator,
				height,
				width,
				verticalFooter: verticalFooterBtns,
				footerExtraExists: Boolean(footerExtra),
				footerPaddingBottom,
			})}
		>
			<header data-aid="FlowLayout-header">
				{handleBack && <BackButton handleBack={handleBack} />}
				{icon && <BnIcon css={iconCss(iconColor)} icon={icon} />}

				<Typography type={ETypography.h4} color={ETypographyColor.primary}>
					{title}
				</Typography>

				{description && (
					<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
						{description}
					</Typography>
				)}
				{descriptionComponent && <div css={fullWidthCss}>{descriptionComponent}</div>}
			</header>
			<main>{children}</main>
			<footer data-aid="FlowLayout-footer">
				<Frame>
					<div css={footerContainerCss(Boolean(footerExtra), Boolean(thirdButtonText || centralizedFooter))}>
						{footerExtra && <div css={footerExtraCss}>{footerExtra}</div>}
						<div css={footerButtonsCss(verticalFooterBtns, footerButtonsGap)}>
							{subButtonText && (
								<Button
									data-aid="button-FlowLayout-second"
									sizer="S"
									onClick={(e) => handleSubButton(e)}
									theme={subButtonTheme ?? 'secondary'}
									disabled={subButtonInProgress}
								>
									{subButtonText}
								</Button>
							)}
							{actionButtonText && (
								<Button
									data-aid="button-FlowLayout-main"
									sizer={actionButtonsSize}
									disabled={actionButtonDisabled}
									onClick={handleActionButton}
									isProcessing={actionButtonInProgress}
									theme="primary"
									data-testid={'action-main-button'}
								>
									{actionButtonText}
								</Button>
							)}{' '}
						</div>
					</div>
				</Frame>
				{thirdButtonText && (
					<Button sizer="S" onClick={handleThirdButton} theme="basic" isProcessing={subButtonInProgress}>
						{thirdButtonText}
					</Button>
				)}
			</footer>
		</div>
	);
};
