/** @jsx jsx */
import React, { ReactNode, Fragment, FC } from 'react';
import { jsx } from '@emotion/react';
import { dynamicHeightCss, flexCol, gapCss } from '@app/react/styles/utils';

const HEADER_AND_ACTION_HEIGHT = 280;

interface Props {
	actions?: ReactNode;
	subActions?: ReactNode;
	gap?: number;
	children: ReactNode;
}

export const DataViewPage: FC<Props> = ({ actions, subActions, children, gap = 20 }) => {
	return (
		<Fragment>
			<div css={[flexCol, gapCss(gap)]}>
				{actions && <div data-aid="data-view-actions-Wrapper">{actions}</div>}
				{subActions && <div data-aid="data-view-actions-Wrapper">{subActions}</div>}
				<div css={dynamicHeightCss(HEADER_AND_ACTION_HEIGHT)} data-aid="data-view-actions-Wrapper">
					{children}
				</div>
			</div>
		</Fragment>
	);
};
