export enum EDurations {
	DAY = 'day',
	WEEK = 'week',
	MINUTES = 'minutes',
	MONTH = 'month',
	YEAR = 'year',
	YEARS = 'years',
	HOURS = 'hours',
}

export enum EDateTimeFormats {
	YYYY_MM_DD = 'YYYY-MM-DD', //ISO 8601 format, the standard format across the BO.
	SLASHED_DATE = 'YYYY/MM/DD',
	DAY_FORMAT = 'MMM DD, YYYY',
	DAY_OF_WEEK_FORMAT = 'ddd',
	DAY_FORMAT_WITH_DAY_OF_WEEK = 'ddd, MMM DD, YYYY',
	MMMM_YYYY = 'MMMM YYYY',
	MMM_DD = 'MMM DD',
	ddd_MMM_DD = 'ddd, MMM DD',
	DD_MM_YYY_SLASHED = 'DD/MM/YYYY',
	H24_WITH_SECONDS = 'HH:mm:ss', //14:24:19
	H24_WITH_MINUTES = 'HH:mm', //14:24
	H12_WITH_MINUTES = 'hh:mm', //02:24
	H12_ampm = 'hh:mm a', //02:24 pm  //TODO: create a formatting function
	DAY_OF_WEEK_AND_1H12_AMPM = 'ddd hh:mm a',
	MONTH_DAY_COMMA_YEAR = 'MMM DD, YYYY',
	DAY = 'ddd',
	DATE_FORMAT_WITH_SINGLE_DIGIT_DAY = 'MMM D, YYYY',
	LONG_DATE = 'ddd - MMM D',
	DATE = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
	MMM = 'MMM',
	MMMM = 'MMMM',
}

export enum ETimeFormats {
	H24_WITH_SECONDS = 'HH:mm:ss', //14:24:19
	H24_WITH_MINUTES = 'HH:mm', //14:24
	H12_WITH_MINUTES = 'hh:mm', //02:24
	H12_ampm = 'hh:mm a', //02:24 pm
	H12_AMPM_UPPERCASE = 'hh:mm A', //02:24 PM
}

export enum EDaysLong {
	MONDAY = 'Monday',
	TUESDAY = 'Tuesday',
	WEDNESDAY = 'Wednesday',
	THURSDAY = 'Thursday',
	FRIDAY = 'Friday',
	SATURDAY = 'Saturday',
	SUNDAY = 'Sunday',
}

export enum EDaysShort {
	MON = 'Mon',
	TUE = 'Tue',
	WED = 'Wed',
	THU = 'Thu',
	FRI = 'Fri',
	SAT = 'Sat',
	SUN = 'Sun',
}

export enum EMonthsShort {
	JAN = 'Jan',
	FEB = 'Feb',
	MAR = 'Mar',
	APR = 'Apr',
	MAY = 'May',
	JUN = 'Jun',
	JUL = 'Jul',
	AUG = 'Aug',
	SEP = 'Sep',
	OCT = 'Oct',
	NOV = 'Nov',
	DEC = 'Dec',
}
