import { IGenericFilters } from '@app/react/types/filters';
import { ActivityEnum, SportsEnum } from '@bondsports/types';
import { ConnectedResourcesFiltersEnum } from '../../customers/ManagePaymentMethods/types';

export interface InstructorRow {
	id: number;
	firstName: string;
	lastName: string;
	email?: string;
	status: ActivityEnum;
	facilities: string[];
	activities: SportsEnum[];
}

export type TAvailableFlters = { facilities: { id: number; name: string }[]; activities: number[] };

export enum InstructorsFiltersEnum {
	FACILITIESIDS = 'facilitiesIds',
	STATUSES = 'statuses',
	SPORTS = 'sports',
}
export interface IInstructorsFilters extends IGenericFilters {
	[InstructorsFiltersEnum.FACILITIESIDS]: number[];
	[InstructorsFiltersEnum.STATUSES]: number[];
	[InstructorsFiltersEnum.SPORTS]: number[];
	[ConnectedResourcesFiltersEnum.TYPES]: number[];
	// TODO: Uncomment after infinite scroll is implemented
	// [ReservationsFiltersEnum.RESOURCE_IDS]: number[];

}

export interface IUseInstrocturesFilters {
	debouncedSelectedFilters: IInstructorsFilters;
	flatSelectedFilters: number[];
	onSelectFilter: (value: boolean, valueOfChecked: number, sectionClicked: keyof IInstructorsFilters) => void;
}

export enum RowActionsEnum {
	EDIT = 'edit',
	DEACTIVATE = 'deactivate',
	ACTIVATE = 'activate',
}

export interface IRowActions {
	instructorId: number;
	status: ActivityEnum;
	organizationId: number;
}

export type TRowActionsPopup = IRowActions & { refetchInstructors: () => Promise<void> };
