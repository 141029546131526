import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { capitalize } from '../../functions';
import { StyledCheckbox } from './style';
import { ErrorMessage } from '../ErrorMessage';
import { BnIcon } from '../icons_v2';
import { ETypographyColor } from '../../types';

export interface Props {
	checked?: boolean;
	setChecked?: (val: boolean) => void;
	icon?: string;
	label?: string;
	width?: number;
	error?: string;
	isDisabled?: boolean;
	color?: string;
	isDisplayAsBlock?: boolean;
	sizer?: 'M' | 'S';
	functionDisable?: boolean;
	checkboxPositionTop?: number;
	truncate?: boolean;
}
export const Checkbox = ({
	checked,
	setChecked,
	label = '',
	sizer = 'S',
	icon = '',
	color,
	width = 20,
	isDisplayAsBlock,
	error,
	isDisabled = false,
	functionDisable = false,
	checkboxPositionTop = 0,
	truncate = false,
	...props
}: Props) => {
	const colors = useTheme();
	const [visited, setVisited] = useState<boolean>(false);

	const functionalityDisabled = isDisabled ? isDisabled : functionDisable;

	return (
		<>
			<StyledCheckbox
				data-testid="styled-checkbox"
				color={color}
				theme={{ ...colors }}
				isDisplayAsBlock={isDisplayAsBlock}
				disabled={isDisabled}
				width={width}
				checkboxPositionTop={checkboxPositionTop}
			>
				{(label || icon) && (
					<div className={`${sizer}`} data-aid="index-Checkbox">
						{icon && <BnIcon icon={icon} />}
						<span className={truncate ? 'truncate' : ''}>{capitalize(label)}</span>
					</div>
				)}
				<input
					data-testid="checkbox-input"
					type="checkbox"
					checked={checked}
					disabled={functionalityDisabled}
					onChange={e => {
						if (!functionalityDisabled) {
							//@ts-ignore
							if (setChecked) {
								//@ts-ignore
								setChecked(e.target.checked);
							}
						}
					}}
					{...props}
					onBlur={() => setVisited(true)}
				/>
				<span className={`checkmark ${visited && error ? 'error' : ''}`}></span>
			</StyledCheckbox>
			{visited && <ErrorMessage error={error || ''} />}
		</>
	);
};
