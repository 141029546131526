/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { i18n } from '../../../lib/i18n';
import { Column } from '../../../atoms/layoutPrimitives';
import { FC, useMemo, useState } from 'react';
import { errorRowCss, removeMarginsCss } from '../styles';
import { DiscountAmountInput, EDiscountUnit } from '../../../molecules/discount-amount-input';
import { DiscountReasonSelect } from './discount-reason-select';
import { IOption } from '../../../components/Select_v2';
import { AddPublicPrivateNotes } from '../../../molecules/add-public-private-notes';
import { ErrorRow } from './error-row';
import { TDiscountApplicant, TCustomState } from '../types';
import { useNotes } from '../hooks/useNotes';

interface Props {
	totalAmount: number;
	remainingAmount: number;
	remainingPercentage: number;
	reasons: IOption[];
	onChange: ({ amount, type, reason, publicNote, privateNote }: TCustomState) => void;
	discountApplicant: TDiscountApplicant;
	initialState?: TCustomState;
}
export const CustomDiscount: FC<Props> = ({
	totalAmount,
	remainingAmount,
	remainingPercentage,
	reasons = [],
	onChange,
	discountApplicant,
	initialState,
}) => {
	const [discountAmount, setDiscountAmount] = useState<number | undefined>(initialState?.amount);
	const [discountType, setDiscountType] = useState<EDiscountUnit | undefined>(initialState?.type);
	const [selectedReason, setSelectedReason] = useState<number | undefined>(initialState?.reason);
	const { handleNotesChange } = useNotes(
		{ privateNote: initialState?.privateNote, publicNote: initialState?.publicNote },
		onChange
	);

	const labels = i18n.addDiscount.content.custom;

	const isShowError = useMemo(() => {
		switch (discountType) {
			case EDiscountUnit.PERCENT:
				return Boolean((discountAmount ?? 0) > remainingPercentage);
			case EDiscountUnit.AMOUNT:
				return Boolean((discountAmount ?? 0) > remainingAmount);
			default:
				return false;
		}
	}, [discountAmount, discountType, remainingAmount, remainingPercentage]);

	const handleDiscountAmountChange = (type: EDiscountUnit, amount: number) => {
		setDiscountAmount(amount);
		setDiscountType(type);
		onChange({ amount, type });
	};

	const handleReasonChange = (val: string) => {
		setSelectedReason(Number(val));
		onChange({ reason: Number(val) });
	};

	return (
		<Column fullWidth gap={'2.5rem'} styles={removeMarginsCss}>
			{/* error */}
			<Column fullWidth>
				<ErrorRow
					labels={labels}
					amount={remainingAmount}
					percentage={remainingPercentage}
					styles={errorRowCss(isShowError)}
				/>
				{/* input */}
				<DiscountAmountInput
					amountPreDiscount={totalAmount}
					onChange={handleDiscountAmountChange}
					value={discountAmount}
					type={discountType}
					discountApplicant={discountApplicant}
				/>
			</Column>
			{/* reason */}
			<DiscountReasonSelect
				options={reasons}
				value={selectedReason ? String(selectedReason) : undefined}
				onChange={handleReasonChange}
			/>
			{/* notes */}
			<AddPublicPrivateNotes
				onChange={handleNotesChange}
				initialPublicNote={initialState?.publicNote}
				initialPrivateNote={initialState?.privateNote}
			/>
		</Column>
	);
};
