/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { Tag } from '../../../components/Tag';
import { oldThemeColors as colors } from '../../../styles/theme';
import { Icons } from '../../../types';
import { TooltipWithButton } from '../../tooltip-with-button';
import { formatDateTimeTz, getDaysUntilDate } from '../../invoicing/utils/date-formatting.util';
import { i18n } from '../../../lib/i18n';
import { EScheduledChargeStatus } from '../../../organisms/invoiceTemplate/types';

const labels = {
	individual: 'Individual',
	user: 'Individual',
	organization: 'Organization',
	family: 'Family',
	primary: 'Primary',
	dependent: 'Dependent',
	independent: 'Independent',
	active: 'Active',
	inactive: 'InActive',
	archived: 'Archived',
	expire: 'Expire',
	pending: 'Pending',
	paid: 'Paid',
	void: 'Voided',
	not_paid: 'Unpaid',
	partial: 'Partially paid',
	charged: 'Charged',
	refunded: 'Refunded',
	future: 'Upcoming',
	succeeded: 'Paid',
	canceled: 'Canceled',
	retry_in_progress: 'Failed',
	scheduled: 'Scheduled Payments',
	failed: 'Failed',
	revert: 'revert',
};
enum EStatusColorMapper {
	family = 'purple',
	user = 'yellow',
	organization = 'red',
	primary = 'yellow',
	dependent = 'pink',
	independent = 'blue',
	not_paid = 'red',
	paid = 'green',
	void = 'gray',
	partial = 'yellow',
	future = 'blue',
	succeeded = 'green',
	canceled = 'purple',
	scheduled = 'blue',
	charged = 'green',
	male = 'blue',
	archived = 'blue',
	female = 'purple',
	other = 'yellow',
	retry_in_progress = 'red',
	failed = 'red',
	refunded = 'blue',
	revert = 'gray',
}
const formatDateUtil = formatDateTimeTz().formatDate;

const _getFailedTooltipContent = (row: any, ongoingRetry: boolean): string => {
	const toolTips = i18n.invoice.toolTips;
	let toolTipContent: string = "";
	let daysUntilRetry: number = 0;
	if(ongoingRetry) {
		if(!!row?.parentInstallmentId) {
			toolTipContent  += `${toolTips.combined}`;
		}
		else {
		 	daysUntilRetry = getDaysUntilDate(row?.plannedDate);
		}
	}
	toolTipContent += ` ${toolTips.failedAttemps(row?.failedCount)}`
	const lastFailuredDate: string = row?.lastFailedAt ? toolTips.lastFailed(formatDateUtil((new Date(row?.lastFailedAt)).toString())) : '';
	toolTipContent += ` ${lastFailuredDate} ${toolTips.error(row?.errorMessage)}`;
	if (ongoingRetry && !row?.parentInstallmentId && daysUntilRetry >= 0) {
		toolTipContent += ` ${toolTips.nextRetry(daysUntilRetry)}`;
	}

	if (row?.originalPaymentMethodId !== row?.paymentMethodId) {
		toolTipContent += ` ${toolTips.paymentMethodUpdated}`;
	}
	return toolTipContent;
};

const getPaidOnDateTooltip = (row: any, toolTips: Record<string, any>) => {
	const originalPlannedDate = new Date(row?.originalPlannedDate);
	const chargeDate = new Date(row?.chargedAt);
	const dateContent: string = formatDateUtil((chargeDate).toString() ?? (originalPlannedDate).toString());
	if (originalPlannedDate > chargeDate) {
		return toolTips.paidInAdvance(dateContent);
	}
	if (row?.originalPlannedDate !== row?.chargedAt) {
		return toolTips.paidOn(dateContent);
	}
	return '';

}

const _getPaidTooltipContent = (row: any): string => {
	const toolTips = i18n.invoice.toolTips;
	let toolTipContent: string = getPaidOnDateTooltip(row, toolTips) ?? '';
	if(row?.failedCount){
		toolTipContent = toolTips.failedAttempsAfterPayment(toolTipContent, toolTips.paid, toolTips.after, toolTips.failedAttemps(row?.failedCount));
	}
	if(row?.lastFailedAt){
		toolTipContent += ` ${toolTips.lastFailed(formatDateUtil((new Date(row?.lastFailedAt)).toString()))}`;
	}
	if (row?.errorMessage){
		toolTipContent += ` ${toolTips.error(row?.errorMessage)}`;
	}
	return toolTipContent;
};

export const cellCss = (isAction: boolean, isLast?: boolean, isIcon?: boolean, svgWidth = 20) => css`
	padding: 6px 8px;
	margin-right: 4px;
	cursor: ${isAction ? 'pointer' : 'default'};
	color: ${colors.brandPrimary};
	width: ${isIcon ? '20px' : 'unset'};
	${isLast ? 'padding: 6px 0px;' : ''}
	border-bottom: none !important;

	.TableCell-paddingCheckbox {
		padding: 0 0 0 8px;
	}
	a {
		text-decoration: underline;
		color: ${colors.brandPrimary};
	}
`;

export const Status = ({ column, row }: { column: any; row: any }) => {
	const value = row[column.id];
	const title = labels[value];
	return (
		<div css={cellCss(column.action, false)}>
			<Tag title={title} color={EStatusColorMapper[row[column.id]] || 'blue'} />
		</div>
	);
};
export const StatusWithIcon = ({ column, row }: { column: any; row: any }) => {
	const isRetryInProgress = row?.status === EScheduledChargeStatus.RETRY_IN_PROGRESS;
	const value = row[column.id];
	const title = labels[value];
	const isFailed = row?.errorMessage && (isRetryInProgress || row?.status === EScheduledChargeStatus.FAILED) && row?.errorMessage !== 'UNKNOWN';
	const tooltipContent: string = isFailed ? _getFailedTooltipContent(row, isRetryInProgress) : _getPaidTooltipContent(row);
	const displayTooltip = !!tooltipContent && (isFailed || row?.status === EScheduledChargeStatus.SUCCEEDED);
	return (
		displayTooltip ? (
			<TooltipWithButton icon={Icons.info} tooltipContent={tooltipContent}>
				<Tag title={title} color={EStatusColorMapper[row[column.id]] || 'blue'} />
			</TooltipWithButton>
		) : (
			<Tag title={title} color={EStatusColorMapper[row[column.id]] || 'blue'} />
		)
	);
};
