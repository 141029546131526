/** @jsx jsx */
import { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { MediaDto } from "@bondsports/types";
import { CloudinaryUploadController } from "./CloudinaryUploadController";

const DEFAULT_MAX_IMAGE_SIZE = 4000000; // 4MB
const DEFAULT_IMAGE_FORMATS = ["jpg", "jpeg", "png", "webp"];
const DEFAULT_SOURCES = ["local", "camera"];

interface DirectImageUploaderProps {
  // The MediaDto object representing the most recently uploaded image.
  setMedia: Dispatch<SetStateAction<MediaDto>>;

  //allow uploading multiple images
  allowMultiple?: boolean;

  //allow the uploading user to crop the image before uploading
  allowCropping?: boolean;

  //where should uploads be allowed from? Currently supporting "local" and "camera"
  sources?: string[];

  //filesize in bytes
  maxImageSize?: number;

  //file extensions, not http content types
  imageFormats?: string[];

  //additional options to pass to the uploader implementation
  options?: {};

  //Clicking the children will open the uploader
  children?: ReactNode;
}

/**
 * Presents a default button (or the given children) that opens an image uploader when clicked.
 * Directly uploads an image to a third party service and returns a MediaDto object representing the uploaded image.
 * Sets the MediaDto object via the setMedia state action.
 * 
 * Important: The MediaDto object is NOT persisted by this component.
 * It must be saved and associated with the entity that the image is being uploaded for.
 * 
 * Current clienside implementation only supports Cloudinary.  See CloudinaryUploadController.tsx for more details.
 */
export const DirectImageUploader: FC<DirectImageUploaderProps> = ({ setMedia, allowMultiple = false, allowCropping = true, sources = DEFAULT_SOURCES, maxImageSize = DEFAULT_MAX_IMAGE_SIZE, imageFormats = DEFAULT_IMAGE_FORMATS, options = {}, children }) => {

  return CloudinaryUploadController({ setMedia, allowMultiple, allowCropping, sources, maxImageSize, imageFormats, children, options });

}