import { TChargeScheduled } from '@app/react/hooks/purchase/types';
import { PlatformsEnum } from '@app/react/types/orders';

export const buildChargeInstallmentPayload = ({
	userId,
	invoiceId,
	organizationId,
	token,
	type,
	shiftId,
	isSelectAll,
	selectedIds,
	applyForFuture
}): TChargeScheduled => {
	return {
		query: { userId, invoiceId, organizationId },
		body: {
			paymentMethodData: {
				token,
				type,
				applyForFuture,
			},
			platform: PlatformsEnum.BO,
			shiftId,
			...(isSelectAll && selectedIds?.length && { excludedIds: selectedIds }),
			...(!isSelectAll && { includedIds: selectedIds }),
		},
	};
};
