import { TranslationEn } from 'assets/i18n/en';
import { DurationUnitTypesEnum } from 'app/react/components/shared/formBlocks/utils/utils';
import { MaintenanceTimingEnum } from 'app/react/types/Booking';
import { MaintenanceDto } from 'app/react/types/reservation';
import * as dayjs from 'dayjs';
import { IConflictsSummarySlot, MaintenanceSlotDto, SlotDto } from '../../NewReservationTypes';
import { DraftSlot } from '@app/react/types/slot';
import { ConflictRange } from '@app/react/types/conflicts';
import { Slot } from '@bondsports/types';

function addMinutes(d: Date, minutes: number) {
	d = new Date(d?.getTime());
	d.setTime(d.getTime() + 60 * 1000 * minutes);
	return d;
}

function convertToDateString(date: Date) {
	return dayjs(date).format('YYYY-MM-DD');
}

function convertToTimeString(date: Date) {
	return dayjs(date).format('HH:mm:ss');
}

/**
 * This function is exact the same as in the API -> createMaintenanceSlot
 */
export function updateMaintenanceSlot(
	parentSlot: SlotDto,
	maintenanceData: MaintenanceDto,
	maintenanceSlot: SlotDto
): MaintenanceSlotDto {
	let startDate: string = parentSlot.startDate;
	let startTime: string = parentSlot.startTime;
	let endDate: string = parentSlot.endDate;
	let endTime: string = parentSlot.endTime;
	let date: Date;
	let duration: number = maintenanceData.durationValue;
	if (maintenanceData.maintenanceDurationdurationType === DurationUnitTypesEnum.HOURS) duration = 60 * duration;

	switch (+maintenanceData.maintenanceTiming) {
		case MaintenanceTimingEnum.AFTER:
			startDate = endDate;
			startTime = endTime;
			date = new Date(`${startDate} ${startTime}`);
			date = addMinutes(date, duration);
			endDate = convertToDateString(date);
			endTime = convertToTimeString(date);
			break;
		case MaintenanceTimingEnum.BEFORE:
			endDate = startDate;
			endTime = startTime;
			date = new Date(`${startDate} ${startTime}`);
			date = addMinutes(date, -1 * duration);
			startDate = convertToDateString(date);
			startTime = convertToTimeString(date);
			break;
		case MaintenanceTimingEnum.AT_THE_BEGINING:
			date = new Date(`${startDate} ${startTime}`);
			date = addMinutes(date, duration);
			endDate = convertToDateString(date);
			endTime = convertToTimeString(date);
			break;
		case MaintenanceTimingEnum.AT_THE_END:
			date = new Date(`${endDate} ${endTime}`);
			date = addMinutes(date, -1 * duration);
			startDate = convertToDateString(date);
			startTime = convertToTimeString(date);
			break;
		default:
			console.log('Some error occured');
	}

	const newMaintenanceSlot = { ...maintenanceSlot, startDate, startTime, endDate, endTime };

	newMaintenanceSlot.maintenanceDurationdurationType =
		maintenanceData.maintenanceDurationdurationType as DurationUnitTypesEnum;
	newMaintenanceSlot.maintenanceTiming = maintenanceData.maintenanceTiming;
	newMaintenanceSlot.durationValue = maintenanceData.durationValue;

	return newMaintenanceSlot as MaintenanceSlotDto;
}

export const generateMaintenanceString = (maintenance: Slot | MaintenanceDto) => {
	const durationLabels = TranslationEn.duration;
	let label = `${maintenance.durationValue} `;
	if (maintenance.maintenanceDurationdurationType === DurationUnitTypesEnum.HOURS) {
		label = label + durationLabels.hr;
	} else {
		label = label + durationLabels.min;
	}

	switch (maintenance.maintenanceTiming) {
		case MaintenanceTimingEnum.AFTER:
			label = label + ' after';
			break;
		case MaintenanceTimingEnum.AT_THE_BEGINING:
			label = label + ' at the begining';
			break;
		case MaintenanceTimingEnum.AT_THE_END:
			label = label + ' at the end';
			break;
		case MaintenanceTimingEnum.BEFORE:
			label = label + ' before';
			break;
		default:
			break;
	}
	return label;
};

/**
 * Gets a slotId in format 1-0-0-0-0
 * returns an object of the id's {selectedSlotIndex,selectedSlotSeriesIndex,selectedSlotSegmentIndex,sortedSlotIndex}
 * @param slotId - slotId in format 1-0-0-0-0
 */
export const serializeSlotId = (slotId: string) => {
	const indexes = slotId.split('-').map(i => +i);
	return {
		selectedSlotIndex: indexes[1],
		selectedSlotSeriesIndex: indexes[2],
		selectedSlotSegmentIndex: indexes[3],
		sortedSlotIndex: indexes[4],
	};
};

export const getConflictTimes = ({ startDate, startTime, endDate, endTime }: SlotDto): ConflictRange => ({
	startDate,
	startTime,
	endDate,
	endTime,
});

export const generateConflictSlot = (slot: IConflictsSummarySlot) => {
	const originalSlot = slot.originalSlot;
	const { selectedSlotIndex, selectedSlotSeriesIndex, selectedSlotSegmentIndex, sortedSlotIndex } = serializeSlotId(
		slot.id
	);

	return {
		...originalSlot,
		id: slot.id,
		conflicts: slot.conflicts,
		slotIndex: selectedSlotIndex,
		seriesIndex: selectedSlotSeriesIndex,
		segmentIndex: selectedSlotSegmentIndex,
		sortedSlotIndex: sortedSlotIndex,
		originalSlot,
	};
};

export const mapDataToOptions = (data: DraftSlot[]): DraftSlot[] => {
	return data.map(item => item);
};

export const ITEMS_PER_PAGE_DEFAULT = 5;
