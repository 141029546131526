import { atom } from 'recoil';
import { ThemeColors, typography } from '../styles/theme';
import { IColors, ITypography } from '../types/theme';

const permissions = atom<string[]>({
	key: 'permissions',
	default: [],
});

const colorsSettings = atom<IColors>({
	key: 'colors',
	default: ThemeColors,
});

const droppedId = atom<string>({
	key: 'droppedId',
	default: '790',
});

const eventsToSpaces = atom<any>({
	key: 'eventsToSpaces',
	default: {},
});

const events = atom<any>({
	key: 'events',
	default: {},
});

const typographySettings = atom<ITypography>({
	key: 'typography',
	default: {
		...typography,
	},
});

const currentTab = atom<'roles' | 'users' | 'permissions'>({
	key: 'currentTab',
	default: 'roles',
});

const lastRefreshedTimestamp = atom<number>({
	key: 'lastRefreshedTimestamp',
	default: 0,
});

const isPermissionFetched = atom<boolean>({
	key: 'isPermissionFetched',
	default: false,
});

const isWhiteLabeld = atom<boolean>({
	key: 'isWhiteLabeld',
	default: false,
});

const scrollPosition = atom<number>({
	key: 'scrollPosition',
	default: 0,
});

export const Store = {
	colorsSettings,
	droppedId,
	isPermissionFetched,
	eventsToSpaces,
	events,
	typographySettings,
	permissions,
	currentTab,
	isWhiteLabeld,
	lastRefreshedTimestamp,
	scrollPosition,
};
