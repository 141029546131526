/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { alignItemsCss, flex, gapCss, spaceBetweenCss } from '@app/react/styles/utils';
import { ActionsProps } from './types';

export const Actions = ({ filters, actions, alignItems='flex-end', gap=16 }: ActionsProps) => {
	return (
		<div css={spaceBetweenCss} data-aid="table-actions-container">
			<div data-aid="table-actions-filters" css={[flex, gapCss(gap), alignItemsCss(alignItems)]}>
				{filters}
			</div>
			{actions && (
				<div css={flex} data-aid="table-actions">
					<div css={flex}>{actions}</div>
				</div>
			)}
		</div>
	);
};
