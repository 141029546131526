import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { Field as FinalField, useForm } from 'react-final-form';
import { Select_v2, ISelect_V2, IOption_V2 } from './index';
import SelectRadix from '../Select-Radix';
import _ from 'lodash';
import { i18n } from '../../lib/i18n';

export interface IFormProps extends ISelect_V2 {
	name: string;
	onClear?: (val: string) => void;
	required: boolean;
	onSelecting: (val1: string, val2?: string | string[]) => void;
	labelAddition?: ReactNode;
	isAllFieldName?: string;
	values?: string | (string | number)[];
}

export const FormSelect_v2 = ({
	...props
}: IFormProps & React.SelectHTMLAttributes<HTMLSelectElement> & { newSelect?: boolean }) => {
	const form = useForm();

	const onSelect = (val: string | string[]) => {
		props.onSelecting(props.name, val);
	};
	const labels = i18n.fieldValidationErrors;
	return (
		<FinalField
			name={props.name}
			subscription={{ touched: true }}
			validate={(val, allValues) => {
				let res: Record<string, string> | undefined = undefined;
				if (props.required) {
					const isAllSelected = props.isAllFieldName && _.get(allValues, props.isAllFieldName);
					const isEmptyArray = Array.isArray(val) && val.length === 0;
					if (!val || (isEmptyArray && !isAllSelected)) {
						res = { [props.name]: labels.required };
					}
				}
				return res;
			}}
			displayEmpty
		>
			{({ input }) => {
				const metadata = form.getFieldState(props.name);
				const value = props.values || _.get(form.getState().values, props.name);
				const isAllSelected = useMemo(() => {
					return props.isAllFieldName ? _.get(form.getState().values, props.isAllFieldName) : false;
				}, [props.isAllFieldName, form.getState().values]);

				const newProps = { ...props };
				const touched = metadata?.touched ?? false;
				const err = metadata?.error?.[props?.name] ? metadata.error[props.name] : null;
				const { onClear } = props;
				let clear;
				if (newProps.onClear && onClear) {
					clear = () => onClear(props.name);
					delete newProps?.onClear;
				}

				return (
					<Fragment>
						{!props.newSelect && (
							<Select_v2
								{...input}
								{...(newProps as any)}
								selectedLabel={newProps.selectedLabel}
								error={newProps?.error ?? err}
								clear={clear}
								value={value || input.value}
								onSelecte={onSelect}
								isAllSelected={isAllSelected}
							/>
						)}

						{props.newSelect && (
							<SelectRadix
								isTouched={touched}
								onBlur={input?.onBlur}
								options={newProps.options as IOption_V2[]}
								size={newProps?.sizer?.toLowerCase() ?? ('s' as any)}
								onSelect={onSelect}
								value={value ? String(value) : undefined}
								label={newProps.label}
								labelAddition={newProps.labelAddition}
								controlLabel="form-control-label"
								errorMessage={err}
								required={newProps.required}
							/>
						)}
					</Fragment>
				);
			}}
		</FinalField>
	);
};
