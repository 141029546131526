import { GenericResponseDto, PaginationResultDto, AttendanceDto, AttendanceCreateDto } from '@bondsports/types';
import { network } from '../network';
import { environment } from '../../../../environments/environment';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

function fetchAttendance(
	organizationId: number,
	userId: number,
	itemsPerPage?: number,
	page = 1
): Promise<PaginationResultDto<AttendanceDto>> {
	return network.get(
		`${v4APIUrl}/attendance/organization/${organizationId}/user/${userId}?itemsPerPage=${itemsPerPage}&page=${page}`
	);
}

function createAttendance(
	organizationId: number,
	userId: number,
	data: AttendanceCreateDto
): Promise<GenericResponseDto<AttendanceDto>> {
	return network.post(`${v4APIUrl}/attendance/organization/${organizationId}/user/${userId}`, data);
}

function deleteAttendance(organizationId: number, attendanceId: number): Promise<GenericResponseDto> {
	return network.delete(`${v4APIUrl}/attendance/organization/${organizationId}/${attendanceId}`, {});
}

export const attendanceApi = {
	fetchAttendance,
	createAttendance,
	deleteAttendance,
};
