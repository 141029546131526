/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { SelectInstallmentsContainer } from './SelectInstallmentsContainer';
import { FuturePaymentStatusEnum } from '@bondsports/types';
import { Typography } from '../../../atoms';
import { gapCss, flexCss } from '../../../styles/utils';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { SelectAllOptions } from '../../../components/PaginatedSelect/SelectAllOptions';
import { useColors } from '../../../hooks/useColors';
import { PaymentFlowFooter } from './paymentFlowFooter';
import { alignCenterCss, iconContainerCss, stepContainerCss, selectableInstallmentsCss } from './style';
import { PaymentFlowHeader } from './paymentFlowHeader';
import { BnIcon } from '../../../components/icons_v2';
import { SuspenseWrapper } from '../../../components/SuspenseWrapper';

export const SelectInstallmentsStep = ({
	isLoading = false,
	handleSelect,
	handleSelectAll,
	handleDeslectAll,
	isSelectAll = false,
	installments,
	disableSelectUpcoming,
	installmentsInfo,
	labels,
	paymentFormToggle,
	handleNext,
	lastItemElementRef,
	remainingAmount,
}: {
	isLoading?: boolean;
	disableSelectUpcoming?: boolean;
	installments?: any[];
	handleSelect?: (installment: any) => void;
	lastItemElementRef: ((node: HTMLDivElement) => void) | null;
	handleSelectAll: () => void;
	labels: any;
	installmentsInfo: {
		futureSelected: number;
		futureFetched: number;
		failedFetched: number;
		failedSelected: number;
		selectedIds: Set<number>;
		fetchedIds: Set<number>;
	};
	handleDeslectAll: () => void;
	isSelectAll: boolean;
	selectedIdsByStatusMap?: Map<FuturePaymentStatusEnum, Set<number>>;
	paymentFormToggle: () => void;
	handleNext: () => void;
	remainingAmount: string;
}) => {
	const { colors } = useColors();
	
	const selectedIdsLength = installmentsInfo?.selectedIds?.size;
	const showWarning =
		disableSelectUpcoming &&
		((!!installmentsInfo?.futureSelected && !isSelectAll) ||
			(isSelectAll &&
				installmentsInfo?.futureSelected !== installmentsInfo?.futureFetched &&
				!!installmentsInfo?.failedSelected));
	const isNextDisabled = showWarning || (!selectedIdsLength && !isSelectAll);

	return (
		<div css={stepContainerCss} data-aid="selectInstallmentsContainer-invoicingCheckoutLayout">
			<PaymentFlowHeader labels={labels} />
			<SuspenseWrapper loading={isLoading}>
				<SelectAllOptions
					theme={colors}
					handleRemoveAll={handleDeslectAll}
					handleSelectAll={handleSelectAll}
					isIndeterminate={
						!!selectedIdsLength &&
						selectedIdsLength < installmentsInfo?.fetchedIds?.size
					}
					selectAllLabel={labels.selectAll}
					isChecked={
						(isSelectAll && !selectedIdsLength) ||
						(!isSelectAll && selectedIdsLength === installmentsInfo?.fetchedIds?.size)
					}
				/>
				<div css={selectableInstallmentsCss(colors)}>
					<SelectInstallmentsContainer
						futureInstallments={installments}
						selectUpcomingDisabled={disableSelectUpcoming}
						isSelectAll={isSelectAll}
						handleSelect={handleSelect}
						installmentsInfo={installmentsInfo}
						lastItemElementRef={lastItemElementRef}
					/>
					{showWarning && (
						<div css={[flexCss, gapCss(10), alignCenterCss]}>
							<div css={iconContainerCss(colors)}>
								<BnIcon icon={Icons.conflict} />
							</div>
							<Typography type={ETypography.captionAccented} color={ETypographyColor.red}>
								{labels.warnings.failedDeselect}
							</Typography>
						</div>
					)}
				</div>
			</SuspenseWrapper>
			<PaymentFlowFooter
				labels={labels}
				remainingAmount={remainingAmount}
				isLoading={isLoading}
				handleNext={handleNext}
				isNextDisabled={isNextDisabled}
				handleBack={paymentFormToggle}
			/>
		</div>
	);
};
