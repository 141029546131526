import React, { useEffect } from 'react';
import { ConnectedResourcesTable } from './components/ConnectedResourcesTable';
import { useManagePaymentMethodFilters } from './hooks/useInitiateFilters';
import { useGetPaymentMethods } from '../../payments/Schedule/hooks/useGetPaymentMethods';
import { useMultiSelectMethods } from './hooks/useMultiSelectMethods';
import { PaymentMethodsProvider } from '../../payments/Charge/PaymentMethodsProvider';
import { ICustomer } from '@app/react/types/customers';

interface Props {
	organizationId: number;
	customer: ICustomer;
}

export const ManagePaymentMethodsTab = ({
	organizationId,
	customer,
}: Props) => {
	const { getMethods, normalizedPaymentMethods, filteredPaymentMethods, paymentMethods, isLoading: isLoadingMethods } = useGetPaymentMethods(customer);
	const { filterSections } = useManagePaymentMethodFilters();
	const { selectedMethodId, handleSelectMethod } = useMultiSelectMethods(filteredPaymentMethods[0]?.paymentMethodId);

	useEffect(() => {
		if (!normalizedPaymentMethods?.length && isLoadingMethods) {
			getMethods(organizationId, customer?.entityId);
		}
	}, [customer?.entityId, organizationId]);

	return (
		<PaymentMethodsProvider
			userId={customer?.entityId}
			paymentMethodData={paymentMethods}
		>
			<ConnectedResourcesTable
				data-testid="customer-paymentMehtods-page-content"
				isLoadingMethods={isLoadingMethods}
				userId={customer?.entityId}
				customerState={customer}
				methodOptions={paymentMethods}
				normalizedMethods={normalizedPaymentMethods}
				handleSelectMethod={handleSelectMethod}
				initialMethodId={selectedMethodId ? selectedMethodId : filteredPaymentMethods[0]?.paymentMethodId}
				paymentMethods={filteredPaymentMethods}
				refetchPaymentMethods={() => getMethods(organizationId, customer?.entityId)}
				organizationId={organizationId}
				filterSections={filterSections}
			/>
		</PaymentMethodsProvider>
	);
};
