/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { BnIcon, Button, Icons, SizeEnum } from '@bondsports/utils';
import { actionButtonCss } from '../../style';
import { ActionProps } from '../../types';

export const Action = ({ handleAction, isDisabled, icon, dataAid }: ActionProps & { icon: Icons; dataAid: string }) => {
	return (
		<Button
			data-aid={'button-action-' + dataAid}
			disabled={isDisabled}
			css={actionButtonCss(isDisabled)}
			theme="basic"
			sizer={SizeEnum.SMALL}
			type="button"
			onClick={handleAction}
		>
			<BnIcon icon={icon} css={actionButtonCss(isDisabled)} />
		</Button>
	);
};
