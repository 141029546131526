import { TranslationEn } from '../../../assets/i18n/en';
import { environment } from '../../../environments/environment';
import { organizationApi } from '../lib/api/organizationApi';
import { paymentApi } from '../lib/api/paymentApi';
import { auth } from '../lib/auth';
import { download } from '../lib/network';
import { ENotificationType } from '../stores/baseStore';
import { useLayout } from './useLayout';
import { useNotification } from './useNotification';
import { useOrganization } from './useOrganization';

export const useReceipt = () => {
	const { setPopupNotification } = useNotification();
	const { organizationId } = useOrganization();
	const { disabledScreenToggle } = useLayout();

	const sendReceipt = async (
		organizationId: number,
		invoiceId: number,
		sendToAddress: string,
		deliveryMethod: 'email' | 'sms'
	) => {
		const data = { paymentId: invoiceId, sendToAddress, deliveryMethod };
		const res = await paymentApi.sendReceipt(organizationId, data);
		return res;
	};

	/**
	 * Opens a receipt for printing in a new tab of the consumer project
	 * @param userId Paying user id
	 * @param paymentId Comma seperated string of payment ids
	 */
	const printReceipt = async (paymentId: string, payingUserId?: number) => {
		const user = auth.getUserId();
		const url = `${environment.CONSUMER_SITE_URL}/templates/payment/${paymentId}?o=${organizationId}&u=${
			payingUserId ?? user
		}&print=true`;

		await window.open(url, '_blank');
	};

	const downloadReceipt = async (paymentId: number, payingUserId: number) => {
		disabledScreenToggle(true);
		const response = await organizationApi.downloadPaymentPdf(payingUserId, paymentId, organizationId);
		if (!response?.content?.url) {
			disabledScreenToggle(false);
			setPopupNotification(TranslationEn.customers.paymentsInvoices.downloadFailedPayment, ENotificationType.warning);
			return;
		}

		download(response.content.url, response.content.name);
		disabledScreenToggle(false);
		setPopupNotification(TranslationEn.customers.paymentsInvoices.downloadSuccess, ENotificationType.success);
	};

	const handleSendReceipt = (receiptsIds: number[], contactMethod: 'email' | 'sms', sendAddress: string) => {
		if (receiptsIds.every(receiptsId => receiptsId > 0)) {
			disabledScreenToggle(true);
			Promise.all(receiptsIds.map(receiptId => sendReceipt(organizationId, receiptId, sendAddress, contactMethod)))
				.then(res => {
					if (res.find(res => res.err)) {
						console.error(res.find(res => res.err));
					} else {
						setPopupNotification(TranslationEn.payments.sendReceipt.successMessage, ENotificationType.success);
					}
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => disabledScreenToggle(false));
		} else {
			console.error('Missing paymentId for send receipt');
		}
	};

	return { handleSendReceipt, printReceipt, downloadReceipt };
};
