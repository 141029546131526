import { Icons, Input, SizeEnum } from '@bondsports/utils';
import React from 'react';
import { DEFAULT_SEARCH_INPUT_WIDTH } from './constants';

export function SearchInput({
	placeholder,
	value,
	onChange,
	width = DEFAULT_SEARCH_INPUT_WIDTH,
}: {
	placeholder: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	width?: number;
}) {
	return (
		<Input
			data-aid="input-search"
			sizer={SizeEnum.SMALL}
			placeholder={placeholder}
			rightIcon={Icons.search}
			value={value}
			onChange={onChange}
			disableTrailing
			hideError
			style={{ width: `${width}px` }}
			marginTop={0}
		/>
	);
}
