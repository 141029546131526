/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode, useLayoutEffect, useRef } from 'react';
import { css, jsx } from '@emotion/react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor } from '../../../types';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useColors } from '../../../hooks/useColors';
import { PAYMENT_STEPS, DEFAULT_INSTALLMENTS_INFO } from '../../../types/enums/installments';
import { PaymentStep } from './paymentStep';
import { SelectInstallmentsStep } from './SelectInstallmentsStep';
import { Pricify } from '../../../lib/price';
import { containerCss } from './style';
import { UserPaymentMethod } from '../../../types/entities/payment';

export const PaymentForm = ({
	addPaymemtButtons,
	isApplyPaymentMethodCheckbox,
	handlePay,
	paymentMethods,
	installmentSelectionReset,
	paymentMethodSelectReset,
	labels,
	state,
	selectedPaymentMethod,
	handleSelectPaymentMethod,
	handleSelectInstallment,
	futureInstallments,
	isSelectUpcomingDisabled,
	paymentAmountInput,
	isPayButtonDisabled,
	amountToPay,
	handleNext,
	handleBack,
	handleSelectAll,
	handleDeslectAll,
	installmentsInfo,
	isSelectAll = false,
	lastItemElementRef,
	currentStep,
}: {
	installmentSelectionReset: () => void;
	paymentMethodSelectReset: () => void;
	lastItemElementRef: ((node: HTMLDivElement) => void) | null;
	isLoading: boolean;
	addPaymemtButtons: ReactNode;
	paymentMethods: any[];
	handlePay: (selectedPaymentMethod: any, onDone: () => void, amountToPay: number) => void;
	labels: any;
	state: any;
	isApplyPaymentMethodCheckbox?: ReactNode;
	selectedPaymentMethod?: UserPaymentMethod;
	isSelectUpcomingDisabled?: boolean;
	futureInstallments?: any[];
	installmentsInfo?: {
		futureSelected: number;
		futureFetched: number;
		failedFetched: number;
		failedSelected: number;
		selectedIds: Set<number>;
		fetchedIds: Set<number>;
	};
	handleSelectPaymentMethod: (v: UserPaymentMethod) => void;
	handleSelectInstallment?: (installment: any) => void;
	handleSelectAll: () => void;
	handleDeslectAll: () => void;
	isSelectAll?: boolean;
	paymentAmountInput?: ReactNode;
	isPayButtonDisabled?: boolean;
	amountToPay: number;
	handleNext: () => void;
	handleBack: () => void;
	currentStep?: PAYMENT_STEPS;
}) => {
	const { colors } = useColors();
	const { isMobile } = useWindowSize();
	const ref = useRef<HTMLDivElement>(null);
	const relevantLabels = labels.paymentForm;

	const leftToPay = Pricify(state?.paidAmount ? state?.price - state?.paidAmount : state?.price);

	useLayoutEffect(() => {
		setTimeout(() => {
			if (ref.current) {
				ref.current.scrollIntoView({
					behavior: 'smooth',
				});
			}
		}, 100);
	}, [ref]);

	const installmentSelectionToggle = () => {
		installmentSelectionReset();
		handleBack();
	};

	const paymentMethodSelectToggle = () => {
		paymentMethodSelectReset();
		handleBack();
	};

	return (
		<div data-aid="paymentForm" css={containerCss(colors, !paymentAmountInput)} ref={ref}>
			<header>
				<Typography type={isMobile ? ETypography.h6Accented : ETypography.h4} color={ETypographyColor.primary}>
					{relevantLabels.title}
				</Typography>
			</header>
			{paymentAmountInput}
			{currentStep === PAYMENT_STEPS.SELECT_INSTALLMENTS && (
				<SelectInstallmentsStep
					installments={futureInstallments}
					disableSelectUpcoming={isSelectUpcomingDisabled}
					lastItemElementRef={lastItemElementRef}
					handleSelect={handleSelectInstallment}
					handleSelectAll={handleSelectAll}
					handleDeslectAll={handleDeslectAll}
					isSelectAll={isSelectAll}
					labels={labels.scheduledSection}
					installmentsInfo={installmentsInfo || DEFAULT_INSTALLMENTS_INFO}
					paymentFormToggle={installmentSelectionToggle}
					handleNext={handleNext}
					remainingAmount={leftToPay}
				/>
			)}
			{currentStep === PAYMENT_STEPS.PAYMENT && (
				<PaymentStep
					paymentMethods={paymentMethods}
					applyCheckbox={isApplyPaymentMethodCheckbox}
					addPaymemtButtons={addPaymemtButtons}
					handlePay={handlePay}
					state={state}
					labels={labels.paymentMethods}
					selectedPaymentMethod={selectedPaymentMethod}
					handleSelectPaymentMethod={handleSelectPaymentMethod}
					isPayButtonDisabled={isPayButtonDisabled}
					amountToPay={amountToPay}
					handleBack={paymentMethodSelectToggle}
					remainingAmount={leftToPay}
				/>
			)}
		</div>
	);
};
