/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import moment from 'moment';
import { cellCss } from '../styles';
import { dateCss } from '../../../../styles/utils';

export const Date = ({ row, column, isLast, index, longDate = false, source = null }) => {
	let value = source && source === 'participants' ? row.startDateString || row.startDate : row[column.id];
	return (
		<td
			data-aid="td-date"
			key={column.id}
			className="TableCell-root TableCell-body"
			onClick={() => {
				if (column.action) {
					column.action(row);
				}
			}}
			css={cellCss({ isAction: column.action, isLast })}
			align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
		>
			<span css={dateCss}>
				{moment(value).isValid() ? moment(value).format(longDate ? 'ddd, MMM DD, YYYY' : 'MMM DD, YYYY') : '-'}
			</span>
		</td>
	);
};
