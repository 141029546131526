/** @jsx jsx */
import { createContext, ReactNode, useEffect, useState } from "react";
import { jsx } from '@emotion/react';
import { MediaTypesEnum } from "@bondsports/types";
import { TranslationEn } from "@assets/i18n/en";
import { Button } from "ui";

// Create a context to manage the script loading state
const CloudinaryScriptContext = createContext({});

const CLOUDINARY_SCRIPT_ID = "cloudinaryUw";
const CLODUDINARY_SRC_URL = "https://upload-widget.cloudinary.com/global/all.js";

interface CloudinaryUploadWidgetProps {
  uwConfig: any;
  setMedia: (media: any) => void;
  children?: ReactNode;
}

const CloudinaryUploadWidget: React.FC<CloudinaryUploadWidgetProps> = ({ uwConfig, setMedia, children }) => {
  const [loaded, setLoaded] = useState(false);
  const [uploadWidget, setUploadWidget] = useState(null);

  const labels = TranslationEn.directImageUploader;

  useEffect(() => {
    // Check if the script is already loaded
    if (!loaded) {
      const uwScript = document.getElementById(CLOUDINARY_SCRIPT_ID);
      if (!uwScript) {
        // If not loaded, create and load the script
        const script = document.createElement("script");
        script.setAttribute("async", "");
        script.setAttribute("id", CLOUDINARY_SCRIPT_ID);
        script.src = CLODUDINARY_SRC_URL;
        script.addEventListener("load", () => setLoaded(true));
        document.body.appendChild(script);
      } else {
        // If already loaded, update the state
        setLoaded(true);
      }
    } else {
      setUploadWidget((window as any).cloudinary.createUploadWidget(
        uwConfig,
        (error, result) => {
          if (!error && result && result.event === "success") {
            const { secure_url, format, original_filename, public_id } = result.info;
            const mediaData = {
              url: secure_url,
              mediaType: MediaTypesEnum.IMAGE,
              fileType: format,
              name: original_filename,
              title: original_filename,
              mediaKey: public_id,
              provider: 'cloudinary'
            };
            setMedia(mediaData);
          }
        }
      ));
    }
  }, [loaded]);

  const openCloudinaryWidget = () => {
    uploadWidget.open();
  };

  return (
    <CloudinaryScriptContext.Provider value={{ loaded }}>
      <span onClick={openCloudinaryWidget}>
        {children ? (
          children
        ) : (
          <Button>
            {labels.defaultUploadCta}
          </Button>
        )}
      </span>
    </CloudinaryScriptContext.Provider>
  );
}

export default CloudinaryUploadWidget;
export { CloudinaryScriptContext };