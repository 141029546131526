/** @jsx jsx */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'querystring';
import { MembershipStatusIndication } from '@bondsports/utils';
import {
	CancellationStatusEnum,
	MembershipFilterByPropertyEnum,
	MembershipOrderByPropertyEnum,
	OrderByEnum,
} from '@bondsports/types';

import { EmptyTab } from '../EmptyTab';
import { ItemsPerPageCalculator, Table } from '../../../shared/NewTable';
import { CustomSelect } from '../../customSelect';
import { Input } from '../../../shared/Input';
import { MembershipFilter } from './MembershipsFilter';
import { CancelMembershipModal } from '../../../memberships/CancelMembershipModal';
import { RefundCustomerModal } from '../../../memberships/RefundCustomerModal';

import { useMembershipTab } from '../../../../hooks/memberships/useMembershipTab';
import { useCancelMembership } from '../../../../hooks/memberships/useCancelMembership';
import { useToggle } from '../../../../hooks/useToggle';
import { useDebounce } from '../../../../hooks/useDebouncedSearch';
import { useNavigation } from '../../../../hooks/useNavigation';
import { membershipApi } from '../../../../lib/api/membershipApi';
import { createMembershipRows } from './membershipTabUtils';
import { OptionsButton } from './OptionsButton';

import { flex } from '../../../../styles/utils';
import { AlignType } from '../../../shared/NewTable/types';
import {
	ColumnsIDsEnum,
	IMembershipRow,
	IMoreButtonState,
	MembershipMoreButtonOptionEnum,
	MembershipTabSortableColumnsEnum,
	TMembershipFilterValue,
} from '../../../../types/membership';
import { ERoutePaths as EPath } from '../../../../types/navigation';
import { TranslationEn } from '@assets/i18n/en';
import { ColumnType } from '@app/react/components/shared/NewTable/HeaderRow';

const containerCss = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	input {
		margin-bottom: 0 !important;
	}
`;

const filterContainerCss = css`
	${flex}
	justify-content: flex-start;
	gap: 16px;
	margin: 20px 2rem;
`;

const tableContainerCss = css`
	height: 500px;
	flex-grow: 1;
	margin: 1rem 2rem 2rem 2rem;
`;

const membershipsStatusCss = css`
	margin-left: 2rem;
`;

type TDocumentSegment = EPath.INVOICES | EPath.PAYMENTS;

interface Props {
	customerState: any;
	isFamily: boolean;
}

export const MembershipTab = ({ customerState, isFamily }: Props) => {
	const [isProcessing, setIsProcessing] = useState(true);
	const [searchFilter, setSearchFilter] = useState('');
	const [tablePage, setPage] = useState(0);
	const [memberships, setMemberships] = useState<IMembershipRow[]>([]);
	const [meta, setMeta] = useState({});
	const [skipFirstFetch, setSkipFirstFetch] = useState(true);
	const tableContainerRef = useRef(null);
	const location = useLocation();
	const debouncedValue = useDebounce(searchFilter, 300);
	const { itemsPerPage } = ItemsPerPageCalculator(tableContainerRef);
	const [isRefundShowing, refundToggle] = useToggle();
	const { pushNavigation, ngNavigate } = useNavigation();
	const {
		sortProperties,
		sortOrder,
		handleSortColumn,
		handleUpdateFilters,
		selectedFilters,
		filters,
		statusFilter,
		paymentFilter,
		renewalFilter,
		setStatusFilter,
		setPaymentFilter,
		setRenewalFilter,
		handleFilterForActiveStatus,
		handleNoMembershipsError,
		noMemberships,
	} = useMembershipTab();
	const {
		isCancelMembershipShowing,
		cancelledUser,
		cancellationReason,
		setCancellationReason,
		cancelToggle,
		handleCancelIconClick,
		handleCancel,
		setCancelledUser,
	} = useCancelMembership();

	const labels = TranslationEn.customers.membershipTab;
	const { organizationId } = customerState;

	const getMemberships = async ({
		searchTerm,
		filterBy,
		filterFields,
		sortBy,
		sortOrder,
	}: {
		searchTerm?: string;
		filterBy?: MembershipFilterByPropertyEnum[];
		filterFields?: TMembershipFilterValue[];
		sortBy?: MembershipOrderByPropertyEnum[];
		sortOrder?: OrderByEnum[];
	}) => {
		setIsProcessing(true);
		const res = await membershipApi.getMembershipsByCustomerId({
			customerId: customerState.id,
			organizationId: customerState.organizationId,
			itemsPerPage,
			page: tablePage + 1,
			properties: sortBy,
			sortOrder,
			filterBy,
			filterValues: filterFields,
			searchBy: searchTerm,
		});
		const memberships = handleNoMembershipsError(res, debouncedValue);
		if (memberships) {
			const newRows = memberships.data?.map(m => createMembershipRows(m));
			setMemberships(newRows);
			setMeta(memberships.meta);
		}

		setIsProcessing(false);
	};

	const redirectMembership = (id: number) => {
		//client/memberships#/dashboard/188
		ngNavigate(EPath.MEMBERSHIPS, `${EPath.DASHBOARD}/${id}`);
	};

	const redirectToInvoiceOrPayment = (segment: TDocumentSegment, id: number) => {
		const { pathname } = location; //customer/256460/membership
		const documentPath = pathname.replace(EPath.MEMBERSHIP, segment);
		const newPath = `${documentPath}/${id}${segment === EPath.INVOICES ? `/${EPath.DETAILS}` : ''}`; //customer/256460/invoices/185595/details
		pushNavigation(newPath);
	};

	const onCancelMembership = async (isImmediate: boolean) => {
		const cancelCallBack = async () => {
			refundToggle();
			await getMemberships({
				searchTerm: searchFilter,
				filterBy: selectedFilters,
				filterFields: filters,
				sortBy: sortProperties,
				sortOrder,
			});
		};
		handleCancel({ isImmediate, organizationId, callback: cancelCallBack });
	};

	const handleMoreMenu = (option: MembershipMoreButtonOptionEnum, tabRow: IMembershipRow) => {
		switch (option) {
			case MembershipMoreButtonOptionEnum.CANCEL:
				handleCancelIconClick(tabRow);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const parsed = queryString.parse(location.search.replace('?', ''));
		if (parsed.page) {
			setPage(Number(parsed.page) - 1);
		}
		return () => setPage(0);
	}, [location]);

	useEffect(() => {
		handleUpdateFilters();
	}, [statusFilter, paymentFilter, renewalFilter]);

	useEffect(() => {
		if (!skipFirstFetch) {
			handleFilterForActiveStatus();
			const asyncFetch = async () => {
				await getMemberships({
					searchTerm: debouncedValue,
					filterBy: selectedFilters,
					filterFields: filters,
					sortBy: sortProperties,
					sortOrder,
				});
			};
			asyncFetch();
		}
		setSkipFirstFetch(false);
	}, [debouncedValue, tablePage, itemsPerPage, sortProperties, sortOrder, selectedFilters, filters]);

	const columns: ColumnType[] = [
		{
			id: ColumnsIDsEnum.NAME,
			label: 'Membership',
			type: 'longString',
			isSortable: true,
			isUnderlined: true,
			action: ({ membershipId }) => redirectMembership(membershipId),
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.MEMBERSHIP, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.START_DATE,
			label: 'Start date',
			type: 'datetime',
			isSortable: true,
			hideHoursDisplay: true,
			isInitialAscending: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.STARTDATE, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.END_DATE,
			label: 'End date',
			type: 'datetime',
			isSortable: true,
			hideHoursDisplay: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.ENDDATE, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.RENEWAL,
			label: 'Renewal',
			type: 'string',
			isSortable: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.RENEWAL, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.PRICE,
			label: 'Payment',
			type: 'currency',
			isSortable: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.PAYMENT_STATUS, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.INVOICE_ID,
			label: 'Invoice',
			type: 'invoice',
			isSortable: false,
			action: (row: IMembershipRow) => redirectToInvoiceOrPayment(EPath.INVOICES, row.invoiceId),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.PAYMENT_ID,
			label: 'Receipt',
			type: 'invoice',
			isSortable: false,
			action: (row: IMembershipRow) => redirectToInvoiceOrPayment(EPath.PAYMENTS, row.paymentId),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.STATUS,
			label: 'Status',
			type: 'custom',
			isSortable: true,
			isInitialAscending: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.MEMBERSHIP_STATUS, isAsc),
			component: (v, row: IMembershipRow) => {
				return (
					<div css={membershipsStatusCss}>
						<MembershipStatusIndication membershipStatus={row.membershipStatus} />
					</div>
				);
			},
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.MORE,
			label: '',
			type: 'custom',
			isSortable: false,
			action: (row: IMembershipRow) => setCancelledUser(row),
			component: (state: IMoreButtonState) => {
				const { cancellationStatus, isDisabled } = state;
				const { AUTO_RENEWAL } = CancellationStatusEnum;
				let options = [];

				if (!cancellationStatus || cancellationStatus === AUTO_RENEWAL) {
					options = [
						{
							label: labels.more.cancelMembership,
							value: MembershipMoreButtonOptionEnum.CANCEL,
						},
					];
				}

				return (
					<OptionsButton
						isDisabled={isDisabled}
						handleMoreMenu={handleMoreMenu}
						options={options}
						cancelledUser={cancelledUser}
					/>
				);
			},
		},
	];
	if (isFamily) {
		const customerNameColumn: ColumnType = {
			id: ColumnsIDsEnum.CUSTOMER_NAME,
			label: 'Customer Name',
			type: 'longString',
			isSortable: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.NAME, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		};
		columns.unshift(customerNameColumn);
	}

	return (
		<Fragment>
			{noMemberships && !isProcessing ? (
				<EmptyTab noRowsObj={noMemberships} labels={labels} />
			) : (
				<Fragment>
					<div css={containerCss}>
						<div css={filterContainerCss}>
							<Input
								data-aid="MembershipTab-input-search"
								search
								placeholder={labels.searchPlaceholder}
								value={searchFilter}
								onChange={e => setSearchFilter(e.target.value)}
							/>
							<CustomSelect
								title={labels.filter}
								content={
									<MembershipFilter
										statusFilter={statusFilter}
										paymentFilter={paymentFilter}
										renewalFilter={renewalFilter}
										setStatus={setStatusFilter}
										setPayment={setPaymentFilter}
										setRenewal={setRenewalFilter}
									/>
								}
							/>
						</div>
						<div data-aid="MembershipTab-table" ref={tableContainerRef} css={tableContainerCss}>
							<Table
								page={tablePage}
								meta={meta}
								rows={memberships}
								columns={columns}
								defaultSortColumn={'Status'}
								isLoading={!memberships.length}
								subject={EPath.MEMBERSHIPS}
								timezone="UTC"
							/>
						</div>
					</div>
					<CancelMembershipModal
						isModalShown={isCancelMembershipShowing}
						isProcessing={isProcessing}
						cancelledUser={cancelledUser}
						cancellationReason={cancellationReason}
						handleCancel={onCancelMembership}
						setCancellationReason={setCancellationReason}
						cancelToggle={cancelToggle}
					/>
					<RefundCustomerModal
						isRefundShowing={isRefundShowing}
						refundToggle={refundToggle}
						fullName={cancelledUser?.customerName}
						mainAction={() => redirectToInvoiceOrPayment(EPath.INVOICES, cancelledUser.invoiceId)}
					/>
				</Fragment>
			)}
		</Fragment>
	);
};
