/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { FrameContainer } from '../../../utils/FrameContainer';
import { AlertsStatusEnum } from '@app/react/types/customers';
import { BnIcon, ETypography, SkeletonWrapper, Typography } from '@bondsports/utils';
import { ReactNode } from 'react';
import {
	centerTextCss,
	centeredColumn,
	colorCss,
	flexCol,
	fulWidthCss,
	paddingCss,
	paddingTopCss,
	spaceBetweenCss,
} from '@app/react/styles/utils';
import { containerCss, iconCss } from './styles';
import { alertStatusMapper } from '../../utils';
import { colors } from '@app/react/styles/theme';

export const errorMessageCss = css`
	${[centeredColumn, fulWidthCss, centerTextCss, paddingCss(80)]};
	div {
		${colorCss(colors.dangerRed)};
	}
`;

export const iconSkeletonCss = css`
	width: 24px;
	height: 24px;
`;

interface Props {
	title: string;
	status: AlertsStatusEnum;
	footer: string | ReactNode;
	main: ReactNode;
	errorMessage: string;
	isLoading?: boolean;
	heightMultiple?: number;
}
export const AlertsCard = ({ title, status, main, footer, errorMessage, isLoading = false, heightMultiple }: Props) => {
	const isError = status === AlertsStatusEnum.ERROR;
	return (
		<div css={containerCss(heightMultiple)} data-testid="alerts-card">
			<FrameContainer
				withHeaderBottomMargin={false}
				title={title}
				button={
					<div css={iconSkeletonCss}>
						<SkeletonWrapper isLoading={isLoading}>
							<div>
								{status !== AlertsStatusEnum.N_A && (
									<BnIcon
										icon={alertStatusMapper[status].icon}
										css={iconCss(alertStatusMapper[status].color.primary)}
									/>
								)}
							</div>
						</SkeletonWrapper>
					</div>
				}
				margin={'0 0 16px 0'}
			>
				<div css={[flexCol, spaceBetweenCss, fulWidthCss]}>
					<SkeletonWrapper isLoading={isLoading} minHeight={60}>
						<div css={fulWidthCss}>{isError ? <ErrorMessage messsage={errorMessage} /> : main}</div>
					</SkeletonWrapper>
					<SkeletonWrapper isLoading={isLoading}>
						{!isError && (
							<div css={paddingTopCss(12)} data-testid="alerts-card-footer">
								{footer}
							</div>
						)}
					</SkeletonWrapper>
				</div>
			</FrameContainer>
		</div>
	);
};

const ErrorMessage = ({ messsage }: { messsage: string }) => {
	return (
		<div css={errorMessageCss}>
			<Typography type={ETypography.body2Accented}>{messsage}</Typography>
		</div>
	);
};
