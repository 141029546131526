/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { Pricify, PaymentStatusIndication } from '@bondsports/utils';
import { cellCss } from '../styles';
import { flex, gapCss } from '../../../../styles/utils';
import { PaymentStatusEnum } from '@bondsports/types';

interface IRowWithIcon {
	paymentStatus?: PaymentStatusEnum;
	withPaymentStatusIcon?: boolean;
}
type ITableRow = IRowWithIcon & unknown;

interface Props {
	row: ITableRow;
	column: any;
	index: number;
	isLast: boolean;
}

export const Currency = ({ column, row, isLast }: Props) => {
	const value = row[column.id];

	let pricifiedNumber = '';
	if (Array.isArray(value)) {
		pricifiedNumber = `${Pricify(value[0])} - ${Pricify(value[1])}`;
	} else if (value) {
		pricifiedNumber = Pricify(value);
	}

	const withIcon = row.withPaymentStatusIcon && row.paymentStatus;

	return (
		<td
		    data-aid="longString-currecny"
			className={`TableCell-root TableCell-body ${column.id}`}
			align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
			onClick={() => {
				if (column.action) {
					column.action(row);
				}
			}}
			css={cellCss({ isAction: column.action, isLast })}
		>
			{withIcon ? (
				<div css={[flex, gapCss(4)]}>
					{/* @ts-ignore  There seemes to be a type mismatch, even both used the same enum */}
					<PaymentStatusIndication paymentStatus={row.paymentStatus} />
					{pricifiedNumber}
				</div>
			) : (
				pricifiedNumber
			)}
		</td>
	);
};
