/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { HashRouter as Router, Link } from 'react-router-dom';
import { css, jsx, useTheme } from '@emotion/react';
import { getIcon } from '../../icons';
import { IColors } from '../../types/theme';
import { Step } from './type';

const container = (colors: IColors) => css`
	display: flex;
	min-height: 12px;
	a {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 12px;
		color: ${colors.tx_text_secondary};
		text-decoration: none;
		text-transform: capitalize;
		&:hover {
			color: ${colors.tx_text_primary};
		}
	}
	svg {
		margin: 0 8px;
		width: 12px;
		height: 12px;
		color: ${colors.tx_text_secondary};
	}
`;

export interface Props {
	steps: Step[];
}

export const Breadcrumbs = ({ steps }: Props) => {
	const colors = useTheme();
	return (
		<div data-aid="breadcrumbs" css={container(colors)}>
			<Router>
				{steps.map((step, index) => {
					return (
						<Fragment key={index}>
							<Link
								onClick={e => {
									if (step.action) {
										e.preventDefault();
										step.action();
									}
								}}
								key={index}
								to={step.to}
							>
								{step.label}
							</Link>
							{index !== steps.length - 1 && getIcon('forward')}
						</Fragment>
					);
				})}
			</Router>
		</div>
	);
};
