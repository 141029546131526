/** @jsx jsx */

import { Icons } from 'app/react/styles/theme';
import React, { useState, Fragment, useEffect } from 'react';
import { Form } from 'react-final-form';
import { jsx, css } from '@emotion/react';
import moment from 'moment';
import { FormDateInput } from '../../shared/FormControls/FormDateInput';
import SelectTextInput from '../../shared/FormControls/SelectTextInput';
import { InputLabel } from '../../shared/Input/InputLabel';
import { flex, gapCss } from '../../../styles/utils';
import { colors } from '../../../styles/theme';
import { EChargeInnerStep, IPayment } from '../../../types/payment';

import { PaymentMethodBox } from '../utils';
import { Footer } from '../utils/footer';
import { TranslationEn } from 'assets/i18n/en';
import { PaymentMethodTypeEnum, UserPaymentMethod } from '@bondsports/types';

const container = css`
	margin: 2rem;
	min-width: 350px;
`;

const labelCss = css`
	font-family: Montserrat;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.formInputBg};
`;

type TPaymentMethodTemporal = 'ach' | 'card';

interface Props {
	toggle: () => void;
	totalAmount: number;
	setPayments: (arr: IPayment[]) => void;
	setMethod: (str: string) => void;
	state: any;
	setState: (obj: any) => void;
	onNextClicked?: (val: EChargeInnerStep) => void;
	ACHMethods?: UserPaymentMethod[];
}
export const AddSchedulePayment = ({
	toggle,
	setPayments,
	setMethod,
	state,
	setState,
	totalAmount,
	ACHMethods,
	onNextClicked,
}: Props) => {
	const [paymentMethod, setPaymentMethod] = useState<TPaymentMethodTemporal | string>('');
	const [disabled, setDisabled] = useState<boolean>(true);
	const isShowAch = ACHMethods?.length > 0;

	const getNextPaymentDate = (firstPaymentDate, repeat, index) => {
		const format = repeat === 'weekly' ? 'weeks' : 'months';
		return moment(firstPaymentDate).add(index, format).format('YYYY-MM-DD');
	};

	const onSubmit = values => {
		const firstPayment = values.startDate;

		const payPerPayment = totalAmount / values.count;
		const payments = [];
		for (let i = 0; i < values.count; i++) {
			payments.push({
				plannedDate: getNextPaymentDate(firstPayment, values.repeat, i),
				price: payPerPayment,
			});
		}

		setPayments(payments);
		setState({ ...values, paymentMethod: paymentMethod });
		onNextClicked(paymentMethod as EChargeInnerStep);
	};

	const checkKeys = obj => {
		if (!obj.startDate) {
			return false;
		}
		if (paymentMethod === '') {
			return false;
		}
		if (!obj.count) {
			return false;
		}
		if (!obj.repeat) {
			return false;
		}

		return true;
	};

	const handleSelectPayment = (pm: TPaymentMethodTemporal | string) => {
		setPaymentMethod(pm);
		setMethod(pm);
	};

	return (
		<Fragment>
			<Form
				initialValues={state}
				onSubmit={onSubmit}
				validate={obj => {
					if (checkKeys(obj)) {
						const now = moment().format('YYYY-MM-DD');
						const selected = moment(obj.startDate).format('YYYY-MM-DD');
						if (moment(selected) < moment(now)) {
							setDisabled(true);
							return {
								startDate: 'start date must be at least today',
							};
						} else {
							setDisabled(false);
							if (obj.repeat === 'never' && +obj.count !== 1) {
								return {
									count: "count must be 1, if repeat set to 'never'",
								};
							} else {
								return {};
							}
						}
					}

					setDisabled(true);
					return {};
				}}
				render={({ handleSubmit }) => (
					<form autoComplete="off" onSubmit={handleSubmit}>
						<div data-aid="addSchedulePayment-input-start" css={container}>
							<FormDateInput
								rightIcon={Icons.calendar}
								required
								name="startDate"
								sizer="S"
								enablePast={false}
								label={'Starting date'}
							/>
							<div css={flex}>
								<InputLabel dataAid="addSchedulePayment-input-repeat" require label="Count" name="count" type="number" />
								<SelectTextInput
									controlName="repeat"
									require
									text="Repeat"
									menuOptions={[
										{ value: 'weekly', text: 'Weekly' },
										{ value: 'monthly', text: 'Monthly' },
										{ value: 'never', text: 'Never' },
									]}
								/>
							</div>
							<label css={labelCss}>{TranslationEn.payments.whichPaymentMethod}</label>
							<div data-aid="addSchedulePayment-card" css={[flex, gapCss(16)]}>
								<PaymentMethodBox method="card" active={paymentMethod === 'card'} handleClick={handleSelectPayment} />
								{isShowAch && (
									<PaymentMethodBox
										method={PaymentMethodTypeEnum.ACH}
										active={paymentMethod === PaymentMethodTypeEnum.ACH}
										handleClick={handleSelectPayment}
									/>
								)}
							</div>
						</div>

						<Footer
						    data-aid="addSchedulePayment-footer"
							toggle={toggle}
							disabled={disabled}
							loader={false}
							submitButtonText={TranslationEn.memberships.footer.defaultNext}
						/>
					</form>
				)}
			/>
		</Fragment>
	);
};
