import { useEffect, useState } from 'react';
import { PaginationMetaDto } from '@bondsports/types';

export const useSelectConnectedResource = (
    initialMeta = {
		totalItems: 1,
		itemsPerPage: 1,
		totalPages: 1,
		currentPage: 1,
    } as PaginationMetaDto
) => {
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

    useEffect(() => {
        if (isSelectAll && selectedIds?.length === initialMeta?.totalItems) {
            handleSelectAllFlag();
        }
    }, [selectedIds, initialMeta.totalItems]);
    
	const handleSelectAllFlag = () => {
		setSelectedIds([]);
		setIsSelectAll((prev) => !prev);
	};
	
	const handleSelect = (id: number) => {
		const isSelected = selectedIds.some((selectedId: any) => selectedId === id);
        let currentSelected = selectedIds;
		if (isSelected) {
            currentSelected = currentSelected.filter((selectedId: any) => selectedId !== id);
		} else {
            currentSelected = [...currentSelected, id];
		}
        setSelectedIds(currentSelected);
	};

	const isReplacePmDisabled = !initialMeta.totalItems || (isSelectAll && selectedIds.length === initialMeta.totalItems) || (!isSelectAll && !selectedIds.length);

	const isSelectIndeterminate = (): boolean => {
        const totalItems = initialMeta.totalItems;
		return selectedIds.length > 0 && selectedIds.length < totalItems;
	};

	const handleResetData = () => {
		setSelectedIds([]);
		setIsSelectAll(false);
	}

	return {
		meta: initialMeta,
		isReplacePmDisabled,
        isSelectIndeterminate,
		handleSelect,
		isSelectAll,
		handleSelectAll: handleSelectAllFlag,
		selectedIds,
		handleResetData
	};
};
