import { environment } from '../../../../environments/environment';
import { PublishingStatusEnum } from '../../types/enums';
import { network } from '../network';
import {
	ActivityDto,
	GetFacilitiesQueryDto,
	GetOrganizationProgramsDto,
	PaginationResultDto,
	ProgramTypesEnum,
	SimpleProgramDto,
} from '@bondsports/types';

import { IBasicError } from '@app/react/types/errors';
import { buildQueryString } from '../urlUtils';

const temporal_url = `${environment.CS_URLS.API_ROOT}/organizations`;

const RECORD_COUNT_LIMIT = 100;

const RECORD_COUNT_NO_LIMIT = 1000000;

//leagues =  https://dev-api.bondsports.co/v1/organizations/59/leagues?orderBy=name&datascope=full&limit=100&
//tournaments = https://dev-api.bondsports.co/v1/organizations/59/leagues?orderBy=name&datascope=full&limit=100&leagueType=tournament
//events = https://dev-api.bondsports.co/v1/organizations/59/events?limit=1000000

async function getActivitiesByOrganization(
	organizationId: number,
	programType: number,
	includeArchive = false
): Promise<any> {
	switch (programType) {
		case 0:
		case 7:
			return network
				.get(
					`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/leagues?orderBy=name&datascope=full&limit=${RECORD_COUNT_LIMIT}`
				)
				.then(res => res?.data?.map(l => ({ ...l, type: 0 })));
		case 1:
		case 8:
			return network
				.get(
					`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/leagues?orderBy=name&datascope=full&limit=${RECORD_COUNT_LIMIT}&leagueType=tournament`
				)
				.then(res => res.data.map(l => ({ ...l, type: 1 })));
		case 9:
			const events = await network.get(
				`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/events?limit=${RECORD_COUNT_NO_LIMIT}`
			);
			return events;

		default:
			const response = await network.get(
				`${environment.CS_URLS.API_ROOT}/programs/organization/${organizationId}/all/${programType}`
			);
			if (!includeArchive && response.data) {
				response.data = response.data.filter(activity => activity.status !== PublishingStatusEnum.ARCHIVE);
			}

			return response;
	}
}

const getActivities = async () => await network.get(`${environment.CS_URLS.API_ROOT_V4}/activities`);

const getActivityTypesForOrganization = async (organizationId: number) => {
	return await network.get(`${environment.CS_URLS.API_ROOT_V4}/programs/organization/${organizationId}/types/all`);
};

const getPaginatedOrganizationProgramsByType = async (
	organizationId: number,
	filters: GetOrganizationProgramsDto
): Promise<PaginationResultDto<SimpleProgramDto>> => {
	const query = buildQueryString(filters);

	return await network.get(`${environment.CS_URLS.API_ROOT_V4}/programs/organization/${organizationId}/simple${query}`);
};

const getPaginatedSportsByOrganizationId = async (
	organizationId: number,
	queryData: GetFacilitiesQueryDto
): Promise<PaginationResultDto<ActivityDto> | IBasicError> => {
	const search = queryData.search ? encodeURIComponent(queryData.search) : '';
	const query = buildQueryString({ ...queryData, search });

	return await network.get(`${environment.CS_URLS.API_ROOT_V4}/activities/organization/${organizationId}${query}`);
};

export const activitiesApi = {
	getActivities,
	getActivitiesByOrganization,
	getActivityTypesForOrganization,
	getPaginatedOrganizationProgramsByType,
	getPaginatedSportsByOrganizationId,
};
