/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AddPublicPrivateNotesProps, ECollapsedNotes } from '../../types';
import { containerCss } from '../../styles';
import { CollapsedNotesLine } from '../collapsed-notes-line';

export const AddPublicPrivateNotes: FC<AddPublicPrivateNotesProps> = ({
	initialPublicNote,
	initialPrivateNote,
	onChange,
}) => {
	const [isPublicShowing, setIsPublicShowing] = useState<Dayjs | null>(initialPublicNote ? dayjs() : null);
	const [isPrivateShowing, setIsPrivateShowing] = useState<Dayjs | null>(initialPrivateNote ? dayjs() : null);

	const { isPublicNoteFirst, noteType } = useMemo(() => {
		return {
			isPublicNoteFirst:
				isPublicShowing && isPrivateShowing
					? isPublicShowing.isBefore(isPrivateShowing)
					: Boolean(isPublicShowing) && !Boolean(isPrivateShowing),
			noteType:
				isPublicShowing && isPrivateShowing
					? ECollapsedNotes.BOTH
					: isPublicShowing && !isPrivateShowing
					? ECollapsedNotes.PUBLIC
					: isPrivateShowing && !isPublicShowing
					? ECollapsedNotes.PRIVATE
					: ECollapsedNotes.NONE,
		};
	}, [isPrivateShowing, isPublicShowing]);

	const handleOnChange = (publicText?: string, privateText?: string) => {
		onChange(publicText, privateText);
	};

	const handleOpenNote = (isPublic: boolean) => {
		if (isPublic) {
			setIsPublicShowing(dayjs());
		} else {
			setIsPrivateShowing(dayjs());
		}
	};

	const handleClose = (isPublic: boolean) => {
		if (isPublic) {
			setIsPublicShowing(null);
		} else {
			setIsPrivateShowing(null);
		}
	};

	return (
		<div css={containerCss} data-aid="index-add-note">
			<CollapsedNotesLine
				type={noteType}
				isPublicFirst={isPublicNoteFirst}
				onOpen={handleOpenNote}
				onClose={handleClose}
				onChange={handleOnChange}
				initialPublicNote={initialPublicNote}
				initialPrivateNote={initialPrivateNote}
			/>
		</div>
	);
};
