/** @jsx jsx */
import { jsx } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { UserIdentifierDto } from '@app/react/lib/api/userIdentificationApi';
import { CenteredLayout, Conflict, DialogPrimaryLayout, GridLayout, ShortDetailLayout } from 'ui';
import { ICustomer } from '@app/react/types/customers';
import { ETypography, ETypographyColor, truncateStringMiddleEllipsis, Typography } from '@bondsports/utils';
import {
	PASS_TRUNCATE_LENGTH_END,
	PASS_TRUNCATE_LENGTH_START,
	passesBodyTwoColGridCss,
} from './AssignPassModalManager';
import { useAssignPass } from './useAssignPass';

interface IProps {
	existingUserIdentifiers: UserIdentifierDto[];
	newIdentifierString: string;
	customer: ICustomer;
	onCancel: () => void;
}

const existingPassesLabelCss = {
	marginTop: '2rem',
	marginBottom: '2rem',
};

/*
 * Assign a new (ie. unused) pass to this customer, invalidating their old passes.
 */
export const AssignNewPassToCustomerOverridingExistingPassesModalBody = ({
	existingUserIdentifiers,
	newIdentifierString,
	customer,
	onCancel,
}: IProps) => {
	const labels = TranslationEn.customers.customerHeader.assignPassModal.assignNewPassOverridingExistingPasses;

	const assignPassInvalidatingExisting = useAssignPass(customer, newIdentifierString, onCancel, labels, true, false);

	return (
		<DialogPrimaryLayout
			icon={<Conflict className="h-7 w-7 text-error" />}
			title={labels.title}
			description={labels.bodyCopy}
			footerDescription={null}
			primaryButton={labels.assignButton}
			onPrimaryAction={assignPassInvalidatingExisting}
			secondaryButton={labels.cancelButton}
			onSecondaryAction={onCancel}
		>
			<CenteredLayout>
				<GridLayout maxColumns={2} css={passesBodyTwoColGridCss}>
					<div>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{labels.currentPassTH}
						</Typography>
						<ul>
							{existingUserIdentifiers.map(identifier => (
								<li key={identifier.id}>
									<Typography type={ETypography.body2} color={ETypographyColor.primary}>
										{truncateStringMiddleEllipsis(
											identifier.identifier,
											PASS_TRUNCATE_LENGTH_START,
											PASS_TRUNCATE_LENGTH_END
										)}
									</Typography>
								</li>
							))}
						</ul>
					</div>
					<div>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{labels.newPassTH}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{truncateStringMiddleEllipsis(newIdentifierString, PASS_TRUNCATE_LENGTH_START, PASS_TRUNCATE_LENGTH_END)}
						</Typography>
					</div>
				</GridLayout>

				<ShortDetailLayout
					color={ETypographyColor.secondary}
					icon={<Conflict className="h-5 w-5" />}
					css={existingPassesLabelCss}
				>
					{labels.overrideWarning}
				</ShortDetailLayout>
			</CenteredLayout>
		</DialogPrimaryLayout>
	);
};
