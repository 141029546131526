/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/react";
import { IColors } from "@bondsports/utils";

export const paymentMethodsIconCss = (colors: IColors, isDisabled = false) => css`
	color: ${isDisabled ? colors.bg_text_disabled : colors.tx_text_secondary};
	display: block;
`;

export const selectMethodContainerCss = css`
	display: flex;
	flex-direction: column;
	align-items: center;
`;