import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { setOpacity } from '../../styles/utils';
import { css } from '@emotion/react';

export const StyledSelect = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
    width: 100%;
    display: flex;
    background: ${colors.bg_background_fields};
    align-items: center;
    border-radius: 2px;
    margin: 0;
    color: ${colors.tx_text_primary};
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    flex-grow: 1;
    justify-content:space-between;
    outline:none;
    &.placeholder {
      color: ${colors.tx_text_teriaty};
    }

    .insideLeftLabel {
      padding-left: 12px;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

    .value{
        padding: 9px 12px;
        border: 1px solid transparent ;
        border-radius:2px 0 0 2px;
        width:100%;
        min-height:18px;
        cursor:default;
        box-sizing: unset;   
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        label{
          cursor:default;
        }
        text-align:left;
        &.focus{
          border: 1px solid ${colors.bg_border_primary} ;
        }
        &:hover, &.hover{
          
          border: 1px solid ${colors.bg_border_primary} ;
        }
        &.error{
          border: 1px solid ${colors.bg_error} ;
        }
    }
    .button{
        background:${colors.tx_text_teriaty};
        cursor:pointer;
        padding: 9px;
        display: flex;
        border-radius: 0 2px 2px 0;
        align-items: center;
        svg{

          color: ${colors.tx_white} !important;
        }
    }
    &.disabled{
      .button{
        background:${colors.tx_text_disabled};
      }
    }
    .tagValue{
      padding-left:8px;
      white-space: nowrap;
    }
    input {
      color: ${colors.bg_text_primary};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      padding-left: 8px;
      background: transparent;
      border: none;
      margin: 2px;
  
      outline: none;
      width: 100%;
      &::placeholder {
        color: ${colors.tx_text_teriaty};
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;     
      }
    }
  `
);

export const StyledValue = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
   
    &.disabled{
        color:${colors.bg_text_teriaty};
    }
  `
);

export const selectedTagsCss = css`
	padding-left: 4px;
`;
