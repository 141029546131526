/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx, useTheme } from '@emotion/react';
import { FC, useMemo, useState, useEffect, Fragment } from 'react';
import { BnIcon } from '../../../../components/icons_v2';
import { Icons } from '../../../../types';
import { useMiddlewareContext } from '../../../hooks/useMiddlewareContext';
import { IDayToDropProps } from '../../../types';
import { calculateModalPosition } from '../../../utils';
import { Droppable } from '../../Droppable';
import {
	collisionGroupContainerHorizontal,
	horizontalDayToDropContainer,
	moreItemsButtonHorizontal,
	moreModal,
} from '../../styles';
import { AddNewSlotButtonsContainer } from '../AddNewSlotButtons';
import { DraggableHorizontal } from './DraggableHorizontal';

export const DayToDropHorizontal: FC<IDayToDropProps> = ({ id, EventComponent, onAddNewSlotClick }) => {
	const { getEventsGroups, day: daysCount, eventsToSpaces } = useMiddlewareContext();
	const colors = useTheme();

	const eventsGroups = useMemo(() => {
		const events = eventsToSpaces[id];
		return getEventsGroups(events, true);
	}, [eventsToSpaces]);

	const [modals, setModals] = useState<boolean[]>([]);

	useEffect(() => {
		setModals(Array(eventsGroups.length).fill(false));
	}, [eventsGroups]);

	const handleToggleModal = (index: number) => {
		const newModals = modals.slice();
		newModals[index] = !newModals[index];
		setModals(newModals);
	};

	const MAX_COUNT = 4;

	return (
		<div data-aid="DayToDropHorizontal" css={horizontalDayToDropContainer(daysCount, colors)}>
			<AddNewSlotButtonsContainer parentId={id} horizontal={true} onClick={onAddNewSlotClick} />
			<Droppable id={id}>
				{eventsGroups.map((group, index) => {
					const withModal = (group.groups?.length || 0) > MAX_COUNT;

					if (Array.isArray(group.groups)) {
						const moreItemsCount = withModal
							? group.groups.slice(3).reduce((acc, events) => acc + events.length, 0)
							: 0;

						const element = document.querySelector(`#group-${index}`);
						const box = element as HTMLElement;
						const container = box?.parentElement;
						const boxW = container?.parentElement?.parentElement;
						const containerW = boxW?.parentElement;

						return (
							<div id={`group-${index}`} key={index} css={collisionGroupContainerHorizontal(group.top, group.height)}>
								{group.groups?.map((groupItems, index) => {
									return (
										<Fragment key={index}>
											{groupItems.map(event => {
												const isEditable = (event as any).isDraft
													? false
													: !['program_season', 'season'].includes(event.reservation?.creatorType) &&
														String(id) === String(event.spaceId) &&
														!event.parentSlotId;

												const draggableId = String(id) === String(event.spaceId) ? event.id : -1;
												return (
													<DraggableHorizontal
														parentId={id}
														state={event}
														key={event.id}
														id={draggableId}
														groupTop={group.top}
														groupCount={withModal ? MAX_COUNT : group.groups?.length}
														groupIndex={index}
														isEditable={isEditable}
													>
														<EventComponent event={event} />
													</DraggableHorizontal>
												);
											})}
										</Fragment>
									);
								})}
								{withModal && (
									<button
										data-aid="button-DayToDropHorizontal-more"
										css={moreItemsButtonHorizontal(colors)}
										onClick={() => handleToggleModal(index)}
									>
										<div>+{`${moreItemsCount} more`}</div>
									</button>
								)}
								{withModal && modals[index] && (
									<div css={moreModal(colors, calculateModalPosition(boxW, containerW, box, container))}>
										<div className="more-modal-header">
											<div onClick={() => handleToggleModal(index)}>
												<BnIcon icon={Icons.close} />
											</div>
										</div>
										<div className="events-list">
											{group.items.map(event => (
												<div key={event.id} css={{ margin: 2, height: '50px' }}>
													<EventComponent event={event} />
												</div>
											))}
										</div>
									</div>
								)}
							</div>
						);
					} else if (group.item) {
						const isEditable = (group.item as any).isDraft
							? false
							: !['program_season', 'season'].includes(group.item.reservation?.creatorType) &&
								String(id) === String(group.item.spaceId) &&
								!group.item.parentSlotId;

						const draggableId = String(id) === String(group.item.spaceId) ? group.item.id : -1;

						return (
							<DraggableHorizontal
								parentId={id}
								state={group.item}
								key={group.item.id}
								id={draggableId}
								isEditable={isEditable}
							>
								<EventComponent event={group.item} />
							</DraggableHorizontal>
						);
					} else {
						return null;
					}
				})}
			</Droppable>
		</div>
	);
};
