import { ReactNode } from 'react';
import { ETypographyColor } from '../../types';

export type TCell =
	| 'custom'
	| 'currency'
	| 'string'
	| 'dateWithTimeRange'
	| 'icon'
	| 'paymentMethod'
	| 'status'
	| 'statusWithIcon'
	| 'paymentMethodWithIcon'
	| 'fee'
	| 'feeWithIcon'
	| 'date'
	| 'resourceType'
	| 'priceType';

	export enum CellEnum {
		CUSTOM = 'custom',
		CURRENCY = 'currency',
		STRING = 'string',
		DATE_WITH_TIME_RANGE = 'dateWithTimeRange',
		ICON = 'icon',
		PAYMENT_METHOD = 'paymentMethod',
		STATUS = 'status',
		STATUS_WITH_ICON = 'statusWithIcon',
		PAYMENT_METHOD_WITH_ICON = 'paymentMethodWithIcon',
		FEE = 'fee',
		FEE_WITH_ICON = 'feeWithIcon',
		DATE = 'date',
		RESOURCE_TYPE = 'resourceType',
		PRICE_TYPE = 'priceType',
	}

export interface IColumn {
	id: string;
	type: TCell;
	label: string;
	numeric?: boolean;
	disablePadding?: boolean;
	styling?: StylingType;
	component?: (a: unknown, b?: unknown) => JSX.Element;
	action?: (a: unknown, b?: unknown) => void;
}

export enum AlignType {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
}

export interface StylingType {
	align?: AlignType;
	color?: ETypographyColor;
}

export interface ITableProps<T> {
	columns: IColumn[];
	rows: (T & { id?: number })[];
	pagination?: boolean;
	maxHeight?: number;
	bottomBorder?: boolean;
	removeRowSeparator?: boolean;
	isLoading?: boolean;
	footer?: ReactNode;
	isHover?: boolean;
	isExpand?: boolean;
	expandOn?: string[];
	expandableKey?: string;
	expandable?: boolean;
	additionalInfoRow?: string;
	subRowsColumns?: IColumn[];
}

export interface IInvoiceRowSubRow {
	text: string;
	price: string;
	total: string;
}
