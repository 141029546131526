import { promoCodesApi } from '@app/react/lib/api/promoCodesApi';
import { IBasicError } from '@app/react/types/errors';
import { TranslationEn } from '@assets/i18n/en';
import {
	ActivityEnum,
	DiscountDto,
	GetOrganizationPromoCodesDto,
	PaginationResultDto,
	PromoCodeDto,
} from '@bondsports/types';
import { IOption, TFetchDataResponse } from '@bondsports/utils';

export const fetchOrgPromoCodes = async <T>(
	organizationId: number,
	queryData: GetOrganizationPromoCodesDto,
	mapOptionsCallback?: (data: PromoCodeDto[]) => T[]
): TFetchDataResponse<T> => {
	try {
		const response = await promoCodesApi.getOrgPromoCodes({
			organizationId,
			queryData,
		});
		if ((response as IBasicError)?.err) {
			return { err: (response as IBasicError)?.err };
		} else {
			return {
				items: mapOptionsCallback
					? mapOptionsCallback((response as PaginationResultDto<PromoCodeDto>).data)
					: (response as PaginationResultDto<T>).data,
				meta: (response as PaginationResultDto<T>).meta,
			};
		}
	} catch (error) {
		return { err: error };
	}
};

export const isDiscountDisabled = (discount: DiscountDto) =>
	!discount.isValid || discount.uses === discount.maxUses || discount.status === ActivityEnum.INACTIVE;

const mapOptions = (data: PromoCodeDto[]): IOption[] => {
	return data.map(data => {
		return {
			label: data.discount.name,
			value: data.discountId,
			additionalInfo: TranslationEn.parentheses(data.code),
			isDisabled: isDiscountDisabled(data.discount),
		};
	});
};

export const fetchPromoCodes = async (
	organizationId: number,
	queryData: GetOrganizationPromoCodesDto
): TFetchDataResponse<IOption> => {
	return fetchOrgPromoCodes(organizationId, queryData, mapOptions);
};
