import { EndRepeatEnum, MaintenanceTimingEnum } from 'app/react/types/Booking';
import { TranslationEn } from 'assets/i18n/en';
import { ClientAddon, IUpdateSlotPrices } from 'app/react/types/calendar';
import {
	FrequencyEnum,
	ICalculateAddonsResponse as IResponse,
	IDraftReservationFromValues,
	IDraftSegmentsFormValues,
	IDraftSeriesFormValues,
	INewReservationFormValues,
	InvoiceDto,
	ReservationClientDto,
	ReservationDto,
	ReservationPricesUpdateTypeEnum,
	ReservationTypeEnum,
	SlotDto,
	SlotDurationTypeEnum,
	VisibilityEnum,
} from './NewReservationTypes';
import * as dayjs from 'dayjs';
import { deepClone, getFullName } from 'app/react/lib/utils';
import { IUpdatePrices, newReservationApi } from '@app/react/lib/api/newReservationApi';
import {
	CreateDraftSegmentDto,
	DraftReservationDto,
	DurationUnitTypesEnum,
	GenerateReservationDto,
	InstructorResourceDto,
	PackageProductsRelationTypeEnum,
	PriceDto,
	PrivacySettingsEnum,
	Product,
	ProductDto,
	ProductSubTypesEnum,
	ReservationTypeEnum as ApiReservationTypeEnum,
	ResourceNameTypeEnum,
	SpaceResourceDto,
	SportsEnum,
	Facility,
	Price,
} from '@bondsports/types';
import { ISchedule, ResourceWithSports } from './types';
import { capitalize, intersection } from 'lodash';
import { ETypography, ETypographyColor, formatUserName, IOption, IOption_V2 } from '@bondsports/utils';
import { INITIAL_RESERVATION_ADDONS } from './flows/rental/step4/components/ReservationAddons/constants';
import { INITIAL_SEGMENTS_ADDONS } from './flows/rental/step4/components/SegmentsAddons/constants';
import { DurationTypeEnum } from '@bondsports/types/dist/types/reservations/types/enums/reservation.enums';
import { VISIBILITY } from '@app/react/components/shared/formBlocks/VisibilityBlock/constants';
import { CUSTOMER } from '@app/react/components/shared/formBlocks/SingleCustomersSelect/constants';
import { ICustomer } from '@app/react/types/customers';
import { FormApi } from 'final-form';
import { isSameDateOrEarlier, isSameDateOrLater } from '@bondsports/date-time';
import { getBondDayOfWeek } from '@app/react/lib/dates';

const labels = TranslationEn.errors;

//To Do: Remove- duplicated from apps\backoffice\src\app\react\lib\dates.ts
export const timeSerialization = (time: string) => {
	if (time) {
		if (dayjs(time, 'hh:mm a').isValid()) {
			return dayjs(time, 'hh:mm a').format('HH:mm:ss');
		}
		return time;
	} else {
		return '';
	}
};

export const ReservationTypeToSlotType = {
	rental: 'external',
	program: 'internal',
	maintenance: 'maintenance',
};

export const generateData = (values: ReservationClientDto, removeSlots = false) => {
	values.segments = values.segments.map((seg, segIndex) => {
		// relevant to step 2
		if (seg.addonIds) {
			seg.addonIds = seg.addonIds.filter(id => id).map(id => Number(id));
		}

		seg.series = seg.series.map((s, index) => {
			// relevant to step 2
			if (values?.slotsBySeriesBySegments) {
				s.slots = values?.slotsBySeriesBySegments?.[segIndex]?.[index];
			}

			if (removeSlots) {
				delete s.slots;
			}

			// override the start+end times with serialized values
			const startTime = timeSerialization(s.startTime);
			const endTime = timeSerialization(s.endTime);

			const data = {
				...s,
				frequency: s.frequency || FrequencyEnum.NONE,
				slotDurationType: s.duration
					? SlotDurationTypeEnum.DURATION
					: s.allDay
					? SlotDurationTypeEnum.ALL_DAY
					: SlotDurationTypeEnum.DATES,
				startTime,
				endTime,
				maintenance: s.maintenance?.filter(m => m.title),
				repeatEndDate: s.occurrencesType === EndRepeatEnum.ON ? s.repeatEndDate : null,
				numberOccurrences: s.occurrencesType === EndRepeatEnum.AFTER ? s.numberOccurrences : null,
			};

			// remove the start+end times if not exist
			if (!startTime) delete data.startTime;
			if (!endTime) delete data.endTime;
			return data;
		});
		return {
			...seg,
			isPrivate: seg.isPrivate,
		};
	});

	if (values.addons) {
		values.addons = values.addons
			.filter(addon => addon.productId)
			.map(addon => {
				return {
					...addon,
					productId: Number(addon.productId),
					quantity: Number(addon.quantity),
				};
			});
	}

	// remove
	delete values.customer;
	delete values.slotsBySeriesBySegments;
	delete values.optionalAddons;
	delete values.optionalAddonsBySegments;
	delete (values as ReservationClientDto & { finalReservation: any }).finalReservation;

	return values;
};

export const generateNewSegments = ({
	organizationId,
	facilityId,
	data,
	onError,
}: {
	organizationId: number;
	facilityId: number;
	data: any;
	onError;
}) => {
	return newReservationApi.calculateAddons(organizationId, facilityId, generateData({ ...data }, true)).then(res => {
		if ((res as { err: string[] }).err) {
			onError(TranslationEn.errors.noProducts);
		} else {
			if (
				(res as IResponse).reservation.reservationType === ReservationTypeEnum.CUSTOMER &&
				(res as { invoice: InvoiceDto }).invoice.lineItems.length === 0
			) {
				onError(TranslationEn.errors.noProducts);
			} else {
				return (res as IResponse).reservation.segments;
			}
		}
	});
};

export const generateSlotsBySegments = (res: { reservation: ReservationDto; invoice?: InvoiceDto }, form?: any) => {
	const addons = {};
	const slotsBySeriesBySegments: SlotDto[][][] = [];
	const slots = [];
	res?.reservation?.segments?.forEach(segment => {
		const slotBySeries: SlotDto[][] = [];
		segment?.series?.forEach(s => {
			slotBySeries.push(s.slots);
			s?.slots?.forEach(slot => {
				slots.push(slot);
				slot?.product?.childProductPackages?.forEach(addon => {
					if (!addons[addon.childProductId]) {
						addons[addon.childProductId] = { ...addon.childProduct, level: addon.level };
					}
				});
			});
		});
		slotsBySeriesBySegments.push(slotBySeries);
	});

	if (form) {
		form.mutators.onSelect(
			`optionalAddons`,
			Object.keys(addons).map(key => addons[key])
		);
	}

	return { addons, slotsBySeriesBySegments, slots };
};
export const getAddonsByProduct = (product: Product) => {
	const addons = {};
	product?.childProductPackages.forEach(addon => {
		if (!(addon.childProductId in addons)) {
			addons[addon.childProductId] = { ...addon.childProduct, level: addon.level };
		}
	});
	return Object.keys(addons).map(key => addons[key]) as ClientAddon[];
};

export const generateSlots = async ({
	organizationId,
	facilityId,
	data,
	onError,
	callback,
	form,
	name,
	updateInvoice,
	segmentIndex,
	updateProduct = false,
	handleDraftSlots,
}: {
	organizationId: number;
	facilityId: number;
	data: any;
	onError;
	callback?: () => void;
	form?: any;
	name?: string;
	updateInvoice?: boolean;
	segmentIndex?: number;
	updateProduct?: boolean;
	handleDraftSlots?: (v: any) => void;
}) => {
	const newValues = deepClone(data);
	const res = await newReservationApi.calculateAddons(organizationId, facilityId, generateData({ ...data }, true));
	if ((res as { err: string[] }).err) {
		onError(TranslationEn.errors.noProducts);
		return undefined;
	} else {
		if (
			(res as IResponse).reservation.reservationType === ReservationTypeEnum.CUSTOMER &&
			(res as { invoice: InvoiceDto }).invoice.lineItems.length === 0
		) {
			onError(TranslationEn.errors.noProducts);
			return undefined;
		} else {
			const { addons, slotsBySeriesBySegments, slots } = generateSlotsBySegments(res as IResponse);

			form.mutators.onSelect('slotsBySeriesBySegments', slotsBySeriesBySegments);
			newValues.slotsBySeriesBySegments = slotsBySeriesBySegments;
			form.mutators.onSelect('finalReservation', (res as IResponse).reservation);
			newValues.finalReservation = (res as IResponse).reservation;
			form.mutators.onSelect(
				`optionalAddons`,
				Object.keys(addons).map(key => addons[key])
			);
			newValues.optionalAddons = Object.keys(addons).map(key => addons[key]);

			if (handleDraftSlots) {
				handleDraftSlots(slots);
			}

			if (updateProduct) {
				const fieldName = name ? `${name}.product` : 'product';
				const product = (res as IResponse).reservation?.segments[0]?.series[0]?.slots?.[0]?.product;
				form.mutators.onSelect(fieldName, product);
				newValues[fieldName] = product;
			}

			if (segmentIndex !== undefined) {
				const newOptionalAddons = { ...data.optionalAddons };
				newOptionalAddons[segmentIndex] = Object.keys(addons).map(key => addons[key]);
				form.mutators.onSelect(`optionalAddonsBySegments`, newOptionalAddons);
				newValues.optionalAddonsBySegments = newOptionalAddons;
			}
			form.mutators.onSelect(`addons`, [{ productId: '', quantity: '' }]);
			newValues.addons = [{ productId: '', quantity: '' }];
			if (name) {
				form.mutators.onSelect(`${name}.isAddons`, true);
				newValues[`${name}.isAddons`] = true;
				form.mutators.onSelect(`${name}.addonIds`, ['']);
				newValues[`${name}.addonIds`] = [''];
			} else {
				form.mutators.onSelect(`isAddons`, true);
				newValues.isAddons = true;
				form.mutators.onSelect('addonIds', ['']);
				newValues['addonIds'] = [''];
			}
			if (callback) {
				setTimeout(() => {
					callback();
				}, 300);
			}
			if (updateInvoice) {
				form.mutators.onSelect('segments', (res as IResponse).reservation.segments);
				form.mutators.onSelect('invoice', (res as IResponse).invoice);
			}
			newValues.segments = (res as IResponse).reservation.segments;
			newValues.invoice = (res as IResponse).invoice;
			return newValues as ReservationClientDto;
		}
	}
};

export const updateAddons = ({
	values,
	form,
	organizationId,
	facilityId,
	callback,
	onError,
}: {
	values: any;
	form: any;
	organizationId: number;
	facilityId: number;
	callback: () => void;
	onError: (message: string) => void;
}) => {
	const data = JSON.parse(JSON.stringify(values));

	newReservationApi.updateAddons(organizationId, facilityId, generateData(data, false)).then(res => {
		if ((res as unknown as { err: any }).err) {
			onError(labels.noProducts);
		} else {
			const slotBySeriesBySegments: SlotDto[][][] = [];
			(res as { reservation: ReservationDto; invoice: InvoiceDto }).reservation.segments.forEach(segment => {
				const slotBySeries: SlotDto[][] = [];
				segment.series.forEach(s => {
					slotBySeries.push(s.slots);
				});
				slotBySeriesBySegments.push(slotBySeries);
			});

			form.mutators.onSelect('slotsBySeriesBySegments', slotBySeriesBySegments);

			// should be stored
			form.mutators.onSelect(
				'segments',
				(res as { reservation: ReservationDto; invoice: InvoiceDto }).reservation.segments
			);
			form.mutators.onSelect('invoice', res.invoice);
			callback();
		}
	});
};

type IUpdatedPriceObject = IUpdateSlotPrices | IUpdatePrices;

//refactor needed: values and formData are the same
export const handlePricingType = (values: any, obj: IUpdatedPriceObject, formData: any) => {
	switch (values.pricing_type) {
		case ReservationPricesUpdateTypeEnum.INDIVIDUAL:
			const resourcesProducts = generateProductUpdateInterface(formData?.resourcesProducts);
			const reservationAddons = generateProductUpdateInterface(formData?.reservationAddons);
			const slotAddons = generateProductUpdateInterface(formData?.slotAddons);
			obj.products = [...resourcesProducts, ...reservationAddons, ...slotAddons];
			break;
		case ReservationPricesUpdateTypeEnum.CATEGORY:
			obj.category = { slot: values.resourcesSubTotal, addon: values.addonsSubTotal };
			break;
		case ReservationPricesUpdateTypeEnum.GLOBAL:
			obj.globalPrice = formData.reservationTotalPrice;
			break;
		default:
			break;
	}
};

interface AddToCartProps {
	invoice: InvoiceDto;
	finalReservation: ReservationDto;
}

export const updatePrices = ({
	values,
	form,
	organizationId,
	facilityId,
	callback,
	onError,
}: {
	values: any;
	form: any;
	organizationId: number;
	facilityId: number;
	callback: (values: AddToCartProps) => void;
	onError: (message: string) => void;
}) => {
	const formData = JSON.parse(JSON.stringify(values));

	const obj: IUpdatePrices = {
		reservation: generateData({ ...formData }, false),
		type: values.pricing_type,
	};
	handlePricingType(values, obj, formData);

	newReservationApi.updatePrices(organizationId, facilityId, obj).then(res => {
		if ((res as unknown as { err: any }).err) {
			onError(labels.somethingWentWrong);
		} else {
			const slotBySeriesBySegments: SlotDto[][][] = [];
			(res as { reservation: ReservationClientDto; invoice: InvoiceDto }).reservation.segments.forEach(segment => {
				const slotBySeries: SlotDto[][] = [];
				segment.series.forEach(s => {
					slotBySeries.push(s.slots);
				});
				slotBySeriesBySegments.push(slotBySeries);
			});
			const finalReservation = { ...res.reservation, invoice: res.invoice };
			const invoice = res.invoice;
			form.mutators.onSelect('slotsBySeriesBySegments', slotBySeriesBySegments);
			form.mutators.onSelect('finalReservation', finalReservation);
			form.mutators.onSelect('invoice', invoice);
			form.mutators.onSelect(
				'addons',
				(res as { reservation: ReservationClientDto; invoice: InvoiceDto }).reservation.addons
			);
			form.mutators.onSelect('segments', (res as IResponse).reservation.segments);
			callback({ invoice, finalReservation });
		}
	});
};

export const InitialSeriesData = {
	startDate: '',
	duration: false,
	endDate: '',
	allDay: false,
	isRepeat: false,
	repeatOn: [],
	numberOfOccurrences: 0,
	repeatEndDate: '',
	repeatEvery: 0,
	occurrencesType: EndRepeatEnum.ON,
	slotDurationType: SlotDurationTypeEnum.DATES,
	frequency: FrequencyEnum.NONE,
	maintenance: [
		{
			title: '',
			durationValue: 10,
			maintenanceDurationdurationType: DurationUnitTypesEnum.MINUTES,
			maintenanceTiming: MaintenanceTimingEnum.BEFORE,
		},
	],
};

export const initialData: INewReservationFormValues = {
	pricing_type: ReservationPricesUpdateTypeEnum.INDIVIDUAL,
	visibility: VisibilityEnum.PUBLIC,
	colorCodeId: undefined,
	reservationAddons: INITIAL_RESERVATION_ADDONS,
	segmentsAddons: INITIAL_SEGMENTS_ADDONS,
	segments: [
		{
			// @ts-ignore
			series: [structuredClone(InitialSeriesData)],
			isPrivate: false,
			visibility: VisibilityEnum.INHERIT,
		},
	],
};

export const generateProductUpdateInterface = arr => {
	if (arr) {
		return arr.map(product => {
			return {
				productId: product.id,
				price: +product.price,
				originalPrice: product.originalPrice,
				priceId: product.priceId,
			};
		});
	}
	return [];
};

/**
 * Returns the price the first price by the given compression
 * @param prices {Price[]} - prices to get the price from
 * @param compression {(a: Price, b: Price) => number} - compression to compare the prices
 * @returns {Price} - the most accurate price
 */
export function getPriceBy(prices: PriceDto[], compression: (a: PriceDto, b: PriceDto) => number): PriceDto {
	return structuredClone(prices).sort(compression).at(0);
}

/**
 * Flattens the prices of the products
 * @param products {ProductDto[]} - products to get the prices from
 * @returns {Price[]} - prices of the products
 */
export function flatProductPrices(products: ProductDto[]): PriceDto[] {
	return products.flatMap(product => {
		switch (product.productSubType) {
			case ProductSubTypesEnum.PER_PARTICIPANT_RENTAL:
				const childProduct: Product = product.childProductPackages.find(
					pp => pp.relationType === PackageProductsRelationTypeEnum.PARTICIPANT
				)?.childProduct;
				return childProduct.prices as unknown as PriceDto[];
			case ProductSubTypesEnum.GROUP_RENTAL:
			case ProductSubTypesEnum.RENTAL:
				return product.prices;
		}
	});
}

/**
 * Returns the price of the extra participant product
 * @param product {ProductDto} - product to get the extra participant price from
 * @returns {Price} - price of the extra participant product
 */
export function getExtraParticipantPrice(product: ProductDto): PriceDto {
	return product.childProductPackages.find(pp => pp.relationType === PackageProductsRelationTypeEnum.EXTRA_PARTICIPANT)
		?.childProduct?.currPrice as unknown as PriceDto;
}

/**
 * Generates resource options for the select
 * @param resources {ResourceWithSports[]} - resources to generate options for
 */
export function generateResourceOptions(resources: ResourceWithSports[]): IOption_V2[] {
	return resources?.map(resource => {
		const name =
			resource.firstName || resource.lastName ? formatUserName(resource.firstName, resource.lastName) : resource.name;

		return {
			label: name,
			value: resource.id,
			...(resource.resourceSubType && {
				additionalInfo: capitalize(resource.resourceSubType),
				additionalTypographyType: ETypography.body2Accented,
			}),
			additionalRows: [{ additionals: [resource.sports?.map(sport => TranslationEn.sports[sport]).join(', ') ?? ''] }],
		};
	});
}

const PRODUCTS_AND_RESOURCES_LABELS = TranslationEn.forms.newReservation.step2.productsAndResources;

/**
 * Generates product options for the select, based on the selected sports, spaces and instructors
 * @param products {ProductDto[]} - products to generate options for
 * @param instructors {InstructorResourceDto[]} - instructors to check if they have the selected sports
 * @param spaces {SpaceResourceDto[]} - spaces to check if they have the selected sports
 * @param sport {SportsEnum} - selected sport
 * @returns {IOption_V2[]} - product options for the select
 */
export function generateProductOptions(
	products: ProductDto[],
	instructors: InstructorResourceDto[],
	spaces: SpaceResourceDto[],
	sport: SportsEnum
): IOption_V2[] {
	if (!products?.length) {
		return [];
	}

	const spaceIds: Set<number> = new Set(spaces?.map(space => space.id));
	const instructorIds: Set<number> = new Set(instructors?.map(instructor => instructor.id) || []);

	return products.map((product: ProductDto): IOption => {
		let isDisabled: boolean = !intersection(product.sports, [sport]).length;

		isDisabled = !product.productResources
			.filter(pr => pr.type === ResourceNameTypeEnum.SPACE)
			.some(pr => !spaceIds.has(pr.id));

		isDisabled =
			instructorIds.size &&
			!product.productResources
				.filter(pr => pr.type === ResourceNameTypeEnum.INSTRUCTOR)
				.every(pr => !instructorIds.has(pr.id));

		const productPrices: PriceDto[] = flatProductPrices([product]);

		let participants = '';
		if (
			product.productSubType === ProductSubTypesEnum.PER_PARTICIPANT_RENTAL ||
			product.productSubType === ProductSubTypesEnum.GROUP_RENTAL
		) {
			const maxQuantityPrice: PriceDto = getPriceBy(
				productPrices,
				(price, other) => other.maxQuantity - price.maxQuantity
			);
			const extraParticipantPrice: PriceDto = getExtraParticipantPrice(product);

			const extra = extraParticipantPrice?.maxQuantity - extraParticipantPrice?.minQuantity || 0;

			participants = `${maxQuantityPrice.maxQuantity + extra} ${PRODUCTS_AND_RESOURCES_LABELS.products.participants}`;
		}

		const lowestPrice: PriceDto = getPriceBy(productPrices, (price, other) => price.price - other.price);

		const currency: string = lowestPrice?.currency && TranslationEn.currency[lowestPrice.currency.toUpperCase()].symbol;

		const priceRow: string = lowestPrice && `${currency}${lowestPrice.price}${productPrices.length > 1 ? '+' : ''}`;

		return {
			label: product.name,
			value: product.id,
			additionalInfo: priceRow || null,
			additionalRows: [
				{ additionals: [optionSubTypeAndDurationRow(product.productSubType, product.durationMinutes), participants] },
			],
			isDisabled,
		};
	});
}

/**
 * Generates a row for the product options select
 * @param subType {ProductSubTypesEnum} - product sub type
 * @param duration {number} - product duration in minutes
 * @returns {string} - row for the product options select
 */
function optionSubTypeAndDurationRow(subType: ProductSubTypesEnum, duration: number): string {
	const durationAmount = Number((duration / 60).toFixed(1));

	const subTypeLabel: string =
		PRODUCTS_AND_RESOURCES_LABELS.products.productTypes[subType] ??
		PRODUCTS_AND_RESOURCES_LABELS.products.productTypes[ProductSubTypesEnum.RENTAL];

	return `${subTypeLabel} (${durationAmount} ${TranslationEn.duration.hr})`;
}

/**
 * Generates sports options for the select
 * @param sports {SportsEnum[]} - sports to generate options for
 * @param spaces {SpaceResourceDto[]} - spaces to check if they have the selected sports
 * @param instructors {InstructorResourceDto[]} - instructors to check if they have the selected sports
 * @param labels {any} - labels for the additional rows
 * @returns {IOption_V2[]} - sports options for the select
 */
export function getSportsOptions(
	sports: SportsEnum[],
	spaces: SpaceResourceDto[],
	instructors: InstructorResourceDto[],
	labels: any
): IOption_V2[] {
	if (!sports?.length) {
		return [];
	}

	return sports.map((sport: SportsEnum): IOption => {
		const doesSpaceHaveSport: boolean = !spaces?.length || spaces.some(space => space.sports.includes(sport));
		const doesInstructorHaveSport: boolean =
			!instructors?.length || instructors.some(instructor => instructor.sports.includes(sport));

		let message: string = '';
		if (!doesSpaceHaveSport && !doesInstructorHaveSport) {
			message = labels.selectedSpaceAndInstructorDontMatch;
		} else if (!doesSpaceHaveSport) {
			message = labels.selectedSpaceDoesNotMatch;
		} else if (!doesInstructorHaveSport) {
			message = labels.selectedInstructorDoesNotMatch;
		}

		return {
			label: TranslationEn.sports[sport],
			value: sport,
			isDisabled: !doesSpaceHaveSport || !doesInstructorHaveSport,
			...(message && { additionalRows: [{ color: ETypographyColor.red, additionals: [message] }] }),
		};
	});
}

function privacySettingsMapper(visibility: VisibilityEnum): PrivacySettingsEnum {
	switch (visibility) {
		case VisibilityEnum.PRIVATE:
			return PrivacySettingsEnum.PRIVATE;
		case VisibilityEnum.PUBLIC:
		default:
			return PrivacySettingsEnum.PUBLIC;
	}
}

function generateDraftReservationData(
	organizationId: number,
	values: IDraftReservationFromValues,
	form: FormApi
): GenerateReservationDto {
	const privacySetting: PrivacySettingsEnum = privacySettingsMapper(values[VISIBILITY]);
	const customer: ICustomer = values[CUSTOMER];

	return {
		reservation: {
			organizationId,
			name: values.name,
			reservationType:
				values.reservationType === ReservationTypeEnum.CUSTOMER
					? ApiReservationTypeEnum.RENTAL
					: ApiReservationTypeEnum.INTERNAL,
			privacySetting: privacySetting,
			colorCodeId: Number(values.colorCodeId),
			customerId: customer.id,
		},
		// @ts-ignore
		addSegments: values.segments.map<CreateDraftSegmentDto>(segment => {
			const segmentPrivacySetting: PrivacySettingsEnum =
				segment.visibility === VisibilityEnum.INHERIT ? privacySetting : privacySettingsMapper(segment.visibility);

			return {
				id: segment.id,
				resourceIds: segment.spaces.map(space => space.id),
				instructorsIds: segment.instructors?.map(instructor => instructor.id),
				title: segment.title,
				facilityId: segment.facilityId,
				participantsIds: segment.customers,
				participantsNumber: segment.participantCount,
				privacySetting: segmentPrivacySetting,
				reservationId: values.reservationId,
				colorCodeId: Number(values.colorCodeId),
				sportId: segment.sport,
				series: segment.series.map(series => ({
					startDate: series.startDate,
					startTime: series.startTime,
					endDate: series.endDate,
					endTime: series.endTime,
					slotDurationType: DurationTypeEnum.DATES,
					durationUnit: DurationUnitTypesEnum.MINUTES,
					frequency: series.frequency,
					repeatEvery: series.repeatEvery,
					repeatOn: series.repeatOn,
					repeatEndDate: series.repeatEndDate ? series.repeatEndDate : null,
					numberOccurrences: series.occurrences,
					maintenance: series.maintenance,
				})),
			};
		}),
		removeSegmentsIds: values.removedSegmentsIds,
	};
}

export async function generateDraftReservation(
	organizationId: number,
	values: IDraftReservationFromValues,
	form: FormApi
): Promise<DraftReservationDto> {
	const data: GenerateReservationDto = generateDraftReservationData(organizationId, values, form);
	return await newReservationApi.generateDraftReservation(organizationId, data);
}

// TODO: Create message when error structure is defined
/**
 * Creates a resource mismatch error message
 * @param errors {{ startDate: string; endDate: string; startTime: string; endTime: string }[]} - errors to create the message from
 * @returns {string} - formatted error message
 */
export function createProductResourceMismatchError(
	errors: { startDate: string; endDate: string; startTime: string; endTime: string }[]
): string {
	const errorMessages: string[] = [];

	// showing up to 3 errors
	const iterations = errors.length < 3 ? errors.length : 3;
	for (let i = 0; i < iterations; i++) {
		errorMessages.push(TranslationEn.forms.newReservation.step2.mismatchSlotsError(errors[i]));
	}

	return errorMessages.join('\n');
}

/**
 * Validates the schedule of the segment series
 * @param series {IDraftSeriesFormValues} - series to validate
 * @param facility {Facility} - facility to validate against
 * @param spaces {SpaceResourceDto[]} - spaces to validate against
 * @param instructors {InstructorResourceDto[]} - instructors to validate against
 * @returns {string[]} - invalid resources names (facility, spaces, instructors)
 */
export function validateSeriesSchedule(
	series: IDraftSeriesFormValues,
	facility: Facility,
	spaces: SpaceResourceDto[],
	instructors: InstructorResourceDto[]
): string[] {
	const invalidResources: string[] = [];

	const valid: boolean = facility.openingTimes.some(openingTime => validateSchedule(openingTime, series));

	if (!valid) {
		invalidResources.push(facility.name);
	}

	for (const space of spaces ?? []) {
		const valid: boolean = space.activityTimes.some(activityTime => validateSchedule(activityTime, series));

		if (!valid) {
			invalidResources.push(space.name);
		}
	}

	for (const instructor of instructors ?? []) {
		const valid: boolean = instructor.activityTimes.some(activityTime => validateSchedule(activityTime, series));

		if (!valid) {
			invalidResources.push(getFullName(instructor));
		}
	}

	return invalidResources;
}

/**
 * Validates the schedule of the series
 * @param schedule {ISchedule} - schedule to validate against
 * @param series {IDraftSeriesFormValues} - series to validate
 * @returns {boolean} - whether the schedule is valid
 */
function validateSchedule(schedule: ISchedule, series: IDraftSeriesFormValues): boolean {
	const startDay: number = getBondDayOfWeek(series.startDate);
	const endDay: number = getBondDayOfWeek(series.endDate);

	if (schedule.availabilityStartDate && schedule.availabilityEndDate) {
		const availabilityStartDate: Date = new Date(schedule.availabilityStartDate);
		const availabilityEndDate: Date = new Date(schedule.availabilityEndDate);

		if (!isSameDateOrLater(availabilityStartDate, new Date(series.startDate))) {
			return false;
		}

		if (!isSameDateOrEarlier(availabilityEndDate, new Date(series.endDate))) {
			return false;
		}
	}

	return (
		series.startTime >= schedule.open &&
		series.endTime <= schedule.close &&
		schedule.dayOfWeek === startDay &&
		schedule.dayOfWeek === endDay
	);
}
