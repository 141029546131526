import { SelectablePaymentMethodType } from '@bondsports/utils/build/src/types/entities/payment';
import { IResourcesFilter, NormalizedPaymentMethod, OrderByFieldsEnum, ResourceRow } from './types';
import { ConnectedResourceDto, UpdateConnectedResourcePaymentMethodDto, UserPaymentMethod  } from '@bondsports/types';

export function mapDataToTable(data: ConnectedResourceDto[]): ResourceRow[] {
	return data.map(resource => {
		return {
			id: resource.resourceId,
			type: resource.resourceType,
			paymentMethodId: resource.paymentMethod.paymentMethodId,
			paymentType: resource.paymentMethod.paymentMethodType,
			ccLast4: resource.paymentMethod.ccLast4,
			ccBrand: resource.paymentMethod.ccBrand,
			name: resource.name,
			createdAt: resource.createdAt,
			invoiceId: resource.invoiceId,
			membershipId: resource.membershipId,
		};
	});
}

export function mapTableToData(data: ResourceRow[], organizationId: number, newMethod: NormalizedPaymentMethod): UpdateConnectedResourcePaymentMethodDto[] {
	return data?.map(resource => {
		return {
			resourceId: resource.id,
			organizationId,
			resourceType: resource.type,
			paymentMethodId: newMethod.id,
			paymentMethodType: newMethod.type,
		};
	}
	);
}

export const normalizePaymentMethod = ({ id, paymentMethodType, paymentMethodId, ...method }: UserPaymentMethod): NormalizedPaymentMethod => {
	return { id: paymentMethodId, type: paymentMethodType as SelectablePaymentMethodType, meta: { last4: method.ccLast4, company: method.ccBrand } };
};

export const initialFilters: IResourcesFilter = {
	resourceTypes: [],
	search: [],
};

export interface IGetResourcesFilters {
	search?: string;
	page?: number;
	resourceTypes?: number[];
	sortBy?: OrderByFieldsEnum[];
	order?: string[];
}
