/** @jsx jsx */

import React, { useEffect } from 'react';
import { ModalFooterWithTwoButtons } from '@bondsports/utils';
import { jsx, css } from '@emotion/react';

interface ISelectScheduleInstallmentsProps {
	isLoading: boolean;
	page: number;
	handleNext: () => void;
	onClose: () => void;
	noInstallmentsClicked: boolean;
	labels: {
		cancel: string;
		next: string;
	};
}
const InstallmentsFooter = ({
	isLoading,
	handleNext,
	noInstallmentsClicked,
	onClose,
	labels,
}: ISelectScheduleInstallmentsProps) => {
	return (
		<ModalFooterWithTwoButtons
			isLoading={isLoading}
			labels={{
				cancel: labels.cancel,
				next: labels.next,
			}}
			onNext={handleNext}
			onCancel={onClose}
			nextDisabled={noInstallmentsClicked}
		/>
	);
};

export default InstallmentsFooter;
