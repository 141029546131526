import { useCallback, useState } from 'react';
import { useTablePagination } from '@app/react/hooks/useTablePagination';
import { IBasicError } from '@app/react/types/errors';
import { OrderByEnum, PaginationMetaDto, PaginationResultDto } from '@bondsports/types';
import { useSearchFilter } from '../components/settings/PromoCodes/hooks/useSearchFilter';
import { initialMeta } from '@bondsports/utils';
import { useFilters } from './useFilters';
import { IGenericFilters } from '../types/filters';

interface UseTableDataProps<T, S, F, E> {
	initialData?: { rows?: S[]; meta?: PaginationMetaDto; isLoading?: boolean };
	fetchDataApi: (params: F) => Promise<PaginationResultDto<T> | IBasicError>;
	mapDataToTable: (data: T[]) => S[];
	initialSortValues: { order: OrderByEnum; sortBy: E };
	initialFilters?: IGenericFilters;
}

export const useTableData = <T, S, F, E>({
	initialData,
	fetchDataApi,
	mapDataToTable,
	initialSortValues,
	initialFilters = {},
}: UseTableDataProps<T, S, F, E>) => {
	const [isLoading, setIsLoading] = useState(initialData?.isLoading || false);
	const [error, setError] = useState('');
	const [table, setTable] = useState<{ rows: S[]; meta: PaginationMetaDto }>({
		rows: initialData?.rows || [],
		meta: initialData?.meta || initialMeta,
	});

	const { currentPage, nextPage, resetPagination } = useTablePagination();
	const { searchFilter, onSearch, debouncedSearch } = useSearchFilter();
	const { debouncedSelectedFilters, onSelectFilter, flatSelectedFilters } = useFilters(initialFilters);
	const [sortValues, setSortValues] = useState<{ sortBy: E; order: OrderByEnum }>(initialSortValues);

	const rows = table?.rows || [];

	const fetchData = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await fetchDataApi({
				page: nextPage,
				searchFilter,
				...sortValues,
				...debouncedSelectedFilters,
			} as F);
			if ((response as IBasicError)?.err) {
				setError((response as IBasicError)?.err);
				setTable({
					rows: [],
					meta: initialMeta,
				});
			} else {
				const responseData = response as PaginationResultDto<T>;
				setTable({
					rows: mapDataToTable(responseData.data),
					meta: responseData.meta,
				});
			}
		} catch (error) {
			setError(String(error));
		} finally {
			setIsLoading(false);
		}
	}, [nextPage, debouncedSearch, sortValues, debouncedSelectedFilters, fetchDataApi]);

	function handleSort(isAsc: boolean, sortBy: E) {
		setSortValues({ sortBy, order: isAsc ? OrderByEnum.ASC : OrderByEnum.DESC });
	}

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		onSearch(e);
		resetPagination();
	};

	const handleSelectFilter = (value: boolean, valueOfChecked: number, sectionClicked: string | number) => {
		onSelectFilter(value, valueOfChecked, sectionClicked);
		resetPagination();
	};

	return {
		rows,
		error,
		meta: table?.meta,
		currentPage,
		searchFilter,
		onSearch: handleSearch,
		fetchData,
		handleSort,
		isEmptyPage: !isLoading && !error && table?.rows.length === 0,
		isLoading,
		nextPage,
		onSelectFilter: handleSelectFilter,
		flatSelectedFilters,
		setIsLoading,
		sortValues,
		resetPagination,
	};
};
