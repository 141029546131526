/** @jsx jsx */
import { jsx } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { CenteredLayout, Conflict, DialogPrimaryLayout, GridLayout } from 'ui';
import { ICustomer } from '@app/react/types/customers';
import { ETypography, ETypographyColor, truncateStringMiddleEllipsis, Typography } from '@bondsports/utils';
import {
	PASS_TRUNCATE_LENGTH_END,
	PASS_TRUNCATE_LENGTH_START,
	passesBodyTwoColGridCss,
} from './AssignPassModalManager';

interface IProps {
	identifierString: string;
	customer: ICustomer;
	onCancel: () => void;
}

/*
 * No-op: this customer already has this pass assigned.
 */
export const PassAlreadyAssignedToSameCustomerModalBody = ({ identifierString, customer, onCancel }: IProps) => {
	const labels = TranslationEn.customers.customerHeader.assignPassModal.passAlreadyAssignedToSameCustomer;

	return (
		<DialogPrimaryLayout
			icon={<Conflict className="h-7 w-7 text-error" />}
			title={labels.title}
			description={labels.bodyCopy}
			footerDescription={null}
			primaryButton={labels.assignButton}
			onPrimaryAction={onCancel}
		>
			<CenteredLayout>
				<GridLayout maxColumns={2} css={passesBodyTwoColGridCss}>
					<div>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{labels.currentPassTH}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{truncateStringMiddleEllipsis(identifierString, PASS_TRUNCATE_LENGTH_START, PASS_TRUNCATE_LENGTH_END)}
						</Typography>
					</div>
					<div>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{labels.newPassTH}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{truncateStringMiddleEllipsis(identifierString, PASS_TRUNCATE_LENGTH_START, PASS_TRUNCATE_LENGTH_END)}
						</Typography>
					</div>
				</GridLayout>
			</CenteredLayout>
		</DialogPrimaryLayout>
	);
};
