/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { colors, colors_v2 } from '../../../styles/theme';
import { colorCss } from '../../../styles/utils';

/**
 *
 * @param removeRowSeparator
 * @param fullHeight true == 100%; causes performance issues with VirtualizedList
 * @returns
 */
export const tableCss = (removeRowSeparator = false, fullHeight = true) => css`
	${fullHeight ? 'height: 100%;' : ''}
	min-width: 150px;
	width: 100%;
	thead {
		z-index: 1;
		position: sticky;
		top: 0;
	}
	tbody tr td,
	.TableCell-body {
		color: ${colors.brandPrimary};
		border-bottom: ${removeRowSeparator ? '0 !important' : '1px solid rgba(224, 224, 224, 1)'};
	}
	tbody tr:last-child .TableCell-body {
		border-bottom: 0px solid rgba(224, 224, 224, 1);
	}
	[align='right'] {
		text-align: right;
	}
	[align='left'] {
		text-align: left;
	}
	[align='center'] {
		text-align: center;
		div {
			margin: 0 auto;
		}
	}
`;

export const deviderCss = css`
	color: ${colors.white};
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	padding: 4px 16px;
	background: ${colors.lightText};
	&.TableCell-body {
		background: ${colors.lightText};
		border-bottom: 1px solid rgba(224, 224, 224, 1);
	}
`;

export const pageBreakCss = css`
	break-inside: avoid !important;
`;

interface ICellCssProps {
	isAction: boolean;
	isLast?: boolean;
	isIcon?: boolean;
	svgWidth?: number;
	isUnderlined?: boolean;
	isDisabled?: boolean;
}

export const cellCss = ({ isAction, isLast, isIcon, svgWidth = 20, isUnderlined, isDisabled }: ICellCssProps) => css`
	padding: 6px 8px;
	margin-right: 4px;
	cursor: ${isAction ? 'pointer' : 'default'};
	${colorCss(colors.brandPrimary)}
	white-space: nowrap;
	${isUnderlined && 'text-decoration: underline;'}
	width: ${isIcon ? '20px' : 'unset'};
	max-width: 600px;
	.TableCell-paddingCheckbox {
		padding: 0 0 0 8px;
	}
	a {
		text-decoration: underline;
		${colorCss(colors.brandPrimary)}
	}

	svg {
		width: ${svgWidth}px;
		height: ${svgWidth}px;
		${colorCss(colors.lightText)}
	}
	${!isDisabled &&
	`&:hover {
		svg {
			${colorCss(isAction ? colors.brandPrimary : colors.lightText)}
		}
	}`}
	${isDisabled && colorCss(colors.disabled)}
`;

export const iconCell = css`
	height: 20px;
	cursor: pointer;
	width: 20px;
	display: flex;
	justify-content: center;
	div {
		display: flex;
		justify-content: center;
		cursor: pointer;
	}
`;

export const rowCss = (
	isHoverRow: boolean,
	isSubRow: boolean,
	subHeaderBottomBorder: boolean,
	isDisabled?: boolean
) => css`
	padding: 10px 8px;

	&:hover {
		td {
			background-color: ${isHoverRow || isDisabled ? colors_v2.bg_background_secondary : 'transparent'};
		}
	}
	.TableCell-body,
	tr {
		background: ${isSubRow || isDisabled ? colors.lightGray : colors.white};
		border-bottom: ${isSubRow && subHeaderBottomBorder ? '0 !important' : `1px solid ${colors.border_bottom}`};
	}
`;

export const showingStateCss = css`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: #a6bccc;
`;

export const paginationContainer = css`
	display: flex;
	margin-top: 1rem;
	justify-content: space-between;
	align-items: center;
`;

export const pageItem = css`
	.selected {
		background: linear-gradient(270deg, ${colors.brandSecondary} 0%, ${colors.brandSecondary} 100%);
		color: ${colors.white};
		outline: none;
		&:focus {
			outline: none;
		}
	}
`;

export const headerCss = css`
	background: ${colors.formControlBg};
	td {
		.TableCell-root {
			border-bottom: none;
		}
	}
`;

export const expandableButtonCss = css`
	padding: 0;
	margin-left: 10px;
`;

export const checkboxCss = css`
	&:hover {
		background: transparent;
	}
`;

export const iconCss = css`
	border-radius: 2px;
	width: 20px;
	height: 20px;
	background-color: rgba(13, 71, 116, 0.04);
	input:hover ~ & {
		background-color: #ebf1f5;
	}
	input:disabled ~ & {
		box-shadow: none;
		background-color: rgba(206, 217, 224, 0.5);
	}
`;

export const intermidiateIconCss = css`
	background-color: #ebf1f5;
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
	&:before {
		display: block;
		height: 20px;
		width: 20px;

		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3.79993' y='6.46667' width='6.4' height='1.33333' rx='0.666667' fill='%230D4774'/%3E%3C/svg%3E%0A");
		content: '';
	}
	input:hover ~ & {
		background-color: rgba(13, 71, 116, 0.04);
	}
`;

export const checkedIconCss = css`
	background-color: rgba(13, 71, 116, 0.04);
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
	&:before {
		display: block;
		height: 20px;
		width: 20px;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%230d4774'/%3E%3C/svg%3E");
		content: '';
	}
	input:hover ~ & {
		background-color: rgba(13, 71, 116, 0.04);
	}
`;

export const headerTdCss = (isRadio: boolean) => css`
	border-bottom: none;
	color: ${colors.brandPrimary};
	font-family: 'Montserrat';
	padding: 8px;
	font-size: 1.2rem;
	font-weight: 500;
	${isRadio && 'width:3%; '}
	padding-top: 8px;
	padding-bottom: 8px;
	background: ${colors.formControlBg};
	.TableSortLabel-active {
		color: ${colors.brandPrimary};
		font-family: 'Montserrat';
		font-size: 1.2rem;
		font-weight: 500;
	}
`;

export const visuallyHiddenCss = css`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	top: 20px;
	width: 1px;
`;

export const paginationCss = css`
	max-width: 352px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.pagination-item {
		min-width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		&.selected {
			background: linear-gradient(270deg, ${colors.brandSecondary} 0%, ${colors.brandSecondary} 100%);
			outline: none;
			&:focus {
				outline: none;
			}
			& a {
				color: ${colors.white};
			}
		}
		a {
			width: 100%;
			height: 100%;
			padding: 0 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: rgba(3, 8, 12, 0.882);
		}
		&:hover {
			color: ${colors.brandPrimary};
			background-color: rgba(0, 0, 0, 0.04);
		}
		&-icon {
			margin: 0 -8px;
			font-size: 2rem;
			fill: rgba(3, 8, 12, 0.882);
			width: 0.8em;
			height: 0.8em;
			display: inline-block;
			font-size: 2.4rem;
			transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			flex-shrink: 0;
			user-select: none;
		}
		&.arrow-disabled:hover {
			background-color: transparent;
		}
		&.arrow-disabled .pagination-item-icon {
			fill: rgba(0, 0, 0, 0.47);
		}
	}
`;
