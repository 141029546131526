import { atom, RecoilState } from 'recoil';
import { createPrefixedKey } from './stores.util';
import { PERMISSIONS_PREFIX } from './stores.const';

interface PermissionStore {
	// General permissions
	permissions: RecoilState<string[]>;
	lastFetchTimestamp: RecoilState<number>;
	// Super roles for permissions
	isAdmin: RecoilState<boolean>;
}

const permissions = atom<string[]>({
	key: 'new_permissions',
	default: [],
});

const lastFetchTimestamp = atom<number>({
	key: 'new_lastFetchTimestamp',
	default: 0,
});

const isAdmin = atom<boolean>({
	key: createPrefixedKey(PERMISSIONS_PREFIX, 'isAdmin'),
	default: false,
});

export const permissionStore: PermissionStore = {
	permissions,
	lastFetchTimestamp,
	isAdmin,
};

export type { PermissionStore };
