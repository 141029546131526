/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { PaymentMethodTypeEnum, UserPaymentMethod } from '@bondsports/types';
import { CheckboxButton } from '@app/react/components/payments/utils';
import { BnIcon, Icons } from '@bondsports/utils';
import { colors } from '@app/react/styles/theme';

interface IProps {
	method: UserPaymentMethod;
    isSelected: boolean;
    disabled?: boolean;
	handleSelect: (pmId: string) => void;
}

const cardIconCss = css`
	margin-right: 0.5rem;

	div {
		display: flex;
		justify-content: center;
		aling-items: center;
	}
`;

const achIconCss = css`
	width: 24px;
	height: 24px;
	margin-right: 0.5rem;
`;

const SelectableMethod = ({ method, handleSelect, disabled, isSelected }: IProps) => {
    const isCard = method.paymentMethodType === PaymentMethodTypeEnum.CARD;
    const icon: Icons = isCard ? Icons[method.ccBrand] : Icons.bank;
    const styling = isCard ? cardIconCss : achIconCss;
    return (
            <CheckboxButton
                key={`method_Checkboxkey-${method.paymentMethodId}`}
                disabled={disabled}
                backgroundColor={colors.white}
                onClick={() => handleSelect(method.paymentMethodId)}
                active={isSelected}
            >
                <BnIcon icon={icon} css={styling} />
                {method.ccLast4}
            </CheckboxButton>
    )};

export { SelectableMethod };
