import { useErrorModal } from '@app/react/hooks/useErrorModal';
import { customerStore } from '@app/react/stores/customerStore';
import { ICustomer, ICustomerResponse } from '@app/react/types/customers';
import { EStorageKeys } from '@app/react/types/enums';
import { useRecoilState } from 'recoil';
import { localStorage } from '@app/react/lib/storage';
import { customersApi } from '@app/react/lib/api/customersApi';
import { IErrorArr } from '@app/react/types/errors';
import { useNavigation } from '@app/react/hooks/useNavigation';
import {
	getCustomerPagePath,
	getFamilyPagePath,
	getInvoiceByIdPath,
	getMembershipByIdPath,
	NEW_FAMILY_ACCOUNT,
} from '@app/react/lib/paths';
import { useHistory } from 'react-router';
import { ERoutePaths } from '../../../../types/navigation';
import { getConnectedMembers } from '../lib';
import { ENotificationType } from '@bondsports/utils';
import { useNotification } from '@app/react/hooks/useNotification';
import { CustomerFamilyWithMembersDto, CustomerTypeEnum } from '@bondsports/types';

export const useCustomer = () => {
	const [customerState, setCustomerState] = useRecoilState(customerStore.customerState);
	const [isLoadingCustomer, setIsLoadingCustomer] = useRecoilState(customerStore.isLoadingCustomer);
	const [isLoadingLinkedAccounts, setisLoadingLinkedAccounts] = useRecoilState(customerStore.isLoadingLinkedAccountsr);
	const [connectedAccounts, setConnectedAccounts] = useRecoilState(customerStore.connectedAccounts);
	const { setErrorModal } = useErrorModal();
	const { ngNavigate, pushNavigation } = useNavigation();
	const history = useHistory();
	const { setToastNotification } = useNotification();
	const { CUSTOMER_DATA } = EStorageKeys;

	const isLoadingCustomerToggle = () => {
		setIsLoadingCustomer(state => !state);
	};

	const handleLoaderTrigger = (isDisableTriggerLoader: boolean) => {
		if (!isDisableTriggerLoader) {
			isLoadingCustomerToggle();
		}
	};

	const handleError = (errorMessage: string, errCallback?: (err: string) => void) => {
		if (errCallback) {
			errCallback(errorMessage);
		} else {
			setErrorModal({ message: errorMessage });
		}
		return;
	};

	const saveData = (data: ICustomer) => {
		setCustomerState(data);
		localStorage.setItem(CUSTOMER_DATA, data);
	};

	
	const handleResponse = async (response: ICustomerResponse | IErrorArr, enrichWithUser: boolean, errCallback?: (err: string) => void) => {
		if ((response as IErrorArr)?.err) {
			handleError(String((response as IErrorArr)?.err), errCallback);
		}
		else if ((response as ICustomerResponse).data) {
			const data = (response as ICustomerResponse).data;
			if (enrichWithUser && data.entityType === CustomerTypeEnum.USER) {
				const userResponse = await customersApi.getUser(data.entityId);
				if (userResponse.data) {
					data.user = userResponse.data;
				}
			}
			saveData(data);
		}
	};

	/**
	 * Fetches customer data by organization ID and customer ID.
	 * Optionally fetches and includes User data and handles errors.
	 *
	 * @param {Object} params - The parameters for fetching customer data.
	 * @param {number} params.organizationId - The ID of the Organization.
	 * @param {number} params.customerId - The ID of the Customer.
	 * @param {Function} [params.errCallback] - Optional callback function to handle errors.
	 * @param {boolean} [params.isDisableTriggerLoader=false] - Optional flag to disable the loader trigger.
	 * @param {boolean} [params.enrichWithUser=false] - Optional flag to fetch then include User data in the Customer object.
	 * @returns {Promise<void>} - A promise that resolves when the data is fetched and processed.
	 */
	const fetchCustomerData = async ({
		organizationId,
		customerId,
		errCallback,
		isDisableTriggerLoader = false,
		enrichWithUser = false,
	}: {
		organizationId: number;
		customerId: number;
		errCallback?: (err: string) => void;
		isDisableTriggerLoader?: boolean;
		enrichWithUser?: boolean;
	}) => {
		handleLoaderTrigger(isDisableTriggerLoader);
		const response = await customersApi.getCustomerById(organizationId, customerId);
		handleResponse(response, enrichWithUser, errCallback);
		handleLoaderTrigger(isDisableTriggerLoader);
	};

	const getCustomerData = async (organizationId: number, customerId: number) => {
		if (!customerState || customerState?.id !== customerId) {
			const customerData = localStorage.getItem(CUSTOMER_DATA) as ICustomer;
			if (customerData?.id === customerId) {
				setCustomerState(customerData);
			} else {
				fetchCustomerData({ organizationId, customerId });
			}
		}
	};

	const handleGoToCustomerPage = (customerId: number, additionalData?: string) => {
		const path = getCustomerPagePath(customerId);
		history.push(`${additionalData || ''}${path}`);
	};

	const handleGoToFamilyPage = (familyId: number) => {
		const path = getFamilyPagePath({ familyId, isLeadingSlash: false });
		ngNavigate(ERoutePaths.CUSTOMERS, path);
	};

	const goToCreateFamilyAccount = (customerId: number) => {
		history.push(`/${ERoutePaths.CUSTOMER}/${customerId}/${NEW_FAMILY_ACCOUNT}`);
	};

	const updateCustomerByProperty = <K extends keyof ICustomer>(property: K, value: ICustomer[K]) => {
		const updatedCustomer = { ...customerState };
		updatedCustomer[property] = value;

		saveData(updatedCustomer);
	};

	const navigateToMembershipById = (id: number) => ngNavigate(ERoutePaths.MEMBERSHIPS, getMembershipByIdPath(id));
	const navigateInvoiceById = (id: number, param?: string) => pushNavigation(getInvoiceByIdPath(id, param));

	const generateLinkedAccounts = async (customerState: ICustomer) => {
		setisLoadingLinkedAccounts(true);
		const linkedMembers = await getConnectedMembers(customerState, (eror: string) => {
			setToastNotification(eror, ENotificationType.warning);
		});
		setisLoadingLinkedAccounts(false);
		setConnectedAccounts(linkedMembers);
	};

	const resetConnectedAccounts = () => setConnectedAccounts([]);

	const resetCustomerState = () => setCustomerState(null);

	/**
	 * Get the profile picture URL for a Customer object, optionally falling back to the User's profile picture.
	 * For the User object to be included with the Customer, the `enrichWithUser` flag should be set when fetching the Customer data.
	 * 
	 * @param customer The Customer object for which to return the profile picture URL.
	 * @param fallbackToUser When true, and Customer has no profile picture, will return the User's profile picture URL, if available.
	 * @returns 
	 */
	const profilePictureUrl = (customer: ICustomer, fallbackToUser: boolean = true) => {
		if (customer?.profilePicture?.url) {
			return customer.profilePicture.url;
		}
		if (fallbackToUser && customer?.user?.profilePicture?.url) {
			return customer.user.profilePicture.url;
		}
		return null;
	}

	const getCustomerFamiliesWithMembers = async (
		organizationId: number,
		customerId: number,
		handleSuccess: () => void,
		handleError: () => void
	): Promise<CustomerFamilyWithMembersDto[] | []> => {
		try {
			const res = await customersApi.getCustomerFamiliesWithMembers(organizationId, customerId);

			if ((res as { err: string[] })?.err) {
				handleError();
				return [];
			} else {
				handleSuccess();
				return res as CustomerFamilyWithMembersDto[];
			}
		} catch {
			handleError();
		}
	};
	return {
		fetchCustomerData,
		getCustomerData,
		customerState,
		isLoadingCustomer,
		handleGoToCustomerPage,
		handleGoToFamilyPage,
		goToCreateFamilyAccount,
		updateCustomerByProperty,
		navigateToMembershipById,
		navigateInvoiceById,
		isLoadingLinkedAccounts,
		connectedAccounts,
		generateLinkedAccounts,
		resetConnectedAccounts,
		resetCustomerState,
		getCustomerFamiliesWithMembers,
		profilePictureUrl,
	};
};
