import { TranslationEn } from '../../../../../../assets/i18n/en';

export enum DurationUnitTypesEnum {
	MINUTES = 'minutes',
	HOURS = 'hours',
}

const labels = TranslationEn.timeFrame;

export const timeFrameEnumList = [
	{ label: labels.min, value: DurationUnitTypesEnum.MINUTES },
	{ label: labels.hour, value: DurationUnitTypesEnum.HOURS },
];
