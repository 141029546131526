/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { Fragment } from 'react';
import { Typography } from '../../../atoms';
import { Pricify } from '../../../lib/price';
import { IPayingUser, IPaymentMethod, PaymentMethodPicker } from '../../../molecules/PaymentMethodPicker';
import { ETypographyColor, ETypography } from '../../../types';
import { useRefundPopup } from '../hooks/useRefundPopup';
import { flexColCss, hrCss, justifyCenterCss, titleCss } from '../style';
import { ThemeColors as colors } from '../../../styles/theme';
import { ActionSelect, ArchiveSlotInvoiceActionEnum } from '../../../molecules/ActionSelect';

const containerCss = css`
	margin-bottom: 20px;
	height: 100%;
	overflow-x: clip;
`;

interface Props {
	amountToRefund: number;
	usedPaymentMethods: IPaymentMethod[];
	handleSelectedMethods: (val: IPaymentMethod[]) => void;
	handleSelectAction: (val: ArchiveSlotInvoiceActionEnum) => void;
	handleDisable?: (val: boolean) => void;
	disableActions?: boolean;
	isPublicInvoice?: boolean;
	invoiceOwner?: IPayingUser;
}

export const PaymentMethodStep = ({
	amountToRefund,
	usedPaymentMethods,
	handleSelectedMethods,
	handleSelectAction,
	handleDisable,
	disableActions = false,
	isPublicInvoice = false,
	invoiceOwner,
}: Props) => {
	const { labels } = useRefundPopup();

	const stepLabels = labels[0];

	const onChangeOfDetails = (paymentMethods: IPaymentMethod[]) => {
		const selected = paymentMethods.filter(pm => pm.selected);
		handleSelectedMethods(selected);
	};

	return (
		<div css={containerCss} data-aid="PaymentMethodStep-steps">
			<div css={[flexColCss, titleCss]}>
				<Typography css={justifyCenterCss} color={ETypographyColor.primary} type={ETypography.body1Accented}>
					{stepLabels.refundAmount} {Pricify(amountToRefund)}
				</Typography>
				<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
					{stepLabels.refundAmountSubtitle}
				</Typography>
			</div>
			<PaymentMethodPicker
				showAmount
				onChangeOfDetails={onChangeOfDetails}
				handleDisable={handleDisable}
				totalPrice={amountToRefund}
				usedPaymentMethods={usedPaymentMethods}
				invoiceOwner={invoiceOwner}
				isPublicInvoice={isPublicInvoice}
			/>
			{!disableActions && (
				<Fragment>
					<hr css={hrCss(colors)} />
					<ActionSelect
						actions={[
							ArchiveSlotInvoiceActionEnum.RETURN,
							ArchiveSlotInvoiceActionEnum.REFUND,
							ArchiveSlotInvoiceActionEnum.REMOVE,
						]}
						onActionSelect={handleSelectAction}
					/>
				</Fragment>
			)}
		</div>
	);
};
