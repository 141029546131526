import { BnIcon, Icons } from '@bondsports/utils';
import React from 'react';
import { Button } from 'ui';

export const MoreButton = () => {
	return (
		<Button data-testid="button-more" variant="icon">
			<BnIcon icon={Icons.more} />
		</Button>
	);
};
