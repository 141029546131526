/** @jsx jsx */
import { jsx } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { CenteredLayout, DialogPrimaryLayout, GridLayout } from 'ui';
import { ICustomer } from '@app/react/types/customers';
import { ETypography, ETypographyColor, truncateStringMiddleEllipsis, Typography } from '@bondsports/utils';
import {
	PASS_OWNER_TRUNCATE_LENGTH,
	PASS_TRUNCATE_LENGTH_END,
	PASS_TRUNCATE_LENGTH_START,
	passesBodyTwoColGridCss,
} from './AssignPassModalManager';
import { useAssignPass } from './useAssignPass';

interface IProps {
	newIdentifierString: string;
	customer: ICustomer;
	onCancel: () => void;
}

/*
 * Assign a new pass to a customer.
 */
export const AssignNewPassToCustomerModalBody = ({ newIdentifierString, customer, onCancel }: IProps) => {
	const labels = TranslationEn.customers.customerHeader.assignPassModal.assignNewPass;

	const assignNewPass = useAssignPass(customer, newIdentifierString, onCancel, labels, false, false);

	return (
		<DialogPrimaryLayout
			title={labels.title}
			description={labels.bodyCopy}
			footerDescription={null}
			primaryButton={labels.assignButton}
			onPrimaryAction={assignNewPass}
			secondaryButton={labels.cancelButton}
			onSecondaryAction={onCancel}
		>
			<CenteredLayout>
				<GridLayout maxColumns={2} css={passesBodyTwoColGridCss}>
					<div>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{labels.passIDTH}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{truncateStringMiddleEllipsis(newIdentifierString, PASS_TRUNCATE_LENGTH_START, PASS_TRUNCATE_LENGTH_END)}
						</Typography>
					</div>
					<div>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{labels.passOwnerTH}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{truncateStringMiddleEllipsis(customer.name, PASS_OWNER_TRUNCATE_LENGTH)}
						</Typography>
					</div>
				</GridLayout>
			</CenteredLayout>
		</DialogPrimaryLayout>
	);
};
