import { useState } from 'react';
import { paymentApi } from '@app/react/lib/api/paymentApi';
import { PaymentMethodTypeEnum, UserPaymentMethod, UserPaymentMethodOption } from '@bondsports/types';
import { customersApi } from '@app/react/lib/api/customersApi';
import { normalizePaymentMethod } from '../../../customers/ManagePaymentMethods/utils'
import { ICustomer } from '@app/react/types/customers';
import { NormalizedPaymentMethod } from '../../../customers/ManagePaymentMethods/types';

export const useGetPaymentMethods = (customerById?: ICustomer) => {
	const [paymentMethods, setPaymentMethods] = useState<UserPaymentMethodOption[]>([]);
	const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<UserPaymentMethod[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [normalizedPaymentMethods, setNormalizedPaymentMethods] = useState<NormalizedPaymentMethod[]>();
	const [customer, setCustomer] = useState<ICustomer>();
	const fetchPaymentMethods = async (organizationId: number, userId: number) => {
		setIsLoading(true);
		customerById ??= (await customersApi.getCustomerByUserId(organizationId, userId)).data;
		setCustomer(customerById);
		const paymentMethods = await paymentApi.getCustomerUserPaymentMethods(
			organizationId,
			Number(customerById?.entityId)
		);
		setIsLoading(false);
		return paymentMethods;
	};
	const getMethods = async (organizationId: number, userId: number) => {
		const methods = await fetchPaymentMethods(organizationId, userId);
			const filteredResults = methods
			.filter(paymentMethod =>
				([PaymentMethodTypeEnum.ACH, PaymentMethodTypeEnum.CARD].includes(paymentMethod.paymentMethodType) && !!paymentMethod.options?.length)
				)
				.flatMap(fpm => fpm.options);
			setFilteredPaymentMethods(filteredResults);
			setNormalizedPaymentMethods(filteredResults.map(method => normalizePaymentMethod(method)));
			setPaymentMethods(methods);
	};

	return { getMethods, paymentMethods, normalizedPaymentMethods, filteredPaymentMethods, customer, setCustomer, isLoading };
};
