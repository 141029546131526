import {
	CreateFamilyDto,
	CreateCustomerDto,
	CustomerFamilyWithMembersDto,
	CustomerTypeEnum,
	GenericResponseDto,
	PaginationResultDto,
	AddNoteDto,
	EditNoteDto,
	NoteDto,
	PunchPassInfoDto,
	ProgramTypesEnum,
	ProgramInfoDto,
	ResourceNameTypeEnum,
	UserPaymentMethod,
} from '@bondsports/types';
import { environment } from '../../../../environments/environment';
import { network } from '../network';
import { IPayment } from '../../types/orders';
import { CustomerQuery, ICustomer, ICustomerResponse, IGetInvoicesByCustomerId } from '../../types/customers';
import { IBasicError, IErrorArr, isErrArr, ISuccess } from '../../types/errors';
import { buildQueryString } from '../urlUtils';
import { EventWithAttendanceDto } from '@bondsports/types';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

const ITEMS_PER_PAGE_DEFAULT = 100;
const DEFAULT_PAGE = 1;

interface GetCustomersResponse {
	data: ICustomer[];
	meta: {
		currentPage: number;
		itemsPerPage: number;
		totalItems: number;
		totalPages: number;
	};
}

export interface IAddMemberResponse {
	//TODO- import from types and remove
	data: {
		familyCustomerId: number;
		newMembersIds: number[];
	};
}

async function getCustomersByOrganization(
	organizationId: number,
	options: CustomerQuery = {},
	signal?: AbortSignal
): Promise<PaginationResultDto<ICustomer> | IBasicError> {
	const queryString = buildQueryString({
		...options,
		page: options.page ?? DEFAULT_PAGE,
		itemsPerPage: options.itemsPerPage ?? ITEMS_PER_PAGE_DEFAULT,
		customerTypes: options.customerTypes ?? CustomerTypeEnum.USER,
	});
	const response = await network.get(`${v4APIUrl}/customers/organization/${organizationId}${queryString}`, { signal });
	return response;
}

async function getPaymentSecret(organizationId: number, userId: number) {
	const response = await network.get(`${v4APIUrl}/payment/organization/${organizationId}/${userId}/clientSecret`);

	return response.client_secret;
}

async function paymentMethods(organizationId: number, userId: number): Promise<UserPaymentMethod[]> {
	const response = await network.get(`${v4APIUrl}/payment/organization/${organizationId}/${userId}/methods`);

	return response;
}

const getCustomerById = async (organizationId: number, customerId: number): Promise<ICustomerResponse | IErrorArr> => {
	const response = await network.get(`${v4APIUrl}/customers/${customerId}/organization/${organizationId}`);
	return response;
};

const getCustomerByUserId = async (organizationId: number, userId: number) => {
	const response = await network.get(`${v4APIUrl}/organization/${organizationId}/customer/user/${userId}`);
	return response;
};

const getInvoiceById_DEPRICATED = async ({
	customerId,
	invoiceId,
	extended,
	organizationId,
}: {
	customerId: number;
	invoiceId: number;
	extended?: boolean;
	organizationId: number;
}) => {
	const response = await network.get(
		`${v4APIUrl}/payment/organization/${organizationId}/${customerId}/invoices/${invoiceId}?getReservations=true&${
			extended && `extended=true`
		}`
	);
	if (response.statusCode) {
		return response;
		// alert("Oops, looks like something went wrong");
	} else {
		const invoiceData = response;
		const slots = invoiceData.slots ?? [];
		const reservations = invoiceData.reservations ?? [];
		if (!!reservations.length && !!slots.length) {
			for (const slot of slots) {
				const reservation = reservations.find(r => r.id === slot.reservationId);
				if (reservation) {
					slot.reservation = reservation;
				}
			}
		}
		return { ...response, slots };
	}
};

const getInvoiceById = async ({
	customerId,
	invoiceId,
	organizationId,
	getExtended = false,
	getReservations = false,
}: {
	customerId: number;
	invoiceId: number;
	organizationId: number;
	getExtended?: boolean;
	getReservations?: boolean;
}) => {
	const url = new URL(
		`${v4APIUrl}/payment/organization/${organizationId}/customer/${customerId}/invoices/${invoiceId}`
	);
	const params = new URLSearchParams({
		getReservations: String(getReservations),
		extended: String(getExtended),
	});

	url.search = params.toString();
	const query = url.search;

	return await network.get(url.toString(), { query });
};

const getInvoiceByInvoiceIdHash = async (customerId: number, invoiceIdHash: string): Promise<any> => {
	return network.get(`${v4APIUrl}/payment/${customerId}/invoice/invoice-hash/${invoiceIdHash}`);
};

const addCustomer = async (organizationId: number, data: CreateCustomerDto) => {
	return network.post(`${v4APIUrl}/organization/${organizationId}/customer`, data);
};

const getCustomer = async (organizationId: number, customerId: number) => {
	return network.get(`${v4APIUrl}/customers/${customerId}/organization/${organizationId}`);
};

const updateCustomer = async (organizationId: number, customerId: number, data: ICustomer) => {
	const response = await network.patch(`${v4APIUrl}/customers/${customerId}/organization/${organizationId}`, data);
	return response;
};
const searchUsersByOrganization = async (organizationId: number, term: string) => {
	return await getCustomersByOrganization(organizationId, { nameSearch: term, customerTypes: CustomerTypeEnum.USER });
};

const getCustomerFunds = async (customerId: number, invoiceId: number) => {
	const response = await network.get(`${v4APIUrl}/payment/${customerId}/invoices/${invoiceId}/funds`);
	return response;
};

const getUser = async (userId: number) => {
	const response = await network.get(`${environment.CS_URLS.API_ROOT}/users/${userId}`);
	return response;
};

const getPaymentById = async (organizationId: number, paymentId: number, extended?: boolean) => {
	return await network.get(
		`${v4APIUrl}/payment/organization/${organizationId}/payments/${paymentId}?includeInvoice=true&onlyPublicNotes=false&${
			extended ? `extended=true` : ``
		}`
	);
};

/**
 * Void entire single invoice
 * @param {number} organizationId - Requesting organization
 * @param {number} invoiceId - Invoice to void
 * @returns {Promise<IPayment>}
 */
const voidInvoice = async (organizationId: number, invoiceId: number): Promise<IPayment> => {
	return await network.put(`${v4APIUrl}/payment/organization/${organizationId}/invoices/${invoiceId}/void`, {});
};

export type VoidInvoiceItemsBody = {
	lineItems: {
		id: number;
		quantity?: number; // if not passed - full quantity void
		isEdit?: boolean;
	}[];
	meta?: {
		removedResources: {
			resourceType: ResourceNameTypeEnum;
			resourceId: number; // slotId
			lineItemId: number; // line item with slotIds
		}[];
	};
};

const voidInvoiceItems = async (
	organizationId: number,
	invoiceId: number,
	lineItems: any[],
	body?: VoidInvoiceItemsBody
): Promise<IPayment> => {
	return await network.put(
		`${v4APIUrl}/payment/organization/${organizationId}/invoice/${invoiceId}/items/void`,
		body ? body : { lineItems }
	);
};

async function getInvoicesByCustomerId({
	customerId,
	page = 1,
	itemsPerPage,
	paymentStatusFilter = [],
	methodFilter = [],
	searchFilter = '',
	months = [],
	orderByProperty = [],
	statuses = [],
	sortOrder = [],
	isAlerts = false,
}: IGetInvoicesByCustomerId) {
	const searchParam = `id=${searchFilter ? encodeURIComponent(searchFilter) : ''}`;
	const paginationParams = `page=${page}&itemsPerPage=${itemsPerPage}`;
	const filteringParams = `statuses=${statuses}&orderByProperty=${orderByProperty}&order=${sortOrder}&paymentStatuses=${paymentStatusFilter}&paymentTypes=${methodFilter}&months=${months}`;

	const response = await network.get(
		`${v4APIUrl}/payment/${customerId}/invoices?${paginationParams}&${filteringParams}&${searchParam}&alerts=${isAlerts}`
	);
	return response;
}
//any should be changed to CreateFamilyResponseDto when types is updated
const createFamilyAccount = async (
	customerId: number,
	organizationId: number,
	data: CreateFamilyDto
): Promise<any | IErrorArr> => {
	return network.post(`${v4APIUrl}/customers/${customerId}/organization/${organizationId}/family`, data);
};

const editFamilyAccount = async (
	customerId: number,
	organizationId: number,
	data: CreateCustomerDto
): Promise<ICustomer | IErrorArr> => {
	return network.patch(`${v4APIUrl}/customers/${customerId}/organization/${organizationId}`, data);
};

export interface AddFamilyMemberPayload {
	customerIds?: number[];
	customers?: CreateCustomerDto[];
}
const addMembersToFamily = async (
	familyId: number,
	organizationId: number,
	data: AddFamilyMemberPayload
): Promise<IAddMemberResponse | IBasicError> => {
	return network.post(`${v4APIUrl}/customers/${familyId}/organization/${organizationId}/members`, data);
};

const updateEventsColors = async (
	customerId: number,
	organizationId: number,
	data: { futureOnly: boolean }
): Promise<ISuccess | IErrorArr> => {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/customer/${customerId}/update-slots-colors`,
		data
	);
	return response;
};

const getCustomersFamilyAdminAsCustomer = async (
	customer: Partial<ICustomer>
): Promise<{ admin?: ICustomer; family?: ICustomer }> => {
	if (customer.entityType !== CustomerTypeEnum.USER || !customer.linkedAccounts?.length) {
		return { admin: undefined, family: undefined };
	}

	// TODO: we currently support a single family per user
	const familyId = customer.linkedAccounts[0];

	// if the user has a family but no family type customer linked to it, then return the customer as admin.
	if (!familyId) {
		return { admin: customer as ICustomer, family: null };
	}

	const family = await customersApi.getCustomerById(customer.organizationId, familyId);

	if (isErrArr(family)) {
		throw family.err;
	}

	const admin = findAdminOfFamily(family.data);

	return { admin, family: family.data };
};

const findAdminOfFamily = (family: ICustomer) => {
	return family.members?.find(
		member => member.userInFamilyAccounts.find(userInFamily => userInFamily.familyAccountId === family.entityId).isAdmin
	);
};

const getCustomerFamiliesWithMembers = async (
	customerId: number,
	organizationId: number
): Promise<CustomerFamilyWithMembersDto[] | IErrorArr> => {
	const response = await network.get(
		`${v4APIUrl}/customers/${customerId}/organization/${organizationId}/family/members`
	);
	return response;
};

const getFamiliesForMultipleCustomers = async (
	organizationId: number,
	customerIds: number[]
): Promise<CustomerFamilyWithMembersDto[] | IErrorArr> => {
	const response = await network.post(`${v4APIUrl}/customers/organization/${organizationId}/family/members`, {
		customerIds,
	});
	return response;
};
const getCustomerNotes = async (
	organizationId: number,
	customerId: number,
	alertsOnly = false,
	itemsPerPage = 1,
	page = DEFAULT_PAGE,
	options?: Partial<RequestInit>
): Promise<PaginationResultDto<NoteDto>> => {
	const alertsOnlyQueryParam: string = alertsOnly ? 'alertsOnly=true' : '';

	const response = await network.get(
		`${v4APIUrl}/customers/${customerId}/organization/${organizationId}/notes?itemsPerPage=${itemsPerPage}&page=${page}&${alertsOnlyQueryParam}`,
		options
	);

	if (response.err) {
		throw response;
	}

	return response as PaginationResultDto<NoteDto>;
};

const addCustomerNote = async (
	organizationId: number,
	customerId: number,
	dto: AddNoteDto,
	options?: Partial<RequestInit>
): Promise<NoteDto> => {
	const response = await network.post(
		`${v4APIUrl}/customers/${customerId}/organization/${organizationId}/notes/add`,
		dto,
		null,
		options
	);

	if (response.err) {
		throw response;
	}

	return response as NoteDto;
};

const editCustomerNote = async (
	organizationId: number,
	noteId: number,
	dto: EditNoteDto,
	options?: Partial<RequestInit>
): Promise<NoteDto> => {
	const response = await network.put(
		`${v4APIUrl}/customers/organization/${organizationId}/notes/${noteId}/edit`,
		{ ...dto, isPinned: dto.isPinned || dto.isAlert },
		null,
		options
	);

	if (response.err) {
		throw response;
	}

	return response as NoteDto;
};

const archiveCustomerNote = async (
	organizationId: number,
	noteId: number,
	options?: Partial<RequestInit>
): Promise<GenericResponseDto> => {
	const response = await network.delete(
		`${v4APIUrl}/customers/organization/${organizationId}/notes/${noteId}/archive`,
		undefined,
		undefined,
		options
	);

	if (response.err) {
		throw response;
	}

	return response as GenericResponseDto;
};

const getCustomersPunchPasses = async (
	customerId: number,
	organizationId: number,
	itemsPerPage: number,
	page: number,
	options: RequestInit,
	includeFinancialInfo = true
): Promise<PaginationResultDto<PunchPassInfoDto>> => {
	const queryParams = `itemsPerPage=${itemsPerPage}&page=${page}&includeFinancialInfo=${includeFinancialInfo || ''}`;
	const response = await network.get(
		`${v4APIUrl}/customers/organization/${organizationId}/customer/${customerId}/passes?${queryParams}`,
		options
	);

	if (response.err) {
		throw response;
	}

	return response as PaginationResultDto<PunchPassInfoDto>;
};

const getCustomersPrograms = async (
	customerId: number,
	organizationId: number,
	itemsPerPage: number,
	page: number,
	programType: ProgramTypesEnum,
	options: RequestInit,
	includeFinancialInfo = true,
	includeResources = true
): Promise<PaginationResultDto<ProgramInfoDto>> => {
	const queryParams = `itemsPerPage=${itemsPerPage}&page=${page}&programType=${programType}&includeFinancialInfo=${
		includeFinancialInfo || ''
	}&includeResources={${includeResources || ''}`;
	const response = await network.get(
		`${v4APIUrl}/customers/organization/${organizationId}/customer/${customerId}/programs?${queryParams}`,
		options
	);

	if (response.err) {
		throw response;
	}

	return response as PaginationResultDto<ProgramInfoDto>;
};

interface IGetCustomersEventsParams {
	customerId: number;
	organizationId: number;
	itemsPerPage?: number;
	page?: number;
	startDateBefore?: Date;
	startDateAfter?: Date;
	endDateBefore?: Date;
	endDateAfter?: Date;
}

/**
 *  The Attendance child objects returned, if any, are those matching the given User ID.
 * 
 *  NB: If this is ever enhanced to return mutliple Attendances per Event, the pagination will need to be adjusted.
 *  	selectWithPagination in orm.utils will presrent a problem. 
 * 		It appends an OFFSET and LIMIT to the entire query. 
 * 		Today's underlying query joins Attendance records on the Event based on the User, so there should only be one per Event.
 * 		BUT in the case where there are multiple Attendance records, the DB would return each as its own row, 
 * 		and the LIMIT (added by selectWithPagination) would apply to those rows. Thus, you could end up with fewer Events than expected.
 * 
 */
const getCustomersEvents = async ({
	customerId,
	organizationId,
	itemsPerPage = ITEMS_PER_PAGE_DEFAULT,
	page = 1,
	startDateBefore,
	startDateAfter,
	endDateBefore,
	endDateAfter,
}: IGetCustomersEventsParams): Promise<PaginationResultDto<EventWithAttendanceDto>> => {
	const queryParams = buildQueryString({ itemsPerPage, page, startDateBefore, startDateAfter, endDateBefore, endDateAfter });
	const response = await network.get(
		`${v4APIUrl}/organization/${organizationId}/customers/${customerId}/events${queryParams}`
	);

	if (response.err) {
		throw response;
	}

	return response;
}

async function getPrimary(dependent: ICustomer): Promise<ICustomer> {
	const { admin } = await getCustomersFamilyAdminAsCustomer(dependent);
	return admin;
}

export const customersApi = {
	getCustomerById,
	getCustomerByUserId,
	getUser,
	getCustomerFunds,
	getInvoiceById_DEPRICATED,
	getCustomer,
	addCustomer,
	updateCustomer,
	getCustomersByOrganization,
	getPaymentSecret,
	paymentMethods,
	searchUsersByOrganization,
	getPaymentById,
	getInvoiceByInvoiceIdHash,
	getInvoicesByCustomerId,
	voidInvoice,
	voidInvoiceItems,
	createFamilyAccount,
	editFamilyAccount,
	addMembersToFamily,
	updateEventsColors,
	getCustomersFamilyAdminAsCustomer,
	getCustomerFamiliesWithMembers,
	getFamiliesForMultipleCustomers,
	getCustomerNotes,
	addCustomerNote,
	editCustomerNote,
	archiveCustomerNote,
	getCustomersPunchPasses,
	getInvoiceById,
	getCustomersPrograms,
	getCustomersEvents,
	getPrimary,
};
