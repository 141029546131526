/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { AlertsCard } from '../AlertsCard';
import { AlertsStatusEnum } from '@app/react/types/customers';
import { MembershipAlertRow } from './MembershipAlertRow';
import { TranslationEn } from '@assets/i18n/en';
import { MembershipAlert } from './types';
import { ReactNode } from 'react';

interface Props {
	status: AlertsStatusEnum;
	memberships: MembershipAlert[];
	footer: ReactNode;
	isLoading?: boolean;
}

export const Memberships = ({ memberships, footer, status, isLoading = false }: Props) => {
	const labels = TranslationEn.customers.overview.memberships;
	return (
		<AlertsCard
			title={labels.title}
			status={status}
			footer={footer}
			main={<Main memberships={memberships} />}
			errorMessage={labels.errorMessage}
			isLoading={isLoading}
		/>
	);
};

const Main = ({ memberships }: { memberships: MembershipAlert[] }) => {
	return <GridTable memberShips={memberships} />;
};

const GridTable = ({ memberShips }: { memberShips: MembershipAlert[] }) => {
	const gridContainerCss = css`
		display: grid;
		grid-template-columns: 6fr 8fr 1fr;
	`;

	return (
		<div css={gridContainerCss} data-testid="membership-alert-card">
			{memberShips.map((memberShip, index) => (
				<MembershipAlertRow key={index} {...memberShip} />
			))}
		</div>
	);
};
