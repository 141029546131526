import { customersApi } from '@app/react/lib/api/customersApi';
import { ICustomer } from '@app/react/types/customers';
import { CustomerFamilyStatusEnum, EmergencyContact } from '@bondsports/types';

export const getEmergencyContactDetails = (emergencyContacts: EmergencyContact[]) =>
	emergencyContacts.length && emergencyContacts[emergencyContacts.length - 1];

export const chekIsIndependentCustomer = (customer: ICustomer) => {
	return customer?.familyStatus === CustomerFamilyStatusEnum.INDEPENDENT;
};

export const checkIsDependentCustomer = (customer: ICustomer) => {
	return customer?.familyStatus === CustomerFamilyStatusEnum.DEPENDENT;
};

export const getCustomerPrimaryEmail = async (customer?: ICustomer, handleError?: () => void): Promise<string> => {
	let email = customer?.email;
	if (!customer?.email) {
		try {  
			const primary = await customersApi.getPrimary(customer);  
			email = primary?.email ?? email;
		  } catch (error) {  
			handleError && handleError();
		  }
	}
	return email;
};
