/** @jsx jsx */
import { jsx } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { CenteredLayout, DialogPrimaryLayout, GridLayout, Conflict } from 'ui';
import { ICustomer, ICustomerResponse } from '@app/react/types/customers';
import { UserIdentifierDto } from '@bondsports/types';
import { useEffect, useState } from 'react';
import { customersApi } from '@app/react/lib/api/customersApi';
import { ETypography, ETypographyColor, truncateStringMiddleEllipsis, Typography } from '@bondsports/utils';
import {
	PASS_OWNER_TRUNCATE_LENGTH,
	PASS_TRUNCATE_LENGTH_END,
	PASS_TRUNCATE_LENGTH_START,
	passesBodyTwoColGridCss,
} from './AssignPassModalManager';
import { useAssignPass } from './useAssignPass';

interface IProps {
	existingIdentifier: UserIdentifierDto;
	newCustomer: ICustomer;
	onCancel: () => void;
}

/*
 * Assign this in-use pass (held by differentCustomer) to the newCustomer.
 */
export const PassAlreadyAssignedToDifferentCustomerModalBody = ({
	existingIdentifier,
	newCustomer,
	onCancel,
}: IProps) => {
	const labels = TranslationEn.customers.customerHeader.assignPassModal.passAlreadyAssignedToDifferentCustomer;
	const [differentCustomer, setDifferentCustomer] = useState<ICustomer | null>(null);

	const migratePass = useAssignPass(newCustomer, existingIdentifier.identifier, onCancel, labels, false, true);

	useEffect(() => {
		// NB: Do not use `useCustomer` as it acts like a singleton; we're already using it to get the "current" customer.
		const fetchCustomer = async () => {
			try {
				const customerResponse = await customersApi.getCustomerById(
					existingIdentifier.organizationId,
					existingIdentifier.customerId
				);
				setDifferentCustomer((customerResponse as ICustomerResponse).data);
			} catch (error) {
				console.error('Error fetching customer', error);
			}
		};
		fetchCustomer();
	}, [existingIdentifier.organizationId, existingIdentifier.customerId]);

	return (
		<DialogPrimaryLayout
			icon={<Conflict className="h-7 w-7 text-error" />}
			title={labels.title}
			description={labels.bodyCopy}
			footerDescription={null}
			primaryButton={labels.assignButton}
			onPrimaryAction={migratePass}
			secondaryButton={labels.cancelButton}
			onSecondaryAction={onCancel}
		>
			<CenteredLayout>
				<GridLayout maxColumns={2} css={passesBodyTwoColGridCss}>
					<div>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{labels.currentPassOwnerTH}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{differentCustomer && truncateStringMiddleEllipsis(differentCustomer.name, PASS_OWNER_TRUNCATE_LENGTH)}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{truncateStringMiddleEllipsis(
								existingIdentifier.identifier,
								PASS_TRUNCATE_LENGTH_START,
								PASS_TRUNCATE_LENGTH_END
							)}
						</Typography>
					</div>
					<div>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{labels.newPassOwnerTH}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{truncateStringMiddleEllipsis(newCustomer.name, PASS_OWNER_TRUNCATE_LENGTH)}
						</Typography>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{truncateStringMiddleEllipsis(
								existingIdentifier.identifier,
								PASS_TRUNCATE_LENGTH_START,
								PASS_TRUNCATE_LENGTH_END
							)}
						</Typography>
					</div>
				</GridLayout>
			</CenteredLayout>
		</DialogPrimaryLayout>
	);
};
