/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { css, jsx, useTheme } from '@emotion/react';

import { BnIcon } from '../icons_v2';
import { dividerCss, stepCss, stepContainerCss, collapsedDividerCss } from './style';
import { IStep } from './types';

const FIRST_STEP_SPACE = 15;

export const Steps = ({
	arr,
	activeStep,
	handlePrevious,
	isCollapsed = false,
}: {
	arr: IStep[];
	activeStep: number;
	handlePrevious: (val: number) => void;
	isCollapsed?: boolean;
}) => {
	return (
		<div css={stepContainerCss}>
			{arr.map((step, index) => {
				const stepLabel = typeof step === 'string' ? step : step.label;
				const isDisabled = typeof step === 'object' && step.isDisabled;

				if (index === 0) {
					return (
						<Step
							handleClick={handlePrevious}
							key={stepLabel}
							index={index}
							isActive={index === activeStep}
							isCollapsed={isCollapsed}
							passed={index < activeStep}
							label={stepLabel}
							isDisabled={isDisabled}
						/>
					);
				} else {
					return (
						<Fragment key={stepLabel}>
							{isCollapsed ? (
								index - 1 === activeStep ? (
									<CollapsedDivider width={FIRST_STEP_SPACE} />
								) : (
									<CollapsedDivider />
								)
							) : (
								<Divider />
							)}
							<Step
								handleClick={handlePrevious}
								index={index}
								isActive={index === activeStep}
								isCollapsed={isCollapsed}
								passed={index < activeStep}
								label={stepLabel}
								isDisabled={isDisabled}
							/>
						</Fragment>
					);
				}
			})}
		</div>
	);
};

interface IStepProps {
	isActive: boolean;
	index: number;
	label: string;
	passed: boolean;
	handleClick: (val: number) => void;
	isCollapsed: boolean;
	isDisabled?: boolean;
}

const Step = ({ isActive, index, label, passed, handleClick, isCollapsed, isDisabled = false }: IStepProps) => {
	const colors = useTheme();
	return (
		<div
			onClick={() => {
				if (passed && !isDisabled) {
					handleClick(index);
				}
			}}
			css={stepCss(colors, isActive, passed, isDisabled)}
		>
			<div className="step">{passed ? <BnIcon icon="check" props={{}} /> : index + 1}</div>
			{(!isCollapsed || isActive) && <div className="step-label">{label}</div>}
			<div className="step-border" />
		</div>
	);
};

const Divider = () => {
	const colors = useTheme();
	return <div css={dividerCss(colors)} />;
};

const CollapsedDivider = ({ width }: { width?: number }) => {
	return <div css={collapsedDividerCss(width)} />;
};
