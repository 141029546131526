/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, PropsWithChildren } from 'react';
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { useColors } from '../../../hooks/useColors';
import { PaymentMethodRadio } from './paymentMethod';
import { flexCss, gapCss } from '../../../styles/utils';
import { SelectablePaymentMethodType } from '../../../types/entities/payment';
import { selectableContainerCss, cardWrapperCss, alignCenterCss, iconContainerCss } from './style';
import { UserPaymentMethod } from '../../../types/entities/payment'
import { BnIcon } from '../../../components/icons_v2';

interface PaymentMethodContainerProps {
	paymentMethods?: UserPaymentMethod[];
	labels: any;
	selected?: UserPaymentMethod;
	methodType: SelectablePaymentMethodType;
	fixedFee: string;
	feePercentage: string;
	showFeeWarning: boolean;
	handleSelect: (v: UserPaymentMethod) => void;
}
export const PaymentMethodContainer = ({
	paymentMethods,
	children,
	showFeeWarning,
	labels,
	methodType,
	feePercentage,
	fixedFee,
	selected,
	handleSelect,
}: PropsWithChildren<PaymentMethodContainerProps>) => {
	const { colors } = useColors();

	if (!paymentMethods?.length) {
		return <Fragment />;
	}

	return (
		<div css={selectableContainerCss(colors)}>
			{showFeeWarning && (
				<div css={[flexCss, gapCss(10), alignCenterCss]}>
					<div css={iconContainerCss(colors)}>
						<BnIcon icon={Icons.conflict} />
					</div>
					<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
						{labels.feeNotice(feePercentage, fixedFee, labels.methodTypes[methodType])}
					</Typography>
				</div>
			)}
			<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
				{labels.saved}
			</Typography>
			<div css={cardWrapperCss}>
				{paymentMethods?.map(paymentMethod => (
					<PaymentMethodRadio
						key={paymentMethod?.id}
						state={paymentMethod}
						isChecked={selected?.paymentMethodId === paymentMethod?.paymentMethodId}
						handleSelect={handleSelect}
					/>
				))}
			</div>
			<div css={[flexCss, gapCss(10), alignCenterCss]}>
				{children}
			</div>
		</div>
	);
};
