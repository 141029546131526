import React, { useCallback, useState } from 'react';
import { AddonRelationType, Product, ProductPackageChild } from 'bondsports-utils/types/entities/product';
import { SelectedAddonInterface } from 'bondsports-utils/types/entities/addon';
import { EventAsSeasonSegment, PaginationMetaDto, SharedPaginatedResultDto, SharedResourceInfo } from '@bondsports/types';
import { userApi } from '@app/react/lib/api/userApi';
import { productApi } from '../lib/api/productsApi';

export const useAddons = () => {
	const DEFAULT_ITEMS_PER_PAGE = 10;
	const [addons, setAddons] = useState([]);
	const [addonsMeta, setAddonsMeta] = useState<PaginationMetaDto>({
		currentPage: 1,
		totalItems: DEFAULT_ITEMS_PER_PAGE,
		totalPages: 1,
		itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const toSelectedAddon = ({
		product,
		relationType,
	}: {
		product: Product;
		relationType: AddonRelationType;
	}): SelectedAddonInterface => {
		return {
			id: product.id,
			name: product.name,
			price: product && product.currPrice && product.currPrice.price,
			relationType,
			type: 'addon',
			package: product,
		};
	};
	const mapEventsToAddons = (events: EventAsSeasonSegment[]) => {
		return events.map(event => {
			return {
				id: event.id,
				name: event.venueName,
				price: event.price,
				relationType: AddonRelationType.UPSALE,
				type: 'addon',
				package: event,
				};
		});
	}

	function mapProductToAddon(child: ProductPackageChild) {
		return toSelectedAddon({
			product: child.product,
			relationType: child.relationType,
		});
	}

	const getUserProgramAddons = useCallback(
		async (
			isSegment = false,
			organizationId: number,
			userId: number,
			eventOrSegmentId: number,
			productUserId: number,
			page: number
		) => {
			const request = isSegment ? userApi.getUserSegmentAddons : userApi.getUserEventAddons;

			try {
				setIsLoading(true);
				const response = await request(
					organizationId,
					userId,
					eventOrSegmentId,
					productUserId,
					DEFAULT_ITEMS_PER_PAGE,
					page
				);
				if (response.err) {
					throw new Error(response.err);
				} else {
					setError(null);
					setAddons(prevAddons => [...prevAddons, ...response.data]);
					setAddonsMeta(response.meta);
				}
			} catch (error) {
				setAddons([]);
				setError(error.message ?? error.toString());
			} finally {
				setIsLoading(false);
			}
		},
		[setAddons, setAddonsMeta, setError, setIsLoading]
	);

	const getAllAddonsByPaginatedRequest = async (eventOrSegmentId: number, organizationId: number) => {
		const allResourceResponses: SharedPaginatedResultDto<any, SharedResourceInfo>[] = [];
		const resourcesInfo: SharedPaginatedResultDto<any ,SharedResourceInfo>[] = await productApi.getAllResourcePages(
			eventOrSegmentId ?? 0,
			organizationId,
			{
				futureResources: true,
				itemsPerPage: DEFAULT_ITEMS_PER_PAGE
			},
		);
		allResourceResponses.push(...resourcesInfo);

		const allResources: any[] = [];
		allResourceResponses.forEach(resource => {
			const relevantResources = resource.data;
			allResources.push(...relevantResources);
		});

		return allResources;
	};

	return { mapProductToAddon, mapEventsToAddons, toSelectedAddon, getUserProgramAddons, getAllAddonsByPaginatedRequest, addons, addonsMeta, isLoading, error };
};
