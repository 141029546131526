/* eslint-disable */
// @ts-nocheck
import {
	AppendixAddonItem,
	IInvoiceTemplateState,
	IResponse,
	ISingleInvoice,
	ISinglePayment,
	ITaxType,
	PaymentStatusEnum,
} from '../types';
/*import {
 ILineItem,
 Invoice,
 LineItems,
 ProductTypesEnum,
 PurchasedResourceStatusEnum,
 Reservation,
 Resource,
 ResourceNameTypeEnum,
 Segment,
 Series,
 Slot,
 SlotTypeEnum,
 Customer,
 } from '@bondsports/types';*/
import { capitalize } from '../../../functions';
import dayjs from 'dayjs';
import { EDateFormats, getDateStringWithYear, getMonthDayRange, getTimeRangeDisplay } from '../../../lib/timeUtils';
import { expandLineItemData } from '../../../molecules/invoicing/details/lib';
import { chain, cloneDeep, groupBy, uniq, uniqBy } from 'lodash';
import { roundPriceCents } from '../../../lib/price';
import { DiscountOnTypeEnum } from '../../../molecules/invoicing/types';

const APPROVAL_STATUS = 'Approved';

const VOID_OR_REFUNDED = [PaymentStatusEnum.VOID, PaymentStatusEnum.REFUNDED];

const TAX_PRODUCT_TYPE = 'tax';

const buildLineItems = (lineItems: /*LineItems*/ any[]) => {
	const registrationLineItems: /*LineItems*/ any[] = createUserLineItemDictionary(
		lineItems.filter(li => li.productType === /*ProductTypesEnum.REGISTRATION*/ 'registration')
	);
	const reservationLineItems: /*LineItems*/ any[] = createUserLineItemDictionary(
		lineItems.filter(li => li.productType === /*ProductTypesEnum.RESERVATION*/ 'reservation')
	);
	const goodsLineItems: /*LineItems*/ any[] = createUserLineItemDictionary(
		lineItems.filter(li => li.productType === /*ProductTypesEnum.GOODS*/ 'goods')
	);
	return { registrationLineItems, reservationLineItems, goodsLineItems };
};

const cloneAndProcessInvoiceState = (invoiceState: IInvoice): IInvoice => {
	const updatedInvoiceState = cloneDeep(invoiceState);

	const serializeSessionEventsTimes = (resource: IPurchasedResource) => {
		const { sessionEvents } = resource.programSeason || {};
		if (sessionEvents?.length) {
			const updatedSessionEvents = sessionEvents.map((event: IEvent) => ({
				...event,
				startDate: event.startDateString || event.startDate,
				endDate: event.endDateString || event.endDate,
			}));
			resource.programSeason.sessionEvents = updatedSessionEvents;
		}
	};

	updatedInvoiceState?.lineItems?.forEach(lineItem => {
		lineItem.purchasedResources?.forEach(resource => {
			serializeSessionEventsTimes(resource);
		});
	});
	return updatedInvoiceState;
};

/**
 * Generate line items dictionary by userId
 * @param lineItems
 */
const createUserLineItemDictionary = (lineItems: /*LineItems*/ any[]) => {
	const userIds = lineItems.map(li => li.userId);
	let userLineItems: { [userId: number]: any[] } = {};
	for (const userId of userIds) {
		userLineItems[userId] = lineItems.filter(li => li.userId === userId);
	}
	return userLineItems;
};

/**
 * Generate reservation line items
 * @param invoice Invoice
 * @param lineItems
 * @param usedGoods
 * @param goodsLineItems
 * @param appendixItems
 */
const generateReservationLineItems = (
	invoice: /*Invoice*/ any,
	relevantLineItems: any[],
	userId: number,
	usedGoods: number[],
	goodsLineItems: /*LineItems*/ any[],
	appendixItems: any[]
) => {
	const reservations: /*Reservation*/ any[] = uniqBy(
		invoice.slots.map(s => s.reservation),
		'id'
	);
	const items = [];
	for (const reservation of reservations) {
		if (reservation) {
			if (reservation.addons?.length > 0) {
				usedGoods.push(reservation.addons.map(ao => ao.id));
			}

			let seriesArray: /*Series*/ any[] = [];
			reservation.segments?.forEach(segment => {
				if (segment?.series?.filter(ser => ser.slots?.some(sl => sl.invoiceId === invoice.id))?.length) {
					seriesArray = [
						...seriesArray,
						...segment?.series
							?.filter(ser => ser.slots?.some(sl => sl.invoiceId === invoice.id))
							.map(series => {
								series.segment = segment;
								const resources: /*Resource*/ any[] = [];
								series?.slots?.forEach(slot => {
									if (segment?.resourceIds?.includes(slot?.resource?.id)) {
										if (slot.resource) {
											resources.push(slot.resource);
										}
									}
								});
								series.resources = uniqBy(resources, 'id');
								return series;
							}),
					];
				}
			});

			for (const series of seriesArray) {
				const slots = (series?.slots || [])
					.filter(sl => sl.invoiceId === invoice.id && sl.slotType !== 'maintenance')
					.sort((a, b) => dayjs(a.startDate).unix() - dayjs(b.startDate).unix());

				const spaces = series?.resources as /*Resource*/ any[];
				const spacesText = capitalize(spaces?.[0]?.name ?? '').trim();
				const spacesTooltipContent =
					spaces?.length > 1 ? spaces.map(sp => capitalize(sp.name?.trim() ?? '')).join(', ') : '';
				const slotsAddonCount = series.slots?.reduce(
					(acc, slot) => acc + (slot.addons?.filter(addon => addon.approvalStatus === APPROVAL_STATUS)?.length ?? 0),
					0
				);
				const item = {
					description: capitalize(series.segment?.title ?? '').trim(),
					addonCount: {
						text: slotsAddonCount > 0 ? `${slotsAddonCount} item${slotsAddonCount > 1 ? 's' : ''}` : '',
						tooltipContent: '',
						moreCount: 0,
					},
					space: { text: spacesText, tooltipContent: spacesTooltipContent, moreCount: spaces?.length - 1 ?? 0 },
					daysTimes: {
						dateRange: '',
						timeRange: [''],
					},
					addons: [],
					startDate: new Date(),
					slots: slots.map(slot => {
						const spaceName = capitalize(series.resources?.find(r => r.id === slot.spaceId)?.name ?? '').trim();
						const eventDesc =
							(slot?.title ?? '').toLowerCase().trim() !== (series.segment?.title ?? '').toLowerCase().trim()
								? (slot?.title ?? '').toLowerCase().trim()
								: '';
						const addons = slot?.addons;

						addons.forEach(a => {
							const gli = goodsLineItems?.find(gli => gli.productUserId === a.productUserId);
							a.product = gli?.product;
						});
						const addonCountText = capitalize(addons?.[0]?.product?.name.trim()) ?? '';
						const addonTooltipContent = addons?.map(addon => capitalize(addon.product?.name.trim())).join(', ') ?? '';

						return {
							description: eventDesc,
							subTitles: [],
							daysTimes: {
								dateRange: getDateStringWithYear(dayjs(slot.startDate, EDateFormats.YYYY_MM_DD).toDate()),
								timeRange: [getTimeRangeDisplay(slot.startTime, slot.endTime)],
							},
							addonCount: {
								text: addonCountText ?? '',
								tooltipContent: addonTooltipContent,
								moreCount: addons && addons?.length > 0 ? addons?.length - 1 : 0,
							},
							startDate: slot.startDate,
							space: {
								text: spaceName === spacesText ? ' ' : spaceName,
								tooltipContent: '',
								moreCount: 0,
							},
							approvalStatus: slot.approvalStatus,
						};
					}),
				};

				item.startDate = dayjs(series.startDate).toDate();
				if ((reservation.segments as /*Segment*/ any[])?.[0].series?.[0].slots?.length) {
					item.daysTimes.dateRange = getMonthDayRange(
						String(slots[0].startDate),
						String(slots[slots?.length - 1].endDate)
					);
					item.daysTimes.timeRange = [getTimeRangeDisplay(slots[0].startTime, slots[0].endTime)];
				}
				items.push(item);
			}
		}
	}
	appendixItems.push(...items);
};

const handleBuildingAddonData = (
	apr: any,
	li: any,
	pr: any,
	item: {
		startDate?: Date;
		description: string;
		addonCount: { text: string; tooltipContent: string; moreCount: number };
		daysTimes: { dateRange: string; timeRange: any[] };
		addons: any[];
	},
	addons: any[],
	goodsLineItems: any[]
) => {
	const addon = goodsLineItems?.find(gli => gli.parentLineItemId === apr && gli.userId === li.userId);
	if (addon) {
		const selectedAddon = pr.event ?? pr.programSeason ?? pr.space;
		usedGoods.push(addon?.id);
		const dateRange = getDateStringWithYear(dayjs(selectedAddon.startDate).toDate());
		item.daysTimes.dateRange = dateRange;
		item.daysTimes.timeRange = [getTimeRangeDisplay(selectedAddon.startTime, selectedAddon.endTime)];
		const existingAddon = addons?.find(ao => ao.daysTimes.dateRange === dateRange);
		if (existingAddon) {
			existingAddon.daysTimes.timeRange.push(getTimeRangeDisplay(selectedAddon.startTime, selectedAddon.endTime));
		} else {
			addons.push({
				description: '',
				subTitles: [],
				daysTimes: {
					dateRange,
					timeRange: [getTimeRangeDisplay(selectedAddon.startTime, selectedAddon.endTime)],
				},
				addonCount: addon.product?.name,
				startDate: dayjs(selectedAddon.startDateString).toDate(),
			});
		}
	}
};

/**
 * Generate registration line items
 * @param registrationLineItems Line items with a product type if registration
 * @param goodsLineItems Line items with a product type if goods
 * @param usedGoods Used goods
 * @param appendixItems Appendix items
 */
const generateRegistrationLineItems = (
	registrationLineItems: /*LineItems*/ any[],
	goodsLineItems: /*LineItems*/ any[],
	usedGoods: number[],
	appendixItems: any[]
) => {
	for (const li of registrationLineItems) {
		const items = [];
		for (const pr of li.purchasedResources) {
			if (Number(pr.status) !== /*PurchasedResourceStatusEnum.CANCELED*/ 4) {
				const addons: AppendixAddonItem[] = [];
				const item: {
					startDate?: Date;
					description: string;
					addonCount: { text: string; tooltipContent: string; moreCount: number };
					daysTimes: {
						dateRange: string;
						timeRange: any[];
					};
					addons: any[];
					slots: any[];
				} = {
					description: pr.event?.parentSession?.name ?? pr.programSeason?.name ?? '',
					addonCount: { text: '', tooltipContent: '', moreCount: 0 },
					daysTimes: {
						dateRange: '',
						timeRange: [],
					},
					addons: [],
					slots: [],
				};
				switch (pr.resourceType) {
					case /*ResourceNameTypeEnum.EVENT*/
					'event':
						//  Get addons for this event
						item.startDate = dayjs(pr?.event?.startDateString || '').toDate();
						const eventAttendee = pr.event.eventAttendees?.find(ea => ea.productUserId === pr.productUserId);
						if (eventAttendee) {
							item.daysTimes.dateRange = dayjs(pr.event.startDateString, EDateFormats.SLASHED_DATE).format(
								EDateFormats.MMM_D_YYYY
							);
							item.daysTimes.timeRange = [getTimeRangeDisplay(pr.event.startTime || '', pr.event.endTime || '')];

							if (eventAttendee.addonProductUserIds?.length) {
								for (const apr of eventAttendee.addonProductUserIds) {
									handleBuildingAddonData(apr, li, pr, item, addons, goodsLineItems);
								}
							}
						}
						break;
					case /*ResourceNameTypeEnum.PROGRAM_SEASON*/
					'program_season':
						item.startDate = dayjs((pr.programSeason as any)?.startDateString).toDate();
						item.daysTimes.dateRange = getMonthDayRange(
							String(pr.programSeason?.startDate),
							String(pr.programSeason?.endDate)
						);

						const sessionEvents = pr.programSeason.sessionEvents;
						item.slots = sessionEvents
							?.sort((a, b) => {
								return (
									dayjs(a.startDateString, EDateFormats.SLASHED_DATE).toDate() -
									dayjs(b.endDateString, EDateFormats.SLASHED_DATE).toDate()
								);
							})
							?.map(s => {
								const eventDesc = li.purchasedResources.some(
									pr => pr.programSeason.name.toLowerCase().trim() !== (s?.title ?? '').toLowerCase().trim()
								)
									? (s?.title ?? '').toLowerCase().trim()
									: '';
								const addons =
									registrationLineItems?.filter(
										gli => s.addonsProductUserIds?.includes(gli.productUserId as number) && !isVoidOrRefunded(gli)
									) ?? [];
								if (addons?.length > 0) {
									usedGoods.push(...addons.map(a => a.id));
								}

								const addonCountText = capitalize(addons[0]?.product?.name.trim()) ?? '';
								const addonTooltipContent =
									addons?.map(addon => capitalize(addon.product?.name.trim())).join(', ') ?? '';
								return {
									description: eventDesc,
									subTitles: [],
									daysTimes: {
										dateRange: getDateStringWithYear(dayjs(s.startDate, EDateFormats.YYYY_MM_DD).toDate()),
										timeRange: [getTimeRangeDisplay(s.startTime, s.endTime)],
									},
									addonCount: {
										text: addonCountText ?? '',
										tooltipContent: addonTooltipContent,
										moreCount: addons && addons?.length > 0 ? addons?.length - 1 : 0,
									},
									startDate: s.startDate,
									approvalStatus: s.approvalStatus,
								};
							});
						break;
					default:
						break;
				}
				item.addonCount = {
					text: addons?.length > 0 ? `${addons?.length} item${addons?.length > 1 ? 's' : ''}` : ``,
					tooltipContent: '',
					moreCount: 0,
				};
				item.addons = [...addons].sort((a, b) => {
					return a.startDate.getTime() - b.startDate.getTime();
				});
				item.lineItem = li;
				items.push(item);
			}
		}
		appendixItems.push(...items);
	}
};

/**
 * Generate appendix items for goods line items
 * @param goodsLineItems Line items with a product type of goods
 * @param usedGoods Array of goods line item ids that have already been processed
 * @param sortedAppendixItems Array of appendix items that have already been processed
 */
const generateGoodsLineItems = (
	goodsLineItems /*:LineItems*/ = [],
	usedGoods: number[],
	sortedAppendixItems: any[]
) => {
	const remainingGoods = goodsLineItems.filter(gli => !usedGoods.includes(gli.id));
	if (remainingGoods?.length > 0) {
		for (const gli of remainingGoods) {
			let qty = (gli.quantity < 0 ? 0 : gli.quantity) || 1;
			while (qty--) {
				sortedAppendixItems.push({
					description: '',
					addonCount: { text: gli.product?.name ?? 'dont forget to fix product', tooltipContent: '' },
					daysTimes: {
						dateRange: '',
						timeRange: [],
					},
					addons: [],
					startDate: dayjs().toDate(),
				});
			}
		}
	}
};

/**
 * Process all line items in an invoice and return schedules for the appendix of the invoice
 * @param invoice Invoice object to be used to build the appendix schedule
 * @returns Process line items into schedules (where relevant) for appendix of invoice
 */
const buildAppendixData = (invoice: /*Invoice*/ any & { customer: /*Customer*/ any }): any[] => {
	const appendices: any[] = [];
	const processedInvoice = cloneAndProcessInvoiceState(invoice);
	const lineItems = processedInvoice?.lineItems ?? [];

	const { registrationLineItems, reservationLineItems, goodsLineItems } = buildLineItems(lineItems);

	// Gather all userIds from the key of each line item object
	const userIds = uniq([
		...Object.keys(registrationLineItems),
		...Object.keys(reservationLineItems),
		...Object.keys(goodsLineItems),
	]);
	for (const userId of userIds) {
		const appendixItems: any[] = [];
		const usedGoods: number[] = [];
		if (reservationLineItems[userId]?.length > 0) {
			generateReservationLineItems(
				processedInvoice,
				reservationLineItems[userId],
				userId,
				usedGoods,
				goodsLineItems[userId],
				appendixItems
			);
		}

		// Registration line items are for program sessions
		if (registrationLineItems[userId]?.length > 0) {
			generateRegistrationLineItems(registrationLineItems[userId], goodsLineItems[userId], usedGoods, appendixItems);
		}

		const sortedAppendixItems = [...appendixItems].sort((a, b) => {
			return a.startDate.getTime() - b.startDate.getTime();
		});

		// Add all non connected line items of type GOODS as a line item
		if (!reservationLineItems[userId]) {
			generateGoodsLineItems(goodsLineItems[userId], usedGoods, appendixItems);
		}

		if (sortedAppendixItems?.length > 0) appendices.push(sortedAppendixItems);
	}
	return appendices;
};

/**
 * Update line item with tax details
 * @param {ILineItem} lineItem - Line item
 * @param {{taxesExcl: ITaxType, taxesInc: ITaxType}} totalTax - Tax details
 */
export const handleTaxesOnLineItem = (
	lineItems: /*ILineItem*/ any[],
	totalTax: { taxesExcl: ITaxType; taxesInc: ITaxType }
) => {
	return lineItems?.reduce(
		(totalTax, lineItem) => {
			if (!lineItem?.taxPrecent) {
				return totalTax;
			}

			if (lineItem.isTaxInclusive) {
				const taxAmount = lineItem.price;

				totalTax.taxesInc[lineItem.taxPrecent] = (totalTax.taxesInc[lineItem.taxPrecent] ?? 0) + taxAmount;

				return totalTax;
			}

			const taxAmount = !isNaN(lineItem.displayTotalPriceWithTax)
				? lineItem.displayTotalPriceWithTax - lineItem.price
				: lineItem.price;

			totalTax.taxesExcl[lineItem.taxPrecent] = (totalTax.taxesExcl[lineItem?.taxPrecent] ?? 0) + taxAmount;

			return totalTax;
		},
		{
			taxesInc: {},
			taxesExcl: {},
		}
	);
};

const combineTaxAndProducts = (lineItems: any[], lineItemsToGroup: any[]) => {
	const lig = [...lineItemsToGroup];
	const li = [...lineItems];

	li.forEach((line: any) => {
		if (line.productType === /*ProductTypesEnum.TAX*/ TAX_PRODUCT_TYPE) {
			const elementId = lig.findIndex(el => el.id === line.parentLineItemId);
			if (elementId !== -1) {
				lig[elementId].tax = line;
			}
		} else {
			lig.push(line);
		}
	});

	return lig.map(l => l);
};

const MERGE_PRODUCT_TYPES = ['goods'];

const canBeMerged = lineItem => {
	return MERGE_PRODUCT_TYPES.includes(lineItem.productType) || lineItem.product?.isAddon;
};

const isTaxLineItem = (lineItem: any) => {
	return lineItem.productType === TAX_PRODUCT_TYPE;
};

const isVoidOrRefunded = (lineItem: any) => {
	return VOID_OR_REFUNDED.includes(lineItem.paymentStatus);
};

const lineItemGroupByKey = (productId: number, unitPrice: number, userId: number, tax: string, discounts: string) => {
	return `${productId}_${Math.abs(unitPrice)}_${userId}_${tax}_${discounts}`;
};

const groupLineItemsByParent = (parentLineItems: any[], orderedLineItems: any[], voidOrRefundedLineItems: any[]) => {
	if (parentLineItems?.length) {
		for (const productLineItem of parentLineItems) {
			orderedLineItems.push(productLineItem);
			if (voidOrRefundedLineItems?.length) {
				const item = voidOrRefundedLineItems?.find(item => productLineItem.product.id === item.product.id);

				if (item) {
					const itemAlreadyPushed = orderedLineItems?.find(i => i.id === item.id);
					if (!itemAlreadyPushed) orderedLineItems.push(item);
				}
			}
		}
	} else {
		orderedLineItems.push(...voidOrRefundedLineItems);
	}
};

const processTaxLineItems = (orderedLineItems: any[], lineItems: any[]) => {
	for (const lineItem of orderedLineItems) {
		if (isTaxLineItem(lineItem)) {
			if (lineItems[lineItem.parentLineItemId]) {
				lineItems[lineItem.parentLineItemId].tax = lineItem;
			}
		} else {
			lineItems.push(lineItem);
		}
	}
};

const processLineItems = (state: IInvoiceTemplateState, lineItems: any[]) => {
	const orderedLineItems = [];
	const parentLineItems = state?.invoice.lineItems
		.filter(li => !isVoidOrRefunded(li))
		.sort((a, b) => b.price - a.price);

	const voidOrRefundedLineItems = state?.invoice.lineItems.filter(isVoidOrRefunded);
	groupLineItemsByParent(parentLineItems, orderedLineItems, voidOrRefundedLineItems);
	processTaxLineItems(orderedLineItems, lineItems);

	return orderedLineItems;
};

/**
 * Group and sort line items for invoice + payment
 * @param state Full template state
 * @param lineItems Line items to group and update
 * @param showVoid Show voided line items with the rest of the line items
 * @param onAddDiscountLineItem Callback to add a discount line item
 */
const sortAndGroupLineItems = (
	state: IInvoiceTemplateState,
	lineItems: any,
	showVoid = true,
	onAddDiscountLineItem = () => ({})
) => {
	const lineItemsToGroup: [] = state?.invoice?.lineItems ?? [];

	if (!lineItemsToGroup) {
		return;
	}
	const filteredOutProducts = chain(lineItemsToGroup)
		.filter(li => !isTaxLineItem(li))
		.filter(li => showVoid || li.paymentStatus !== PaymentStatusEnum.VOID)
		.filter(li => !canBeMerged(li))
		.value()
		.map(li => {
			return {
				...mergeLineItems([li], showVoid),
			};
		});

	const productsLineItems = chain(lineItemsToGroup)
		.filter(li => !isTaxLineItem(li))
		.filter(canBeMerged)
		.value();
	const productsLineItemsWithMore = productsLineItems.map(li => ({
		...li,
	}));

	const productLineItemsToGroup = combineTaxAndProducts(productsLineItemsWithMore, []);
	const taxLineItems = lineItemsToGroup.filter(isTaxLineItem);
	const combinedLineItemsToGroup = combineTaxAndProducts(taxLineItems, productLineItemsToGroup);

	const products = groupBy(combinedLineItemsToGroup ?? [], li => {
		// Groups the lineItems by their productId.
		const taxIndex = `tax-${li?.taxLineItem?.productId}`;
		const discounts = li.discounts;
		const discountIndex = `discounts-paymentId-${discounts?.map(d => d.paymentId).join('-pr-')}`;
		return lineItemGroupByKey(li.productId, li.unitPrice, li.userId, taxIndex, discountIndex);
	});

	const mergedLineItems = [];
	for (const productId in products) {
		const productLineItems = products[productId].filter(li => !isVoidOrRefunded(li));
		const refundedLineItems = products[productId].filter(li => li.paymentStatus === PaymentStatusEnum.REFUNDED);
		const voidedLineItems = products[productId].filter(li => li.paymentStatus === PaymentStatusEnum.VOID);
		mergedLineItems.push(
			mergeLineItems(productLineItems, showVoid),
			showVoid ? mergeLineItems(voidedLineItems, showVoid) : undefined,
			mergeLineItems(refundedLineItems, showVoid)
		);
	}

	// Removes all the undefined array items, if there are any.
	if (state.invoice) {
		state.invoice.lineItems = [...filteredOutProducts, ...mergedLineItems.filter(Boolean)] ?? [];
		state.invoice.lineItems = processLineItems(state, lineItems);
		state.invoice.lineItems.forEach((li: any) => {
			li.more = {
				lineItemsIds: !isVoidOrRefunded(li) ? (li.lineItemsIds?.length > 0 ? li.lineItemsIds : [li.id]) : [],
				onAddDiscountLineItem: onAddDiscountLineItem,
			};
		});
	}
};

/**
 * Merges an array of lineItems to one lineItem, by adding up the quantity and total price of the first lineItem.
 * @param lineItems
 */
const mergeLineItems = (lineItems: any[], showVoid: boolean) => {
	const mergedLineItem = cloneDeep(lineItems[0]);
	if (mergedLineItem) {
		// Add all line item ids that are being combined
		if (!Boolean(mergedLineItem.lineItemsIds)) {
			Object.assign(mergedLineItem, { lineItemsIds: [] });
		}

		const lineItemsIds = lineItems.map(li => li.id);
		const uniqueLineItemsIds = lineItemsIds.filter((liId, index, self) => self.indexOf(liId) === index);

		mergedLineItem.lineItemsIds = [...uniqueLineItemsIds];
		if (mergedLineItem.taxLineItem) {
			Object.assign(mergedLineItem.taxLineItem, { subTotal: mergedLineItem?.taxLineItem?.taxPrecent });
		}

		for (let i = 1; i < lineItems?.length; i++) {
			const lineItemToMerge = lineItems[i];
			mergedLineItem.quantity += lineItemToMerge.quantity;
			mergedLineItem.totalPrice += lineItemToMerge.totalPrice;
			mergedLineItem.discountAmount += lineItemToMerge.discountAmount ?? 0;

			// mergedLineItem.subtotal += lineItemToMerge.subtotal ?? 0;
			mergedLineItem.displayQuantity += lineItemToMerge.displayQuantity;
			mergedLineItem.displayFullPrice += lineItemToMerge.displayFullPrice;

			mergedLineItem.displayTotalQuantity += lineItemToMerge.displayTotalQuantity;
			mergedLineItem.displayTotalPriceWithTax += lineItemToMerge.displayTotalPriceWithTax;

			mergedLineItem.displayFullPriceWithTax += lineItemToMerge.displayFullPriceWithTax;
			mergedLineItem.displayFullPriceWithVoidAndTax += lineItemToMerge.displayFullPriceWithVoidAndTax;

			mergedLineItem.displayFullPriceWithVoid += lineItemToMerge.displayFullPriceWithVoid;
			mergedLineItem.displayFullQuantityWithVoid += lineItemToMerge.displayFullQuantityWithVoid;

			mergedLineItem.fullPriceWithVoid += lineItemToMerge.fullPriceWithVoid;
			mergedLineItem.fullQuantityWithVoid += lineItemToMerge.fullQuantityWithVoid;

			if (mergedLineItem.taxLineItem) Object.assign(mergedLineItem.taxLineItem, { subTotal: 0 });

			mergedLineItem?.discounts?.forEach(d => {
				if (!Boolean(d.subTotal)) Object.assign(d, { subTotal: d.unitPrice });
			});
			const discountsToMerge = lineItemToMerge.discounts ?? [];
			for (const discount of discountsToMerge) {
				const discountExists = mergedLineItem?.discounts?.find(d => d.paymentId === discount.paymentId);
				if (discountExists) {
					discountExists.subTotal += discount.unitPrice;
					discountExists.price += discount.price;
					discountExists.quantity += discount.quantity;
					discountExists.unitPrice = discountExists.unitPrice === 0 ? discount.unitPrice : discountExists.unitPrice;
				} else {
					mergedLineItem?.discounts?.push(discount);
				}
			}
		}

		const { hasPartialPaid, hasNonVoided } = lineItems.reduce(
			(acc, item) => {
				acc.hasPartialPaid =
					acc.hasPartialPaid ||
					[PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.PARTIAL_PAYMENT].includes(item.paymentStatus);
				acc.hasNonVoided = acc.hasNonVoided || !item.isVoided;
				return acc;
			},
			{ hasPartialPaid: false, hasNonVoided: false }
		);

		mergedLineItem.paymentStatus = hasPartialPaid ? PaymentStatusEnum.PARTIAL_PAYMENT : mergedLineItem.paymentStatus;
		mergedLineItem.isVoid = hasNonVoided;
		mergedLineItem.taxLineItemTotal =
			(showVoid ? mergedLineItem.displayFullPriceWithTax : mergedLineItem.displayFullPriceWithVoidAndTax) +
			mergedLineItem.discountAmount;
	}

	return mergedLineItem;
};

const generateEntitlements = (organizationId: number, state: IInvoiceTemplateState, response: any) => {
	response.discounts = [
		...state.invoice?.discounts?.map(d => {
			return {
				isEntitlement: d.discountOn === DiscountOnTypeEnum.ENTITLEMENT_GROUP,
				isInvoice: d.discountOn === DiscountOnTypeEnum.INVOICE,
				isLineItemDiscount: d.discountOn === DiscountOnTypeEnum.LINE_ITEM,
				createdAt: '',
				id: d.discountId,
				name: d.reason ?? '',
				organizationId: organizationId,
				updatedAt: '',
				amount: d.price,
				percentage: d.discountPercentage,
				paymentId: d.paymentId,
				discountAmount: d.discountAmount,
				actionId: d.actionId,
				type: d.discount?.type,
			};
		}),
	];
	response.totalDiscountAmount = state.invoice?.discountSubtotal;
	return response;
};

const generateLineItemsRows = ({
	state,
	organizationId,
	totalTax,
	showVoid = true,
	onAddDiscountLineItem,
}: {
	state: IInvoiceTemplateState;
	organizationId: number;
	totalTax: any;
	onAddDiscountLineItem: (lineItemsIds: number[]) => void;
	showVoid?: boolean;
}): IResponse => {
	const response: any = {};
	const lineItems: any[] = [];
	const taxLineItems = state.invoice.taxes;

	sortAndGroupLineItems(state, lineItems, showVoid, onAddDiscountLineItem);
	response.appendixData = buildAppendixData(
		state.invoice as unknown as /*Invoice*/ any & { customer: /*Customer*/ any }
	);
	const items = lineItems
		.map(item => {
			generateEntitlements(organizationId, state, response);
			return expandLineItemData(item, state.invoice.slots, showVoid);
		})
		.filter(item => (!showVoid && !item.item.isVoid) || item.quantity || showVoid);

	response.totalTax = handleTaxesOnLineItem(taxLineItems, totalTax);
	response.items = items;

	const getStatus = invoice => {
		switch (invoice.paymentStatus) {
			case PaymentStatusEnum.REFUNDED:
				return PaymentStatusEnum.REFUNDED;
			case PaymentStatusEnum.REVERT:
				return PaymentStatusEnum.REVERT;
			default:
				return invoice.status;
		}
	};

	// handle payments rows
	response.payments = state?.invoice?.payments?.map((payment: any) => {
		const { firstName, lastName } = payment.payingUser;
		return {
			customerName: `${lastName}, ${firstName}`,
			date: payment.createdAt,
			id: payment.id,
			method: payment.paymentType,
			ccLast4: payment.ccLast4,
			ccBrand: payment.ccBrand,
			priceWithoutFee: payment.priceWithoutFee,
			amount: payment.price,
			stripePaymentMethodId: payment.stripePaymentMethodId,
			feesAmount: payment.totalFeesAmount,
			paymentFees: payment.paymentFees,
			status: getStatus(payment),
		};
	});

	return response;
};

/**
 *    Orders The items by grouping them together by the productId, then sorts each group so the refunded lineItems will
 * be after the regular lineItems. After that returns a new array with the new order.
 * @param items
 */

const generateTaxes = (generatedValues: IResponse, tax: any) => {
	Object.keys(generatedValues.totalTax).forEach(key => {
		const typeOfTax = key === 'taxesExcl' ? 'excl' : 'incl';
		Object.keys(generatedValues.totalTax[key]).forEach(precent => {
			tax[typeOfTax] = {
				precent: Number(precent),
				price: generatedValues.totalTax[key][precent],
			};
		});
	});
};

const findFee = (fees: any[], paymentMethod?: any) => {
	if (!fees || !paymentMethod) {
		return;
	}

	const flatFees = fees.filter(f => f?.fee).map(f => f.fee);
	let fee = flatFees?.find(
		f => f.paymentMethodType === paymentMethod.paymentMethodType && f.subPaymentMethodType === paymentMethod.subPaymentMethodType
	);

	if (!fee) {
		fee = flatFees?.find(f => f.paymentMethodType === paymentMethod.paymentMethodType);
	}

	return fee;
};

const updateInvoiceWithPaymentValues = (paymentData: ISinglePayment): ISinglePayment => {
	let invoice: ISingleInvoice | undefined = paymentData.invoices?.length > 0 ? paymentData.invoices?.[0] : undefined;
	if (invoice) {
		const paymentRec = invoice.payments?.find(p => p.id === paymentData.id);
		invoice = {
			...invoice,
			totalFeesAmount: paymentData.totalFeesAmount,
			paidAmount: roundPriceCents(paymentData.price - paymentData.totalFeesAmount),
			payments: paymentRec ? [paymentRec] : [],
			customer: paymentData.customer as any,
			createdAt: paymentData.createdAt,
		};
		paymentData.invoices = [invoice];
	}
	return paymentData;
};

export { generateLineItemsRows, generateTaxes, findFee, sortAndGroupLineItems, updateInvoiceWithPaymentValues };
