export function capitalizeFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function clip(str: string, length: number, suffix = '...') {
	if (str.length <= length) {
		return str;
	}

	return str.slice(0, length).concat(suffix);
}

export function formatUserName(firstName = '', lastName = ''): string {
	const strings: string[] = [capitalizeFirstLetter(lastName), capitalizeFirstLetter(firstName)];
	return strings.join(', ');
}
