import React, { Fragment } from 'react';
import { Field as FinalField } from 'react-final-form';
import { Input } from './index';
import { InputProps } from '../../types';
import { i18n } from '../../lib/i18n';
import { isEmptyNumber } from '../../lib/numberUtils';

export interface IFormProps extends InputProps {
	name: string;
	onClear?: (val: string) => void;
	required?: boolean;
	type?: string;
	error?: string;
	minValError?: string;
}

interface IUseYupValidation {
	useyup?: boolean;
}

export const FormInput = ({
	onClear = (val: string) => {},
	type = 'string',
	error,
	useyup = false,
	...props
}: IFormProps & IUseYupValidation & React.InputHTMLAttributes<HTMLInputElement>) => {
	const clear = () => onClear(props.name);
	return useyup ? (
		<YupFormInput type={type} clear={clear} {...props} error={error} />
	) : (
		<OldFormInput type={type} clear={clear} {...props} error={error} />
	);
};

const OldFormInput: React.FC<IFormProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
	type,
	clear,
	...props
}) => {
	const labels = i18n.fieldValidationErrors;

	return (
		<FinalField
			name={props.name}
			validate={val => {
				let errors = {};
				if (props.required) {
					const isEmtyValue = typeof val === 'number' ? isEmptyNumber(val) : !val;
					if (isEmtyValue) {
						errors[props.name] = labels.required;
						return errors;
					}
				}

				if (props.minLength && val?.length < props.minLength) {
					errors[props.name] = `${labels.minLength} ${props.minLength}`;
					return errors;
				}

				if (props.min && !isEmptyNumber(val) && val < props.min) {
					errors[props.name] = props.minValError || labels.required;
					return errors;
				}
				return;
			}}
			displayEmpty
		>
			{({ input, meta }) => {
				const newProps = { ...props };
				const error = meta.error && (meta.error[props.name] || (typeof meta.error === 'string' && meta.error));

				return (
					<Fragment>
						<Input
							data-input="oldInput"
							type={type}
							{...input}
							{...newProps}
							grayedOut={true}
							error={error}
							clear={clear}
						/>
					</Fragment>
				);
			}}
		</FinalField>
	);
};

const YupFormInput: React.FC<IFormProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
	type,
	clear,
	...props
}) => {
	return (
		<FinalField name={props.name} displayEmpty>
			{({ input, meta }) => {
				const newProps = { ...props };

				return (
					<Fragment>
						<Input
							data-input="newInput"
							type={type}
							{...input}
							{...newProps}
							grayedOut={true}
							error={props.error || meta.error}
							clear={clear}
						/>
					</Fragment>
				);
			}}
		</FinalField>
	);
};
