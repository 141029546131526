import React from 'react';
import { IOption, Select_v2 } from '../../../../components/Select_v2';
import { PromoCodeSelectProps } from '../../types/promoCodes';
import { i18n } from '../../../../lib/i18n';
import { useSelect } from '../../../../hooks/useSelect';
import { IUseSelectInitialData } from '../../../../components/Select_v2/types';
import { useSelectedValue } from '../../../../hooks/useSelectedValue';

const MAX_HEIGHT = 400;
export const PromoCodeSelect: React.FC<PromoCodeSelectProps> = ({ onChange, initialState, fetchData }) => {
	const labels = i18n.addDiscount.content.promoCode.select;

	const { items, isLoading, isError, lastOptionElementRef, handleType, resetSearch } = useSelect<IOption>({
		initialState: { ...initialState } as IUseSelectInitialData<IOption>,
		fetchData,
	});

	const { value, onSelect, handleClear } = useSelectedValue({
		onSelectCallback: (newValue: IOption) => onChange({ selectedPromoCode: newValue }),
		items,
		resetSearch,
		initialValue: initialState.selectedPromoCode,
	});

	return (
		<Select_v2
			options={items}
			isGroups={false}
			value={String(value?.value || '')}
			selectedLabel={value?.label || ''}
			onSelecte={onSelect}
			lastOptionElementRef={lastOptionElementRef}
			isLoading={isLoading}
			isError={isError}
			handleType={handleType}
			placeholder={labels.placeholder}
			label={labels.label}
			maxHeight={MAX_HEIGHT}
			clear={handleClear}
		/>
	);
};
