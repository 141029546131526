/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { SelectBody } from '@app/react/components/shared/MultiSectionSelect/selectBody';
import { BnIcon, Icons, Popup } from '@bondsports/utils';
import { Button } from 'ui';
import { widthPxCss } from '@app/react/styles/utils';
import { SelectSection, handleCheckedType } from '@app/react/components/shared/MultiSectionSelect/types';

export const Filters = <T,>({
	filterSections,
	flatSelectedFilters,
	onSelectFilter,
	disableScroll = false,
}: {
	filterSections: SelectSection<T>[];
	flatSelectedFilters: number[];
	onSelectFilter: handleCheckedType<T>;
	disableScroll?: boolean;
}) => {
	return (
		<Popup
			placement="bottom-start"
			disableScroll={disableScroll}
			body={
				<div css={widthPxCss(180)}>
					<SelectBody
						sections={filterSections}
						checkedOptions={flatSelectedFilters}
						handleChecked={onSelectFilter}
						withDivider
					/>
				</div>
			}
		>
			<Button data-aid="button-filters" variant="icon">
				<BnIcon icon={Icons.filters} />
			</Button>
		</Popup>
	);
};
