import { generateOptions } from '@app/react/lib/generateOptions';
import { TranslationEn } from '@assets/i18n/en';
import { useMemo } from 'react';
import { ConnectedResourcesFiltersEnum } from '../types';
import { SelectSection } from '../../../shared/MultiSectionSelect/types';

export const useManagePaymentMethodFilters = () => {

	const labels = TranslationEn.customers.managePaymentMethods;

	const filterSections: SelectSection<ConnectedResourcesFiltersEnum>[] = useMemo(() => {
		return [
			{
				sectionTitle: labels.table.actions.filters.categories.resourceTypes.title,
				options: generateOptions(labels.table.actions.filters.categories.resourceTypes.options),
				sectionId: ConnectedResourcesFiltersEnum.TYPES,
			},
		];
	}, [labels]);

	return { filterSections };
};
