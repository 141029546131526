/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, Fragment } from 'react';
import { OpenNoteProps } from '../../types';
import { Row } from '../../../../atoms/layoutPrimitives';
import { Typography } from '../../../../atoms';
import { ETypography, ETypographyColor, Icons } from '../../../../types';
import { Tooltip } from '../../../../components/Tooltip';
import { BnIcon } from '../../../../components/icons_v2';
import { Textarea } from '../../../../components/Input/Textarea';
import { jsx } from '@emotion/react';
import { Button } from '../../../../components/Button';
import { flexCss, gapCss } from '../../../../styles/utils';
import { SizeEnum } from '../../../../lib/constants';

export const OpenNote: FC<OpenNoteProps> = ({ openNote, onChange, value, labels, onCancel, isCancelDisabled }) => (
	<Fragment>
		<Row gap="0.5rem" fullWidth justify="space-between" align="center">
			<div css={[flexCss, gapCss(4)]}>
				<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
					{openNote}
				</Typography>
				<Tooltip isTopLayer content={labels.tooltip}>
					<BnIcon icon={Icons.info} />
				</Tooltip>
			</div>
			<div data-aid="button-note-cancel">
				<Button theme={'basicLine'} sizer={SizeEnum.EXTRA_SMALL} onClick={onCancel} disabled={isCancelDisabled}>
					{labels.cancel}
				</Button>
			</div>
		</Row>
		<Textarea
			sizer={SizeEnum.SMALL}
			charLimit={120}
			charCounter
			onChange={onChange}
			value={value}
			style={{ marginTop: 0 }}
		/>
	</Fragment>
);
