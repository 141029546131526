/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { cellCss } from '../styles';
import { Tag } from '../../Tag';
import { TranslationEn } from '../../../../../../assets/i18n/en';
import { EStatusColorMapper } from '../../../../types/customers';

export const Status = ({ column, row, index, isLast }) => {
	const value = row[column.id];
	const title = TranslationEn.customers.tags[value];
	return (
		<td
		    data-aid="td-status"
			className={`TableCell-root TableCell-body ${column.id}`}
			align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
			key={column.id}
			onClick={() => {
				if (column.action) {
					column.action(row);
				}
			}}
			css={cellCss({ isAction: column.action })}
		>
			<Tag title={title} color={EStatusColorMapper[row[column.id]] || 'blue'} />
		</td>
	);
};
