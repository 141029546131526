/** @jsx jsx */

import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { ReactSVG } from 'react-svg';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { imageContainerCss, paddingCss } from '../../../../../styles/utils';
import { TranslationEn } from '@assets/i18n/en';
import { useCustomer } from '../../hooks/useCustomer';
import { DirectImageUploader } from '@app/react/components/shared/UploadFile/DirectImageUploader';
import { CenteredLayout } from 'ui';
import { ICustomer } from '@app/react/types/customers';
import { MediaDto } from '@bondsports/types';

const iconCss = css`
	height: 60px;
	width: 60px;
	margin: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const imgCss = css`
	width: 224px;
	height: 224px;
	border-radius: 300px;
`;

const centeredImageContainerCss = css`
	${imageContainerCss}
	height: auto;
`;

const uploaderContainer = css`
	display: flex;
	justify-content: center;
	padding-top: 1rem;
`;

export const CustomerProfilePictureBlock = ({ customerState, profileEditMode, newMainMedia, setNewMainMedia }:
  {
    customerState: ICustomer,
    profileEditMode: boolean,
    newMainMedia: MediaDto,
    setNewMainMedia: React.Dispatch<React.SetStateAction<MediaDto>>
  }) => {
  const { profilePictureUrl } = useCustomer();

  const profilePicture = newMainMedia?.url || profilePictureUrl(customerState);
  const renderProfileImage = () => {
    if (profilePicture) {
      return <img css={imgCss} src={profilePicture} alt={`${customerState?.name} profile picture`} />;
    }
    return <ReactSVG src="assets/media/icons/customers/image_placeholder.svg" css={iconCss} />;
  };

  return (
    <CenteredLayout css={centeredImageContainerCss}>
      {renderProfileImage()}
      {profileEditMode && (
        <Fragment>
          <div css={uploaderContainer}>
            <DirectImageUploader setMedia={setNewMainMedia} />
          </div>
          {!!newMainMedia && (
            <Typography type={ETypography.caption} color={ETypographyColor.red} css={paddingCss('1rem 0 0 0')}>
              {TranslationEn.customers.detailsPage.unsavedProfilePicture}
            </Typography>
          )}
        </Fragment>
      )}
    </CenteredLayout>
  );
  
}