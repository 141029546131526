import React, { Fragment, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { NotesPopupStepEnum } from '../../../types/notes';
import { NotesListStep } from './NotesListStep';
import { ErrorStep } from './ErrorStep';
import { NotesPopupContext } from '../../../store/notes';
import { NoteFormStep } from './NoteFormStep';
import { useFetch } from '../../../hooks/useFetch';
import { AddNoteDto, EditNoteDto, NoteDto } from '../../../types/entities/notes';
import { ArchiveNoteStep } from './ArchiveNoteStep';

type StepperProps = {
	toggle: () => void;
	step: NotesPopupStepEnum;
	setStep: React.Dispatch<SetStateAction<NotesPopupStepEnum>>;
	setTitle: React.Dispatch<SetStateAction<string>>;
	onNoteCreated?: (note: NoteDto) => void;
	onNoteEdited?: (note: NoteDto) => void;
	onNoteArchived?: (note: NoteDto) => void;
	selectedNote?: NoteDto;
};

export const Stepper: React.FC<StepperProps> = ({
	toggle,
	setTitle,
	setStep,
	step,
	onNoteCreated,
	onNoteEdited,
	onNoteArchived,
}) => {
	const { actions, entityType, entityId, labels, creatorName, creatorId, notesToDisplay, maxAlerts, maxTitleLength, maxContentLength } =
		useContext(NotesPopupContext);

	const [error, setError] = useState<{ message?: string; err?: string }>();
	const [totalNotes, setTotalNotes] = useState<number>(0);
	const [previousStep, setPreviousStep] = useState<NotesPopupStepEnum>();
	const [page, setPage] = useState<number>(1);
	const [selectedNote, setSelectedNote] = useState<NoteDto>();

	const { data } = useFetch(
		//@ts-ignore
		({ signal }) => {
			if (step !== NotesPopupStepEnum.LIST) {
				return actions!.getNotes!(1, maxAlerts, true, signal);
			}

			return;
		},
		[step]
	);

	const isAlertDisabled: boolean = useMemo(
		//@ts-ignore
		() => data?.meta.totalItems === maxAlerts && !data?.data.find(note => note.id === selectedNote?.id),
		[data]
	);

	useEffect(() => {
		setTitle(labels!.titles[step]);
	}, [step]);

	const changeStepHandler = (nextStep: NotesPopupStepEnum) => {
		setPreviousStep(step);
		setStep(nextStep);
	};

	const goBackToListPageOrClosePopup = (goToPage?: number, forceGoBackToList = false) => {
		if (previousStep || forceGoBackToList) {
			setPage(currentPage => goToPage || currentPage);
			setStep(previousStep ?? NotesPopupStepEnum.LIST);
		} else {
			return toggle();
		}
	};

	const onCancel = () => goBackToListPageOrClosePopup();

	if (error) {
		return <ErrorStep setError={setError} error={error} toggle={toggle} />;
	}

	switch (step) {
		case NotesPopupStepEnum.LIST: {
			return (
				<NotesListStep
					onError={setError}
					//@ts-ignore
					getPaginatedNotes={actions!.getNotes}
					changeStep={changeStepHandler}
					setNoteToEdit={setSelectedNote}
					setNoteToArchive={setSelectedNote}
					page={page}
					setPage={setPage}
					labels={labels!.list}
					setTotalNotes={setTotalNotes}
				/>
			);
		}
		case NotesPopupStepEnum.ADD_NOTE: {
			const addNoteFormSubmitHandler = (dto: AddNoteDto) => {
				actions!.addNote!(dto)
					.then(note => {
						goBackToListPageOrClosePopup(1, true);
						onNoteCreated?.(note);
					})
					.catch(setError);
			};

			const initialValue: AddNoteDto = {
				entityId: entityId!,
				entityType: entityType!,
				title: '',
				content: '',
				isAlert: false,
				isPinned: false,
				isPublic: false,
				creatorId: creatorId!,
				creatorName: creatorName!,
			};

			return (
				<NoteFormStep
					onCancel={onCancel}
					onSubmit={addNoteFormSubmitHandler}
					initialValue={initialValue}
					isAlertDisabled={isAlertDisabled}
					disableAll={!data}
					labels={labels!.form}
					maxTitleLength={maxTitleLength}
					maxContentLength={maxContentLength}
				/>
			);
		}
		case NotesPopupStepEnum.EDIT_NOTE: {
			const editNoteSubmitHandler = (dto: EditNoteDto) => {
				return actions!.editNote!(selectedNote!.id, dto)
					.then(note => {
						goBackToListPageOrClosePopup(1);
						onNoteEdited?.(note);
					})
					.catch(setError);
			};

			const initialValue: EditNoteDto = {
				title: selectedNote?.title!,
				content: selectedNote?.content!,
				isAlert: !!selectedNote?.isAlert,
				isPinned: !!selectedNote?.isPinned,
				isPublic: !!selectedNote?.isPublic,
				creatorName: creatorName!,
				creatorId: creatorId!,
			};

			return (
				<NoteFormStep
					onCancel={onCancel}
					onSubmit={editNoteSubmitHandler}
					initialValue={initialValue}
					isAlertDisabled={isAlertDisabled}
					disableAll={!data}
					labels={labels!.form}
					maxTitleLength={maxTitleLength}
					maxContentLength={maxContentLength}
				/>
			);
		}
		case NotesPopupStepEnum.DELETE_NOTE: {
			const calculateLastPage = () => {
				return totalNotes ? Math.ceil((totalNotes - 1) / notesToDisplay) : Number.MAX_VALUE;
			};

			const onArchivedHandler = (note: NoteDto) => {
				actions!.archiveNote!(note.id)
					.then(res => {
						if (!res.succeeded) {
							throw new Error(res.message);
						}

						// calculates the last page after deleting the note
						const lastPage: number = calculateLastPage();
						// if the last page is less then the current page go to last page
						const goToPage: number = lastPage < page ? lastPage : page;

						goBackToListPageOrClosePopup(goToPage);
						onNoteArchived?.(note);
					})
					.catch(setError);
			};

			return <ArchiveNoteStep note={selectedNote!} onArchived={onArchivedHandler} onCancel={onCancel} />;
		}
		default: {
			return <Fragment />;
		}
	}
};
