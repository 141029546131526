/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Icons } from '@bondsports/utils';
import { ActionProps } from '../../types';
import { Action } from './Action';

export const ReplaceItem = (props: ActionProps) => {
	return <Action icon={Icons.replace} dataAid={'replace-item'} {...props} />;
};
