/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { BnIcon } from '../../../components/icons_v2';
import { capitalize } from '../../../functions';
import { ETypography, ETypographyColor } from '../../../types';
import { DEFAULT_ICON_SIZE, DEFAULT_LINE_HEIGHT } from '../utils/consts';

const iconContainerCss = (size = DEFAULT_ICON_SIZE) => css`
	margin-right: 1rem;
	white-space: nowrap;
	height: ${size}px;
	width: ${size}px;
	svg {
		display: block;
		width: auto !important;
		height: 100% !important;
	}
`;

const flexRowCss = (height = DEFAULT_LINE_HEIGHT) => css`
	display: flex;
	align-items: center;
	word-wrap: nowrap;
	height: ${height}px;
`;

interface PaymentDetailProps {
	text: string;
	icon: string;
	color?: ETypographyColor;
	iconSize?: number;
	height?: number;
}

export const PaymentDetailAndIcon = ({
	text,
	icon,
	iconSize = DEFAULT_ICON_SIZE,
	height = DEFAULT_LINE_HEIGHT,
	color = ETypographyColor.primary,
}: PaymentDetailProps) => {
	return (
		<div data-aid="paymentDetailsAction" css={flexRowCss(height)}>
			{icon && (
				<div css={iconContainerCss(iconSize)}>
					<BnIcon icon={icon} />
				</div>
			)}
			<Typography color={color} type={ETypography.body2}>
				{capitalize(text)}
			</Typography>
		</div>
	);
};
