/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { NotesListFooter } from '../../../organisms/notes/NotesList/Footer';
import { NotesPopupLabels, NotesPopupStepEnum } from '../../../types/notes';
import { Note, NoteProps } from '../../../molecules/notes/NotesList/Note';
import { NoteDto } from '../../../types/entities/notes';
import { PaginationResultDto } from '../../../types/dtos';
import { useColors } from '../../../hooks/useColors';
import { useFetch } from '../../../hooks/useFetch';
import {
	alignItemsCss,
	centerTextCss,
	flexColCss,
	flexGrowCss,
	gapCss,
	heightPxCss,
	justifyContentCss,
	marginBottomCss,
	marginTopCss,
} from '../../../styles/utils';
import { ETypography, ETypographyColor } from '../../../types';
import { Typography } from '../../../atoms';
import { Button } from '../../../components/Button';
import { ResourceNameTypeEnum } from '../../../types/enums';
import { LoadingWithSpinner } from '../../../molecules/Loading/LoadingWithSpinner';

export type NotesListProps = {
	entityId: number;
	entityType: ResourceNameTypeEnum;
	onError: (error: Error) => void;
	getPaginatedNotes: (
		page: number,
		itemsPerPage: number,
		alertsOnly?: boolean,
		signal?: AbortSignal
	) => Promise<PaginationResultDto<NoteDto>>;
	changeStep: (step: NotesPopupStepEnum) => void;
	timezone: string;
	onEdit: (note: NoteDto) => void;
	onArchive: (note: NoteDto) => void;
	page: number;
	setPage: (page: number) => void;
	labels: NotesPopupLabels['list'];
	notesToDisplay: number;
	NoteComponent?: React.FC<NoteProps>;
	setTotalNotes: (notes: number) => void;
};

const listCss = css`
	height: 500px;
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: start;
	overflow-y: scroll;
`;

const wrapperCss = (color: string) => css`
	width: 100%;
	border-top: 1px solid ${color};
	padding: 24px 40px 0 40px;
`;

const MAX_PAGE_VIEW = 5;

export const NotesList: React.FC<NotesListProps> = ({
	onError,
	getPaginatedNotes,
	changeStep,
	entityId,
	entityType,
	timezone,
	onEdit,
	onArchive,
	page,
	setPage,
	labels,
	NoteComponent,
	notesToDisplay,
	setTotalNotes,
}) => {
	const { colors } = useColors();
	const { data, isFirstFetch } = useFetch(
		async ({ signal }) => {
			return await getPaginatedNotes(page, notesToDisplay, false, signal);
		},
		[page],
		{
			onSuccess: res => setTotalNotes(res.meta.totalItems),
			onError: error => {
				onError(error);
			},
		}
	);

	const onAddHandler = () => changeStep(NotesPopupStepEnum.ADD_NOTE);

	if (isFirstFetch) {
		return (
			<div data-aid="NotesList-NotesList">
				<div
					css={[
						flexColCss,
						wrapperCss(colors.bg_border_seperator as string),
						marginBottomCss(24),
						justifyContentCss('center'),
						heightPxCss(500),
					]}
				>
					<div>
						<LoadingWithSpinner labels={labels.loading} />
					</div>
				</div>
				<NotesListFooter
					pages={data?.meta?.totalPages || 0}
					currentPage={page}
					setPage={setPage}
					onAdd={onAddHandler}
					labels={labels.buttons}
					maxPagesView={MAX_PAGE_VIEW}
				/>
			</div>
		);
	}

	if (!data?.data?.length) {
		return (
			<div
				css={[
					flexColCss,
					wrapperCss(colors.bg_border_seperator as string),
					marginBottomCss(24),
					marginTopCss(24),
					justifyContentCss('space-between'),
					centerTextCss,
					alignItemsCss('center'),
				]}
			>
				<div css={[flexColCss, flexGrowCss(1), justifyContentCss('center'), alignItemsCss('center'), gapCss(4)]}>
					<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
						{labels.errors.noData(entityType, entityId)}
					</Typography>
					<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
						{labels.errors.tryLater}
					</Typography>
				</div>
				<Button data-aid="button-NotesList-back" theme={'primary'} sizer={'L'}>
					{labels.buttons.goBack}
				</Button>
			</div>
		);
	}

	return (
		<Fragment>
			<div data-aid="NotesList-dataMap" css={[wrapperCss(colors.bg_border_seperator as string), marginTopCss(24), listCss]}>
				{data.data.map((note, index) => (
					<Fragment>
						{NoteComponent ? (
							<NoteComponent
								key={note.id}
								note={note}
								onEdit={onEdit}
								onDelete={onArchive}
								drawSeparator={!!index}
								timezone={timezone}
								labels={labels}
							/>
						) : (
							<Note
								key={note.id}
								note={note}
								onEdit={onEdit}
								onDelete={onArchive}
								drawSeparator={index < notesToDisplay - 1}
								timezone={timezone}
								labels={labels}
							/>
						)}
					</Fragment>
				))}
			</div>
			<NotesListFooter
				pages={data?.meta?.totalPages || 0}
				currentPage={page}
				setPage={setPage}
				onAdd={onAddHandler}
				labels={labels.buttons}
				maxPagesView={MAX_PAGE_VIEW}
			/>
		</Fragment>
	);
};
