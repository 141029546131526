/** @jsx jsx */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { DataViewPage } from '../../../../shared/DataviewPage';
import { Actions } from '../../../../shared/DataviewPage/components/actions';
import { Table } from '../../../../shared/NewTable';
import { SearchInput } from '../../../../shared/DataviewPage/components/actions/components/filter/SearchInput';
import { ReplaceItem } from '../../../../shared/DataviewPage/components/actions/components/actions/ReplaceItem';
import { TranslationEn } from '@assets/i18n/en';
import { AlignType } from '../../../../shared/NewTable/types';
import { SingleLineToolTipCell } from '../../../../shared/NewTable/Cell/TooltipCell';
import { useTableData } from '../../../../../hooks/useTableData';
import {
	ConnectedResourceDto,
	OrderByEnum,
	UpdateConnectedResourcePaymentMethodDto,
	UserPaymentMethod,
	UserPaymentMethodOption,
} from '@bondsports/types';
import { mapDataToTable, mapTableToData } from '../../utils';
import { ConnectedResourcesFiltersEnum, NormalizedPaymentMethod, OrderByFieldsEnum, ResourceRow } from '../../types';
import { Filters } from '../../../../shared/DataviewPage/components/actions/components/filter/filters';
import { SelectSection } from '../../../../shared/MultiSectionSelect/types';
import { paymentApi } from '@app/react/lib/api/paymentApi';
import { ERoutePaths } from '../../../../../types/navigation';
import { useLocation } from 'react-router-dom';
import { useNavigation } from '../../../../../hooks/useNavigation';
import { RowActionsPopup } from './RowActionsPopup';
import { useSelectConnectedResource } from '../../hooks/useSelectResource';
import { ReplacePaymentMethodModal } from '../ReplacePaymentMethodModal';
import { ICustomer } from '../../../../../types/customers';
import { ColumnType } from '../../../../shared/NewTable/HeaderRow';
import { MultiSelectPaymentMethods } from '../MultiSelectPaymentMethods';
import { useModal } from '@bondsports/utils';
import { AddMethodWrapper } from '../AddMethodWrapper';
import { selectMethodContainerCss } from '../../styles';
import { PageContent, PageContentMapper } from 'ui';
import { EmptyPage } from '@app/react/components/settings/shared/EmptyPage';
import { separatorCss } from '@app/react/styles/utils';

interface IProps {
	customerState: ICustomer;
	normalizedMethods: NormalizedPaymentMethod[];
	paymentMethods: UserPaymentMethod[];
	methodOptions: UserPaymentMethodOption[];
	refetchPaymentMethods: () => Promise<void>;
	handleSelectMethod: (pmId: string) => void;
	initialMethodId: string;
	organizationId: number;
	isLoadingMethods: boolean;
	userId: number;
	filterSections: SelectSection<ConnectedResourcesFiltersEnum>[];
}

const initSortValues = { order: OrderByEnum.ASC, sortBy: OrderByFieldsEnum.INVOICE };

const ConnectedResourcesTable = ({ isLoadingMethods, organizationId, initialMethodId, refetchPaymentMethods, handleSelectMethod, normalizedMethods, methodOptions, paymentMethods, userId, filterSections, customerState }: IProps) => {
	const location = useLocation();
	const { pushNavigation } = useNavigation();
	const [showReplace, toggleReplace] = useState<boolean>(false);
	const [currentResource, setCurrentResource] = useState<number>(null);

	const fetchDataApi = useCallback((params: {
		searchFilter?: string;
		page?: number;
		order?: string;
		sortBy?: OrderByFieldsEnum[];
		resourceTypes?: number[];
	}) => {
		return paymentApi.getConnectedResourcesByFilters(userId, organizationId, [initialMethodId], {
			page: params.page,
			order: [params.order],
			sortBy: params.sortBy,
			resourceTypes: params.resourceTypes,
			search: params.searchFilter,
		});
	}, [userId, organizationId, initialMethodId]);

	const { isShowing: isAddNewCardOpen, toggle: toggleNewCard } = useModal();
	const {
		currentPage,
		rows,
		meta,
		searchFilter,
		onSearch,
		handleSort,
		fetchData,
		flatSelectedFilters,
		isLoading,
		error,
		onSelectFilter,
	} = useTableData<
		ConnectedResourceDto,
		ResourceRow,
		{
			searchFilter?: string;
			itemsPerPage?: number;
			page?: number;
			order?: string;
			paymentMethodIds?: string[];
			sortBy?: OrderByFieldsEnum[];
		},
		OrderByFieldsEnum
	>({
		fetchDataApi,
		mapDataToTable: (data: ConnectedResourceDto[]) => mapDataToTable(data),
		initialSortValues: initSortValues,
		initialData: { isLoading: false }
	});
	const {
		handleSelect,
		isReplacePmDisabled,
		handleSelectAll,
		isSelectIndeterminate,
		selectedIds,
		handleResetData,
		isSelectAll
	} = useSelectConnectedResource(meta);

	const manageTableLabels = TranslationEn.customers.managePaymentMethods;
	const labels = manageTableLabels.table;
	const columnsLabels = labels.columns;

	const handleMethodClicked = (pmId: string) => {
		handleSelectMethod(pmId);
		handleResetData();
	}

	const fetchResourceData = useCallback(() => {
		if (initialMethodId) {
			fetchData();
		}
	}, [initialMethodId, fetchData]);

	useEffect(() => {
		fetchResourceData();
	}, [fetchResourceData]);

	const redirectToInvoiceOrPayment = (
		path: ERoutePaths.INVOICES | ERoutePaths.DETAILS,
		id: number,
	) => {
		const { pathname } = location; //customer/256460/membership
		const documentPath = pathname.replace(`${ERoutePaths.METHODS}`, path);
		const newPath = `${documentPath}/${id}${path === ERoutePaths.INVOICES ? `/${ERoutePaths.DETAILS}` : ''}`;

		pushNavigation(newPath);
	};

	const columns = useMemo(
		(): ColumnType[] => [
			{
				id: 'invoiceId',
				label: columnsLabels.invoice,
				type: 'invoice',
				isSortable: true,
				onSort: (isAsc: boolean) => handleSort(isAsc, OrderByFieldsEnum.INVOICE),
				action: (row: ResourceRow) => {
					redirectToInvoiceOrPayment(ERoutePaths.INVOICES, row.invoiceId);
				},
				styling: {
					align: AlignType.LEFT,
				},
			},
			{
				id: 'name',
				label: columnsLabels.name,
				type: 'custom',
				component: (name: string) => <SingleLineToolTipCell text={name} />,
				styling: {
					align: AlignType.LEFT,
				},
				isSortable: true,
				onSort: (isAsc: boolean) => handleSort(isAsc, OrderByFieldsEnum.NAME)
			},
			{
				id: 'type',
				label: columnsLabels.type,
				type: 'resourceType',
				styling: {
					align: AlignType.LEFT,
				},
				isSortable: true,
				onSort: (isAsc: boolean) => handleSort(isAsc, OrderByFieldsEnum.RESOURCE_TYPE),
			},
			{
				id: 'paymentMethod',
				type: 'paymentMethodWithIcon',
				label: columnsLabels.method,
				styling: { align: AlignType.LEFT },
			},
			{
				id: 'id',
				label: '',
				type: 'custom',
				component: (resourceId: number) => (
					<RowActionsPopup
						id={resourceId}
						handleReplace={handleReplaceButton}
					/>
				),
			},
		],
		[columnsLabels, handleSort]
	);
	
	const openReplaceMethod = () => {
		toggleReplace(true);
	}

	const handleReplaceButton = async (selectedId: number) => {
		openReplaceMethod();
		setCurrentResource(selectedId);
	}

	const handleReplace = async (newMethod: string) => {
		const selectedMethodData: NormalizedPaymentMethod = normalizedMethods.find((method) => method.id === newMethod);
		const recourceIds = !!currentResource ? [currentResource] : selectedIds;
		// TODO: refactor after backend pagination implementation
		const selectedRows: ResourceRow[] = isSelectAll ? rows.filter((row) => !recourceIds.includes(row.id)) : rows?.filter((row) => recourceIds.includes(row.id));
		const dataToSend: UpdateConnectedResourcePaymentMethodDto[] = mapTableToData(selectedRows, organizationId, selectedMethodData);
		await paymentApi.updateConnectedResourcesPaymentMethod(userId, organizationId, dataToSend);
		handlePostReplace();
	}

	const handlePostReplace = () => {
		handleResetData();
		fetchData();
	}

	const handleReplaceModalClose = () => {
		setCurrentResource(null);
		toggleReplace(false);
	}

	const handleAddCardToggle = () => {
		toggleNewCard();
		refetchPaymentMethods();
	}
	const errorLabels = manageTableLabels.errors;

	return (
		<PageContent data-testid="manage-methods-tab-content" style={{maxHeight: '85vh'}}>
			<PageContentMapper
				isEmptyPage={!isLoading && !isLoadingMethods && !customerState}
				isLoading={isLoadingMethods}
				isError={Boolean(error)}
				errorMessage={errorLabels.noMethods}
				NoDataPage={
					<EmptyPage
						title={manageTableLabels.noDataPage.title}
						description={manageTableLabels.noDataPage.description}
					/>
				}
			>
				<Fragment>
					<DataViewPage
						actions={
							<div css={[selectMethodContainerCss, separatorCss()]}>
								<MultiSelectPaymentMethods
									data-testid="connected-resources-select-method"
									customer={customerState} 
									userId={userId} 
									paymentMethods={paymentMethods} 
									handleSelect={handleMethodClicked} 
									selectedIds={[initialMethodId]}
								/>
								<AddMethodWrapper isOpen={isAddNewCardOpen} paymentMethodsOptions={methodOptions} userId={userId} customer={customerState} setIsOpen={toggleNewCard} handleToggleClose={handleAddCardToggle} />
							</div>
						}
						subActions={
							<Actions
								filters={
									<Fragment>
										<SearchInput placeholder={labels.actions.search.placeholder} value={searchFilter} onChange={onSearch} />
										<div data-testid="connected-resources-filters">
											<Filters<ConnectedResourcesFiltersEnum>
												filterSections={filterSections}
												flatSelectedFilters={flatSelectedFilters}
												onSelectFilter={onSelectFilter}
												disableScroll
											/>
										</div>
									</Fragment>
								}
								actions={
										<ReplaceItem handleAction={openReplaceMethod} isDisabled={isReplacePmDisabled} />
								}
							/>
						}
						>
						<span data-testid="connected-resources-table">
							<Table
								isExpand
								rows={rows}
								columns={columns as ColumnType[]}
								subject={labels.subject}
								padding
								isRowsError={Boolean(error)}
								isSelectRow
								pagination
								page={currentPage}
								meta={meta}
								ssr
								defaultSortColumn={labels.columns.invoice}
								isAllSelected={isSelectAll}
								rowsErrorMessage={error}
								emptyTableMessage={manageTableLabels.noDataPage.title}
								onSelect={handleSelect}
								selectedArr={selectedIds}
								onSelectAll={handleSelectAll}
								isSelectIndeterminate={isSelectIndeterminate()}
							/>
						</span>
					</DataViewPage>
						<ReplacePaymentMethodModal
						isShowing={showReplace}
						userId={userId}
						methodOptions={methodOptions}
						toggle={handleReplaceModalClose}
						replacablePaymentMethods={normalizedMethods}
						customerState={customerState}
						paymentMethods={paymentMethods}
						refetchPaymentMethods={refetchPaymentMethods}
						onReplace={handleReplace}
					/>
	 			</Fragment>
			</PageContentMapper>
	 	</PageContent>
	);
};

export { ConnectedResourcesTable, initSortValues };
