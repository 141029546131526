import { css } from '@emotion/react';
import { colors } from '@app/react/styles/theme';

export const alternativeModalCss = css`
	margin-top: 23px;
	width: 676px;
	height: 488px;

	.modal-layout-header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1rem;
		align-items: center;
		h1 {
			font-size: 20px;
			line-height: normal;
		}
	}

	.modal-layout-footer {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: end;
		gap: 16px;
		width: 100%;
		padding: 0px 30px;
		div button:nth-child(2) {
			min-width: 87px;
			justify-content: center;
		}
	}

	.modal-layout-content {
		height: 359px;
		display: flex;
		margin-top: 24px;
		margin-right: 10px;
		margin-left: 10px;
		border-bottom: 1px solid ${colors.borderSeperator};
		border-top: 1px solid ${colors.borderSeperator};

		td:last-child {
			width: 90px;
			padding-right: 32px;
		}

		tr td:first-child {
			padding-left: 20px;
		}
	}
`;
