import { createUserIdentifier, getUserIdentifiers, UserIdentifierDto } from '@app/react/lib/api/userIdentificationApi';
import { barcodeScanner, ScannerContext } from '@app/react/lib/scanner';
import { useState, useEffect, useCallback } from 'react';
import { useCustomer } from '../../customerPage/hooks/useCustomer';
import { ICustomer } from '@app/react/types/customers';
import { ENotificationType } from '@bondsports/utils';
import { TranslationEn } from '@assets/i18n/en';
import { useNotification } from '@app/react/hooks/useNotification';

export enum VIEW_STATE {
  IdentifierEntry,
  AssignNewPassToCustomer,
  AssignNewPassToCustomerOverridingExistingPasses,
  PassAlreadyAssignedToSameCustomer,
  PassAlreadyAssignedToDifferentCustomer,
}

export const useAssignPass = (customer: ICustomer, newIdentifierString: string, onCancel: () => void, labels, invalidateExisting: boolean = false, migrateExisting: boolean = false) => {
  const { setToastNotification } = useNotification();
  
  const assignPass = async () => {
    try {
      const resp = await createUserIdentifier(customer.organizationId,
        {
          organizationId: customer.organizationId,
          customerId: customer.id,
          identifier: newIdentifierString,
          validFrom: new Date(),
        },
        invalidateExisting,
        migrateExisting
      );
      if (resp.data) {
        setToastNotification(labels.successToast, ENotificationType.success);
      } else {
        setToastNotification(labels.errorToast, ENotificationType.warning);
      }
    } catch (error) {
      setToastNotification(labels.errorToast, ENotificationType.warning);
    }
    onCancel();
  };

  return assignPass;
};

export const useAssignPassModalManager = (organizationId, customerId, isOpen, setOpen) => {
  const [scannerContext, setScannerContext] = useState<ScannerContext | undefined>(undefined);
  const [customersExistingUserIdentifiers, setCustomersExistingUserIdentifiers] = useState<UserIdentifierDto[]>([]);
  //existingIdentifier is the one matching the inputted identifier string (may or may not belong to this customer)
  const [existingIdentifier, setExistingIdentifier] = useState<UserIdentifierDto | undefined>(undefined);
  const [inputtedIdentifierString, setInputtedIdentifierString] = useState<string>('');
  const [viewState, setViewState] = useState<VIEW_STATE>(VIEW_STATE.IdentifierEntry);
  const { getCustomerData, customerState } = useCustomer();

  const onCancel = useCallback(() => {
    setOpen(false);
    setViewState(VIEW_STATE.IdentifierEntry);
  }, []);

  const assignNewIdentifier = useCallback(() => {
    setViewState(
      customersExistingUserIdentifiers.length > 0 ?
        VIEW_STATE.AssignNewPassToCustomerOverridingExistingPasses :
        VIEW_STATE.AssignNewPassToCustomer);
  }, [customersExistingUserIdentifiers]);

  const handleExistingIdentifier = useCallback((existingIdentifier: UserIdentifierDto) => {
    setViewState(
      existingIdentifier.customerId == customerId ?
        VIEW_STATE.PassAlreadyAssignedToSameCustomer :
        VIEW_STATE.PassAlreadyAssignedToDifferentCustomer);
  }, [customerId]);

  // Both scan handlers are called by the barcodeScanner and when the user manually submits an identifier.
  // The Scanner runs a "simulated scan" on manual entry, allowing for identical processing paths.
  const scanSuccessIdentifierMissHandler = useCallback(async (scanCode) => {
    setInputtedIdentifierString(scanCode);
    assignNewIdentifier();
  }, [assignNewIdentifier]);

  const scanSuccessIdentifierHitHandler = useCallback(async (userIdentifier, context) => {
    setInputtedIdentifierString(userIdentifier.identifier);
    setExistingIdentifier(userIdentifier);
    handleExistingIdentifier(userIdentifier);
  }, [handleExistingIdentifier]);
  
  useEffect(() => {
    barcodeScanner.removeContext(scannerContext);
    if (isOpen) {
      barcodeScanner.pushContext(scannerContext);
    }

    return () => {
      barcodeScanner.removeContext(scannerContext);
    };
  }, [isOpen, scannerContext]);

  useEffect(() => {
    setScannerContext({
      organizationId,
      customerId,
      scanSuccessIdentifierMissHandler,
      scanSuccessIdentifierHitHandler
    });
  }, [organizationId, customerId, scanSuccessIdentifierMissHandler, scanSuccessIdentifierHitHandler]);

  useEffect(() => {
    if (isOpen) {
      // This is used in the AssignPassModal which is rendered in all Customer "More Menu" components,
      // but the side should only happen when the modal is actually opened.
      getCustomerData(organizationId, customerId);
      getUserIdentifiers(organizationId, customerId).then((response) => {
        setCustomersExistingUserIdentifiers(response.data);
      });
    } else {
      //do not `resetCustomerState()` becuase useCustomer manages a global "Customer" state via Recoil.
      setCustomersExistingUserIdentifiers([]);
    }

  }, [organizationId, customerId, isOpen]);

  return {
    viewState,
    inputtedIdentifierString,
    customersExistingUserIdentifiers,
    customerState,
    onCancel,
    existingIdentifier
  };
};