import { userActivitiesApi } from '@app/react/lib/api/userActivitiesApi';
import { PaginationMetaDto, UserActivityDto } from '@bondsports/types';
import { useState } from 'react';

export const useActivities = () => {
	const [availableActivities, setAvailableActivities] = useState<UserActivityDto[]>([]);
	const [activitiesMeta, setActivitiesMeta] = useState<PaginationMetaDto>();
	const [isFetchingActivities, setFetchingActivities] = useState<boolean>(false);
	const [fetchActivitiesError, setFetchActivitiesError] = useState<string | null>(null);

	const fetchAvailableActivities = async (userId: number, organizationId: number, page = 1, itemsPerPage?: number) => {
		setFetchingActivities(true);
		try {
			const response = await userActivitiesApi.getAvailableUserActivities(userId, organizationId, page, itemsPerPage);
			setFetchingActivities(false);
			setAvailableActivities(response.data);
			setActivitiesMeta(response.meta);
		} catch (err) {
			setFetchActivitiesError(err.message);
		}
		setFetchingActivities(false);
	};

	return {
		fetchAvailableActivities,
		availableActivities,
		activitiesMeta,
		isFetchingActivities,
		fetchActivitiesError,
	};
};
