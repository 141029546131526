import {
	DiscountModalTabEnum,
	IPromoCodeDiscountInfo,
	IPromoCodeState,
	TCustomState,
	TDiscountModalState,
} from './types';

const getReleventData = (promoCodeState: IPromoCodeState): TDiscountModalState => {
	return {
		discountType: DiscountModalTabEnum.PROMO_CODE,
		discountId: promoCodeState.selectedPromoCode ? Number(promoCodeState.selectedPromoCode.value) : undefined,
		discountState: {
			publicNote: promoCodeState.publicNote,
			privateNote: promoCodeState.privateNote,
		},
	};
};

export const getActiveState = (
	customState: TCustomState,
	promoCodeState: IPromoCodeState,
	currentTab?: DiscountModalTabEnum
): TDiscountModalState => {
	const customDiscount = { discountState: customState, discountType: DiscountModalTabEnum.CUSTOM };
	switch (currentTab) {
		case DiscountModalTabEnum.CUSTOM:
			return customDiscount;
		case DiscountModalTabEnum.PROMO_CODE: {
			return getReleventData(promoCodeState);
		}
		default:
			return customDiscount;
	}
};
