import { chunk, clamp } from 'lodash';
import { PaginationMetaDto } from '@bondsports/types';

export function getChunkIndex(pageChunks: number[][], chunkSize: number, currentPage: number): number {
	return clamp(Math.floor((currentPage - 1) / chunkSize), 0, pageChunks.length - 1);
}

export function chunkPages(pages: number, chunkSize: number): number[][] {
	return chunk(new Array(pages).fill(0), chunkSize);
}

export function hasMorePages(meta: PaginationMetaDto): boolean {
	return meta && meta.currentPage < meta.totalPages;
}

export const initialMeta: PaginationMetaDto = {
	totalItems: 0,
	itemsPerPage: 0,
	totalPages: 0,
	currentPage: 0,
};
