/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { cellCss } from './Status';
import { priceTypeTranslations } from '../../../lib/i18n/en';

export const PriceType = ({ column, row }: { column: any; row: any }) => {
	const value = row[column.id];
	const title = priceTypeTranslations[value];
	return (
		<div css={cellCss(column.action, false)}>
			{title}
		</div>
	);
};