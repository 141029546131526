/** @jsx jsx */

import React, { FC } from 'react';
import { jsx, css } from '@emotion/react';
import { heightPxCss, minHeightPxCss } from '@app/react/styles/utils';

const customerHeaderContainer = (isColumn?: boolean, withTabs?: boolean, printable?: boolean) => css`
	display: flex;
	flex-direction: ${isColumn ? 'column' : 'row'};
	justify-content: space-between;
	flex-direction: ${isColumn ? 'column' : 'row'};
	background: ${printable ? 'white' : 'transparent'};
	box-shadow: 0px 2px 22px rgba(21, 93, 148, 0.0749563);
	padding: ${`12px 20px ${withTabs ? 0 : '12px'} 24px`};
	z-index: 0;
	position: relative;
`;

const stickyCss = css`
	position: sticky !important;
	top: 0 !important;
`;

export const HeaderContainer: FC<{
	isColumn?: boolean;
	withTabs?: boolean;
	printable?: boolean;
	isSticky?: boolean;
	hight?: number;
}> = ({ isColumn = false, withTabs = false, children, printable = true, isSticky = false, hight }) => {
	return (
		<div
			className={printable ? 'no-print' : 'print-container'}
			css={[
				isSticky
					? [customerHeaderContainer(isColumn, withTabs, printable), stickyCss]
					: customerHeaderContainer(isColumn, withTabs, printable),
				hight && minHeightPxCss(hight),
			]}
			data-aid="header-container"
		>
			{children}
		</div>
	);
};
