/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Fee } from '@bondsports/types';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { TooltipWithButton } from '../../tooltip-with-button';
import { Typography } from '../../../atoms';
import { Pricify, roundPriceCents } from '../../../lib/price';
import { EmptyCell } from '../EmptyCell';

interface IFees {
	fee: Fee;
	feeAmount?: number;
}
interface FeesWithIconToolTip {
	finalFee: string;
	fee: Fee;
}
export const FeesMapper = ({ state, withIcon }: { state: IFees; withIcon?: boolean }) => {
	const feeAmount = state?.feeAmount ?? 0;
	if (!feeAmount) {
		return <EmptyCell />;
	}
	const formmatedFee = Pricify(feeAmount, undefined, undefined, true);
	if (withIcon) {
		return <FeesWithIcon finalFee={formmatedFee} fee={state!.fee} />;
	}
	return (
		<Typography type={ETypography.body2} color={ETypographyColor.primary}>
			{formmatedFee}
		</Typography>
	);
};

export const FeesWithIcon = ({ finalFee, fee }: FeesWithIconToolTip) => {
	const percent =
		Number(fee?.percentageValue ?? 0) > 0 ? `${roundPriceCents(Number(fee.percentageValue ?? 0) * 100)}%` : '';
	const addition = Number(fee?.percentageValue ?? 0) > 0 && Number(fee?.fixValue ?? 0) > 0 ? ` + ` : '';
	const fixed =
		Number(fee?.fixValue ?? 0) > 0 ? `${Pricify(Number(fee?.fixValue ?? 0), undefined, undefined, true)} fixed` : ``;
	const tooltipContent = fee ? `${fee?.name ?? ''} - ${percent}${addition}${fixed}` : '';
	return (
		<TooltipWithButton icon={Icons.info} tooltipContent={tooltipContent}>
			<Typography type={ETypography.body2} color={ETypographyColor.primary}>
				{finalFee}
			</Typography>
		</TooltipWithButton>
	);
};
