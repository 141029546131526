export enum PAYMENT_STEPS {
	INVOICE = 0,
	SELECT_INSTALLMENTS = 1,
	PAYMENT = 2,
}

export const DEFAULT_INSTALLMENTS_INFO = {
	futureFetched: 0,
	failedFetched: 0,
	futureSelected: 0,
	failedSelected: 0,
	fetchedIds: new Set<number>(),
	selectedIds: new Set<number>(),
};
