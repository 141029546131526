/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { DiscountAmountInputProps, EDiscountUnit } from './types';
import { fullWidthCss } from '../../styles/utils';
import { Input } from '../../components/Input';
import { Select_v2 } from '../../components/Select_v2';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types';
import { roundPriceCents } from '../../lib/price';
import { discountTypeOptions, MAX_PERCENTAGE } from './constants';
import { calculateDiscount } from './utils';
import { typeSelectCss } from './styles';
import { Column, Row } from '../../atoms/layoutPrimitives';
import { i18n } from '../../lib/i18n';

export const DiscountAmountInput: React.FC<DiscountAmountInputProps> = ({
	amountPreDiscount,
	value,
	type = EDiscountUnit.PERCENT,
	onChange,
	discountApplicant,
}) => {
	const [selectedType, setSelectedType] = useState<EDiscountUnit>(type);
	const [percentInputValue, setPercentInputValue] = useState<number | undefined>();
	const labels = i18n.addDiscount.amountInput;
	const isInvoice = discountApplicant === 'invoice';

	const { totalAfterDiscount, dollarValue, percentValue, inputValue } = useMemo(() => {
		let totalAfterDiscount;
		let dollarValue;
		let percentValue;

		switch (selectedType) {
			case EDiscountUnit.PERCENT: {
				const calcValue = (amountPreDiscount * (value ?? 0)) / 100;
				totalAfterDiscount = value
					? roundPriceCents(amountPreDiscount - calcValue)
					: roundPriceCents(amountPreDiscount);
				dollarValue = value ? roundPriceCents(calcValue) : 0;
				percentValue = value ? roundPriceCents(value) : 0;
				break;
			}
			case EDiscountUnit.AMOUNT:
			default: {
				totalAfterDiscount = value ? roundPriceCents(amountPreDiscount - value) : roundPriceCents(amountPreDiscount);
				dollarValue = value ? roundPriceCents(value) : 0;
				percentValue = value && amountPreDiscount > 0 ? roundPriceCents((value / amountPreDiscount) * 100) : 0;
				break;
			}
		}

		const inputValue = selectedType === EDiscountUnit.AMOUNT ? dollarValue : percentValue;

		return { totalAfterDiscount, dollarValue, percentValue, inputValue };
	}, [value, percentInputValue, amountPreDiscount, selectedType]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { amount, percent } = calculateDiscount(selectedType, Number(e.target.value), amountPreDiscount);

		setPercentInputValue(percent);
		onChange(selectedType, (selectedType === EDiscountUnit.AMOUNT ? amount : percent) ?? undefined);
	};

	const handleOnClear = () => {
		onChange(selectedType, undefined);
	};

	const handleTypeChange = useCallback((newType: EDiscountUnit) => {
		setSelectedType(newType);
		setPercentInputValue(undefined);
		onChange(newType, undefined);
	}, []);

	useEffect(() => {
		if (type !== selectedType) {
			handleTypeChange(type);
		}
	}, [type, selectedType, handleTypeChange]);

	return (
		<div css={fullWidthCss} data-aid="discount-amount-input-discount-amount-input">
			<Column gap="0" align={'start'}>
				<Row gap="1rem" fullWidth>
					<Input
						sizer="S"
						label={labels.inputLabel}
						value={inputValue || undefined}
						onChange={handleOnChange}
						type="number"
						decimalScale={2}
						max={type === EDiscountUnit.AMOUNT ? amountPreDiscount : MAX_PERCENTAGE}
						clear={handleOnClear}
						placeholder="0"
					/>
					<Select_v2
						value={selectedType}
						onSelecte={handleTypeChange}
						options={discountTypeOptions}
						isGroups={false}
						label=" " // create placeholder to hold label's position
						css={typeSelectCss}
					/>
				</Row>
				<Row fullWidth justify="space-between">
					<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
						{labels.discountTotals(dollarValue, percentValue)}
					</Typography>
					<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
						{isInvoice
							? labels.subtotalBalanceAfterDiscount(totalAfterDiscount)
							: labels.invoiceSubtotalBalance(totalAfterDiscount)}
					</Typography>
				</Row>
			</Column>
		</div>
	);
};
