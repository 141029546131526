import { environment } from '../../../../environments/environment';
import { network } from '../network';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { FacilityUpdateStatusEnum, ICreateFacilityObj } from 'app/react/types/facility';
import { MediaItem } from 'app/react/types/media';
import { imageApi } from './imageApi';
import { FacilitySection } from 'app/react/forms/settings/facility/types';
import { SectionEnum } from 'app/react/types/settings';
import { ECalendarMode } from '../../stores/calendarStore';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { ICalendarSlotCard } from '@bondsports/types';

dayjs.extend(utc);

const facility_Url = `${environment.CS_URLS.API_ROOT_V4}/facilities/organization`;

function dateTimeInTimeZone(rawDate: Date | string, timezone?: string) {
	const m = momentTimezone.tz(rawDate, timezone);
	const date = m.format('YYYY-MM-DDTHH:mm:ss') + '.000Z';
	return date;
}

const getSpacesByDay = async (organizationId: number, facilityId: number, date?: string, mode?: ECalendarMode) => {
	let startDate = moment.utc(date).date(1).format('YYYY-MM-DD');
	let endDate = moment.utc(date).date(31).format('YYYY-MM-DD');

	switch (mode) {
		case ECalendarMode.DAILY: {
			startDate = moment.utc(date).format('YYYY-MM-DD');
			endDate = moment.utc(date).add(1, 'day').format('YYYY-MM-DD');
			break;
		}
		case ECalendarMode.WEEKLY: {
			if (moment(date).day() === 0) {
				startDate = moment.utc(date).weekday(1).subtract(1, 'week').format('YYYY-MM-DD'); // should be 1
				endDate = moment.utc(date).weekday(0).format('YYYY-MM-DD'); // should be 0
			} else {
				startDate = moment.utc(date).weekday(1).format('YYYY-MM-DD'); // should be 1
				endDate = moment.utc(date).weekday(0).add(1, 'week').format('YYYY-MM-DD'); // should be 0
			}
			break;
		}
	}
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/reservations/organization/${organizationId}/facility/${facilityId}?startDate=${startDate}&endDate=${endDate}&limit=5000&onlyActiveSessions=true&includeBlocking=true&checkConflicts=true&resourcesTypes=space`
	);
	if (!response.err) {
		const newResponse = response.map(resource => {
			let newSessions = [...resource.slots];
			newSessions = newSessions.map(session => {
				const startT = session.startTime.split(':');
				const endT = session.endTime.split(':');
				return {
					...session,
					startDate: dayjs.utc(session.startDate).hour(startT[0]).minute(startT[1]).format(),
					endDate: dayjs.utc(session.endDate).hour(endT[0]).minute(endT[1]).format(),
				};
			});
			return {
				...resource,
				slots: newSessions,
			};
		});

		return newResponse;
	} else {
		//handle error..
	}
};

const getGroupsByFacilityId = async (organizationId: number, facilityId: number) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/rentals/organization/${organizationId}/resource-group/facility/${facilityId}`
	);
	return response;
};

const getPackages = async (organizationId: number, facilityId: number) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/packages?creatorId=${facilityId}&creatorType=venue`
	);
	return response;
};

const getVenuePaymentToken = async (organizationId: number, provider = 'stripe') => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT}/payments/client-token?provider=${provider}&id=${organizationId}&type=organization`
	);
	return response;
};

const updateBooking = async (reservationId: number, data: any) => {
	const response = await network.put(`${environment.CS_URLS.API_ROOT_V2}/reservations/${reservationId}`, data);
	return response;
};

const cancelBooking = async (reservationId: number) => {
	const response = await network.delete(`${environment.CS_URLS.API_ROOT_V2}/reservations/${reservationId}`, {});
	return response;
};

const updateSession = async (sessionId: number, data: any) => {
	const response = await network.put(`${environment.CS_URLS.API_ROOT_V2}/reservations/sessions/${sessionId}`, data);
	return response;
};

const cancelSession = async (sessionId: number) => {
	const response = await network.delete(`${environment.CS_URLS.API_ROOT_V2}/reservations/sessions/${sessionId}`, {});
	return response;
};
const createFacility = async (organizationId: number, data: ICreateFacilityObj) => {
	const response = await network.post(`${facility_Url}/${organizationId}`, data);
	return response;
};

const updateFacilityStatus = async (organizationId: number, facilityId: number, status: FacilityUpdateStatusEnum) => {
	const response = await network.put(`${facility_Url}/${organizationId}/${facilityId}/${status}`, {});
	return response;
};

const updateFacilityData = async (
	organizationId: number,
	facilityId: number,
	section: SectionEnum,
	data: FacilitySection
) => {
	const response = await network.put(`${facility_Url}/${organizationId}/${facilityId}/${section}`, data);
	return response;
};

const uploadFacilityMedia = (file: MediaItem, facilityId: number) => {
	return imageApi.uploadFileItemImage(file).then(async response => {
		const fileObject = {
			url: response.secure_url,
			provider: 'cloudinary',
			fileType: response.format,
			mediaKey: response.public_id,
			fileName: response.original_filename,
		};
		return await network.post(`${environment.CS_URLS.API_ROOT}/venues/${facilityId}/uploadMedia?handleType=main`, {
			file: fileObject,
			//  handleType: type,
		});
	});
};

const getFacilityById = async (organizationId: number, facilityId: number) => {
	const response = await network.get(`${facility_Url}/${organizationId}/${facilityId}`, {});
	return response;
};

const getCalendarSlotById = async (
	organizationId: number,
	reservationId: number,
	slotId: number
): Promise<ICalendarSlotCard> => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/reservations/${reservationId}/organization/${organizationId}/slot/${slotId}/calendar-card`
	);
	return response;
};

const getResourcesByFacilityID = async (organizationId: number, facilityId: number) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/resources/organization/${organizationId}/facility/${facilityId}`
	);
	return response;
};

export const facilityApi = {
	getResourcesByFacilityID,
	getVenuePaymentToken,
	getPackages,
	getSpacesByDay,
	updateBooking,
	cancelBooking,
	updateSession,
	getCalendarSlotById,
	cancelSession,
	getGroupsByFacilityId,
	createFacility,
	uploadFacilityMedia,
	getFacilityById,
	updateFacilityData,
	updateFacilityStatus,
};
