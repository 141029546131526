import { useDebounce } from '@app/react/hooks/useDebouncedSearch';
import { useState } from 'react';

const DELAY_MS = 300;
export const useSearchFilter = () => {
	const [searchFilter, setSearchFilter] = useState('');
	const debouncedSearch = useDebounce(searchFilter, DELAY_MS);

	function onSearch(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchFilter(e.target.value);
	}
	return { searchFilter, onSearch, debouncedSearch };
};
