/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import { cellCss } from '../styles';
import { dateCss, timeCss } from '../../../../styles/utils';
import { EDateTimeFormats } from '@app/react/types/times';

dayjs.extend(utc);
dayjs.extend(timezone);

const TimeDisplay = ({
	time,
	format,
	stylingCss,
	prefix = '',
	timezone,
}: {
	time: string;
	format: EDateTimeFormats;
	stylingCss: SerializedStyles;
	prefix?: string;
	timezone?: string;
}) => {
	const content = `${prefix} ${dayjs(time).tz(timezone).format(format)}`;
	return <span data-aid="TimeDisplay" css={stylingCss}>{content}</span>;
};

export const DateTime = ({ row, column, isLast, timezone }) => {
	const { id, styling, action, hideHoursDisplay } = column;
	const { TagComponent, additionalDate, additionalDatePrfix, taggedColumnId } = row;
	const value = row[id];
	const isTagComponent = !!TagComponent && id === taggedColumnId;
	return (
		<td
		    data-aid="td-datetime"
			className="TableCell-root TableCell-body"
			align={styling?.align ? (styling?.align === 'right' ? 'right' : 'left') : 'center'}
			onClick={() => {
				if (action) {
					action(row);
				}
			}}
			css={cellCss({ isAction: !!action, isLast })}
		>
			<TimeDisplay time={value} format={EDateTimeFormats.DAY_FORMAT} stylingCss={dateCss} timezone={timezone}/>
			{isTagComponent && (
				<Fragment>
					{TagComponent}
					<TimeDisplay
						time={additionalDate}
						format={EDateTimeFormats.DAY_FORMAT}
						stylingCss={timeCss}
						prefix={additionalDatePrfix}
						timezone={timezone}
					/>
				</Fragment>
			)}
			{!hideHoursDisplay && (
				<TimeDisplay time={value} format={EDateTimeFormats.H12_ampm} stylingCss={timeCss} timezone={timezone} />
			)}
		</td>
	);
};
