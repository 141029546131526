import { useNotification } from '@app/react/hooks/useNotification';
import { useOrganization } from '@app/react/hooks/useOrganization';
import { paymentApi } from '@app/react/lib/api/paymentApi';
import { UserPaymentMethodOption, PaymentMethodTypeEnum, UserPaymentMethod } from '@bondsports/types';
import { ENotificationType } from '@bondsports/utils';
import { useEffect, useState } from 'react';
import { splitPaymentMethods } from '../lib';

export interface PaymentMethods {
	ACHMethods: UserPaymentMethod[];
	creditCards: UserPaymentMethod[];
}

export const usePaymentMethods = (userId?: number, paymentMethodData?: UserPaymentMethodOption[]) => {
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethods | null>();
	const [loader, setLoader] = useState(false);
	const { organizationId } = useOrganization();
	const { setPopupNotification } = useNotification();

	const fetchPaymentMethodsByUserId = async (userId: number, paymentMethodType?: PaymentMethodTypeEnum) => {
		const res = await paymentApi.getCustomerUserPaymentMethods(organizationId, userId, paymentMethodType);
		return res;
	};

	const fetchPaymentMethods = async (userId: number, paymentMethodType?: PaymentMethodTypeEnum) => {
		setLoader(true);
		const res = await fetchPaymentMethodsByUserId(userId, paymentMethodType);
		setLoader(false);
		return res;
	};

	const setPaymentMethodsIfUserExists = async (paymentMethodType?: PaymentMethodTypeEnum) => {
		if (!paymentMethodType && paymentMethodData?.length) {
			const { creditCards, ACHMethods } = splitPaymentMethods(paymentMethodData);
			setPaymentMethods({ creditCards: creditCards, ACHMethods: ACHMethods });
		} else {
			if (userId) {
				const userPaymentMethodsRes = await fetchPaymentMethods(userId, paymentMethodType);
				if (userPaymentMethodsRes?.err) {
					setPopupNotification(String(userPaymentMethodsRes.err), ENotificationType.warning);
					return;
				}
				const { creditCards, ACHMethods } = splitPaymentMethods(userPaymentMethodsRes);
				setPaymentMethods({ creditCards, ACHMethods });
			}
		}

		setLoader(false);
	};

	const resetPaymentMethods = () => {
		setPaymentMethods(null);
	};

	useEffect(() => {
		if (!userId && paymentMethods) {
			resetPaymentMethods();
		}
	}, [userId]);

	return { fetchPaymentsLoader: loader, paymentMethods, setPaymentMethodsIfUserExists };
};
