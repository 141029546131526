import { EReservationStatus } from '@app/react/types/reservation';
import { CalculateFeeResult, InvoiceDiscountOnEnum, ItemDiscountDto } from '@bondsports/types';
import { TranslationEn } from 'assets/i18n/en';
import { cloneDeep } from 'lodash';
import { roundPriceCents } from '../../../lib/pricing';
import { AlignType } from '../../shared/NewTable/types';
import { TAllowedColors } from '@bondsports/utils';
import { IInvoice } from './types';
import { ILineItem } from '@app/react/types/orders';

export const scheduledPaymentsColumns = [
	{
		id: 'originalPlannedDate',
		label: `${TranslationEn.customers.paymentsInvoices.date}`,
		type: 'date',
		styling: { align: AlignType.LEFT },
	},
	{
		id: 'price',
		label: `${TranslationEn.customers.paymentsInvoices.amount}`,
		type: 'currency',
	},
	{
		id: 'status',
		label: `${TranslationEn.customers.listColumns.status}`,
		type: 'status',
		styling: { align: AlignType.RIGHT },
	},
];

export const getReservationStatusTag = (reservationStatus: string): { title: string; color: TAllowedColors } => {
	if (reservationStatus)
		switch (reservationStatus) {
			case EReservationStatus.APPROVED:
				return {
					title: EReservationStatus.APPROVED,
					color: 'green',
				};
			case EReservationStatus.AWAITING_ADMIN:
				return {
					title: EReservationStatus.AWAITING_ADMIN,
					color: 'gray',
				};
			case EReservationStatus.AWAITING_CUSTOMER:
				return {
					title: EReservationStatus.AWAITING_CUSTOMER,
					color: 'gray',
				};
			case EReservationStatus.REJECTED:
				return {
					title: EReservationStatus.REJECTED,
					color: 'red',
				};
			case EReservationStatus.DELETED:
				return {
					title: EReservationStatus.DELETED,
					color: 'red',
				};
			case EReservationStatus.CANCELED:
				return {
					title: EReservationStatus.CANCELED,
					color: 'red',
				};
			default:
				return {
					title: 'Error',
					color: 'gray',
				};
		}
};

export const generateRowsWithOriginalQuantity = itemsRows => {
	const newItemsRows = cloneDeep(itemsRows);
	const indexes = [];
	newItemsRows.forEach((item, index) => {
		if (item.displayQuantity > item.quantity) {
			return indexes.push(index);
		}
	});
	indexes.forEach(i => {
		newItemsRows[i].quantity = newItemsRows[i].displayQuantity;
		newItemsRows[i].total = newItemsRows[i].displayFullPrice;
	});

	return newItemsRows;
};

export const getFinalPriceFromFees = (fees: CalculateFeeResult[]) =>
	roundPriceCents(fees.reduce((total, fee) => total + fee.totalPrice, 0));

export const processInvoiceResponse = (response: any) => {
	const invoiceData = response;
	const slots = invoiceData.slots ?? [];
	const reservations = invoiceData.reservations ?? [];
	if (!!reservations.length && !!slots.length) {
		for (const slot of slots) {
			const reservation = reservations.find(r => r.id === slot.reservationId);
			if (reservation) {
				slot.reservation = reservation;
			}
		}
	}
	return { ...response, slots };
};

export const hasPromoCodeByType = (discountOn: InvoiceDiscountOnEnum): ((discount: ItemDiscountDto) => boolean) => {
	return (discount: ItemDiscountDto) => discount.discountOn === discountOn;
};

export const getLineItemById = (invoice: IInvoice, id: number): ILineItem | undefined =>
	invoice?.lineItems?.find(lineItem => lineItem.id === id);
