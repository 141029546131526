/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { Fragment } from 'react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor } from '../../../types';
import { i18n } from '../../../lib/i18n';
import { formatPhoneNumber } from '../../../functions';
import { EInvoiceVariant } from '../../../organisms/invoiceTemplate/types';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { hrCss } from '../../../organisms/invoiceTemplate/styles';

const containerCss = css`
	margin: 20px 20px 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 96%;
`;

const thermalContainerCss = css`
	margin: 2rem 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const logoCss = css`
	img {
		max-height: 40px;
		width: auto;
	}
`;

const titleCss = css`
	.body-accented {
		justify-content: center;
	}
`;

const contactCss = (alignItems = 'end') => css`
	display: flex;
	flex-direction: column;
	align-items: ${alignItems};

	.body-2-accented {
		font-size: 10px;
	}
`;

interface Props {
	organization: any;
	invoiceId: number;
	isPrint: boolean;
	variant?: EInvoiceVariant;
}

const OrgHeader = (props: { thermalPrint?: boolean; mainMedia: { url: string }; name: string }) => (
	<Fragment>
		{!props.thermalPrint ? (
			<div css={logoCss}>
				<img src={props.mainMedia?.url ?? ''} alt="logo" />
			</div>
		) : (
			<div>
				<Typography color={ETypographyColor.primary} type={ETypography.body1Accented}>
					{props.name ?? ''}
				</Typography>
			</div>
		)}
	</Fragment>
);

const TitleHeader = (props: { title: string; invoiceId: number }) => (
	<div css={titleCss}>
		<Typography color={ETypographyColor.primary} type={ETypography.body1Accented}>
			{`${props.title} ${props.invoiceId}`}
		</Typography>
	</div>
);

const ContactHeader = ({
	phoneNumber,
	email,
	thermalPrint = true,
}: {
	phoneNumber?: string;
	email?: string;
	thermalPrint?: boolean;
}) => (
	<div css={contactCss(thermalPrint ? 'center' : 'end')}>
		<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
			{phoneNumber ?? 'No phone number'}
		</Typography>
		<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
			{email ?? 'No email'}
		</Typography>
	</div>
);

const LayoutHeader = (props: { thermalPrint?: boolean; className: string; children?: React.ReactNode }) => (
	<div
		className={props.className}
		css={props.thermalPrint ? thermalContainerCss : containerCss}
		data-aid="PrintHeader-printHeader"
	>
		{props.children}
	</div>
);

export const PrintHeader = ({ organization, invoiceId, isPrint, variant }: Props) => {
	const { isMobile } = useWindowSize();
	const title = variant ? i18n.invoice.header[variant] : i18n.invoice.header.title;
	const phoneNumber = formatPhoneNumber(organization?.phoneNumber ?? '');
	const email = organization?.email ?? '';

	return (
		<Fragment>
			{!isPrint ? (
				<Fragment />
			) : (
				<Fragment>
					<LayoutHeader className="no-print">
						<OrgHeader mainMedia={organization.mainMedia} name={organization?.name} />
						<TitleHeader title={title} invoiceId={invoiceId} />
						<ContactHeader phoneNumber={phoneNumber} email={email} />
					</LayoutHeader>
					<LayoutHeader className="print" thermalPrint>
						<OrgHeader thermalPrint mainMedia={organization.mainMedia} name={organization?.name} />
						<ContactHeader phoneNumber={phoneNumber} email={email} />
						<br />
						<TitleHeader title={title} invoiceId={invoiceId} />
						<br />
						<hr css={hrCss} />
						<br />
					</LayoutHeader>
				</Fragment>
			)}
		</Fragment>
	);
};
