import { ColorInput, TinyColor } from '@ctrl/tinycolor';
import { css } from '@emotion/react';

import { breakpoints, colors, gridMaxWidth, gridPadding, colors_v2 } from './theme';
import { STYLE_UNITS } from '@bondsports/utils';

/**
 * Media Query helpers
 * See usage examples in `docs/styling.md`
 */

export const mediaUp = (screenSize: string) => `@media (min-width: ${screenSize})`;
export const mediaDown = (screenSize: string) => `@media (max-width: ${screenSize})`;
export const mediaDownVertical = (screenSize: string) => `@media (max-height: ${screenSize})`;
export const mediaBetween = (minScreenSize: string, maxScreenSize: string) =>
	`@media (min-width: ${minScreenSize}) and (max-width: ${maxScreenSize})`;
/** CSS media query for anything below tablet screen. */
export const mobileOnly = mediaDown(`${breakpoints.tablet - 1}px`);
/** CSS media query for smallest tablet screen and above. */
export const tabletUp = mediaUp(breakpoints.tabletPx);
/** CSS media query for smallest desktop screen and above. */
export const desktopUp = mediaUp(breakpoints.desktopPx);
/** CSS media query for screens that go above theme's grid. */
export const gridUp = `@media (min-width: ${gridMaxWidth + gridPadding * 2}px)`;
/** CSS media query for screens that go between tablet and our grid width. */
export const tabletToGrid = mediaBetween(breakpoints.tabletPx, `${gridMaxWidth + gridPadding * 2}px`);

/**
 * Color helpers
 */

/**
 * Sets the alpha value on a current color and returns it as an RGBA string.
 */
export function setOpacity(
	// Any valid color that TinyColor accepts
	color: ColorInput,
	// Opacity level (from 0 to 1)
	opacity: number
) {
	return new TinyColor(color).setAlpha(opacity).toRgbString();
}

/**
 * Adding measurements to make a target mobile-friendly and easier to tap.
 *
 * @see
 * [Tap targets are not sized appropriately](https://developers.google.com/web/tools/lighthouse/audits/tap-targets)
 */
export const tapTargetCss = css`
	${mobileOnly} {
		min-height: 48px;
		min-width: 48px;
	}
`;

/**
 * Truncate text.
 */
export const truncateTextCss = (
	// The number of lines to clamp
	lineClamp: number | 'none'
) => css`
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${lineClamp};
`;

export const brandGradientBackgroundCss = css`
	background-image: linear-gradient(90deg, ${colors.brandSecondary}, ${colors.brandSecondaryLight});
`;

export const flex = css`
	display: flex;
	align-items: center;
`;

export const flexCol = css`
	display: flex;
	flex-direction: column;
`;

export const alignItemsCenter = css`
	align-items: center;
`;

export const baseCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
`;
export const dateCss = css`
	${baseCss};
	font-size: 1.4rem;
	line-height: 130%;
	color: ${colors.brandPrimary};
	display: block;
`;

export const timeCss = css`
	${baseCss};
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.formInputBg};
`;

export const participantTimeCss = css`
	${baseCss};
	font-size: 1.4rem;
	line-height: 1.5rem;
	color: ${colors.formInputBg};
`;

export const ChargeButtonCss = css`
	background: linear-gradient(270deg, ${colors.brandSecondary} 0%, ${colors.brandSecondary} 100%);
	color: ${colors.white};
	border: 1px solid ${colors.brandSecondary};
	&:hover {
		box-shadow: 0px 2px 16px 8px rgba(247, 181, 0, 0.2);
		border: 1px solid ${colors.brandSecondary};
	}
	&:disabled {
		box-shadow: none;
		border: none;
	}
`;

export const SecondaryButtonCss = css`
	border: 1px solid transparent;
	&:hover {
		box-shadow: none;
		text-decoration: underline;
		border: 1px solid transparent;
	}
`;

export const flexBetweenCss = css`
	${flex};
	justify-content: space-between;
`;

export const flexEndCss = css`
	${flex};
	justify-content: flex-end;
`;

export const iconCss = css`
	border-radius: 2px;
	width: 20px;
	height: 20px;
	background-color: rgba(13, 71, 116, 0.04);
	input:hover ~ & {
		background-color: #ebf1f5;
	}
	input:disabled ~ & {
		box-shadow: none;
		background-color: rgba(206, 217, 224, 0.5);
	}
`;

export const iconWithColorCss = (iconColor: string, height?: number, width?: number) => css`
	color: ${iconColor || colors.brandPrimary};
	height: ${height || 28}px;
	width: ${width || 28}px;
	display: block;
`;

export const checkedIconCss = css`
	background-color: rgba(13, 71, 116, 0.04);
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
	&:before {
		display: block;
		height: 20px;
		width: 20px;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%230d4774'/%3E%3C/svg%3E");
		content: '';
	}
	input:hover ~ & {
		background-color: rgba(13, 71, 116, 0.04);
	}
`;

export const CheckedIconCss = css`
	${iconCss}
	${checkedIconCss}
`;

export const checkboxCss = (equalPadding?: boolean, disabled?: boolean) => css`
	&.MuiCheckbox-root {
		padding: 8px;
		padding-left: ${equalPadding ? 0 : '8px'};
		${disabled ? disableCss : ''}
	}
	&:hover {
		background: transparent;
	}
`;

export const filterContainer = css`
	${flexCol};
	width: 200px;
	margin-top: 1rem;
	padding: 1rem 0 1rem 0;
	font-family: Montserrat;
	font-size: 1.4rem;
	line-height: 130%;
	color: ${colors.brandPrimary};
	label {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: ${colors.lightText};
	}
`;

export const marginTop = (px: number) => css`
	margin-top: ${px}px;
`;

export const TableContainer = css`
	td {
		padding: 10px !important;
	}
	th {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
`;

export const imageContainerCss = css`
	background: ${colors.white};
	border: 1px dashed ${colors.formInputBg};
	box-sizing: border-box;
	box-shadow: 0px 1.57143px 17.2857px 6.28571px rgba(21, 93, 148, 0.0749563);
	border-radius: 1.57143px;
	padding: 3rem;
`;

export const border = `1px solid ${colors.borderSeperator}`;

export const borderBottom = css`
	border-bottom: ${border};
`;

export const borderTop = css`
	border-top: ${border};
`;

export const marginLeftCss = (margin: number) => css`
	margin-left: ${margin}px;
`;
export const fullWidthCss = css`
	width: 100%;
`;

export const marginRightCss = (manrgin: number) => css`
	margin-right: ${manrgin}px;
`;

export const underlineButton = (fontWeight?: number, fontSize?: number, marginTop?: number) => css`
	text-decoration: underline;
	color: ${colors.brandPrimary} !important;
	padding: 0px !important;
	font-weight: ${fontWeight || 500} !important;
	font-size: ${fontSize || 1.2}rem !important;
	margin-top: ${marginTop || '0'}px;
`;
export const noMarginTopCss = css`
	margin-top: 0;
`;

export const lightIconCss = css`
	color: ${colors.lightText};
`;
export const marginBottomCss = (margin: number) => css`
	margin-bottom: ${margin}px;
`;

export const fulWidthCss = css`
	width: 100%;
	height: 100%;
`;

export const fullHeightCss = css`
	height: 100%;
`;

export const marginTopCss = (marginTop: number) => css`
	margin-top: ${marginTop}px;
`;

export const flexCss = css`
	display: flex;
`;

export const flexWrapCss = css`
	display: flex;
	flex-wrap: wrap;
`;

export const wrapperContainer = css`
	${flexWrapCss};
	width: 100%;
`;
export const widthCss = (width: number) => css`
	width: ${width}%;
`;

export const heightPercentCss = (height: number) => css`
	height: ${height}%;
`;

export const spaceBetweenCss = css`
	display: flex;
	justify-content: space-between;
`;

export const flexEndContainerCss = css`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;
export const fullWidth = css`
	width: 100%;
`;

export const fullHeight = css`
	height: 100%;
`;

export const paddingCss = (padding: number | string) => css`
	padding: ${padding}px;
`;

export const paddingHorizontalCss = (padding: number, unit = STYLE_UNITS.PX) => css`
	${paddingLeftCss(padding, unit)}
	${paddingRightCss(padding, unit)}
`;
export const uppercaseCss = css`
	text-transform: uppercase !important;
`;

export const iconSizeCss = (size: number) => css`
	width: ${size}px;
	height: ${size}px;
`;
export const gapCss = (gap: number) => css`
	gap: ${gap}px;
`;

export const svgIcon = (width: number, height: number = width) => css`
	width: ${width}px;
	height: ${height}px;
`;

export const flexRowCss = css`
	display: flex;
	align-items: center;
`;

export const checkboxContainer = css`
	label {
		margin: 10px;
		margin-right: 0;
	}
`;

export const primaryColor = css`
	color: ${colors_v2.tx_text_primary};
`;

export const flexDirectionColumnCss = css`
	flex-direction: column;
`;

export const cancelButtonCss = css`
	text-transform: uppercase !important;
`;

export const heightCss = (height: number) => css`
	height: ${height}px;
`;

export const noPaddingCss = css`
	padding: 0px !important;
`;

export const justifyCenterCss = css`
	${flexCss};
	justify-content: center;
`;

export const widthPxCss = (width: number) => css`
	width: ${width}px;
`;

export const separatorCss = (where: 'top' | 'bottom' | 'right' | 'left' = 'bottom') => css`
	border-${where}: solid 1px;
	border-color: ${colors_v2.bg_border_seperator};
`;

export const heightPxCss = (height: number) => css`
	height: ${height}px;
`;

export const minHeightPxCss = (height: number) => css`
	min-height: ${height}px;
`;

export const justifyAndAlignCenter = css`
	justify-content: center;
	align-items: center;
`;

export const centeredColumn = css`
	${flexCol}
	${justifyAndAlignCenter}
`;

export const pointerCss = css`
	cursor: pointer;
`;

export const disableCss = css`
	pointer-events: none;
`;

export const topLineHeader = css`
	${flex};
	margin: 0 1rem;
	justify-content: space-between;
`;

export const topLineHeaderBread = css`
	${topLineHeader};
	margin-bottom: 1rem;
`;

export const svgCss = (width?: string, height?: string, color?: string) => css`
	${color && `color: ${color}`};
	svg {
		${width && !height ? `width: ${width}; height: auto;` : height && !width ? `height: ${height}; width: auto;` : ``};
		${width && height && `width: ${width}; height: ${height};`};
	}
`;

export const centerText = css`
	text-align: center;
`;

export const pointer = css`
	cursor: pointer;
`;

export const paddingTopCss = (padding: number) => css`
	padding-top: ${padding}px;
`;

export const paddingBottomCss = (padding: number) => css`
	padding-bottom: ${padding}px;
`;

export const boldlinkCss = css`
	.body-2-link {
		font-weight: 500;
	}
`;
export const fitContentCss = css`
	width: fit-content;
`;

export const paddingLeftCss = (padding: number, unit: string = STYLE_UNITS.PX) => css`
	padding-left: ${padding}${unit};
`;

export const paddingRightCss = (padding: number, unit: string = STYLE_UNITS.PX) => css`
	padding-right: ${padding}${unit};
`;

export const underlineCss = css`
	text-decoration-line: underline;
`;

export const scrollYCss = css`
	overflow-y: scroll;
	height: 100%;
`;

export const marginCss = (margin: number) => css`
	margin: ${margin}px;
`;

export const marginStringCss = (margin: string) => css`
	margin: ${margin};
`;

export const centerTextCss = css`
	text-align: center;
`;

export const colorCss = (color: string) => css`
	color: ${color};
`;

export const justifyContentCss = (how: string) => css`
	justify-content: ${how};
`;

export const alignItemsCss = (how: string) => css`
	align-items: ${how};
`;

export const flexGrowCss = (howMuch = 1) => css`
	flex-grow: ${howMuch};
`;

export const positionCss = (position: string) => css`
	position: ${position};
`;

export const overflowBehaviorCss = (x = 'none', y = 'none') => css`
	overflow-x: ${x};
	overflow-y: ${y};
`;

export const sidePaddingCss = (padding: number) => css`
	padding-right: ${padding}px;
	padding-left: ${padding}px;
`;

export const verticalPaddingCss = (padding: number) => css`
	padding-top: ${padding}px;
	padding-bottom: ${padding}px;
`;

export const scrollebleYCss = css`
	overflow-y: scroll;
`;

export const dynamicHeightCss = (offset: number) => css`
	height: calc(100vh - ${offset}px);
	overflow-y: auto;
`;

const DEFAULT_PERCENT = 50;
const DEFAULT_GAP = 8;
const DEFAULT_MOBILE_WIDTH = 100;
const DEFAULT_MOBILE_GAP = 0;

export const responsiveContainer = (
	percent = DEFAULT_PERCENT,
	gap = DEFAULT_GAP,
	mobileWidth = DEFAULT_MOBILE_WIDTH,
	mobileGap = DEFAULT_MOBILE_GAP
) => css`
	width: calc(${mobileWidth}% - ${mobileGap}px);
	@media (min-width: 768px) {
		width: calc(${percent}% - ${gap}px);
	}
`;

export const flexGrowSize = (size: number) => css`
	flex: ${size};
`;
