/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../components/Loader/index';
import { justifyCenterCss } from '../../../organisms/refundPopup/style';
import { flexCenterCss, fullHeightCss, fullWidthCss } from '../../../styles/utils';
import { spaceColumnContainer } from '../../styles';
import { IDailyViewProps } from '../../types';
import { spaceWidth } from '../styles';
import { CurrentTimeLine } from './CurrentTimeLine';
import { DayToDrop } from './DayToDrop';

export const DailyViewVertical: FC<IDailyViewProps> = ({
	spaces,
	EventComponent,
	isModeChanged,
	onAddNewSlotClick,
	hourSize,
	isLoading,
}) => {
	const [updates, setUpdates] = useState(false);

	useEffect(() => {
		setUpdates(true);
	}, [hourSize]);

	const columnsCount = useMemo(() => {
		return spaces.reduce((acc, { children }) => acc + (children.length || 1), 0);
	}, [spaces]);

	useEffect(() => {
		if (updates) setUpdates(false);
	}, [updates]);

	return (
		<Fragment>
			{updates ? null : isLoading ? (
				<div
					css={[
						flexCenterCss,
						justifyCenterCss,
						fullWidthCss,
						css`
							height: 80vh;
						`,
					]}
				>
					<Loader width={60} />
				</div>
			) : (
				<Fragment>
					{spaces.map((space, index) => {
						const hiddenEvents = false;
						return (
							<Fragment key={index}>
								{space.children.length === 0 ? (
									<div key={space.id} css={spaceColumnContainer(columnsCount, 1)} data-aid="DailyViewVertical-daily">
										<DayToDrop
											id={String(space.id)}
											EventComponent={EventComponent}
											withoutChildren
											isModeChanged={isModeChanged}
											hiddenEvents={hiddenEvents}
											onAddNewSlotClick={onAddNewSlotClick}
										/>
									</div>
								) : (
									<div key={space.id} css={spaceColumnContainer(columnsCount, space.children.length || 1)}>
										{space.children.map(item => {
											return (
												<DayToDrop
													key={item.id}
													id={String(item.id)}
													EventComponent={EventComponent}
													isModeChanged={isModeChanged}
													hiddenEvents={hiddenEvents}
													onAddNewSlotClick={onAddNewSlotClick}
												/>
											);
										})}
									</div>
								)}
							</Fragment>
						);
					})}
				</Fragment>
			)}
			<CurrentTimeLine width={columnsCount * spaceWidth} />
		</Fragment>
	);
};
