/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { DiscountFooterFooterProps } from '../types';
import { Row } from '../../../atoms/layoutPrimitives';
import { Button } from '../../../components/Button';
import { i18n } from '../../../lib/i18n';
import { SizeEnum } from '../../../lib/constants';

export const DiscountFooter = ({ isValid, onSubmit, onCancel, isProcessing }: DiscountFooterFooterProps) => {
	const labels = i18n.addDiscount.footer;
	return (
		<Row gap="1rem" justify="flex-end" fullWidth>
			<Button data-aid="button-discountFooter-cancel" theme="basic" sizer="S" onClick={onCancel}>
				{labels.cancel}
			</Button>
			<Button
				data-aid="button-discountFooter-confirm"
				theme="primary"
				sizer={SizeEnum.SMALL}
				onClick={onSubmit}
				disabled={!isValid}
				isProcessing={isProcessing}
			>
				{labels.confirm}
			</Button>
		</Row>
	);
};
