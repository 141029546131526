import React, { useMemo } from 'react';
import { Theme } from '@emotion/react';
import { capitalize } from '../../functions';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types/theme';
import { Checkbox } from '../Checkbox';
import { BnIcon } from '../icons_v2';
import { AdditionalsConnectorEnum, IOption, AdditionalRow } from '../../types';
import { StyledSelectOption_v2 } from '../Options_v2/style';
import { ISelectOptions } from '../Options_v2/types';

interface OptionProps {
	colors: Theme;
	index: number;
	opt: IOption;
	selectedValuesArray: string[] | number[] | Date[];
	value: string | string[] | number[] | Date[];
	optionVal: string;
	size: 'S' | 'M';
	lastOptionElementRef?: (node: Element) => void;
}

export const SelectOption = ({
	colors,
	index,
	size,
	onSelect,
	opt,
	autoFocus,
	isMultiple,
	selectedValuesArray,
	value,
	optionVal,
	isAllSelected,
	lastOptionElementRef,
}: Omit<ISelectOptions, 'size' | 'options'> & OptionProps) => {
	const onClick = () => {
		if (!opt.isDisabled) onSelect(opt.value);
	};

	const labelComponent = useMemo(() => {
		//TODO: add ellipsis to label
		return (
			<div className="first-row">
				{opt.icon && <BnIcon icon={opt.icon} />}
				<Typography
					color={opt.isDisabled ? ETypographyColor.disabled : ETypographyColor.primary}
					type={ETypography.body2}
				>
					{capitalize(opt.label)}
				</Typography>
				{opt.additionalInfo && (
					<Typography
						color={opt.isDisabled ? ETypographyColor.disabled : ETypographyColor.secondary}
						type={opt.defaultRow?.additionalTypographyType || ETypography.body2}
						className="additional-info"
					>
						{opt.additionalInfo}
					</Typography>
				)}
			</div>
		);
	}, [opt]);

	const additionalRowsComponent = useMemo(() => {
		if (!opt.additionalRows?.length) return null;

		return opt.additionalRows.map((row: AdditionalRow) => {
			const { additionals, additionalsConnector, color, type } = row;
			if (!additionals?.length) return null;

			const displayColor: ETypographyColor =
				color ?? (opt.isDisabled ? ETypographyColor.disabled : ETypographyColor.secondary);
			const displayType: ETypography = type ?? ETypography.overline;

			return (
				<div className="additional-row" key={opt.value}>
					<Typography color={displayColor} type={displayType}>
						{additionals[0]}
					</Typography>
					{additionals[1] && (
						<>
							<Typography color={displayColor} type={displayType} className="connector">
								{additionalsConnector || AdditionalsConnectorEnum.DASH}
							</Typography>
							<Typography color={displayColor} type={displayType}>
								{additionals[1]}
							</Typography>
						</>
					)}
				</div>
			);
		});
	}, [opt]);

	const optionValString = String(optionVal);
	const selectedSet = useMemo(() => new Set(selectedValuesArray.map(String)), [selectedValuesArray]);

	const isChecked = useMemo(() => {
		const isOptionSelected = selectedSet.has(optionValString);
		return isAllSelected ? !isOptionSelected : isOptionSelected;
	}, [selectedSet, optionValString, isAllSelected, value]);

	return (
		<StyledSelectOption_v2
			theme={{ ...colors }}
			key={index}
			onClick={onClick}
			tabIndex={0}
			size={size}
			autoFocus={autoFocus ? index === 0 : false}
			isDisabled={opt.isDisabled}
			ref={lastOptionElementRef}
			isMultiple={isMultiple}
		>
			<div className="option-container">
				{isMultiple && (
					<Checkbox
						checkboxPositionTop={8} // Looks good in bo but not in storybook
						data-aid="Select_v2-checkbox"
						width={16}
						functionDisable
						setChecked={() => {}}
						checked={isChecked}
						isDisabled={opt.isDisabled}
					/>
				)}
				<div className="wrapper" data-aid="Select_v2-span">
					{labelComponent}
					{additionalRowsComponent}
				</div>
			</div>
		</StyledSelectOption_v2>
	);
};
