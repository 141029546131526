/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { InstallmentRadio } from './installmentLine';
import { cardWrapperCss } from './style';


export const SelectInstallmentsContainer = ({
	handleSelect,
	futureInstallments,
	selectUpcomingDisabled = false,
	installmentsInfo,
	lastItemElementRef,
	isSelectAll = false,

}: {
	handleSelect?: (installment: any) => void;
	installmentsInfo: {
		futureSelected: number,
		futureFetched: number,
		failedFetched: number,
		failedSelected: number,
		selectedIds: Set<number>,
		fetchedIds: Set<number>
	};
	futureInstallments?: any[];
	lastItemElementRef: ((node: HTMLDivElement) => void) | null;
	isSelectAll?: boolean;
	selectUpcomingDisabled?: boolean;
}) => {

	
	return (
			<div css={cardWrapperCss}>
				{futureInstallments?.map((installment, i) => {
					const isChecked = installmentsInfo.selectedIds?.has(installment?.id) || false;
					const isSelected = isSelectAll ? !isChecked : isChecked;
					const isLast = i === futureInstallments.length - 1;
					return (
					<span key={installment?.id} ref={isLast ? lastItemElementRef : null}>
					<InstallmentRadio
					key={installment?.id}
					state={installment}
					disabled={selectUpcomingDisabled}
					isChecked={isSelected}
					handleSelect={() => handleSelect && handleSelect(installment)}
					/>
					</span>
					);
				})}
		</div>
	);
};
