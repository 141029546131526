/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, useTheme } from '@emotion/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EDateFormats } from '../lib/timeUtils';
import { AsideContainer } from './atoms/AsideContainer';
import { DailyViewVertical } from './atoms/daily/DailyViewVertical';
import { DailyViewHorizontal } from './atoms/daily/horizontal/DailyViewHorizontal';
import { DndProvider } from './atoms/DndProvider';
import { HeaderContainer } from './atoms/HeaderContainer';
import { MonthlyView } from './atoms/monthly/MonthlyView';
import { spaceWidth } from './atoms/styles';
import { WeeklyView } from './atoms/weekly/WeeklyView';
import { useDebouncedValue } from './hooks/useDebouncedValue';
import { MiddlewareContextProvider, useMiddlewareContext } from './hooks/useMiddlewareContext';
import { bodyContainer, bodyContent, cssProvider, rootContainer, dndProviderContainer } from './styles';
import { ECalendarMode, ECalendarView, ICalendarProps } from './types';
import {
	filterSlotsForDailyView,
	filterSlotsForOldSlots,
	getBodyContainerHeight,
	scrollToHorizontal,
	scrollToVertical,
} from './utils';
import { oneHourHeight, oneHourWidth } from './utils/timeUtils';

dayjs.extend(customParseFormat);

const isSafari =
	typeof window !== 'undefined'
		? window.navigator.vendor &&
			window.navigator.vendor.indexOf('Apple') > -1 &&
			window.navigator.userAgent &&
			window.navigator.userAgent.indexOf('CriOS') == -1 &&
			window.navigator.userAgent.indexOf('FxiOS') == -1
		: false;

const SCROLL_BEHAVIOR = 'smooth';

const SCROLL_TO_BEHAVIOR = 'instant';

const Calendar: FC<ICalendarProps> = ({
	key,
	spaces,
	widthChangeTrigger,
	slots,
	EventComponent,
	DragOverlayComponent,
	ResourceGroupHeader,
	onChange,
	options = {},
	resourceId,
	isLoading = false,
	groups,
	resources,
}) => {
	const colors = useTheme();
	const [lastMode, setLastMode] = useState(ECalendarMode.DAILY);
	const { mode = ECalendarMode.DAILY, view = ECalendarView.VERTICAL } = options || {};
	const horizontal = view === ECalendarView.HORIZONTAL;

	const { day: daysCount, setOptions, initiateEventsToSpaces, getTopAndHeight } = useMiddlewareContext();

	const isModeChanged = mode !== lastMode;

	const [width, setWidth] = useState(200);

	const headerRef = useRef<HTMLDivElement>(null);
	const bodyRef = useRef<HTMLDivElement>(null);
	const verticalSpacesRef = useRef<HTMLDivElement>(null);

	const scrollToHour = useCallback(
		(now: number) => {
			if (mode === ECalendarMode.DAILY && bodyRef.current) {
				if (isSafari) {
					if (horizontal) {
						scrollToHorizontal(bodyRef.current, now * oneHourWidth);
						scrollToVertical(bodyRef.current, 0);
					} else {
						scrollToHorizontal(bodyRef.current, 0);
						scrollToVertical(bodyRef.current, now * oneHourHeight);
					}
				} else {
					if (horizontal) {
						bodyRef.current.scrollTo({ left: now * oneHourWidth, top: 0, behavior: SCROLL_BEHAVIOR });
					} else {
						bodyRef.current.scrollTo({ left: 0, top: now * oneHourHeight, behavior: SCROLL_BEHAVIOR });
					}
				}
			}
		},
		[horizontal, mode]
	);

	const scrollTo = useCallback(() => {
		const { resourceId, startTime, endTime } = options?.scrollTo || {};
		const body = bodyRef?.current;

		if (!body) {
			return;
		}

		const resource = document.getElementById(`resource_${resourceId}`);

		let resourcePosition = 0;
		if (resource) {
			if (horizontal) {
				resourcePosition = resource.offsetTop;
			} else {
				resourcePosition = resource.offsetLeft + resource.clientWidth / 2;
			}
		}

		let hoursPosition = 0;
		if (startTime && endTime) {
			const date = dayjs(options?.date).format('DD/MM/YYYY');
			const parseFormat = 'DD/MM/YYYY HH:mm';

			const start = dayjs(`${date} ${startTime}`, parseFormat);
			let end = dayjs(`${date} ${endTime}`, parseFormat);
			if (dayjs(start).diff(end, 'millisecond') >= 0) {
				end = end.add(1, 'day');
			}

			const { top, height } = getTopAndHeight(start.toDate().getTime(), end.toDate().getTime(), horizontal);

			hoursPosition = top + height / 2;
			if (!horizontal) hoursPosition += height / 2;
		}

		const halfBodyWidth = body.clientWidth / 2;
		const halfHoursHeight = body.clientHeight / 2;

		if (isSafari) {
			if (horizontal) {
				if (hoursPosition) {
					scrollToHorizontal(body, hoursPosition - halfBodyWidth);
				}
				if (resourcePosition) {
					scrollToVertical(body, resourcePosition - halfHoursHeight);
				}
			} else {
				if (resourcePosition) {
					scrollToHorizontal(body, resourcePosition - halfBodyWidth);
				}
				if (hoursPosition) {
					scrollToVertical(body, hoursPosition - halfHoursHeight);
				}
			}
		} else {
			if (horizontal) {
				if (hoursPosition && resourcePosition) {
					body.scrollTo(hoursPosition - halfBodyWidth, resourcePosition - halfHoursHeight);
				} else if (hoursPosition) {
					body.scrollTo(hoursPosition - halfBodyWidth, body.scrollTop);
				} else if (resourcePosition) {
					body.scrollTo(body.scrollLeft, resourcePosition - halfHoursHeight);
				}
			} else {
				if (hoursPosition && resourcePosition) {
					body.scrollTo({
						left: resourcePosition - halfBodyWidth,
						top: hoursPosition - halfHoursHeight,
						behavior: SCROLL_BEHAVIOR,
					});
				} else if (resourcePosition) {
					body.scrollTo({ left: resourcePosition - halfBodyWidth, top: body.scrollTop, behavior: SCROLL_BEHAVIOR });
				} else if (hoursPosition) {
					body.scrollTo({ left: body.scrollLeft, top: hoursPosition - halfHoursHeight, behavior: SCROLL_BEHAVIOR });
				}
			}
		}
	}, [getTopAndHeight, horizontal, options?.date, options?.scrollTo, isSafari]);

	const bodyContainerHeight = getBodyContainerHeight(spaces, mode, horizontal);

	const cleanSpaces = useMemo(() => {
		return spaces.map((space: any) => {
			return { ...space, slots: [] };
		});
	}, [spaces]);

	const handleOptionsScrollPosition = useCallback(
		(scrollPositionFromOptions: { scrollTop: number; scrollLeft: number }) => {
			if (scrollPositionFromOptions.scrollTop || scrollPositionFromOptions.scrollLeft) {
				const body = bodyRef?.current;
				if (body) {
					body.scrollTo({
						left: scrollPositionFromOptions.scrollLeft,
						top: scrollPositionFromOptions.scrollTop,
						behavior: SCROLL_TO_BEHAVIOR,
					});
				}
			}
		},
		[]
	);

	useEffect(() => {
		if (!isLoading) {
			scrollTo();
			if (
				options?.mode === ECalendarMode.DAILY &&
				dayjs(options.date, EDateFormats.YYYY_MM_DD).isSame(dayjs(), 'day') &&
				!options.scrollPosition?.scrollTop &&
				!options.scrollPosition?.scrollLeft
			) {
				scrollToHour(
					Number(options.scrollTo?.startTime ? dayjs(options.scrollTo?.startTime).format('HH') : dayjs().format('HH'))
				);
			} else if (Boolean(options?.scrollPosition?.scrollTop) || Boolean(options?.scrollPosition?.scrollLeft)) {
				handleOptionsScrollPosition(options.scrollPosition as any);
			}
		}

		setOptions(options);
		setLastMode(mode);
	}, [handleOptionsScrollPosition, isLoading, mode, options, scrollTo, scrollToHour, setOptions]);

	useEffect(() => {
		if (slots && slots?.eventsToSpaces) {
			if (mode === ECalendarMode.DAILY && !options?.infiniteScrolling) {
				initiateEventsToSpaces({
					events: slots.events,
					eventsToSpaces: filterSlotsForDailyView(slots.eventsToSpaces, options?.date),
				});
			} else if (mode === ECalendarMode.DAILY) {
				initiateEventsToSpaces({
					events: slots.events,
					eventsToSpaces: filterSlotsForOldSlots(slots.eventsToSpaces, options?.date),
				});
			} else {
				initiateEventsToSpaces(slots);
			}
		}
	}, [initiateEventsToSpaces, mode, options?.date, options?.infiniteScrolling, slots]);

	useEffect(() => {
		let resizeObserver: ResizeObserver;

		if (bodyRef?.current) {
			resizeObserver = new ResizeObserver(data => {
				setWidth(data?.[0]?.contentRect?.width || 0);
			});
			resizeObserver.observe(bodyRef?.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, [view, options?.hourSize, bodyRef?.current, isLoading]);

	useEffect(() => {
		const debounce = (func: (...args: any[]) => void, wait: number) => {
			let timeout: NodeJS.Timeout;
			return (...args: any[]) => {
				clearTimeout(timeout);
				timeout = setTimeout(() => func(...args), wait);
			};
		};

		const handleScroll = (e: Event) => {
			const body = e.target as Element;

			if (headerRef.current && headerRef.current.scrollLeft !== body.scrollLeft) {
				headerRef.current.scrollLeft = body.scrollLeft;
			}
			if (verticalSpacesRef.current && verticalSpacesRef.current.scrollTop !== body.scrollTop) {
				verticalSpacesRef.current.scrollTop = body.scrollTop;
			}
			debouncedOnChange({
				type: 'SCROLL',
				data: {
					scrollTop: body.scrollTop ?? 0,
					scrollLeft: body.scrollLeft ?? 0,
				},
			});
		};

		const debouncedOnChange = debounce(onChange, 400);

		bodyRef?.current?.addEventListener('scroll', handleScroll, false);

		return () => {
			bodyRef?.current?.removeEventListener('scroll', handleScroll, false);
		};
	}, [mode, view]);

	return (
		<div css={cssProvider(colors, spaceWidth, mode === ECalendarMode.MONTHLY)} data-aid="SACalendar-calendar">
			<div css={rootContainer}>
				<AsideContainer
					bodyContainerHeight={bodyContainerHeight}
					verticalSpacesRef={verticalSpacesRef}
					width={width}
					spaces={spaces}
					ResourceGroupHeader={ResourceGroupHeader}
					groups={groups}
					resources={resources}
					selectedMonthlyResource={resourceId}
					mode={mode}
					view={view}
				/>

				<DndProvider
					DragOverlayComponent={DragOverlayComponent}
					onChange={val => {
						onChange(val);
					}}
					horizontal={horizontal}
				>
					<div css={dndProviderContainer(colors)} ref={bodyRef}>
						<HeaderContainer
							headerRef={headerRef}
							width={width}
							spaces={spaces}
							ResourceGroupHeader={ResourceGroupHeader}
							resourceId={resourceId}
							groups={groups}
							resources={resources}
							mode={mode}
							view={view}
						/>
						<div id="bodyContainer" css={bodyContainer(horizontal, width)}>
							<div css={bodyContent(colors, bodyContainerHeight * (!horizontal ? daysCount : 1), horizontal)}>
								{mode === ECalendarMode.DAILY &&
									(horizontal ? (
										<DailyViewHorizontal
											spaces={spaces}
											EventComponent={EventComponent}
											onAddNewSlotClick={onChange}
											isModeChanged={isModeChanged}
											isLoading={isLoading}
										/>
									) : (
										<DailyViewVertical
											spaces={cleanSpaces}
											EventComponent={EventComponent}
											isModeChanged={isModeChanged}
											onAddNewSlotClick={onChange}
											hourSize={options?.hourSize?.vertical}
											isLoading={isLoading}
										/>
									))}
								{mode === ECalendarMode.WEEKLY && (
									<WeeklyView spaces={spaces} EventComponent={EventComponent} isLoading={isLoading} />
								)}
								{mode === ECalendarMode.MONTHLY && (
									<MonthlyView
										spaceId={String(resourceId || spaces[0]?.id)}
										EventComponent={EventComponent}
										isLoading={isLoading}
									/>
								)}
							</div>
						</div>
					</div>
				</DndProvider>
			</div>
		</div>
	);
};

export const SACalendar = (props: ICalendarProps) => (
	<MiddlewareContextProvider onChange={props.onChange}>
		<Calendar {...props} />
	</MiddlewareContextProvider>
);
