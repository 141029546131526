/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { BnIcon, Button, EPickerTheme, Icons, SizeEnum } from '@bondsports/utils';

interface IProps {
	onAdd: () => void;
}

export const PlusButton = ({ onAdd }: IProps) => {
	return (
		<Button data-aid="button-PlusButton" sizer={SizeEnum.EXTRA_SMALL} theme={EPickerTheme.BASIC} onClick={onAdd}>
			<BnIcon icon={Icons.plus} />
		</Button>
	);
};
