/** @jsx jsx */

import React, { useEffect, useRef, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { useModal } from '@bondsports/utils';
import { Modal } from '../Modal';
import { TranslationEn } from 'assets/i18n/en';
import { colors } from '../../../styles/theme';
import { ReactSVG } from 'react-svg';
import { Button } from '@bondsports/utils';
import { useHistory } from 'react-router-dom';

const container = css`
	margin: 0 3rem;
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Montserrat';
	text-align: center;

	h1 {
		color: ${colors.brandPrimary};
		margin: 0.5rem 0;
		font-weight: bold;
		font-size: 2rem;
		line-height: 2.4rem;
	}

	h2 {
		font-size: 1.4rem;
		line-height: 130%;
		text-align: center;
		color: ${colors.formInputBg};
		margin: 0.5rem 0 3rem 0;
	}

	label {
		color: ${colors.dangerRed};
		overflow-x: hidden;
	}
`;

const marginTopCss = css`
	margin-top: 2rem;
`;

type Props = {
	onClose?: () => void;
};

export const useErrorModalDepricated = (props?: Props) => {
	const [isProcessing, setProcessing] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const { toggle: ErrorToggle, isShowing: isErrorModalShowing } = useModal();
	const history = useHistory();

	const handleError = (message: string, turnOffProcessing = true) => {
		if (turnOffProcessing) {
			setProcessing(false);
		}
		setError(message);
		ErrorToggle();
	};

	const opened = useRef(false);
	useEffect(() => {
		if (isErrorModalShowing) {
			opened.current = true;
		} else if (opened.current) {
			opened.current = false;
			props?.onClose?.();
		}
	}, [isErrorModalShowing]);

	const ErrorModalDepricated = ({
		message = '',
		title,
		goBack,
		approve,
		isDismissable = true,
	}: {
		title?: string;
		message?: string;
		goBack?: boolean;
		isDismissable?: boolean;
		approve?: boolean;
	}) => {
		const labels = TranslationEn.errors;
		return (
			<Modal
				showButton={isDismissable}
				isShowing={isErrorModalShowing}
				isDismissable={isDismissable}
				toggle={ErrorToggle}
			>
				<div css={container}>
					<ReactSVG src="assets/media/icons/conflict.svg" />
					<h1>{title ?? labels.somethingWentWrong}</h1>
					{message && <label>{message}</label>}
					{!goBack && !approve && (
						<h2>
							{labels.refresh}
							<br />
							{labels.tryLater}
						</h2>
					)}
					{goBack && !approve && (
						<Button
							data-aid="button-ErrorDepricated-back"
							css={marginTopCss}
							sizer="S"
							theme="primary"
							onClick={() => history.goBack()}
						>
							Go Back
						</Button>
					)}
					{approve && !goBack && (
						<Button
							data-aid="button-ErrorDepricated-approve"
							css={marginTopCss}
							sizer="S"
							theme="primary"
							onClick={() => history.goBack()}
						>
							{labels.approve.toUpperCase()}
						</Button>
					)}
				</div>
			</Modal>
		);
	};

	return {
		ErrorToggle,
		isErrorModalShowing,
		ErrorModalDepricated,
		handleError,
		isProcessing,
		setProcessing,
		error,
		setError,
	};
};
