/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { useColors } from '../../../hooks/useColors';
import { PaymentForm } from './paymentForm';
import { CheckoutCard } from './checkoutCard';
import { PAYMENT_STEPS } from '../../../types/enums/installments';
import { backgroundCss, bodyCss, checkoutCardContainerCss, invoiceWrapperCss, layoutCss } from './style';
import { UserPaymentMethod } from '../../../types/entities/payment'

export const InvoicingCheckoutLayout = ({
	memo,
	addPaymemtButtons,
	isApplyPaymentMethodCheckbox,
	header,
	paymentMethods,
	handlePay,
	organizationName,
	scheduleStepIncluded = false,
	handleDownload,
	state,
	labels,
	isLoading,
	selectedPaymentMethod,
	handleSelectPaymentMethod,
	handleSelectInstallment,
	handleDeslectAll,
	handleFetchInstallments,
	handleSelectAll,
	lastItemElementRef,
	isSelectAll,
	futureInstallments,
	paymentAmountInput,
	isPayButtonDisabled,
	isSelectUpcomingDisabled,
	installmentsInfo,
	amountToPay,
	resetFormValues,
	resetInstallmentsData,
	children = <Fragment />,
}: {
	memo: string;
	header: ReactNode;
	addPaymemtButtons: ReactNode;
	isApplyPaymentMethodCheckbox?: ReactNode;
	organizationName: string;
	paymentMethods: any[];
	isSelectUpcomingDisabled?: boolean;
	scheduleStepIncluded?: boolean;
	handlePay: (selectedMethod: UserPaymentMethod, onDone: () => void, onSuccess: () => void, amountToPay: number) => void;
	handleDownload: (callback: () => void) => void;
	state: any;
	labels: any;
	isLoading: boolean;
	selectedPaymentMethod: any;
	installmentsInfo?: {
		futureSelected: number;
		futureFetched: number;
		failedFetched: number;
		failedSelected: number;
		selectedIds: Set<number>;
		fetchedIds: Set<number>;
	};
	lastItemElementRef: ((node: HTMLDivElement) => void) | null;
	isSelectAll?: boolean;
	handleSelectAll: () => void;
	handleDeslectAll: () => void;
	handleSelectInstallment?: (installment: any) => void;
	handleFetchInstallments?: () => void;
	futureInstallments?: any[];
	handleSelectPaymentMethod: (v: UserPaymentMethod) => void;
	paymentAmountInput?: ReactNode;
	isPayButtonDisabled?: boolean;
	amountToPay: number;
	resetFormValues: () => void;
	resetInstallmentsData: () => void;
	children?: ReactNode;
}) => {
	const [currentStep, setCurrentStep] = useState(PAYMENT_STEPS.INVOICE);
	const { colors } = useColors();

	useEffect(() => {
		if (currentStep === PAYMENT_STEPS.SELECT_INSTALLMENTS && handleFetchInstallments && scheduleStepIncluded) {
			handleFetchInstallments();
		}
	}, [currentStep, handleFetchInstallments, scheduleStepIncluded]);

	const STEP_INCREMENT = 1;
	const STEP_DECREMENT = -1;

	const handleStepChange = useCallback((step) => {
		if (!scheduleStepIncluded) {
			setCurrentStep(step > 0 ? PAYMENT_STEPS.PAYMENT : PAYMENT_STEPS.INVOICE);
			return;
		}
		setCurrentStep(prev => prev + step);
	}, [scheduleStepIncluded]);

	const handleBack = () => handleStepChange(STEP_DECREMENT);
	const handleNext = () => handleStepChange(STEP_INCREMENT);

	const displayStep = useMemo(() => {
		switch (currentStep) {
			case PAYMENT_STEPS.SELECT_INSTALLMENTS:
			case PAYMENT_STEPS.PAYMENT:
				return (
					<PaymentForm
						isLoading={isLoading}
						handleBack={handleBack}
						installmentSelectionReset={resetInstallmentsData}
						paymentMethodSelectReset={resetFormValues}
						addPaymemtButtons={addPaymemtButtons}
						paymentMethods={paymentMethods}
						futureInstallments={futureInstallments}
						isSelectUpcomingDisabled={isSelectUpcomingDisabled}
						handleNext={handleNext}
						handlePay={(value, onDone) => {
							handlePay(value, onDone, successCallback, amountToPay);
						}}
						isApplyPaymentMethodCheckbox={isApplyPaymentMethodCheckbox}
						labels={labels}
						state={state}
						currentStep={currentStep}
						selectedPaymentMethod={selectedPaymentMethod}
						handleSelectInstallment={handleSelectInstallment}
						handleSelectAll={handleSelectAll}
						handleDeslectAll={handleDeslectAll}
						isSelectAll={isSelectAll}
						installmentsInfo={installmentsInfo}
						lastItemElementRef={lastItemElementRef}
						handleSelectPaymentMethod={handleSelectPaymentMethod}
						paymentAmountInput={paymentAmountInput}
						isPayButtonDisabled={isPayButtonDisabled}
						amountToPay={amountToPay}
					/>
				);
				break;
			default:
				return <Fragment>{children}</Fragment>;
		}
	}, [currentStep, state, installmentsInfo, lastItemElementRef, amountToPay, isSelectAll, selectedPaymentMethod, futureInstallments, paymentMethods, isLoading, paymentAmountInput, addPaymemtButtons, children]);

	const handlePayButton = () => {
		setCurrentStep(scheduleStepIncluded ? PAYMENT_STEPS.SELECT_INSTALLMENTS : PAYMENT_STEPS.PAYMENT);
	};

	const successCallback = () => {
		setCurrentStep(PAYMENT_STEPS.INVOICE);
		resetFormValues();
		resetInstallmentsData();
	};

	const isOnPaymentFlow = currentStep === PAYMENT_STEPS.SELECT_INSTALLMENTS || currentStep === PAYMENT_STEPS.PAYMENT;

	return (
		<div data-aid="invoiceCheckoutLayout" css={layoutCss(colors)}>
			<header>{header}</header>
			<div css={backgroundCss(colors)}>
				<div css={bodyCss}>
					<div css={checkoutCardContainerCss}>
						<CheckoutCard
							handlePayButton={handlePayButton}
							memo={memo}
							organizationName={organizationName}
							handleDownload={handleDownload}
							labels={labels}
							state={state}
							isLoading={isLoading}
							isPaymentPage={isOnPaymentFlow}
							isPaymentMethodSelected={!!selectedPaymentMethod}
						/>
					</div>
					<div css={invoiceWrapperCss}>{displayStep}</div>
				</div>
			</div>
		</div>
	);
};
