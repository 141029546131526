/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import moment from 'moment';
import { cellCss } from '../styles';

export const Age = ({ row, index, column, isLast }) => {
	let duration;
	if (row[column.id]) {
		duration = `${moment().diff(moment(row[column.id]), 'years', false)} yrs`;
	} else {
		duration = '-';
	}
	return (
		<td
		    data-aid="td-age"
			className="TableCell-root TableCell-body"
			css={cellCss({ isAction: column.action, isLast })}
			key={column.id}
			align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
			onClick={() => {
				if (column.action) {
					column.action(row);
				}
			}}
		>
			{duration}
		</td>
	);
};
