import React, { useEffect, useState } from 'react';
import { IGetPromoCodesFilters, StateEnum } from '../../types/promoCodes';
import { Typography } from '../../../../atoms';
import { ETypography, ETypographyColor } from '../../../../types';
import { Loader } from '../../../../components/Loader';
import { PromoCodeContent } from './promo-code-content';
import { IPromoCodeState } from '../../types';
import { i18n } from '../../../../lib/i18n';
import { IOption } from '../../../../../build/src';
import { FetchDataCallback } from '../../../../types/fetchData';
import { ERR, FIRST_PAGE, SELECT_ITEMS_PER_PAGE_DEFAULT } from '../../../../lib/constants';
import { ErrorComponent } from '../../../../components/Error';

interface PromoCodeTabProps {
	onChange: (promoCodeState: Partial<IPromoCodeState>) => void;
	initialState: IPromoCodeState;
	fetchData: FetchDataCallback<IOption, IGetPromoCodesFilters>;
}

export const PromoCodeTab: React.FC<PromoCodeTabProps> = ({ onChange, initialState, fetchData }: PromoCodeTabProps) => {
	const [componentState, setCurrentState] = useState<StateEnum>(
		initialState?.initialComponentState || StateEnum.LOADING
	);
	const labels = i18n.addDiscount.content.promoCode;

	useEffect(() => {
		if (componentState === StateEnum.LOADING) {
			fetchData({ page: FIRST_PAGE, itemsPerPage: SELECT_ITEMS_PER_PAGE_DEFAULT }).then(response => {
				if (ERR in response) {
					setCurrentState(StateEnum.ERROR);
				} else if (response.items?.length > 0) {
					onChange({
						initialOptions: response.items,
						initialComponentState: StateEnum.CODES,
						initialMeta: response.meta,
					});
					setCurrentState(StateEnum.CODES);
				} else {
					setCurrentState(StateEnum.NO_CODES);
				}
			});
		}
	}, []);

	switch (componentState) {
		case StateEnum.APPLIED:
			return (
				<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
					{labels.alreadyApplied}
				</Typography>
			);
		case StateEnum.LOADING:
			return <Loader />;
		case StateEnum.ERROR:
			return <ErrorComponent typographyType={ETypography.caption}>{labels.error}</ErrorComponent>;
		case StateEnum.NO_CODES:
			return (
				<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
					{labels.noValidCodes}
				</Typography>
			);
		case StateEnum.CODES:
			return (
				<>
					{initialState.initialOptions && initialState.initialMeta ? (
						<PromoCodeContent
							onChange={onChange}
							initialState={{
								...initialState,
								initialOptions: initialState.initialOptions,
								initialMeta: initialState.initialMeta,
							}}
							fetchData={fetchData}
						/>
					) : (
						<></>
					)}
				</>
			);
		default:
			return <div>{labels.defaultStateMessage}</div>;
	}
};
