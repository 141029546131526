import { PaymentMethodTypeEnum, UserPaymentMethod, UserPaymentMethodOption } from '@bondsports/types';
import { Icons } from '@bondsports/utils';

export const splitPaymentMethods = (paymentMethods: UserPaymentMethodOption[]) => {
	const creditCards: UserPaymentMethod[] = [].concat(
		...paymentMethods
			.filter(paymentMethod => paymentMethod.paymentMethodType === PaymentMethodTypeEnum.CARD)
			.map(pm => pm.options ?? [])
	);

	const ACHMethods: UserPaymentMethod[] = [].concat(
		...paymentMethods
			.filter(paymentMethod => paymentMethod.paymentMethodType === PaymentMethodTypeEnum.ACH)
			.map(pm => pm.options)
	);
	return { creditCards, ACHMethods };
};

export const mapPaymentMethod = (paymentMethod: UserPaymentMethod) => {
	const ICON_SIZE = 24;
	switch (paymentMethod?.paymentMethodType) {
		case PaymentMethodTypeEnum.CARD:
			return {
				icon: paymentMethod?.ccBrand as Icons,
				label: paymentMethod?.ccLast4 || '',
			};
		case PaymentMethodTypeEnum.ACH:
			return {
				icon: Icons.bank,
				label: paymentMethod?.ccLast4,
				iconSize: ICON_SIZE,
			};
		default:
			return {
				icon: undefined,
				label: '',
			};
	}
};
