import { useEffect, useState } from 'react';
import { IInvoiceTemplateState, IResponse, PaymentStatusEnum } from '../types';
import { generateLineItemsRows, generateTaxes } from '../lib';
import { roundWithDecimal } from '../../../lib/utils';
import { DiscountOnTypeEnum } from '../../../molecules/invoicing/types';

export const useInvoiceTemplate = (isPayment = false) => {
	const [rows, setRows] = useState([]);
	const [appendix, setAppendix] = useState([]);
	const [payments, setPayments] = useState([]);
	const [showVoid, setShowVoid] = useState(false);
	const [meta, setMeta] = useState({
		subtotal: 0,
		totalDue: 0,
		tax: {},
		total: 0,
		discounts: [],
	});

	const setShowVoidFirstTime = (state: IInvoiceTemplateState) => {
		if (
			!rows.length &&
			!appendix.length &&
			!payments.length &&
			!meta.subtotal &&
			!meta.totalDue &&
			!meta.total &&
			state.invoice.paymentStatus === PaymentStatusEnum.VOID
		) {
			setShowVoid(true);
		}
	};

	const calculateMeta = (generatedValues: IResponse, state: IInvoiceTemplateState, tax: any) => {
		const calculatedMeta: {
			subtotal: number;
			totalDue: number;
			tax: any;
			total: number;
			totalDiscountAmount: number;
			discounts: any[];
		} = {
			subtotal: state.invoice?.subtotal,
			totalDue: Number(state.invoice?.price) - state.invoice?.paidAmount,
			tax: tax,
			total: roundWithDecimal(state.invoice?.price - (!isPayment ? state.invoice?.totalFeesAmount ?? 0 : 0)),
			totalDiscountAmount: generatedValues.totalDiscountAmount,
			discounts: generatedValues.discounts
				? generatedValues.discounts?.map(
						(discount: {
							id: number;
							paymentId: number;
							name: string;
							percentage: number;
							amount: number;
							isEntitlement: boolean;
							isInvoice: boolean;
							isLineItemDiscount: boolean;
							reason: string;
							discountAmount: number;
							actionId: string;
							type: string;
						}) => {
							return {
								name: discount.name,
								precent: discount.percentage,
								price: discount.amount,
								isEntitlement: discount.isEntitlement,
								isInvoice: discount.isInvoice,
								isLineItemDiscount: discount.isLineItemDiscount,
								paymentId: discount.paymentId,
								id: discount.id,
								discountAmount: discount.discountAmount,
								actionId: discount.actionId,
								type: discount.type,
							};
						}
				  )
				: [],
		};
		return calculatedMeta;
	};

	const handleProcessData = (state: IInvoiceTemplateState, handleAddLineItemDiscount: (val: number[]) => void) => {
		setShowVoidFirstTime(state);
		const generatedValues = generateLineItemsRows({
			state,
			organizationId: Number(state.organization?.id),
			totalTax: {
				taxesExcl: {},
				taxesInc: {},
			},
			onAddDiscountLineItem: handleAddLineItemDiscount,
			showVoid,
		});

		setPayments(generatedValues.payments);
		setRows(generatedValues.items);
		setAppendix(generatedValues.appendixData);

		const tax: any = {};
		if (generatedValues.totalTax) {
			generateTaxes(generatedValues, tax);
		}
		const calculatedMeta = calculateMeta(generatedValues, state, tax);
		setMeta(calculatedMeta as any);
	};

	return {
		rows,
		payments,
		appendix,
		meta,
		showVoid,
		handleProcessData,
		setShowVoid,
	};
};
