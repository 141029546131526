/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { PaymentMethodContainer } from './paymentMethodContainer';
import { PaymentMethodTypeEnum, SelectablePaymentMethodType } from '../../../types/entities/payment';
import { flexCss, gapCss } from '../../../styles/utils';
import { ETypography, ETypographyColor } from '../../../types';
import { useColors } from '../../../hooks/useColors';
import { Typography } from '../../../atoms';
import { Pricify } from '../../../lib/price';
import { PaymentFlowFooter } from './paymentFlowFooter';
import { findFee } from '../../../organisms/invoiceTemplate/lib';
import { stepContainerCss, methodsWrapperCss, alignCenterCss } from './style';
import { PaymentFlowHeader } from './paymentFlowHeader';
import { UserPaymentMethod, SubPaymentMethodTypeEnum } from '../../../types/entities/payment';


export const PaymentStep = ({
	addPaymemtButtons,
	applyCheckbox,
	handlePay,
	paymentMethods,
	labels,
	state,
	selectedPaymentMethod,
	handleSelectPaymentMethod,
	isPayButtonDisabled,
	amountToPay,
	handleBack,
	remainingAmount,
}: {
	addPaymemtButtons: ReactNode;
	applyCheckbox: ReactNode;
	paymentMethods: UserPaymentMethod[];
	handlePay: (selectedPaymentMethod: any, onDone: () => void, amountToPay: number) => void;
	labels: any;
	state: any;
	selectedPaymentMethod?: UserPaymentMethod;
	handleSelectPaymentMethod: (v: UserPaymentMethod) => void;
	paymentAmountInput?: ReactNode;
	isPayButtonDisabled?: boolean;
	amountToPay: number;
	handleBack: () => void;
	remainingAmount: string;
}) => {
	const [isLoading, setLoading] = useState(false);
	const { colors } = useColors();
	const showGeneralFeeWarning = !!state.fees?.length;
	const selectedFee = findFee(state.fees ?? [], selectedPaymentMethod);
	const feePercentage = selectedFee?.percentageValue ? `${selectedFee.percentageValue}` : undefined;
	const fixedFee = `${selectedFee?.fixValue ?? ''}`;
	const isCreditCard = selectedPaymentMethod?.paymentMethodType === PaymentMethodTypeEnum.CARD;
	const typeWithFee =
		!isCreditCard && selectedPaymentMethod?.subPaymentMethodType &&
		![SubPaymentMethodTypeEnum.DEBIT, SubPaymentMethodTypeEnum.PREPAID, SubPaymentMethodTypeEnum.UNKNOWN].includes(
			selectedPaymentMethod.subPaymentMethodType
		);
	const showFeeWarning: boolean = !!(
		selectedPaymentMethod &&
		(feePercentage || fixedFee) &&
		!typeWithFee
	);
	const methodType: SelectablePaymentMethodType = selectedPaymentMethod?.paymentMethodType as SelectablePaymentMethodType;

	const handlePayClick = () => {
		setLoading(true);
		handlePay(selectedPaymentMethod, () => setLoading(false), amountToPay);
	};

	labels.next = `${labels.pay} ${Pricify(amountToPay)}`;

	return (
		<div css={stepContainerCss} data-aid="paymentMethodContainer-invoicingCheckoutLayout">
			<PaymentFlowHeader labels={labels} />
			<div css={methodsWrapperCss(colors)}>
				<div css={[flexCss, gapCss(18)]}>{addPaymemtButtons}</div>
				{showGeneralFeeWarning && (
					<div css={[flexCss, alignCenterCss]}>
						<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
							{labels.warnings.defaultFeeNotice}
						</Typography>
					</div>
				)}
			</div>
			<PaymentMethodContainer
				paymentMethods={paymentMethods}
				showFeeWarning={showFeeWarning}
				methodType={methodType}
				fixedFee={fixedFee}
				feePercentage={feePercentage ?? ''}
				labels={labels}
				selected={selectedPaymentMethod}
				handleSelect={handleSelectPaymentMethod}>
				{applyCheckbox}
				</PaymentMethodContainer>
			<PaymentFlowFooter
				labels={labels}
				isLoading={isLoading}
				isNextDisabled={isPayButtonDisabled}
				handleBack={handleBack}
				handleNext={handlePayClick}
				remainingAmount={remainingAmount}
			/>
		</div>
	);
};
