import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private auth: AuthenticationService) {}

	canActivate(): boolean {
		const isLoggedIn = this.auth.isSignedInAndUserDetailsSubmitted();

		if (!isLoggedIn) {
			const redirectUrl = new URL(environment.LOGIN_REDIRECT_URL);
			redirectUrl.searchParams.set('state', window.location.toString());

			window.location.assign(redirectUrl.toString());
			return false;
		}

		return true;
	}
}
