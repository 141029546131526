/** @jsxRuntime classic */
/** @jsx jsx */
import { AlignType, IColumn } from '../../table/types';
import { LineItemColumn } from './lineItemColumn';
import {
	ALLOWED_DISCOUNT_LINE_ITEM,
	BACKOFFICE_VARIANTS,
	DiscountOnTypeEnum,
	DiscountTypeEnum,
	ResourceNameTypeEnum,
} from '../types';
import { capitalize } from '../../../functions';
import { MembershipTypeEnum } from '../../../types/entities/membership';
import { EInvoiceVariant, PaymentStatusEnum, SUBLINE_ICON_TEXT } from '../../../organisms/invoiceTemplate/types';
import { MoreMenu } from '../../../components/more-menu-button';
import { i18n } from '../../../lib/i18n';
import { Pricify, roundPriceCents } from '../../../lib/price';
import { css, jsx } from '@emotion/react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { Fragment } from 'react';
import { BnIcon } from '../../../components/icons_v2';
import { inlineIconCss, sublineCss, textColCss } from '../../../organisms/invoiceTemplate/styles';
import { Button } from '../../../components/Button';
import { fullWidthCss } from '../../../styles/utils';

const typeMapper = {
	event: ResourceNameTypeEnum.EVENT,
	venue: ResourceNameTypeEnum.VENUE,
	team: ResourceNameTypeEnum.TEAM,
	league: ResourceNameTypeEnum.LEAGUE,
	user: ResourceNameTypeEnum.USER,
	organization: ResourceNameTypeEnum.ORGANIZATION,
	app: ResourceNameTypeEnum.APP,
	feed: ResourceNameTypeEnum.FEED,
	match: ResourceNameTypeEnum.MATCH,
	round: ResourceNameTypeEnum.ROUND,
	portal: ResourceNameTypeEnum.PORTAL,
	season: ResourceNameTypeEnum.SEASON,
	tournament: ResourceNameTypeEnum.TOURNAMENT,
	membership: ResourceNameTypeEnum.MEMBERSHIP,
	division: ResourceNameTypeEnum.DIVISION,
	gameslot: ResourceNameTypeEnum.GAMESLOT,
	space: ResourceNameTypeEnum.SPACE,
	reservation: ResourceNameTypeEnum.RESERVATION,
	invoice: ResourceNameTypeEnum.INVOICE,
	customer: ResourceNameTypeEnum.CUSTOMER,
	package: ResourceNameTypeEnum.PACKAGE,
	facility: ResourceNameTypeEnum.FACILITY,
	program: ResourceNameTypeEnum.PROGRAM,
	program_season: ResourceNameTypeEnum.PROGRAM_SEASON,
	product: ResourceNameTypeEnum.PRODUCT,
	group: ResourceNameTypeEnum.GROUP,
	variant: ResourceNameTypeEnum.VARIANT,
	registration: ResourceNameTypeEnum.EVENT,
	league_registration: ResourceNameTypeEnum.SEASON,
};

/**
 * Represents an EmptyHolder that is used to display an empty space in a React component.
 *
 * @returns {JSX.Element} The JSX element representing the empty space.
 */
const EmptyHolder = (): JSX.Element => <span>{'\u00A0'}</span>;

/**
 * Process the relational data of the line item and return a line item object for display on an invoice or payment
 * @returns {IDisplayLineItem} - Display line item object
 * @param lineItem
 * @param slots
 * @param showVoid
 */
export const expandLineItemData = (lineItem: any, slots?: any[], showVoid = true, withSubRow = true) => {
	const qty = showVoid ? lineItem.displayQuantity : lineItem.displayFullQuantityWithVoid;
	const purchasedResource = lineItem.purchasedResources?.[0];
	const productType: ResourceNameTypeEnum = typeMapper[lineItem.productType];

	const item = {
		name: lineItem.product.name,
		description: lineItem.paymentStatus === PaymentStatusEnum.VOID ? '-- Item voided --' : lineItem.product.description, // Todo - grouping - extract english
		secondDescription: '',
		img: lineItem.img || '',
		productType: lineItem.productType,
		isVoid: lineItem.paymentStatus === PaymentStatusEnum.VOID || lineItem.isVoided,
		productId: lineItem.productId,
		paymentStatus: lineItem.paymentStatus,
	};

	let timePeriod: string = '';
	const name = capitalize(`${lineItem?.customerFirstName ?? ''} ${lineItem?.customerLastName ?? ''}`.trim());
	switch (productType) {
		case ResourceNameTypeEnum.MEMBERSHIP:
			if (purchasedResource?.membership) {
				if (purchasedResource?.membership?.membershipType === MembershipTypeEnum.FIXED) {
					// TODO awaiting answers on display differences
					const years = Math.floor(purchasedResource?.membership?.durationMonths / 12);
					const months = purchasedResource?.membership?.durationMonths % 12;
					timePeriod = `${years > 0 ? `${years} year${years > 1 ? `s` : ``}` : ``} ${months > 0 ? months : ``} ${
						months > 1 ? `months` : months > 0 ? 'month' : ''
					}`.trim();
				}
				if (purchasedResource?.membership?.membershipType === MembershipTypeEnum.ROLLING) {
					// TODO awaiting answers on display differences
					const years = Math.floor(purchasedResource?.membership?.durationMonths / 12);
					const months = purchasedResource?.membership?.durationMonths % 12;
					timePeriod = `${years > 0 ? `${years} year${years > 1 ? `s` : ``}` : ``} ${months > 0 ? months : ``} ${
						months > 1 ? `months` : months > 0 ? 'month' : ''
					}`.trim();
				}
				item.description = capitalize(`${purchasedResource?.membership?.customerTypes?.[0] || ''}`);
				item.secondDescription = `${timePeriod || ''} \u2022 ${name || ''}`;
			}
			break;
		case ResourceNameTypeEnum.SEASON:
			if (purchasedResource?.leagueSeason) {
				item.description = capitalize(`${purchasedResource?.leagueSeason?.league?.name || ''}`);
				item.secondDescription = createSecondDescription(purchasedResource?.leagueSeason?.name, name);
			}
			break;
		case ResourceNameTypeEnum.EVENT:
			if (purchasedResource?.event) {
				item.description = capitalize(`${purchasedResource?.event?.parentSession?.program?.name || ''}`);
				item.secondDescription = createSecondDescription(purchasedResource?.event?.parentSession?.name, name);
			}
			if (purchasedResource?.programSeason) {
				item.description = capitalize(`${purchasedResource?.programSeason?.program?.name || ''}`);
				item.secondDescription = createSecondDescription(purchasedResource?.programSeason?.name, name);
			}
			break;
		case ResourceNameTypeEnum.SPACE:
		case ResourceNameTypeEnum.RESERVATION:
			if (slots?.[0]?.reservation || purchasedResource?.space)
				item.description = capitalize(`${slots?.[0]?.reservation?.name || purchasedResource?.space?.name}`);
			break;
		case ResourceNameTypeEnum.PRODUCT:
		default:
			break;
	}

	const lineItemTotalPrice = getTotalLineItemPrice(lineItem, showVoid);
	const subRows = withSubRow ? createSubRow(lineItem, lineItemTotalPrice) : [];

	const isDiscountOptionAvailable = ALLOWED_DISCOUNT_LINE_ITEM.includes(lineItem?.paymentStatus);

	return {
		item,
		quantity: qty, // temp solution -> we need to figure out how to handle qty with events
		price: lineItem.unitPrice || 0,
		paidAmount: lineItem?.displayTotalPaid || lineItem?.totalPaid, // Todo - grouping - why it is not merge
		status: lineItem.paymentStatus, // Todo - grouping - why it is not merge
		tax: lineItem?.taxPrecent ? `${lineItem?.taxPrecent}% ${lineItem?.isTaxInclusive ? 'inc' : ''}` : '-', // Todo - label - extract english out
		subtotal: lineItemTotalPrice,
		total: lineItemTotalPrice,
		more: isDiscountOptionAvailable ? lineItem.more : [],
		subRows: subRows,
	};
};

const createDiscoutLineItems = (lineItem: any, lineItemTotalPrice: number): any[] => {
	let discountLines: string | any[] = [];
	if (lineItem?.discounts?.length) {
		let discountTotal = 0;
		let paymentId = 0;
		discountLines = [...lineItem.discounts]?.map((discount: any) => {
			if (discount?.paymentId !== paymentId) {
				paymentId = discount.paymentId;
			}
			discountTotal += roundPriceCents(discount.subTotal ?? discount.discountAmount);
			const isLineItemDiscount: boolean = discount.discountOn === DiscountOnTypeEnum.LINE_ITEM;

			return {
				quantity: roundPriceCents(discount.quantity),
				text: `${isLineItemDiscount ? SUBLINE_ICON_TEXT : ''}${discount.reason ?? ''}${
					discount.discountPercentage ? ` (${discount.discountPercentage}%)` : ''
				}`,
				price: Pricify(discount.unitPrice, undefined, undefined, true),
				/* We add the discount to the line item total price to get the total price with discount because discounts
				have a negative price value */
				total: Pricify(roundPriceCents(lineItemTotalPrice + discountTotal), undefined, undefined, true),
				more: {
					paymentId: isLineItemDiscount ? paymentId : undefined,
					paymentStatus: lineItem.paymentStatus,
					actionId: isLineItemDiscount ? discount.actionId : undefined,
					discount: discount.discount,
				},
			};
		});
	}
	return discountLines;
};

const createTaxLineItem = (lineItem: any): any => {
	return lineItem.taxLineItem?.price !== 0
		? {
				text: `Tax (${lineItem.taxLineItem?.taxPrecent ?? 0}%)${lineItem.taxLineItem?.isTaxInclusive ? ' inc' : ''}`, // Todo - grouping - move english outside
				price: `${Pricify(lineItem.displayUnitTax ?? 0, undefined, undefined, true)}${
					lineItem.taxLineItem?.isTaxInclusive ? ' (inc)' : ''
				}`, // Todo - grouping - move english outside
				total: lineItem.taxLineItem?.isTaxInclusive
					? ''
					: `${Pricify(lineItem.taxLineItemTotal, undefined, undefined, true)}`,
		  }
		: null;
};

const createSubRow = (lineItem: any, lineItemTotalPrice: number): any[] => {
	const discountLines: any[] = createDiscoutLineItems(lineItem, lineItemTotalPrice);
	const taxLineItem = createTaxLineItem(lineItem);

	const subRows = [];

	if (discountLines?.length) subRows.push(...discountLines);
	if (taxLineItem) subRows.push(taxLineItem);

	return subRows;
};

const getTotalLineItemPrice = (lineItem: any, showVoid = true): number => {
	if (showVoid) {
		return lineItem.displayFullPrice;
	}
	return lineItem.displayFullPriceWithVoid;
};

/**
 * Create second description line for line item on invoice or payment display
 * @param {string} desc - Product description (will be truncated)
 * @param {string} customerName - Customer name (will NOT be truncated)
 * @returns {string} - Description \u2022 Customer name with the description being truncated if needed
 */
const createSecondDescription = (desc: string, customerName: string): string => {
	const name = capitalize(customerName);
	return `${capitalize(desc || '').slice(0, 30 - name.length)}${
		name.length + (desc.length || 0) + 3 > 30 ? '...' : ''
	} \u2022 ${name}`;
};

export const extractInlineIcon = (val: any) => {
	const inStringIconRegex = /{{(.*?)}}/;
	const inStringIconString = inStringIconRegex.exec(String(val))?.[1];
	// replace the icon string with an empty string so that the icon is not displayed twice including the {{}}
	if (Boolean(inStringIconString)) val = String(val).replace(`{{${String(inStringIconString)}}}`, '');
	return { val, inStringIconString };
};

export const subRowsColumns = (
	isMobile = false,
	variant = EInvoiceVariant.CONSUMER,
	onRemoveDiscount = (discountGroupId: string) => {},
	discountVisibility = {
		customDiscount: true,
		promoCode: true,
	},
): IColumn[] => {
	let columns = [];
	if (isMobile) {
		columns = [
			{
				id: 'text',
				label: '',
				styling: { align: AlignType.LEFT },
				type: 'custom',
				component: (val: any) => {
					const extracted = extractInlineIcon(val);
					val = extracted.val;
					const inStringIconString = extracted.inStringIconString;
					return (
						<div css={textColCss()}>
							{Boolean(inStringIconString) ? (
								<div css={inlineIconCss}>
									<BnIcon icon={inStringIconString} />
								</div>
							) : (
								<Fragment />
							)}
							<Typography type={ETypography.body2} color={ETypographyColor.primary}>
								{val}
							</Typography>
						</div>
					);
				},
			},
			{
				id: 'price',
				label: '',
				styling: { align: AlignType.LEFT },
				type: 'string',
			},
			{
				id: 'total',
				label: '',
				styling: { align: AlignType.LEFT },
				type: 'string',
			},
		];
	} else {
		columns = [
			{
				id: 'text',
				label: '',
				styling: { align: AlignType.LEFT },
				type: 'custom',
				component: (val: any) => {
					const inStringIconRegex = /{{(.*?)}}/;
					const inStringIconString = inStringIconRegex.exec(String(val))?.[1];
					// replace the icon string with an empty string so that the icon is not displayed twice including the {{}}
					if (Boolean(inStringIconString)) val = String(val).replace(`{{${String(inStringIconString)}}}`, '');
					return (
						<div css={textColCss()}>
							{Boolean(inStringIconString) ? (
								<div css={inlineIconCss}>
									<BnIcon icon={inStringIconString} />
								</div>
							) : (
								<Fragment />
							)}
							<Typography type={ETypography.body2} color={ETypographyColor.primary}>
								{val}
							</Typography>
						</div>
					);
				},
			},
			{
				id: 'quantity',
				label: '',
				styling: { align: AlignType.LEFT },
				type: 'string',
			},
			{
				id: 'price',
				label: '',
				styling: { align: AlignType.LEFT },
				type: 'string',
			},
			{
				id: 'total',
				label: '',
				styling: { align: AlignType.LEFT },
				type: 'string',
			},
		];
	}
	if (BACKOFFICE_VARIANTS.includes(variant) && (discountVisibility.customDiscount || discountVisibility.promoCode)) {
		columns.push({
			id: 'more',
			label: '',
			type: 'custom',
			styling: { align: AlignType.CENTER },
			component: (state: {
				paymentId: number;
				actionId: string;
				paymentStatus: PaymentStatusEnum;
				discount?: { type: string };
			}) => {
				return (
					<div css={fullWidthCss}>
						{Boolean(state?.actionId) ? (
							<Button data-aid="button-invoiceDetails-do" sizer="S" theme="basic" css={sublineCss}>
								<BnIcon onClick={() => onRemoveDiscount(state.actionId)} icon={Icons.indications_cancelled} />
							</Button>
						) : (
							<EmptyHolder />
						)}
					</div>
				);
			},
		});
	}

	return columns as IColumn[];
};

export const calculateColumns = (
	labels: any,
	isLoading: boolean,
	isMobile: boolean,
	variant = EInvoiceVariant.CONSUMER,
	discountVisibility = {
		customDiscount: true,
		promoCode: true,
	},
): IColumn[] => {
	let columns = [];
	if (isMobile) {
		columns = [
			{
				id: 'item',
				label: labels.item,
				type: 'custom',
				styling: { align: AlignType.LEFT },
				component: (state: any) => {
					return <LineItemColumn {...state} isLoading={isLoading} />;
				},
			},
			{
				id: '',
				label: '',
				type: 'custom',
				component: () => {
					return <span>{'\u00A0'}</span>;
				},
			},
			{
				id: 'subtotal',
				label: labels.total,
				type: 'currency',
				styling: { align: AlignType.RIGHT },
			},
		];
	} else {
		columns = [
			{
				id: 'item',
				label: labels.item,
				type: 'custom',
				styling: { align: AlignType.LEFT },
				component: (state: any) => {
					return <LineItemColumn {...state} isLoading={isLoading} />;
				},
			},
			{
				id: 'quantity',
				label: labels.quantity,
				styling: { align: AlignType.LEFT },
				type: 'string',
			},
			{
				id: 'price',
				label: labels.price,
				styling: { align: AlignType.LEFT },
				type: 'currency',
			},
			{
				id: 'subtotal',
				label: labels.total,
				styling: { align: AlignType.LEFT },
				type: 'currency',
			},
		];
	}
	if (BACKOFFICE_VARIANTS.includes(variant) && (discountVisibility.customDiscount || discountVisibility.promoCode)) {
		columns.push({
			id: 'more',
			label: '',
			type: 'custom',
			styling: { align: AlignType.CENTER },
			component: (state: { lineItemsIds: number[]; onAddDiscountLineItem: (va: number[]) => void }) => {
				return (
					<Fragment>
						{Boolean(state?.lineItemsIds?.length) ? (
							<MoreMenu
								options={[
									{
										label: i18n.addDiscount.lineItemMenu,
										value: 'discount',
									},
								]}
								handleActions={() => state.onAddDiscountLineItem(state.lineItemsIds)}
							/>
						) : (
							<EmptyHolder />
						)}
					</Fragment>
				);
			},
		});
	}

	return columns as IColumn[];
};
