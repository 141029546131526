/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { FC, Fragment, memo } from 'react';
import { useMiddlewareContext } from '../hooks/useMiddlewareContext';
import {
	headerComponentContainerSpace,
	renderHoursContainer,
	renderHoursSeparator,
	renderSpacesHorizontalView,
} from '../styles';
import { ECalendarMode, ECalendarView, IAsideContainerProps } from '../types';
import { asideContainer, spaceHeight, timeBoxWidth } from './styles';
import { TimeLine } from './Timeline';

export const AsideContainer: FC<IAsideContainerProps> = memo(
	({
		width,
		verticalSpacesRef,
		spaces,
		ResourceGroupHeader,
		bodyContainerHeight,
		groups,
		resources,
		selectedMonthlyResource,
	}) => {
		const { options } = useMiddlewareContext();
		const { mode, view = ECalendarView.VERTICAL } = options || {};
		const horizontal = view === ECalendarView.HORIZONTAL;

		if (mode === ECalendarMode.MONTHLY) return null;

		return (
			<div ref={verticalSpacesRef} css={asideContainer(mode, horizontal)} data-aid="AsideContainer-atoms">
				<div css={horizontal ? renderSpacesHorizontalView(bodyContainerHeight) : renderHoursContainer}>
					{horizontal ? (
						<Fragment>
							<div css={renderHoursSeparator(horizontal)} />
							{spaces.map(space => (
								<div
									key={space.id}
									id={`resource_${space.id}`}
									css={headerComponentContainerSpace(
										spaceHeight * (space.children.length || 1),
										space.children.length || 1
									)}
								>
									<ResourceGroupHeader
										resources={resources}
										state={space}
										view={view}
										selectedMonthlyResource={selectedMonthlyResource}
										mode={mode}
										groups={groups}
									/>
								</div>
							))}
						</Fragment>
					) : (
						<TimeLine width={width + timeBoxWidth} />
					)}
				</div>
			</div>
		);
	}
);
