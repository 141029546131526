/** @jsx jsx */
import React, { ReactElement, ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import { Icons, Tooltip, BnIcon } from '@bondsports/utils';
import { colors } from '../../../styles/theme';
import { marginBottomCss } from '@app/react/styles/utils';

const frameContainerCss = (height: number | string, width: number, margin: string) => css`
	background: white;
	box-shadow: 0px 2px 22px rgba(21, 93, 148, 0.0749563);
	padding: 2rem;
	width: ${width}%;
	margin: ${margin};
	height: ${typeof height === 'number' ? `${height}px` : height};
	border-radius: 4px;
`;

const titleCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 20px;
	margin: 0;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: ${colors.brandPrimary};
`;

const buttonContainerCss = css`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const headerCss = (withoutHeaderBorder: boolean, headerPadding: string, withHeaderBottomMargin: boolean) => css`
	display: flex;
	margin: 0;
	${withHeaderBottomMargin && marginBottomCss(withoutHeaderBorder ? 2 : 10)};
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-bottom: ${headerPadding};
	border-bottom: ${withoutHeaderBorder ? 'none' : `1px solid ${colors.borderPrimary}`};
`;

const infoCss = css`
	position: relative;
	display: flex;
	align-items: center;
	div {
		display: flex;
	}
	svg {
		margin-left: 8px;
		width: 16px;
		height: 16px;
		color: ${colors.formInputBg};
	}
`;

interface IProps {
	title: string | ReactElement;
	button?: ReactElement;
	additionalButton?: ReactElement;
	height?: number | string;
	dataAid?: string;
	info?: string;
	width?: number;
	margin?: string;
	headerPadding?: string;
	withoutHeaderBorder?: boolean;
	children: ReactNode;
	withHeaderBottomMargin?: boolean;
}
export const FrameContainer = ({
	children,
	title,
	dataAid,
	info,
	button,
	additionalButton,
	height = 'auto',
	width = 100,
	margin = '0 0 2rem 0',
	withoutHeaderBorder = false,
	headerPadding = '2rem',
	withHeaderBottomMargin = true,
}: IProps) => {
	return (
		<div data-aid={dataAid} css={frameContainerCss(height, width, margin)}>
			{title && (
				<div css={headerCss(withoutHeaderBorder, headerPadding, withHeaderBottomMargin)}>
					<h2 css={titleCss} data-testid="frame-container-title">
						{title}
						{info && (
							<div css={infoCss}>
								<Tooltip content={info}>
									<BnIcon icon={Icons.info} />
								</Tooltip>
							</div>
						)}
					</h2>
					<div css={buttonContainerCss}>
						{button}
						{additionalButton}
					</div>
				</div>
			)}
			<div className="childClass">{children}</div>
		</div>
	);
};
