/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { usePurchase } from '../../../hooks/purchase/usePurchase';
import { TranslationEn } from '@assets/i18n/en';
import { paymentApi } from '@app/react/lib/api/paymentApi';
import { FuturePaymentStatusEnum, PaymentMethodTypeEnum, UserPaymentMethod, UserPaymentMethodOption } from '@bondsports/types';
import { ContactMethodEnum } from '@bondsports/utils';
import { useToggle } from '@app/react/hooks/useToggle';
import { IPurchaseRes } from '@app/react/types/orders';
import { Charge, FlowType } from '../Charge';
import { TChargeScheduled, TPrintDetails } from '@app/react/hooks/purchase/types';
import { roundPriceCents } from '../../../lib/pricing';
import { useStation } from '@app/react/hooks/useStation';
import { buildChargeInstallmentPayload } from '../utils/chargeInstallment';
import { useReceipt } from '@app/react/hooks/useReceipt';
import { ICustomer } from '@app/react/types/customers';

const labels = TranslationEn.customers.chargeInstallments;

const selectPaymentMethodsContainer = css`
	position: relative;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

interface IProps {
	toggle: () => void;
	userId: number;
	invoiceId: number;
	handleNext: () => void;
	handleBack: () => void;
	selectedIds: number[];
	setIsProcessing: (value: boolean) => void;
	isProcessing: boolean;
	ErrorToggle: () => void;
	setSelectedCustomer: (value: ICustomer) => void;
	setError: (value: string) => void;
	initialPaymentMethods: UserPaymentMethodOption[];
	initialPrice: number;
	isApplyPaymentMethodDisabled: boolean;
	isSelectAll: boolean;
	customer: ICustomer;
	organizationId: number;
}

const ChargeInstallmentStep = ({
	userId,
	toggle,
	invoiceId,
	organizationId,
	selectedIds,
	initialPrice,
	isSelectAll,
	setSelectedCustomer,
	ErrorToggle,
	isApplyPaymentMethodDisabled,
	setIsProcessing,
	setError,
	initialPaymentMethods,
	customer,
	handleNext,
	handleBack,
	isProcessing,
}: IProps) => {
	const [feeAmount, setFeeAmount] = useState<number>(0);
	const [isShowingDoneConfirmation, DoneConfirmationToggle] = useToggle();
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<UserPaymentMethod>(initialPaymentMethods[0]?.options?.[0]);
	const [finalPrice, setFinalPrice] = useState<number>(initialPrice || 0);
	const { shiftId } = useStation();
	const { handleChargeScheduled } = usePurchase();
	const { handleSendReceipt: sendReceipt, printReceipt } = useReceipt();
	const [printDetails, setPrintDetails] = useState<TPrintDetails>({
		customerId: 0,
		paymentId: 0,
	});
	const handlePrintReceipt = () => {
		printReceipt(String(printDetails.paymentId), printDetails.customerId);
	};
	const handleSendReceipt = (contactMethod: ContactMethodEnum, sendAddress: string) => {
		sendReceipt([printDetails.paymentId], contactMethod, sendAddress);
	};

	const getFinalPrice = async () => {
		if (initialPrice !== 0) {
			await paymentApi
				.getRecalcFee(
					organizationId,
					selectedPaymentMethod?.paymentMethodType || PaymentMethodTypeEnum.CARD,
					roundPriceCents(initialPrice),
					selectedPaymentMethod?.subPaymentMethodType
				)
				.then(res => {
					setFinalPrice(res.totalPrice);
					setFeeAmount(res.feeAmount);
				})
				.finally(() => {
					setIsProcessing(false);
				});
		} else {
			setFinalPrice(initialPrice);
		}
	};
	useEffect(() => {
		getFinalPrice();
	}, [selectedPaymentMethod, selectedIds, organizationId, isSelectAll]);

	const handleSelectPaymentMethod = (paymentMethod?: UserPaymentMethod) => {
		if (paymentMethod) {
			setSelectedPaymentMethod(paymentMethod);
		}
		setIsProcessing(true);
	};

	const handleCharge = async ({ token, type, applyPaymentMethodToFuture }) => {
		setIsProcessing(true);
		const payload: TChargeScheduled = buildChargeInstallmentPayload({
			userId: customer?.entityId,
			invoiceId,
			organizationId,
			token,
			type,
			shiftId,
			isSelectAll,
			selectedIds,
			applyForFuture: applyPaymentMethodToFuture
		});
		try {
			const response = await handleChargeScheduled(payload);
			const errorCheck = (response as any).err;
			if (errorCheck) {
				throw new Error(errorCheck);
			}
			switch (response.status) {
				case FuturePaymentStatusEnum.FAILED:
					handlePurchaseError(response);
					break;
				default: {
					const customerRes = response.customer;
					if (customerRes && Object.keys(customer).length) {
						setSelectedCustomer({
							...customer,
							storedCredit: customerRes.storedCredit,
						});
					}
					const payingUserId = response.payment?.payingUserId;
					const paymentId = response.payment?.id;
					setPrintDetails({ customerId: payingUserId, paymentId });
					break;
				}
			}
		} catch (err) {
			handlePayError(err);
			handleNext();
			ErrorToggle();
		} finally {
			setIsProcessing(false);
		}
	};
	const handlePayError = (err: string) => {
		setError(String(err));
		ErrorToggle();
		handleErrorCleanup();
	};

	const handlePurchaseError = (response: IPurchaseRes) => {
		handleErrorCleanup();
	};
	const handleErrorCleanup = () => {
		handleBack();

		if (isShowingDoneConfirmation) {
			DoneConfirmationToggle();
		}
	};
	return (
		<div css={selectPaymentMethodsContainer}>
			<Charge
				toggle={handleBack}
				totalAmount={finalPrice}
				originalPrice={finalPrice}
				userId={customer?.entityId}
				customer={customer}
				organizationId={organizationId}
				isAmountEditable={false}
				alternativeHandleCharge={handleCharge}
				handleClose={toggle}
				invoiceId={invoiceId}
				isScheduled={false}
				initialLabels={labels}
				showFeeWarning={true}
				isProcessing={isProcessing}
				feeAmount={feeAmount}
				showApplyMethodCheckbox
				isApplyPaymentMethodDisabled={isApplyPaymentMethodDisabled}
				paymentMethods={initialPaymentMethods}
				onSelectPaymentMethod={handleSelectPaymentMethod}
				alternativeHandlePrintReceipt={handlePrintReceipt}
				alternativeHandleSendReceipt={handleSendReceipt}
				flowType={FlowType.SCHEDULED}
			/>
		</div>
	);
};
export default ChargeInstallmentStep;
