/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { FC } from 'react';
import { useMiddlewareContext } from '../hooks/useMiddlewareContext';
import { headerComponentContainer, headerContainer, headerContent } from '../styles';
import { ECalendarMode, ECalendarView, IHeaderContainerProps } from '../types';
import { MonthlyHeaderContainer } from './monthly/MonthlyHeaderContainer';
import { timeBoxWidth } from './styles';
import { TimeLine } from './Timeline';

export const HeaderContainer: FC<IHeaderContainerProps> = ({
	width,
	headerRef,
	spaces,
	ResourceGroupHeader,
	resourceId,
	resources,
	groups,
}) => {
	const { options } = useMiddlewareContext();
	const { mode, view = ECalendarView.VERTICAL } = options || {};
	const horizontal = view === ECalendarView.HORIZONTAL;

	if (mode == ECalendarMode.MONTHLY) {
		let space = resourceId && spaces.find(item => Number(item.id) === Number(resourceId));

		if (!space && spaces[0]) {
			space = spaces[0];
		}

		if (space && space.children?.length > 0) {
			space = { ...space.children[0], children: [] };
		}

		return space ? (
			<MonthlyHeaderContainer
				width={width}
				headerRef={headerRef}
				ResourceGroupHeader={ResourceGroupHeader}
				space={space}
				resources={resources}
				view={view}
				mode={mode}
				groups={groups}
			/>
		) : null;
	}

	return (
		<div css={headerContainer(width, horizontal)} data-aid="HeaderContainer-atoms">
			<div ref={headerRef} css={headerContent(horizontal)}>
				{horizontal ? (
					<TimeLine width={width + timeBoxWidth} />
				) : (
					spaces.map(space => (
						<div
							key={space.id}
							id={`resource_${space.id}`}
							css={headerComponentContainer(
								space.children.length || 1,
								spaces.reduce((acc, { children }) => acc + (children.length || 1), 0)
							)}
						>
							<ResourceGroupHeader
								resources={resources}
								state={space}
								view={view}
								selectedMonthlyResource={resourceId}
								mode={mode}
								groups={groups}
							/>
						</div>
					))
				)}
			</div>
		</div>
	);
};
