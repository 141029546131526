/** @jsx jsx */
import React, { Fragment, useCallback, useState } from 'react';
import { jsx } from '@emotion/react';
import * as dayjs from 'dayjs';
import { GenderEnum, MediaDto } from '@bondsports/types';
import { useErrorModalDepricated } from '../../components/shared/ErrorDepricated';
import { useColorCode } from '../../hooks/useColorCode';
import { useCustomer } from '../../components/customers/customerPage/hooks/useCustomer';
import { customersApi } from '../../lib/api/customersApi';
import { checkIsDependentCustomer, getEmergencyContactDetails } from '../../components/customers/lib';
import { EDateTimeFormats } from '../../types/times';
import { ICustomer } from '../../types/customers';
import { CustomerDetailsForm } from './CustomerDetailsForm';

interface IDetails extends ICustomer {
	//TBD check if theres a better interface to extend
	id: number;
	firstName: string;
	lastName: string;
	gender: GenderEnum;
	birthDate: string;
	phoneNumber: string;
	email: string;
	street: string;
	city: string;
	zip: string;
	state: string;
	colorCodeId: number;
	emergencyContactName?: string;
	emergencyContactPhone?: string;
}

interface Props {
	state: ICustomer;
	toggle: () => void;
	organizationId: number;
	customerId: number;
	editColorModalToggle: () => void;
	newMainMedia?: MediaDto;
	onDirtyChange: (isDirty: boolean) => void;
}

export const EditCustomerDetailsForm = ({ state, toggle, organizationId, customerId, editColorModalToggle, newMainMedia, onDirtyChange }: Props) => {
	const [error, setError] = useState('');
	const { ErrorModalDepricated, ErrorToggle } = useErrorModalDepricated();
	const { getDefaultColorCodeId } = useColorCode();
	const { fetchCustomerData } = useCustomer();
	const emergencyContact = getEmergencyContactDetails(state.emergencyContacts);

	const isDependentCustomer = checkIsDependentCustomer(state);

	const handleSubmit = async (organizationId, entries) => {
		const response = await customersApi.updateCustomer(organizationId, customerId, entries as ICustomer);
		return response;
	};

	const prepareData = useCallback((data: IDetails) => {
		return {
			id: data.id,
			firstName: data.firstName,
			lastName: data.lastName,
			gender: data.gender,
			birthDate: data.birthDate,
			phoneNumber: data.phoneNumber,
			email: data.email,
			street: data.street,
			city: data.city,
			zip: data.zip,
			state: data.state,
			colorCodeId: +data.colorCodeId ? +data.colorCodeId : null,
			emergencyContactName: data.emergencyContactName,
			emergencyContactPhone: data.emergencyContactPhone,
			mainMedia: newMainMedia,
		};
	}, [newMainMedia]);

	const triggerEditColorModalIfColorChanged = (colorCodeId?: number) => {
		if (colorCodeId !== state.colorCodeId) {
			editColorModalToggle();
		}
	};

	const submit = useCallback(
		async entries => {
			const newData = prepareData(entries);
			handleSubmit(organizationId, newData)
				.then(res => {
					if (res.data) {
						fetchCustomerData({
							organizationId: organizationId,
							customerId: res.data.id,
							isDisableTriggerLoader: true,
						});
						toggle();
						triggerEditColorModalIfColorChanged(entries.colorCodeId);
					} else {
						setError(String(res.errorMessage));
						ErrorToggle();
					}
				})
				.catch(err => {
					setError(String(err.message));
					ErrorToggle();
				});
		},
		[organizationId, prepareData]
	);

	const generatedState = () => {
		const newState = {
			...state.address,
			...state,
			emergencyContactName: emergencyContact?.name,
			emergencyContactPhone: emergencyContact?.phoneNumber,
		};
		delete newState.emergencyContacts;

		Object.keys(state).forEach(key => {
			if (!state[key]) {
				delete newState[key];
			}
		});
		// there is dirty data with 'null' as phone number
		if (!Number(newState.phoneNumber)) {
			delete newState.phoneNumber;
		}
		const initialValues = {
			...newState,
			birthDate:
				newState.birthDate && dayjs(newState.birthDate).isValid()
					? dayjs(newState.birthDate).format(EDateTimeFormats.YYYY_MM_DD)
					: '',
			colorCodeId: newState.colorCodeId || getDefaultColorCodeId(),
		};
		return initialValues;
	};

	return (
		<Fragment>
			<ErrorModalDepricated message={error} />
			
			<CustomerDetailsForm
				initialValues={generatedState()}
				onCancel={toggle}
				onSubmit={submit}
				onDirtyChange={onDirtyChange}
				isDependentCustomer={isDependentCustomer}
			/>
		</Fragment>
	);
};