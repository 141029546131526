/** @jsxRuntime classic */
/** @jsx jsx */

import { useCallback } from 'react';
import { css, jsx } from '@emotion/react';
import { IAddNewSlotButtonProps, IAddNewSlotButtonsContainerProps } from '../../types';
import { DEFAULT_NEW_SLOT_DURATION, DEFAULT_NEW_SLOT_HOUR_SIZE, getTimeFromMinutes } from '../../utils/timeUtils';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { getNumberOfSlotsByDuration, getSizeOfSlots } from '../../utils';

const baseCss = css`
	position: absolute;
	border: none;
	&:hover {
		border: 1px solid #dae6f0;
	}
`;

export const AddNewSlotButton = ({ index, parentId, size, horizontal, duration, onClick }: IAddNewSlotButtonProps) => {
	const verticalCss = css`
		${baseCss};
		height: ${size}px;
		width: 100%;
		top: ${index * size + 7}px;
	`;
	const horizontalCss = css`
		${baseCss};
		width: ${size}px;
		height: 100%;
		left: ${index * size}px;
	`;

	const divCss = horizontal ? horizontalCss : verticalCss;

	const handleClick = useCallback(() => {
		if (!onClick) {
			return;
		}
		onClick({
			type: 'NEW_BOOKING',
			data: {
				parentId: parentId,
				startTime: getTimeFromMinutes(index * duration),
				endTime: getTimeFromMinutes((index + 1) * duration),
			},
		});
	}, [onClick]);

	return <div key={`${parentId}-slot-${index}`} onClick={handleClick} css={divCss}></div>;
};

export const AddNewSlotButtonsContainer = ({ parentId, horizontal, onClick }: IAddNewSlotButtonsContainerProps) => {
	const { options } = useMiddlewareContext();

	const duration = options.addNewSlotButton?.duration ?? DEFAULT_NEW_SLOT_DURATION;

	const hourSize =
		(horizontal ? options.hourSize?.horizontal : options.hourSize?.vertical) ?? DEFAULT_NEW_SLOT_HOUR_SIZE;

	const numberOfSlots = getNumberOfSlotsByDuration(duration);
	const size = getSizeOfSlots(hourSize, numberOfSlots);

	return (
		<div>
			{Array(numberOfSlots)
				.fill('')
				.map((v, idx) => (
					<AddNewSlotButton
                        key={idx}
						index={idx}
						parentId={parentId}
						size={size}
						duration={duration}
						horizontal={horizontal}
						onClick={onClick}
					/>
				))}
		</div>
	);
};
