/** @jsx jsx */
import * as React from 'react';
import { UserPaymentMethodOption, PaymentMethodTypeEnum, UserPaymentMethod } from '@bondsports/types';
import { jsx, css } from '@emotion/react';
import { createContext, Fragment, ReactNode } from 'react';
import { usePaymentMethods } from './usePaymentMethods';

interface IPaymentMethodsContext {
	setPaymentMethodsIfUserExists?: (paymentMethodType?: PaymentMethodTypeEnum) => void;
	creditCards?: UserPaymentMethod[];
	ACHMethods?: UserPaymentMethod[];
	fetchPaymentsLoader?: boolean;
}

export const PaymentMethodsContext = createContext<IPaymentMethodsContext>({});

interface Props {
	children: ReactNode;
	userId?: number;
	paymentMethodData?: UserPaymentMethodOption[];
}

export const PaymentMethodsProvider = ({ children, userId, paymentMethodData }: Props) => {
	const { fetchPaymentsLoader, paymentMethods, setPaymentMethodsIfUserExists } = usePaymentMethods(
		userId,
		paymentMethodData
	);

	const handleSetPaymentMethodsIfUserExists = async (paymentMethodType?: PaymentMethodTypeEnum) => {
		await setPaymentMethodsIfUserExists(paymentMethodType);
	};

	return (
		<div data-aid="PaymentMethodsProvider">
		<Fragment>
			<PaymentMethodsContext.Provider
				value={{
					setPaymentMethodsIfUserExists: handleSetPaymentMethodsIfUserExists,
					creditCards: paymentMethods?.creditCards,
					ACHMethods: paymentMethods?.ACHMethods,
					fetchPaymentsLoader,
				}}
			>
				{children}
			</PaymentMethodsContext.Provider>
		</Fragment>
		</div>
	);
};
