import jwt_decode from 'jwt-decode';
import { environment } from 'environments/environment';
import { CookiesWorker } from '@app/shared/services/auth/CookiesWorker';

/**
 * User Token Settings
 * @param token
 */

function setToken(token: string) {
	localStorage.setItem('id_token', token);
}

function getToken() {
	const authCheck = localStorage.getItem('id_token');

	return authCheck || false;
}

export interface Prop {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
}
function getUserId() {
	return CookiesWorker.getBondUserDetails().id;
}

function getAuthHeaders() {
	const options = {
		headers: {
			accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		},
	};

	return options;
}

function getUserEmail() {
	return CookiesWorker.getBondUserEmail();
}

const getAuthOrganizationId = () => {
	return localStorage.getItem('selected_org_id');
};

const handleLogout = () => {
	localStorage.clear();

	if (!environment.LOGOUT_REDIRECT_URL) {
		console.error("Logout URL is not set");
		return;
	}

	const logoutRedirectUrl = new URL(window.location.toString().split('#').shift().split('?').shift());
	logoutRedirectUrl.pathname = '';
	const stringifiedLogoutRedirectUrl = logoutRedirectUrl.toString().replace(/\/+$/, '');

	const logoutUrl = new URL(environment.LOGOUT_REDIRECT_URL);
	logoutUrl.searchParams.set('state', stringifiedLogoutRedirectUrl);

	window.location.assign(logoutUrl.toString());
};

export const auth = {
	getToken,
	getUserId,
	getAuthHeaders,
	getUserEmail,
	getAuthOrganizationId,
	handleLogout,
};
