/** @jsx jsx */
import React, { useState, Fragment } from 'react';
import { TranslationEn as i18n } from '@assets/i18n/en';
import { Button } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import { noop } from 'lodash/fp';
import { Divider } from 'app/react/forms/activities/createNewSession/utils/divider';
import { marginStringCss, flexCss, gapCss, mobileOnly, tabletUp } from '../../../../../styles/utils';

const responsivePadding = css`
	display: flex;

	${tabletUp} {
		padding: 0 4rem;
	}

	${mobileOnly} {
		padding: 0 1rem 1rem;
	}
`;

export const NextStepFooter = ({
	onCancel,
	onContinue,
	canContinue,
}: {
	onCancel: () => void;
	onContinue: () => void | Promise<void>;
	canContinue: boolean;
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = async () => {
		setIsLoading(true);

		await onContinue();

		setIsLoading(false);
	};

	return (
		<Fragment>
			<Divider />
			<div
				css={[
					flexCss,
					gapCss(6),
					marginStringCss('1em 0 0 0'),
					responsivePadding,
					css`
						justify-content: flex-end;
					`,
				]}
			>
				<Button data-aid="button-NextStepFooter-cancel" theme="secondary" sizer="S" onClick={onCancel}>
					{i18n.buttons.cancel}
				</Button>
				<Button
					data-aid="button-NextStepFooter-continue"
					disabled={!canContinue}
					isProcessing={isLoading}
					theme="primary"
					sizer="S"
					onClick={canContinue ? handleClick : noop}
				>
					{i18n.buttons.continue}
				</Button>
			</div>
		</Fragment>
	);
};
