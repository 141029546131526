/** @jsx jsx */
import { FC, Fragment } from 'react';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { Divider } from '@material-ui/core';
import { css, jsx } from '@emotion/react';
import { TranslationEn as i18n } from '@assets/i18n/en';
import { flexCol, alignItemsCss, paddingCss, centerTextCss, marginStringCss } from '../../../../../styles/utils';

interface Props {
	margins?: string;
}

export const Header: FC<Props> = ({ margins }) => {
	const { header: labels } = i18n.replaceFlow;
	const headerMargins = margins ?? '3rem 1rem 1.5rem 1rem';
	return (
		<Fragment>
			<div css={[flexCol, alignItemsCss('center'), marginStringCss(headerMargins), centerTextCss]}>
				<Typography type={ETypography.h3} color={ETypographyColor.primary}>
					{labels.header}
				</Typography>

				<div css={paddingCss('8px')}>
					<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
						{labels.body}
					</Typography>
				</div>
				<div css={[paddingCss('8px'), centerTextCss]}>
					<Typography type={ETypography.caption} color={ETypographyColor.primary}>
						{labels.subText}
					</Typography>
				</div>
			</div>
			<Divider />
		</Fragment>
	);
};
