/** @jsxRuntime classic */
/** @jsx jsx */
import { ReactNode } from 'react';
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor } from '../../../types';

const container = css`
	display: flex;
	flex-direction: column;
	gap: 20px;
	height: 100%;
	width: 100%;
	> .caption-accented {
		text-transform: uppercase !important;
	}
`;

const labelRowContainerCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const SectionContainer = ({
	title,
	children,
	labelRowComponent,
	className,
}: {
	title: string;
	children: ReactNode;
	labelRowComponent?: ReactNode;
	className?: string;
}) => {
	return (
		<div css={container} className={className} data-aid="index-sectionContainer">
			<div css={labelRowContainerCss}>
				<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
					{title}
				</Typography>
				{labelRowComponent && labelRowComponent}
			</div>
			{children}
		</div>
	);
};
