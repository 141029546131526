/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/react';
import { barcodeScanner } from '@app/react/lib/scanner';
import { ManualIdentifierEntryModalBody } from './ManualIdentifierEntryModalBody';
import { AssignNewPassToCustomerModalBody } from './AssignNewPassToCustomerModalBody';
import { AssignNewPassToCustomerOverridingExistingPassesModalBody } from './AssignNewPassToCustomerOverridingExistingPassesModalBody';
import { PassAlreadyAssignedToSameCustomerModalBody } from './PassAlreadyAssignedToSameCustomerModalBody';
import { PassAlreadyAssignedToDifferentCustomerModalBody } from './PassAlreadyAssignedToDifferentCustomerModalBody';
import { useAssignPassModalManager, VIEW_STATE } from './useAssignPass';

export const PASS_TRUNCATE_LENGTH_START = 10;
export const PASS_TRUNCATE_LENGTH_END = 10;
export const PASS_OWNER_TRUNCATE_LENGTH = 30;

export const passesBodyTwoColGridCss = {
  width: '75%'
};

/*
 * Manager of the dialog box inner-logic for "assigning a pass" and it's complexity / edge cases.
 *
 * This manager first shows a body that allows for manual entry (via input field).
 * It maintains the scanner context and handles accordingly;
 *   Scanned IDs will be automatically submitted when number entry is shown (input field will not be considered).
 *   Scanned IDs will be ignored during the rest of the process.
 * 
 * Depending on the result of the scan/assign lookup, one of the other modal bodys will be shown.
 * ID miss -> "AssignNewPassToCustomer" and "AssignNewPassToCustomerOverridingExistingPasses"
 *    has two variants depending on whether or not this customer has a currently valid pass
 * ID hit on same customer -> "PassAlreadyAssignedToSameCustomer"
 * ID his on different customer -> "PassAlreadyAssignedToDifferentCustomer"
 */
export const AssignPassModalManager = ({ organizationId, customerId, isOpen, setOpen }) => {
  const {
    viewState,
    inputtedIdentifierString,
    customersExistingUserIdentifiers,
    customerState,
    onCancel,
    existingIdentifier
  } = useAssignPassModalManager(organizationId, customerId, isOpen, setOpen);
  
  return (
    <Fragment>
      {viewState === VIEW_STATE.IdentifierEntry && (
        <ManualIdentifierEntryModalBody
          existingUserIdentifiers={customersExistingUserIdentifiers}
          onCancel={onCancel}
          onSubmit={(identifier) => { barcodeScanner.simulateScan(identifier) }} />
      )}
      {viewState === VIEW_STATE.AssignNewPassToCustomer && (
        <AssignNewPassToCustomerModalBody
          newIdentifierString={inputtedIdentifierString}
          customer={customerState}
          onCancel={onCancel} />
      )}
      {viewState === VIEW_STATE.AssignNewPassToCustomerOverridingExistingPasses && (
        <AssignNewPassToCustomerOverridingExistingPassesModalBody
          existingUserIdentifiers={customersExistingUserIdentifiers}
          newIdentifierString={inputtedIdentifierString}
          customer={customerState}
          onCancel={onCancel} />
      )}
      {viewState === VIEW_STATE.PassAlreadyAssignedToSameCustomer && (
        <PassAlreadyAssignedToSameCustomerModalBody
          identifierString={inputtedIdentifierString}
          customer={customerState}
          onCancel={onCancel} />
      )}
      {viewState === VIEW_STATE.PassAlreadyAssignedToDifferentCustomer && (
        <PassAlreadyAssignedToDifferentCustomerModalBody
          existingIdentifier={existingIdentifier}
          newCustomer={customerState}
          onCancel={onCancel} />
      )}
    </Fragment>
  );
};
