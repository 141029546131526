// Barcode scanning support library
// For checking in Users via the UserIdentification API

import onScan from 'onscan.js';
import { getUserIdentifier, MIN_USER_IDENTIFIER_LENGTH, UserIdentifierDto } from './api/userIdentificationApi';

const ADDITIONAL_KEY_CODES = [
  186, // semi-colon and colon
  190, // period and greater-than
  191, // forward slash and question mark
  189, // dash and underscore
]
export class ScannerContext {
  // Required for the Scanner to perform an API lookup of an associated UserIdentifier.
  // If not provided, the Scanner will not perform any API lookups, and the scanSuccessIdentifierHitHandler will not be called.
  organizationId?: number;

  // optional, convenience for the caller
  customerId?: number;
  userId?: number;
  
  // Called when a scan is detected and it matches a UserIdentifier
  scanSuccessIdentifierHitHandler?: (userIdentifier: UserIdentifierDto, context: ScannerContext) => void;
  
  // Called when a scan is detected, but there is no matching UserIdentifier
  scanSuccessIdentifierMissHandler?: (scanCode: string, context: ScannerContext) => void;
}

// Reports result of barcode scanning to the top ScannerContext.
// Ensures scanning library is attached and removed from the document as needed.
//
// Use singleton instance `barcodeScanner` to interact with this class.
// `pushContext` to become the first responder for barcode scans.
// Then `removeContext` when you no longer want to handle barcode scans.
// Scans are only sent to the first responder.
class Scanner {
  contexts: ScannerContext[] = [];
  get currentContext() {
    return this.contexts[this.contexts.length - 1];
  }

  pushContext(context: ScannerContext) {
    this.contexts.push(context);
    this.ensureScanAttached();
  }

  removeContext(context?: ScannerContext) {
    if (context) {
      this.contexts = this.contexts.filter(c => c !== context);
      if (this.contexts.length === 0) {
        this.disable();
      }
    }
  }

  simulateScan(scanCode: string) {
    this.handleScan(scanCode, 1);
  }

  private ensureScanAttached() {
    if (!onScan.isAttachedTo(document)) {
      onScan.attachTo(document, {
        onScan: this.handleScan.bind(this),
        keyCodeMapper: this.keyCodeMapper,
        minLength: MIN_USER_IDENTIFIER_LENGTH,
        });
    }
  }

  // By default, onScan.js ignores keycodes that are not alphanumeric.
  // We override and capture common keycodes.
  private keyCodeMapper(oEvent) {
    if (ADDITIONAL_KEY_CODES.includes(oEvent.which)) {
      return oEvent.key;
    }
    // Fall back to the default decoder in all other cases
    return onScan.decodeKeyEvent(oEvent);
  }
  
  private disable() {
    onScan.isAttachedTo(document) &&
    onScan.detachFrom(document);
  }

  private async handleScan(sCode: string, iQty: number) {
    const context = this.currentContext;
    if (!context) {
      console.error('No context for scan, should have been removed from document.');
      this.disable();
      return;
    }

    if (context.organizationId) {
      const resp = await getUserIdentifier(context.organizationId, sCode);
      if (resp.data) {
        context.scanSuccessIdentifierHitHandler?.(resp.data, context);
        return;
      }
    }
    context.scanSuccessIdentifierMissHandler?.(sCode, context);
  }
}

export const barcodeScanner = new Scanner();