import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { hmrBootstrap } from '../hmr';
import { environment } from './environments/environment';
import { Loader as GoogleMapsAPILoader } from '@googlemaps/js-api-loader';
import * as Sentry from '@sentry/angular';

Sentry.init({
	dsn: environment.SENTRY_DSN,
	tunnel: '/tunnel',
	integrations: [
		// Registers and configures the Tracing integration,
		// which automatically instruments your application to monitor its
		// performance, including custom Angular routing instrumentation
		Sentry.browserTracingIntegration(),
		// Registers the Replay integration,
		// which automatically captures Session Replays
		Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: 0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	// tracePropagationTargets: ['localhost', environment.CS_URLS.API_DOMAIN],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

const googleMapsLoader = new GoogleMapsAPILoader({
	apiKey: environment.GOOGLE_API_KEY,
	version: 'weekly',
});

googleMapsLoader.importLibrary('places');
googleMapsLoader.importLibrary('maps');
googleMapsLoader.importLibrary('geocoding');

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.production) {
	enableProdMode();
	bootstrap();
} else {
	if (module['hot']) {
		hmrBootstrap(module, bootstrap);
	} else {
		bootstrap();
	}
}
