/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { FormPart } from '../../utils/formPart';
import { FrameContainer } from '../../utils/FrameContainer';
import { NO_VALUE_INDICATION } from '../../utils/constValues';
import { EditButton } from '../../../shared/EditButton';
import { flexWrapCss } from '../../../../styles/utils';
import { TranslationEn } from '../../../../../../assets/i18n/en';

interface IProps {
	onEdit?: () => void;
	name: string;
	phone: string;
}

export const EmergencyContacts = ({ onEdit, name, phone }: IProps) => {
	const labels = TranslationEn.customers.detailsPage;
	const { emergencyDetails, contactName, phoneNumber } = labels;
	return (
		<FrameContainer title={emergencyDetails} button={onEdit && <EditButton onEdit={onEdit} />}>
			<div css={flexWrapCss}>
				<FormPart label={contactName} value={name ?? NO_VALUE_INDICATION} />
				<FormPart label={phoneNumber} value={phone ?? NO_VALUE_INDICATION} />
			</div>
		</FrameContainer>
	);
};
