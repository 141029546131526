/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { Button, ETypography, ETypographyColor, PaymentStatusIndication, Typography } from '@bondsports/utils';

import { MembershipStatus } from '../MembershipStatus';
import { flex, gapCss } from '@app/react/styles/utils';
import { Fragment } from 'react';
import { AlertColumn } from '../../utils/AlertColumn';
import { MembershipAlert } from '../types';
import { EllipsisContainer } from '@app/react/components/shared/EllipsisContainer';
import { fullWidthContainers } from '@app/react/styles/ellipsisStyles';

export const MembershipAlertRow = ({
	membershipStatus,
	paymentStatus,
	name,
	redirectToMembership,
}: MembershipAlert) => {
	return (
		<Fragment>
			<AlertColumn>
				<div css={[flex, gapCss(12)]} data-testid="membership-alert-status">
					<PaymentStatusIndication paymentStatus={paymentStatus} />
					<MembershipStatus membershipStatus={membershipStatus} />
				</div>
			</AlertColumn>
			<AlertColumn>
				<Button
					data-aid="button-MembershipAlertRow-redirect"
					theme="basic"
					sizer="S"
					onClick={redirectToMembership}
					css={fullWidthContainers}
				>
					<Typography type={ETypography.body2Link} color={ETypographyColor.primary}>
						<EllipsisContainer>{name}</EllipsisContainer>
					</Typography>
				</Button>
			</AlertColumn>
			{/**for the notes */}
			<AlertColumn />
		</Fragment>
	);
};
