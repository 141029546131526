import React from 'react';
import { css, jsx } from '@emotion/react';
import { IColors } from '../../../types';
import { marginTopCss, mobileOnly, notMobile, setOpacity } from '../../../styles/utils';
import { iconCss } from '../../../organisms/refundPopup/style';
import { oldThemeColors as brandColors } from '../../../styles/theme';

export const cardWrapperCss = css`
	display: flex;
	height: 270px;
	overflow-y: auto;
	width: 100%;
	flex-direction: column;
	gap: 8px;
`;

export const stepContainerCss = css`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	> header {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
`;

export const alignCenterCss = css`
	align-items: center;
`;

export const methodsWrapperCss = (colors: IColors) => css`
	padding-top: 18px;
	padding-bottom: 20px;
	border-bottom: 1px solid ${colors.bg_border_seperator};
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const flexRowCss = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

export const selectableContainerCss = (colors: IColors) => css`
	padding: 20px 0;
	border-bottom: 1px solid ${colors.bg_border_seperator};
	gap: 16px;
	display: flex;
	flex-direction: column;
	${mobileOnly} {
		padding-bottom: 100px;
	}
`;

export const selectableInstallmentsCss = (colors: IColors) => css`
	padding: 12px 0;
	border-bottom: 1px solid ${colors.bg_border_seperator};
	gap: 16px;
	display: flex;
	height: 360px;
	${mobileOnly} {
		height: 400px;
	}
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
`;

export const iconContainerCss = (colors: IColors) => css`
	${iconCss(String(colors.bg_error), 20, '0')};
	flex-shrink: 0;
`;

export const paymentMethodCss = (colors: IColors, isChecked: boolean) => css`
	padding: 20px 16px;
	display: flex;
	background: ${colors.bg_background_secondary};
	border: 1px solid ${isChecked ? brandColors.brandPrimary : colors.bg_border_primary};
	align-items: center;
	gap: 12px;
	justify-content: start;
	border-radius: 3px;
	cursor: pointer;
	> label {
		padding-left: 30px !important;
		margin: 0 !important;
	}

	.checkmark {
		transform: translateY(-50%);
	}
`;

export const cardIconCss = css`
	width: 32px;
	height: 32px;
`;

export const bankIconCss = (colors: IColors) => css`
	color: ${colors.tx_text_teriaty};
`;

export const containerCss = (colors: IColors, isMarginBottom: boolean) => css`
	max-width: 600px;
	> header {
		padding-bottom: 20px;
		border-bottom: 1px solid ${colors.bg_border_seperator};
		margin-bottom: ${isMarginBottom ? 20 : 0}px;
		.h4 {
			color: ${brandColors.brandPrimary} !important;
		}
		${mobileOnly} {
			.h6-accented {
				color: ${brandColors.brandPrimary} !important;
			}
		}
	}
	${mobileOnly} {
		padding: 20px;
	}
`;

export const flex = css`
	display: flex;
	gap: 4px;
`;

export const buttonContainerCss = css`
	${flex};
	.primary {
		:not(:disabled) {
			background: ${brandColors.brandPrimary} !important;
		}
	}
`;

export const footerCss = css`
	display: flex;
	justify-content: end;
	margin-top: 36px;
	button {
		padding: 14px 46px !important;
	}
	${mobileOnly} {
		background: white;
		z-index: 20;
		position: fixed;
		bottom: 0;
		align-items: center;
		justify-content: space-between;
		margin-top: 0 !important;

		left: 0;
		right: 0;
		box-shadow: 0px -4px 8px rgba(61, 64, 67, 0.1);
		padding: 20px 16px;
		button {
			padding: 10px 12px !important;
		}
	}
`;

export const backButtonCss = css`
	button {
		text-transform: capitalize !important;
		padding-left: 0 !important;
	}
`;

export const installmentCss = (colors: IColors, disabled = false) => css`
	padding: 12px 16px;
	display: flex;
	height: 100%;
	background: ${disabled ? colors.bg_background_disabled : colors.bg_background_secondary};
	border: 1px solid ${colors.bg_text_disabled};
	pointer-events: ${disabled ? 'none' : 'auto'};
	align-items: center;
	gap: 12px;
	justify-content: space-between;
	border-radius: 3px;
	cursor: pointer;
	> label {
		padding-left: 30px !important;
		margin: 0 !important;
	}

	.checkmark {
		transform: translateY(-50%);
	}
`;

export const statusCss = css`
	width: 100px;
`;

export const layoutCss = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	height: 100%;
	scroll-behavior: smooth;

	> header {
		background: ${colors.bg_white};
		padding: 28px;
		box-shadow: 0px 2px 22px rgba(21, 93, 148, 0.0749563);
		z-index: 1;
		border-radius: 2px;
	}
`;
export const backgroundCss = (colors: IColors) => css`
	background: ${colors.bg_background_secondary};
	display: flex;
	flex-grow: 1;
	height: 200px;
`;

export const bodyCss = css`
	display: flex;
	flex-grow: 1;
	padding: 20px;
	max-width: 1200px;
	margin: 0 auto;
	gap: 20px;
	overflow-y: scroll;
	${mobileOnly} {
		flex-direction: column;
		padding: 0;
		gap: 0;
	}
`;

export const invoiceWrapperCss = css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	scroll-behavior: smooth;
	${mobileOnly} {
		box-shadow: 0px 2px 22px rgba(61, 64, 67, 0.0749563);
	}
	${notMobile} {
		filter: drop-shadow(0px 2px 22px rgba(61, 64, 67, 0.0749563));
	}
`;

export const checkoutCardContainerCss = css`
	max-width: 450px;
	min-width: 390px;
	position: sticky;
	top: 0;
	${mobileOnly} {
		position: relative;
	}
`;

export const paymentMethodButtonCss = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 18px 8px;
	background: ${colors.bg_background_secondary};
	border: 1px solid ${colors.bg_border_primary};
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	cursor: pointer;
	svg {
		color: ${colors.tx_text_teriaty};
	}

	&:hover {
		border: 1px solid ${colors.tx_text_primary};
		svg {
			color: ${colors.tx_text_primary};
		}
	}
	width: 154px;
	height: 80px;
`;

export const cardContainerCss = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background: ${colors.bg_white};
	box-shadow: 0px 2px 22px rgba(61, 64, 67, 0.0749563);
	border-radius: 0px 0px 5px 5px;
	gap: 52px;
`;

export const headerCss = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

export const infoCss = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	.h2 {
		color: ${brandColors.brandPrimary} !important;
	}
`;

export const checkoutFooterCss = (colors: IColors) => css`
	display: flex;
	gap: 24px;
	// Override coloring
	.secondary {
		border: 1px solid ${brandColors.brandPrimary} !important;
		color: ${brandColors.brandPrimary} !important;
	}
	.primary {
		background: ${brandColors.brandPrimary} !important;
		:hover {
			box-shadow: 0px 2px 16px 8px ${setOpacity(colors.bg_system || '', 0.2)} !important;
		}
	}

	${mobileOnly} {
		background: white;
		z-index: 20;
		position: fixed;
		bottom: 0;
		justify-content: center;
		left: 0;
		right: 0;
		box-shadow: 0px -4px 8px rgba(61, 64, 67, 0.1);
		padding: 20px 34px;
	}
`;

export const middleCss = css`
	display: flex;
	flex-direction: column;
`;

export const paidLineCss = css`
	display: flex;
	gap: 6px;
	margin-top: 8px;
	align-items: center;
`;

export const copyLinkCss = css`
	${marginTopCss(8)}
	cursor: pointer;
`;
