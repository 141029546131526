/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ETypography, ETypographyColor } from '../../../types';
import { Typography } from '../../../atoms';
import { noContentCellCss, noContentCellTextCss } from '../styles/mapper.style';

export const EmptyCell = () => {
	return (
		<div css={noContentCellCss}>
			<Typography color={ETypographyColor.primary} css={noContentCellTextCss} type={ETypography.body2}>
				-
			</Typography>
		</div>
	);
};
