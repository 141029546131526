/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { colors } from '../../../../styles/theme';


export const alternativeModalCss = css`
width: 100%;
height: 100%;
.modal-layout-header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 1rem;
    align-items: center;
}
.modal-layout-footer {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
    gap: 6px;
    width: 100%;

    button {
        margin-top: 0.5rem;
    }
}
.modal-layout-content {
    min-width: 67.5rem;
    display: flex;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid ${colors.borderSeperator};
    border-top: 1px solid ${colors.borderSeperator};
}
`;
