/** @jsx jsx */

import React, { Fragment, useEffect } from 'react';
import { ModalHeaderWithPrimaryAndSecondaryLabels, ModalLayout } from '@bondsports/utils';
import { jsx, css } from '@emotion/react';
import SelectInstallmentsTable from './selectScheduleInstallments';
import { alternativeModalCss } from './styles';
import { TranslationEn } from '@assets/i18n/en';
import InstallmentsFooter from './selectInstallmentsFooter';

interface ISelectScheduleInstallmentsProps {
	isLoading: boolean;
	scheduledPayments: any[];
	meta: any;
	selectedIds: number[];
	isAllSelected: boolean;
	page: number;
	tableRef?: React.RefObject<HTMLDivElement>;
	handleSelectAll: () => void;
	handleSelect: (id: number) => void;
	handleNext: () => void;
	onClose: () => void;
}
const SelectInstallmentsStep = ({
	isLoading,
	scheduledPayments,
	meta,
	tableRef,
	selectedIds,
	isAllSelected,
	handleSelectAll,
	page,
	handleSelect,
	handleNext,
	onClose,
}: ISelectScheduleInstallmentsProps) => {
	useEffect(() => {
		if (selectedIds.length === meta.totalItems) {
			handleSelectAll();
		}
	}, [selectedIds, meta]);

	const labels = TranslationEn.customers.chargeInstallments;
	const noInstallmentsClicked =
		(!isAllSelected && !selectedIds.length) || (isAllSelected && selectedIds.length === meta.totalItems);

	return (
		<ModalLayout data-aid="select-installments-modal" alternativeCss={alternativeModalCss}>
			<Fragment>
				<ModalLayout.Header data-aid="select-installments-modal-header">
					<ModalHeaderWithPrimaryAndSecondaryLabels
						labels={{ title: labels.title, subtitle: labels.subTitleFirstLine }}
					/>
				</ModalLayout.Header>
				<ModalLayout.Content data-aid="select-installments-modal-content">
					<SelectInstallmentsTable
						subject={labels.subject}
						isLoading={isLoading}
						scheduledPayments={scheduledPayments}
						meta={meta}
						tableRef={tableRef}
						selectedIds={selectedIds}
						handleSelect={handleSelect}
						isSelectAll={isAllSelected}
						handleSelectAll={handleSelectAll}
						page={page}
					/>
				</ModalLayout.Content>
				<ModalLayout.Footer data-aid="select-installments-modal-footer">
					<InstallmentsFooter
						handleNext={handleNext}
						isLoading={isLoading}
						page={page}
						labels={labels}
						onClose={onClose}
						noInstallmentsClicked={noInstallmentsClicked}
					/>
				</ModalLayout.Footer>
			</Fragment>
		</ModalLayout>
	);
};

export default SelectInstallmentsStep;
