import { TranslationEn } from '@assets/i18n/en';
import { RowMangePaymenMethodActionsEnum, TRowActionsPopup } from '../types';

export const useRowActionsPopup = ({
	id,
	handleReplace,
}: TRowActionsPopup) => {
	const labels = TranslationEn.customers.managePaymentMethods.table.rowActionsPopup;

	const options = [
		{
			label: labels[RowMangePaymenMethodActionsEnum.REPLACE].label,
			value: RowMangePaymenMethodActionsEnum.REPLACE,
		}
	];

	function handleMoreMenu(value: RowMangePaymenMethodActionsEnum) {
		switch (value) {
			case RowMangePaymenMethodActionsEnum.REPLACE:
				handleReplace(id);
				break;
		}
	}

	return { handleMoreMenu, options };
};
