/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, css } from '@emotion/react';
import { EBrandingColorsOptions, EPickerTheme, ETypography, ETypographyColor, Icons } from '../../../types';
import { useColors } from '../../../hooks/useColors';
import { Typography } from '../../../atoms';
import { Button } from '../../../components/Button';
import { Pricify } from '../../../lib/price';
import dayjs from 'dayjs';
import { BnIcon } from '../../../components/icons_v2';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { marginTopCss, gapCss } from '../../../styles/utils';
import { PublicTag } from '../../PublicTag';
import { PaymentStatusEnum } from '../../../organisms/invoiceTemplate/types';
import { Link2Icon } from '@radix-ui/react-icons';
import { flexCss } from '../../../styles/utils';
import { flexColCss } from '../../../organisms/refundPopup/style';
import { SizeEnum } from '../../../lib/constants';
import { cardContainerCss, checkoutFooterCss, copyLinkCss, headerCss, infoCss, middleCss, paidLineCss } from './style';

export const CheckoutCard = ({
	memo,
	handlePayButton,
	organizationName,
	handleDownload,
	state,
	labels,
	isLoading,
	isPaymentPage,
	isPaymentMethodSelected = false,
}: {
	memo: string;
	handlePayButton: () => void;
	organizationName: string;
	handleDownload: (callback: () => void) => void;
	state: any;
	labels: any;
	isLoading: boolean;
	isPaymentPage: boolean;
	isPaymentMethodSelected?: boolean;
}) => {
	const [isDownloadLoading, setDownloadLoading] = useState(false);
	const { colors } = useColors();
	const totalToPay =
		!isPaymentPage && !isPaymentMethodSelected ? state.amountToPay : state?.amountWithFees || state?.amountToPay;

	const downloadToggle = () => setDownloadLoading(prev => !prev);
	const isFullyPaid = state?.paymentStatus === PaymentStatusEnum.FULLY_PAID;
	const isFullyCharged = state?.price === state?.paidAmount;

	const relevantLabels = labels.checkoutCard;

	const copyInvoiceLink = () => {
		window.navigator.clipboard.writeText(window.location.href);
	};

	return (
		<div data-aid="checkoutCard" css={cardContainerCss(colors)}>
			<div css={infoCss(colors)}>
				<div css={headerCss}>
					<SkeletonWrapper isLoading={isLoading} minHeight={6}>
						<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
							{relevantLabels.title} {organizationName}
						</Typography>
					</SkeletonWrapper>

					<SkeletonWrapper isLoading={isLoading} minHeight={14}>
						<div>
							<Typography type={ETypography.h4} color={ETypographyColor.primary}>
								{relevantLabels.invoiceNum} {state?.id}
							</Typography>

							{state?.isPublic && (
								<div css={flexCss}>
									<div css={marginTopCss(8)}>
										<PublicTag />
									</div>
									<div css={copyLinkCss}>
										<Link2Icon onClick={copyInvoiceLink} />
									</div>
								</div>
							)}
						</div>
					</SkeletonWrapper>
				</div>
				<div css={infoCss}>
					<div>
						<SkeletonWrapper isLoading={isLoading} minHeight={10}>
							<Typography type={ETypography.h2} color={ETypographyColor.primary}>
								{Pricify(totalToPay)}
							</Typography>
						</SkeletonWrapper>
						{isPaymentMethodSelected && totalToPay - state?.paymentAmount > 0 && (
							<div css={[flexColCss, gapCss(4), marginTopCss(8)]}>
								<Typography type={ETypography.body1} color={ETypographyColor.secondary}>
									fee {Pricify(totalToPay - state?.paymentAmount)}
								</Typography>
								<Typography type={ETypography.body1Accented} color={ETypographyColor.secondary}>
									subtotal {Pricify(state?.paymentAmount)}
								</Typography>
							</div>
						)}
						{isFullyPaid && (
							<div css={paidLineCss}>
								<BnIcon icon={Icons.checkmark_circle} />
								<Typography type={ETypography.body1Accented} color={ETypographyColor.secondary}>
									{relevantLabels.paidOn} {dayjs(state?.updatedAt).format('MMM DD, YYYY')}
								</Typography>
							</div>
						)}
					</div>
					<div css={middleCss}>
						<SkeletonWrapper isLoading={isLoading} minHeight={6}>
							<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
								{memo}
							</Typography>
						</SkeletonWrapper>
					</div>
				</div>
			</div>
			<div data-aid="checkoutCardButton" css={checkoutFooterCss(colors)}>
				<Button
					data-aid="button-checkoutCardButton-toggle"
					sizer={SizeEnum.MEDIUM}
					isProcessing={isDownloadLoading}
					loadingColor={EBrandingColorsOptions.bg_text_primary}
					theme="secondary"
					onClick={() => {
						downloadToggle();
						handleDownload(downloadToggle);
					}}
				>
					{relevantLabels.download}
				</Button>
				{!isFullyCharged && (
					<Button
						data-aid="button-checkoutCardButton-pay"
						sizer={SizeEnum.MEDIUM}
						disabledColor={EBrandingColorsOptions.tx_text_disabled}
						theme={!isPaymentPage ? EPickerTheme.PRIMARY : EPickerTheme.BASIC}
						disabled={isPaymentPage}
						onClick={handlePayButton}
					>
						{relevantLabels.pay}
					</Button>
				)}
			</div>
		</div>
	);
};
