import React, { useEffect, forwardRef } from 'react';
import { Container } from './container';
import { Background } from './background';
import { animated, useTransition } from '@react-spring/web';
import { DomPortal } from '../DomPortal';

export interface ModalProps {
	isShowing: boolean;
	toggle: () => void;
	padding?: number;
	minWidth?: string;
	minHeight?: string;
	maxWidth?: string;
	maxHeight?: string;
	isDismissable?: boolean;
	isFullSize?: boolean;
	isFullWidth?: boolean;
	isFullHeight?: boolean;
	isMaximizable?: boolean;
	overflow?: string;
	children?: React.ReactNode;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
	(
		{
			isShowing,
			toggle,
			children,
			minWidth,
			minHeight,
			maxWidth,
			maxHeight,
			padding = 1,
			isDismissable = true,
			isFullSize = false,
			isFullWidth = false,
			isFullHeight = false,
			isMaximizable = false,
			overflow,
		},
		forwardRef
	) => {
		const fadeInOutTransition = useTransition(isShowing, {
			from: { opacity: 0 },
			enter: { opacity: 1 },
			leave: { opacity: 0 },
			reverse: isShowing,
			config: { duration: 100 },
		});

		useEffect(() => {
			const body = document.querySelector('body');
			body!.style.overflowY = isShowing ? 'hidden' : 'auto';
		}, [isShowing]);

		return fadeInOutTransition(
			(styles, modal) =>
				modal && (
					<DomPortal selector={'body'}>
						<animated.div style={styles}>
							<Background onClick={() => (isDismissable ? toggle() : undefined)} />
							<Container
								ref={forwardRef}
								padding={padding}
								toggle={toggle}
								minWidth={minWidth}
								minHeight={minHeight}
								maxWidth={maxWidth}
								maxHeight={maxHeight}
								isDismissable={isDismissable}
								isFullSize={isFullSize}
								isFullWidth={isFullWidth}
								isFullHeight={isFullHeight}
								isMaximizable={isMaximizable}
								overflow={overflow}
							>
								{children}
							</Container>
						</animated.div>
					</DomPortal>
				)
		);
	}
);
