import { IGenericFilters } from '@app/react/types/filters';
import { PaymentMethodTypeEnum, ResourceNameTypeEnum } from '@bondsports/types';
import { SportsEnum } from 'app/shared/services/programs/OLD_program.service';
import { LevelOfPlayEnum, MediaType } from '@app/shared/services/programs/programs.service';
import { RCAddress } from 'app/react/types/address';
import { GenderEnum, Customer } from '@bondsports/types';
import { SelectablePaymentMethodType } from '@bondsports/utils/build/src/types/entities/payment';

export interface ResourceRow {
	id: number;
	type: ResourceNameTypeEnum;
	name: string;
	createdAt: Date;
	paymentMethodId: string;
	paymentType: PaymentMethodTypeEnum;
	ccLast4: string;
	ccBrand: string;
	resourceId?: number;
	invoiceId?: number;
	membershipId?: number;
}

export interface IOrganizationCustomers extends Customer {
	organizationName: string;
}

interface SchoolItem {
	schoolId: number;
}

export interface AthleteSportsSchema {
	id?: number;
	athleteId: number;
	sports: SportsEnum;
	levelOfPlay: LevelOfPlayEnum;
}
interface AthleteMetadata {
	shirtSize?: string;
	schoolList?: SchoolItem[];
}

interface AthleteSchema {
	id?: number;
	userId: number;
	metadata?: AthleteMetadata;
	athleteSports: AthleteSportsSchema[];
}

export interface MediaSchema {
	id?: number;
	url: string;
	name: string;
	title: string;
	mediaKey: string;
	description: string;
	provider: string;
	mediaType: MediaType;
	fileType: string;
	format?: string;
	userCreatorId: number;
	ownerId: number;
	isDefault: boolean;
}

interface NotificationEntitySettings {
	active: boolean;
}


export interface NormalizedPaymentMethod {
	id: string;
	type: SelectablePaymentMethodType;
	meta: {
		last4?: string;
		company?: string;
	};
}

export interface UserSettings {
	id: number;
	about?: string;
	athlete?: AthleteSchema;
	notificationSettings?: {
		teams: NotificationEntitySettings;
		leagues: NotificationEntitySettings;
		events: NotificationEntitySettings;
		venues: NotificationEntitySettings;
		feed: NotificationEntitySettings;
		users: NotificationEntitySettings;
	};
	pushNotifications?: boolean;
	profilePictureId?: number;
	profilePicture?: MediaSchema;
	address?: RCAddress;
	email?: string;
	phoneNumber?: string;
	merchantId?: number;
	firstName?: string;
	lastName?: string;
	weight?: number;
	height?: number;
	birthDate?: Date;
	gender?: GenderEnum;
	privateProfile?: boolean;
	hasPassword?: boolean;
	avatar?: string;
	customers?: IOrganizationCustomers[];
}
export type TAvailableFlters = { facilities: { id: number; name: string }[]; activities: number[] };

export enum ConnectedResourcesFiltersEnum {
	TYPES = 'resourceTypes',
	SEARCH = 'search',
}

export enum OrderByFieldsEnum {
    NAME = "name",
    RESOURCE_TYPE = "resourceType",
    INVOICE = "invoiceId",
}

export interface IResourcesFilter extends IGenericFilters {
	[ConnectedResourcesFiltersEnum.TYPES]: number[];
	[ConnectedResourcesFiltersEnum.SEARCH]: number[];
}

export enum RowMangePaymenMethodActionsEnum {
	REPLACE = 'replace',
}

export interface IRowActions {
	id: number;
	handleReplace: (id: number) => void;
}

export type TRowActionsPopup = IRowActions;
