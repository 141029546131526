import {
	ActivityEnum,
	GetProductsByTypeQueryDto,
	IResourcesAvailability,
	PriceDto,
	ProductResourceDto,
	ProductSubTypesEnum,
	ResourceGlDto,
} from '@bondsports/types';
import { Product } from './product';
import { ActivityTime } from './season';
import { IOption } from '@bondsports/utils';

export interface ProductAvailabilityTimesDto {
	availabilityStartDate?: string;
	availabilityEndDate?: string;
	daysOfWeek: number[];
	startTime: string[];
	endTime: string[];
}

export interface RentalProductAddonDto {
	productId: number;
	price: number;
	isFlatPrice: number;
	isMandatory: number;
}

export interface CreatePriceDto {
	id?: number;
	currency: string;
	startDate: string;
	endDate: string;
	price: number;
	name: string;
}

interface BasicRentalProduct {
	durationDays?: number;
	durationMinutes?: number;
	sports: number[];
}

export interface CreateRentalProductDto extends BasicRentalProduct {
	id?: number;
	glIds: number[];
	productType: string;
	organizationId: number;
	name: string;
	description?: string;
	prices: CreatePriceDto[];
	tax: number;
	isTaxInclusive: boolean | string;
	isProRated: boolean;
	isForAllCustomers: boolean;
	membershipsAvailableFor?: number[];
	customersAvailableFor?: number[];
	resourcesIdsToApplyOn?: number[];
	resourcesType?: string;
	forms: number[];
	documents?: string[];
	addons: RentalProductAddonDto[];
	availabilityTimes: ProductAvailabilityTimesDto[];
	isPublic: boolean;
}

export interface RentalProduct extends Product, BasicRentalProduct {
	isForAllCustomers: boolean;
	productResources: ProductResourceDto[];
	resources: IResourcesAvailability[];
	activityTimes: ActivityTime[];
	glCodes: ResourceGlDto[];
	GL: ResourceGlDto[];
}

export interface ProductResources {
	createdAt: string;
	deletedAt: string;
	id: number;
	organizationId: number;
	productId: number;
	resourceId: number;
	resourceType: string;
	updatedAt: string;
	resourceName: string;
}

export enum CustomDurationTypeEnum {
	minutes = 'minutes',
	hours = 'hours',
	days = 'days',
}

export enum DurationsEnum {
	halfHour = 0.5,
	hour = 1,
	hourAndHalf = 1.5,
	twoHours = 2,
	custom = 3,
	fullDay = 24,
}

export enum ProductPackageLevelEnum {
	HOUR = 'hour',
	SLOT = 'slot',
	RESERVATION = 'reservation',
}

export interface BasiceRentalProductRow {
	glCodes: IOption[];
	name: string;
	sportTypes: number[];
	duration: string;
	availabilities: string[];
	prices?: PriceDto[];
	id: number;
}

export enum AdditionalStatusesEnum {
	ARCHIVED = 'archived',
}

export type ActivityStatusesEnum = ActivityEnum | AdditionalStatusesEnum;

export interface RentalProductRow extends BasiceRentalProductRow {
	resources: string[];
	productSubType: ProductSubTypesEnum;
	status: ActivityStatusesEnum;
	more: {
		id: number;
		status: ActivityStatusesEnum;
	};
}

export interface GetProductsByTypeQuery extends GetProductsByTypeQueryDto {}
