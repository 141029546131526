import { css } from '@emotion/react';
import { colors } from '../../../../styles/theme';
import { setOpacity } from 'app/react/styles/utils';

export const ContentCss = `
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

body {
  color: ${colors.brandPrimary};
  font-family: Montserrat;
  font-size: 12pt;
  line-height: 1.3;
}



.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: ${colors.lightText}; 
  font-size: 0.875rem;
  font-weight: 400;
}

`;

export const richTextWrapper = css`
	display: flex;
	flex-direction: column;
	width: 100%;
	div {
		width: 100%;
		margin: 10px 0;
	}
	.tox-tinymce {
		border: none !important;
	}
	.tox .tox-toolbar-overlord {
		background: none !important;
		background-color: white !important;
	}

	iframe,
	.tox .tox-toolbar,
	.tox .tox-toolbar__overflow,
	.tox .tox-toolbar__primary {
		background: none !important;
		background-color: ${setOpacity(colors.brandPrimary, 0.04)} !important;
		.mce-content-body {
			color: ${colors.brandPrimary} !important;
		}
	}
	.tox .tox-tbtn,
	.tox .tox-tbtn svg {
		color: ${colors.lightText} !important;
		fill: ${colors.lightText} !important;
	}
	.tox-editor-header {
		background-color: ${setOpacity(colors.brandPrimary, 0.04)} !important;
		border: 1px solid #dae6f0;
		box-sizing: border-box;
		border-radius: 2px;
	}
	.tox .tox-statusbar__text-container {
		display: none !important;
	}
	.caption {
		margin-top: -12px;
		color: ${colors.formInputBg};
	}
`;
