/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Radio } from '../../../components/Radio';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { BnIcon } from '../../../components/icons_v2';
import { Typography } from '../../../atoms';
import { Fragment, useMemo } from 'react';
import { PaymentMethodTypeEnum } from '../../../types/entities/payment';
import { UserPaymentMethod } from '../../../types/entities/payment';
import { useColors } from '../../../hooks/useColors';
import { paymentMethodCss, cardIconCss, bankIconCss } from './style';

interface Props {
	state: UserPaymentMethod;
	isChecked?: boolean;
	handleSelect: (v: UserPaymentMethod) => void;
}

export const PaymentMethodRadio = (props: Props) => {
	const { colors } = useColors();
	const Method = useMemo(() => {
		const methodsMapper = {
			[PaymentMethodTypeEnum.CARD]: <CreditCard {...props} />,
			[PaymentMethodTypeEnum.ACH]: <ACH {...props} />,
		};
		return methodsMapper[props.state.paymentMethodType];
	}, [props.state.paymentMethodType, props.isChecked]);

	return (
		<div
			data-aid="paymentMethodRadio"
			css={paymentMethodCss(colors, Boolean(props.isChecked))}
			onClick={() => props.handleSelect(props.state)}
		>
			<Radio value={props.state?.id} checked={props.isChecked} setChecked={() => {}} />
			{Method}
		</div>
	);
};

const CreditCard = ({ state, isChecked }: Props) => {
	const formattedMonth = state?.expMonth && state?.expMonth < 9 ? `0${state?.expMonth}` : state?.expMonth;
	const formattedYear = state?.expYear && state?.expYear.toString().slice(-2);
	return (
		<Fragment>
			{state?.paymentMethodType ===  PaymentMethodTypeEnum.CARD && <BnIcon icon={Icons[state?.ccBrand!]} css={cardIconCss} />}
			<Typography type={ETypography.body1} color={isChecked ? ETypographyColor.primary : ETypographyColor.disabled}>
				**** **** **** {state?.ccLast4}
			</Typography>
			<Typography
				type={ETypography.body2Accented}
				color={isChecked ? ETypographyColor.primary : ETypographyColor.disabled}
			>
				{formattedMonth}/
				{formattedYear}
			</Typography>
		</Fragment>
	);
};

const ACH = ({ state, isChecked }: Props) => {
	const { colors } = useColors();
	const accountLast4Chars = state?.ccLast4;
	return (
		<Fragment>
			<BnIcon icon={Icons.bank} css={bankIconCss(colors)} />
			<Typography type={ETypography.body1} color={isChecked ? ETypographyColor.primary : ETypographyColor.disabled}>
				{accountLast4Chars}
			</Typography>
		</Fragment>
	);
};
