import { PaginationResultDto } from '@bondsports/types';
import { environment } from '../../../../environments/environment';
import { network } from '../network';
import { buildQueryString } from '../urlUtils';

const v4Api = `${environment.CS_URLS.API_ROOT_V4}`;

// Get an array of all currently valid identifiers matching the organization (and optionally customer, user, facility)
export const getUserIdentifiers = async (organizationId: number, customerId?: number, userId?: number, facilityId?: number): Promise<PaginationResultDto<UserIdentifierDto>> => {
  const url = `${v4Api}/organization/${organizationId}/user-identification/${buildQueryString({customerId, userId, facilityId})}`;
	return await network.get(url);
};

// Get a single currently-valid UserIdentifier, matching the given organizationd and identifier (and optionally facilityId)
export const getUserIdentifier = async (organizationId: number, identifier: string, facilityId?: number): Promise<{ data: UserIdentifierDto }> => {
  const url = `${v4Api}/organization/${organizationId}/user-identification/${identifier}${buildQueryString({facilityId})}`;
	return await network.get(url);
};

export const createUserIdentifier = async (organizationId: number, payload: UserIdentifierCreateDto, invalidateExisting: boolean = false, migrateExisting: boolean = false): Promise<{ data: UserIdentifierDto }> => {
  return await network.post(`${v4Api}/organization/${organizationId}/user-identification?invalidateExisting=${invalidateExisting}&migrateExisting=${migrateExisting}`, payload);
}

//TODO: When apiv2 is merged, use DTOs and consts from '@bondsports/types';

export const MIN_USER_IDENTIFIER_LENGTH = 4;

export interface UserIdentifierCreateDto {
  organizationId: number;
  customerId?: number;
  userId?: number;
  identifier: string;

  validFrom?: Date;
	validUntil?: Date;
}

export interface UserIdentifierDto {
  id: number;

  organizationId: number;
  facilityId?: number;
  userId?: number;
  customerId?: number;

  identifier: string;
  identifierType: number;

  importId?: string;

	validFrom?: Date;
	validUntil?: Date;

  createdAt: Date;
  updatedAt: Date;
}