/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { BnIcon, Button, ETypography, ETypographyColor, Icons, Typography } from '@bondsports/utils';
import { TranslationEn as i18n } from '@assets/i18n/en';
import { colors } from '@app/react/styles/theme';
import {
	alignItemsCenter,
	centerTextCss,
	flexCol,
	gapCss,
	justifyContentCss,
	paddingCss,
} from '../../../../../../styles/utils';
import { NormalizedPaymentMethod } from '../../../types';
import { PaymentMethod } from '../PaymentMethod';

const container = css`
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const SuccessMessage = ({
	paymentMethod,
	onClick,
}: {
	paymentMethod?: NormalizedPaymentMethod;
	onClick: () => void;
}) => {
	const { success: labels } = i18n.replaceFlow;

	return (
		<div data-aid="Success" css={container}>
			<div
				css={css`
					color: ${colors.btnGreenBg};
				`}
			>
				<BnIcon icon={Icons.check} />
			</div>
			<div css={centerTextCss}>
				<Typography type={ETypography.h3} color={ETypographyColor.primary}>
					{labels.header}
				</Typography>
			</div>
			<div css={[paddingCss('1em'), flexCol, justifyContentCss('center'), alignItemsCenter, gapCss(11), centerTextCss]}>
				<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
					{labels.body}
				</Typography>
				{paymentMethod && <PaymentMethod paymentMethod={paymentMethod} />}
			</div>
			<Button data-aid="button-Success" theme="primary" sizer="S" onClick={onClick}>
				{labels.button}
			</Button>
		</div>
	);
};
