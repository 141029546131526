/** @jsx jsx */
/** @jsxRuntime classic */
import React from 'react';
import { jsx } from '@emotion/react';
import { TranslationEn } from '../../../../assets/i18n/en';
import { underlineCss } from '../../styles/utils';
import { FAQ_URL } from '../../types/urls';
import { Typography, ETypographyColor, ETypography } from '@bondsports/utils';

const labels = TranslationEn.faq;

export const FAQLink = ({ color = ETypographyColor.primary, shortTitle = false }: { color?: ETypographyColor, shortTitle?: boolean}) => {
	return (
		<Typography color={color} type={ETypography.captionLink}>
			<a href={FAQ_URL} css={underlineCss} target="_blank">
				{shortTitle ? labels.shortTitle : labels.linkTitle}
			</a>
		</Typography>
	);
};
