/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Typography, ETypography, ETypographyColor, Tooltip } from '@bondsports/utils';
import { DEFAULT_SINGLE_LINE_TOOLTIP_WIDTH } from '../consts';

const containerCss = (width: number, fixedWidth = false) => css`
	max-width: ${width}px;
	${fixedWidth ? `width: ${width}px;` : ''}
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

interface ToolTipCellProps {
	text: string;
	width?: number;
	color?: ETypographyColor;
	consistentWidth?: boolean;
}

export const SingleLineToolTipCell = ({ text, consistentWidth = false, width = DEFAULT_SINGLE_LINE_TOOLTIP_WIDTH, color = ETypographyColor.primary  }: ToolTipCellProps) => {
	const textRef = useRef<HTMLDivElement>(null);
	const [displayTooltip, setDisplayTooltip] = useState(false);

	useEffect(() => {
		setDisplayTooltip(textRef?.current?.scrollWidth > width);
	}, [textRef, text]);

	const DummaryWrapper = useCallback(
		({ children }: { children: ReactNode } & any) => {
			return <Fragment>{children}</Fragment>;
		},
		[displayTooltip]
	);

	const Wrapper = displayTooltip ? TooltipWrapper : DummaryWrapper;

	return (
		<Wrapper text={text}>
			<Typography type={ETypography.body2} color={color}>
				<div ref={textRef} css={containerCss(width, consistentWidth)}>
					{text}
				</div>
			</Typography>
		</Wrapper>
	);
};

const TooltipWrapper = ({ children, text }: { children: ReactNode; text: string }) => {
	return (
		<Tooltip isTopLayer content={text}>
			{children}
		</Tooltip>
	);
};
