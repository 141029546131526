/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import {
	UserPaymentMethod,
} from '@bondsports/types';
import { SelectableMethod } from '../SelectableMethod';
import { ICustomer } from '../../../../../types/customers';
import { wrapContainerCss } from '../../../../payments/Charge/CashOrDocument/styles';

interface IProps {
	paymentMethods: UserPaymentMethod[];
	selectedIds: string[];
	userId: number;
	customer: ICustomer;
	handleSelect: (pmId: string) => void;
}

const MultiSelectBlock = ({ paymentMethods, handleSelect, selectedIds }: IProps) => {

    const isActive = (methodId: string): boolean => {
        return selectedIds.includes(methodId);
    }

	const isOnlyMethodClicked = (methodId: string): boolean => {
		return selectedIds.length === 1 && selectedIds[0] === methodId;
	}

	return (
        <div data-aid="multi-select-method-container" css={wrapContainerCss}>
        {paymentMethods?.map(method => {
			const isSelected: boolean = isActive(method.paymentMethodId);
			const isDisabled: boolean = isOnlyMethodClicked(method.paymentMethodId);
            return (
				<SelectableMethod 
					key={method.id} 
					method={method} 
					disabled={isDisabled} 
					isSelected={isSelected} 
					handleSelect={handleSelect} 
				/>
            );
        })}
    </div>
	);
};

export { MultiSelectBlock };
