/** @jsx jsx */
import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { Divider } from 'app/react/forms/activities/createNewSession/utils/divider';
import { Typography, ETypography, ETypographyColor, Checkbox, SizeEnum } from '@bondsports/utils';
import { SelectSection, handleCheckedType } from './types';

const spaceBetweenTypography = css`
	padding-top: 10px;
`;

const heightCss = (maxHeight: number) => css`
	max-height: ${maxHeight}px;
	overflow-y: auto;
`;

const customCheckboxHeight = css`
	height: 30px;
`;

interface StatusesFilterBodyProps<T> {
	sections: SelectSection<T>[];
	checkedOptions: number[] | string[];
	withDivider?: boolean;
	handleChecked: handleCheckedType<T>;
}

export const SelectBody = <T,>({
	sections,
	checkedOptions,
	withDivider = false,
	handleChecked,
}: StatusesFilterBodyProps<T>) => {
	return (
		<Fragment>
			{sections.map((selectSection, index) => {
				return (
					<Fragment key={index}>
						{selectSection.options.length > 0 && (
							<Fragment>
								<Typography type={ETypography.overlineAccented} color={ETypographyColor.teriaty}>
									{selectSection.sectionTitle}
								</Typography>
								<div css={[spaceBetweenTypography, selectSection.maxHeight && heightCss(selectSection.maxHeight)]}>
									{selectSection.options.map((option, index) => (
										<div css={customCheckboxHeight}>
											<Checkbox
												label={option.label}
												icon={option?.icon ?? ''}
												checked={checkedOptions.findIndex(checked => checked === option.value) !== -1}
												setChecked={val => {
													handleChecked(val, option.value, selectSection.sectionId);
												}}
												sizer={SizeEnum.SMALL}
												width={20}
												key={index}
												truncate
											/>
										</div>
									))}
								</div>
								{withDivider && index !== sections.length - 1 && <Divider />}
							</Fragment>
						)}
					</Fragment>
				);
			})}
		</Fragment>
	);
};
