/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { cellCss } from '../styles';
import { AlignType } from '../types';
import { NO_VALUE_INDICATION } from '../../../customers/utils/constValues';

export const Invoice = ({ row, column }) => {
	const { id, action, styling } = column;
	const invoiceId = row[id];

	const content = invoiceId ? `#${invoiceId}` : NO_VALUE_INDICATION;
	const isAction = !!action && !!invoiceId;
	return (
		<td
		    data-aid="longString-invoice"
			className="TableCell-root TableCell-body"
			css={cellCss({ isAction, isUnderlined: !!invoiceId })}
			align={styling.align ?? AlignType.CENTER}
			onClick={() => {
				if (isAction) {
					action(row);
				}
			}}
		>
			{content}
		</td>
	);
};
