/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { cellCss } from '../styles';

export const Balance = ({ row, index, column, isLast }) => {
	let balance;
	if (row[column.id]) {
		if (row[column.id] > 0) {
			balance = `+ $${row[column.id]}`;
		} else {
			balance = `- $${row[column.id] * -1}`;
		}
	} else {
		balance = '-';
	}
	return (
		<td
		    data-aid="td-balance"
			key={column.id}
			className="TableCell-root TableCell-body"
			align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
			onClick={() => {
				if (column.action) {
					column.action(row);
				}
			}}
			css={cellCss({ isAction: column.action, isLast })}
		>
			{balance}
		</td>
	);
};
