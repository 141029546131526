export enum SectionEnum {
	MAIN_DETAILS = 'details',
	TIMES = 'times',
	SPORTS = 'sports',
	AMENTIES = 'amenities',
	PROPERTIES = 'properties',
	RELATIONSHIPS = 'relationships',
}

export interface TimeSlot {
	on?: number[];
	startTime?: string;
	endTime?: string;
}

export interface OpeningTimes {
	open: string;
	close: string;
	dayOfWeek: number;
}

export interface TimesFormValues {
	timeSlots?: TimeSlot[] | OpeningTimes[];
}
export interface ISportsObj {
	sports?: number[];
}
export interface IAmenitiesObject {
	amenities?: number[];
}
