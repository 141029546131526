/** @jsx jsx */

import { Fragment, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { TranslationEn } from '@assets/i18n/en';
import { jsx } from '@emotion/react';
import { useToggle } from '../../../hooks/useToggle';
import { WarningAlert } from '../../shared/warningAlert';

interface DirtyNavigationBlockerProps {
	isDirty: boolean;
}

/**
 * When isDirty, this component will block navigation and prompt the user to confirm they want to leave the page.
 *
 * If navigation is from the React Router, the user will be prompted with our standard modal.
 * If navigation is from the browser (eg. refresh, close tab), the user will be prompted with the browser's standard dialog.
 *
 * Usage:
 * <DirtyNavigationBlocker isDirty={formIsDirty} />
 *
 * @param isDirty Should reflect the "dirty" (ie. unsaved changes) state of the component.
 */
export const DirtyNavigationBlocker = ({ isDirty }: DirtyNavigationBlockerProps) => {
	const labels = TranslationEn.forms.generic.abandonUnsavedChangesConfirmation;
	const history = useHistory();

	const [isShowingWarningPopup, warningPopupToggle] = useToggle();
	const [nextLocation, setNextLocation] = useState<Location | null>(null);
	const [willNavigate, setWillNavigate] = useState(false);

	const doNavigate = async () => {
		setWillNavigate(true);
		warningPopupToggle();
		//navigate on the next tick, after willNavigate is updated and downstream components have a chance to _react_
		setTimeout(() => {
			history.push(nextLocation.pathname);
		}, 0);
	};

	const doNotNavigate = () => {
		warningPopupToggle();
	};

	const handleBlockedNavigation = (nextLocation, _action)  => {
		setNextLocation(nextLocation);
		warningPopupToggle();
		return false;
	};

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (isDirty && !willNavigate) {
				// This will present the standard browser dialog.
				// As of this writing, no customizations are possible.
				event.preventDefault();
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isDirty, willNavigate]);

	return (
		<Fragment>
			<Prompt when={isDirty && !willNavigate} message={handleBlockedNavigation} />
			{isShowingWarningPopup && (
				<WarningAlert
					open={isShowingWarningPopup}
					labels={labels}
					onPrimaryAction={doNavigate}
					onSecondaryAction={doNotNavigate}
				/>
			)}
		</Fragment>
	);
};
