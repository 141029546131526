import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IColors } from '../../types/theme';

export const InputContainer = styled.div<any>(
	({ theme: colors, disabled = false, marginTop = 16 }: { theme: IColors; disabled?: boolean; marginTop?: number }) => `
  margin: ${marginTop}px 0 1px 0;
  width:100%;
  position:relative;
  min-width: 90px;
  &:focus{
    border:1px solid green !important;
  }
  &.tight{
    margin-top: 0;
  }
  .breakLine{
    position:absolute ;
    top:0;
    right:0;
  }
  .label{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    color: ${colors.tx_text_secondary};
    margin-bottom:8px;
    padding:0px;
    display: flex;
    align-items: center;  
  }
  .helper{
    font-family: Montserrat;
    font-style: normal;
    display: flex;
    align-items: center;
    font-weight: normal;
    color: ${colors.tx_text_secondary};
    margin-top:4px;
    display: flex;
    align-items: flex-start;
    box-sizing: unset;   
  }

  &.M{
    .label{
      font-size: 14px;
      line-height: 17px;
      height: 17px; 
    }
    .helper{
      font-size: 14px;
      line-height: 17px;
    }
    svg{
      width: 20px;
      height: 20px;
    }
  }
  &.S{
    .label{
      font-size: 12px;
      line-height: 15px;
      height: 15px; 
    }
    .helper{
      font-size: 12px;
      line-height: 15px;
    }
  }

  .select-container{
    display:flex;
    align-items:center;
    .remove{
      margin-left:12px;
      cursor:${disabled ? 'auto' : 'pointer'};

      svg{
        width:20px;
        height:20px;
        color:${colors.tx_text_teriaty}
      }
      &:hover{
        svg{
          color:${disabled ? colors.tx_text_teriaty : colors.tx_text_primary};
        }
      }
    }
  }
`
);

const CLEAR_BUTTON_WIDTH = 16;
const INNER_TEXT_PADDING = 4;

export const StyledInputContainer = styled.div<any>(
	({
		theme: colors,
		disableTrailingClearButtonPadding = true,
		grayedOut = false,
	}: {
		theme: IColors;
		disableTrailingClearButtonPadding?: boolean;
		grayedOut?: boolean;
	}) => `
  display: flex;
  padding: 9px 12px;
  background: ${colors.bg_background_fields};
  align-items: center;
  border-radius: 2px;
  position:relative;
  border: 1px solid transparent ;
  color: ${colors.tx_text_teriaty};
  &.focus{
    border: 1px solid ${colors.bg_border_primary} ;
  }
  &:hover, &.hover{
    border: 1px solid ${colors.bg_border_primary} ;
  }
  &.disabled{
    &:hover, &.hover{
      border: 1px solid transparent ;
    }
  };
  &.error{
    border: 1px solid ${colors.bg_error} ;
  }
  
  input:disabled {
      color: ${grayedOut ? colors.tx_text_teriaty : colors.tx_text_primary} !important;
  }
  
  textarea:disabled {
      color: ${grayedOut ? colors.tx_text_teriaty : colors.tx_text_primary} !important;
  }
  
  input,textarea {
    width: 100%;
    flex: 0 1 100%;
    margin: 0;
    border: none;
    background: transparent;
    color: ${colors.tx_text_primary};
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    outline:none;
    resize:none;
    padding: 0 ${disableTrailingClearButtonPadding ? 0 : `${INNER_TEXT_PADDING + CLEAR_BUTTON_WIDTH}px`} 0 0;
    &::placeholder {
      color: ${colors.tx_text_teriaty};
    }
  }

  .insideLeftLabel {
    padding-right: 4px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }
  &.M {
    font-size: 16px
  }
  &.S {
    font-size: 14px
  }

  .static{
    display:flex;
    position:absolute;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    gap:4px;
    lineHeight: 130%;
  }
  &.M{
    input{
      font-size: 16px;
      line-height: 20px;
    }

    .static{
      font-size: 16px;
      line-height: 20px;
    }
  }
  .icon_container{
    color:${colors.tx_text_teriaty};
    height: 20px;
    width: 20px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  &.disabled {
    color:${colors.tx_text_disabled};
    input,textarea {
      &::placeholder {
        color: ${colors.tx_text_disabled};
      }
  }
`
);

export const DeleteButtonStyle = styled.button<any>(
	({ theme: colors }: { theme: IColors }) => `
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 0;
  top:50%;
  transform:translateY(-50%);
  justify-content: center;
  align-items: center;
  outline: none;
  color:${colors.tx_text_teriaty};
  &:hover,icon_container{
    color:${colors.tx_text_primary} ;
  }
`
);

export const RightButton = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
    margin-left:6px;
    padding-left:12px;
    border-left:1px solid ${colors.bg_border_seperator};
    button{
      padding: 2.5px 4px;
      background:${colors.bg_text_teriaty} ;
      border-radius: 2px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color:${colors.tx_white};
      border:none;
      cursor:pointer;
      &:hover{
        background:${colors.bg_text_primary};
      }
    }
  `
);

export const ArrowsButton = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
  // background: ${colors.bg_background_fields};
  cursor:pointer;
  padding:0 4px;
  display: flex;
  border-radius: 0 2px 2px 0;
  align-items: center;

  color: ${colors.tx_text_teriaty};
  position:absolute;
  top:0;
  right: 0;
  bottom:0;
  display:flex;
  flex-direction: column;
  justify-content: center;
  svg{
    width:16px;
    height:16px;
    display:flex;
    align-items: center;
    justify-content: center;
    &:hover{
      color: ${colors.tx_text_primary};
    }
  }
  button{
    all:unset;
    cursor:pointer;
    &:disabled{
      cursor:default;
      svg{
        &:hover{
          color: ${colors.tx_text_teriaty} !important;
        }
      }
    }
  }
  `
);

export const charCounterCss = css`
	position: absolute;
	bottom: -4px;
	right: 0;
	transform: translateY(100%);
`;
