import { PaginationResultDto, UserActivityDto } from '@bondsports/types';
import { network } from '../network';
import { environment } from '../../../../environments/environment';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

function getAvailableUserActivities(
	userId: number,
	organizationId: number,
	page?: number,
	itemsPerPage?: number
): Promise<PaginationResultDto<UserActivityDto>> {
	return network.get(
		`${v4APIUrl}/user-activities/organization/${organizationId}/user/${userId}?itemsPerPage=${itemsPerPage}&page=${page}`
	);
}

export const userActivitiesApi = {
	getAvailableUserActivities,
};
