/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { BnIcon, ETypography, ETypographyColor, Icons, PaymentMethodTypeEnum, Typography } from '@bondsports/utils';
import { flexCss, gapCss } from '../../../../../styles/utils';
import { NormalizedPaymentMethod } from '../../types';

function getIcon(paymentMethod: NormalizedPaymentMethod): Icons {
	const companyIcon = Icons[paymentMethod.meta.company as Icons];

	if (companyIcon) {
		return companyIcon;
	} else if (paymentMethod.type === PaymentMethodTypeEnum.ACH) {
		return Icons.bank;
		// eslint-disable-next-line no-else-return
	} else {
		return Icons.credit_card;
	}
}
export const PaymentMethod = ({ paymentMethod }: { paymentMethod: NormalizedPaymentMethod }) => (
	<div data-aid="PaymentMethod-tr" css={[flexCss, gapCss(6)]}>
		<BnIcon icon={getIcon(paymentMethod)} />
		<Typography type={ETypography.caption} color={ETypographyColor.primary}>
			{paymentMethod.meta.last4}
		</Typography>
	</div>
);
