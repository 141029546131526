/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Button, ETypography, ETypographyColor, SizeEnum, Typography } from '@bondsports/utils';
import { centeredContainerCss } from '../styles';

const descriptionCss = css`
	margin-top: 8px;
	margin-bottom: 20px;
`;

interface Props {
	title: string;
	description: string;
	buttonText?: string;
	buttonAction?: () => void;
}
export const EmptyPage = ({ title, description, buttonText, buttonAction }: Props) => {
	return (
		<div data-aid="EmptyPage" css={centeredContainerCss}>
			<Typography color={ETypographyColor.primary} type={ETypography.h3}>
				{title}
			</Typography>
			<div css={descriptionCss}>
				<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
					{description}
				</Typography>
			</div>

			{buttonAction && buttonText && (
				<Button data-aid="button-EmptyPage-action" theme="primary" sizer={SizeEnum.SMALL} onClick={buttonAction}>
					{buttonText}
				</Button>
			)}
		</div>
	);
};
