import {
	DraftReservationDto,
	DraftSlotsFilterResultDto,
	GetDraftReservationSlotsFiltersDto,
	IGetDraftReservationSlotsFilter,
} from '@bondsports/types';
import { IOption } from '@bondsports/utils';
import { getFixedDayName, getMonthName } from '../lib/dates';
import { EDateTimeFormats } from './times';
import { FilterOptions } from './NewReservation';

export interface DraftReservation extends DraftReservationDto {}

export const mapDraftReservationDtoToDraftReservation = (dto: DraftReservationDto): DraftReservation => {
	return dto;
};

export interface DraftSlotsFilters {
	days: IOption[];
	months: IOption[];
	years: IOption[];
	spaces: IOption[];
	instructors: IOption[];
	segments: IOption[];
}

export const mapDraftSlotsFilterResultDtoToDraftSlotsFilters = (dto: DraftSlotsFilterResultDto): DraftSlotsFilters => ({
	days: dto.days.map(day => ({
		value: day,
		label: getFixedDayName(day, false),
	})),
	months: dto.months.map(month => ({
		value: month,
		label: getMonthName(month, EDateTimeFormats.MMMM),
	})),
	years: dto.years.map(year => ({
		value: year,
		label: String(year),
	})),
	spaces: dto.spaces.map(space => ({
		value: space.id,
		label: space.name,
	})),
	instructors: dto.instructors.map(instructor => ({
		value: instructor.id,
		label: instructor.name,
	})),
	segments: dto.segments.map(segment => ({
		value: segment.id,
		label: segment.name,
	})),
});

export const mapFiltersToIGetDraftReservationSlotsFilter = (
	filters?: FilterOptions
): GetDraftReservationSlotsFiltersDto => ({
	days: filters?.days?.map(day => Number(day)),
	months: filters?.months?.map(month => Number(month)),
	spacesIds: filters?.spaces?.map(space => Number(space)),
	instructorsIds: filters?.instructors?.map(instructor => Number(instructor)),
	includeConflicts: filters?.conflictsOnly || undefined,
});
