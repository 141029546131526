/** @jsx jsx */
import { Fragment, useState } from 'react';
import { jsx } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { MIN_USER_IDENTIFIER_LENGTH, UserIdentifierDto } from '@app/react/lib/api/userIdentificationApi';
import { CenteredLayout, Conflict, DialogPrimaryLayout, GridLayout, ShortDetailLayout } from 'ui';
import dayjs from 'dayjs';
import { EDateTimeFormats } from '@app/react/types/times';
import {
	Input,
	Typography,
	ETypography,
	ETypographyColor,
	SizeEnum,
	truncateStringMiddleEllipsis,
} from '@bondsports/utils';
import {
	PASS_TRUNCATE_LENGTH_END,
	PASS_TRUNCATE_LENGTH_START,
	passesBodyTwoColGridCss,
} from './AssignPassModalManager';

interface IProps {
	existingUserIdentifiers: UserIdentifierDto[];
	onCancel: () => void;
	onSubmit: (identifier: string) => void;
}

const existingPassesLabelCss = {
	marginTop: '2rem',
	marginBottom: '2rem',
};

/*
 * Allows user to manually enter an identifier.
 * NB: The AssignPassModalManager will handle the scanner context and automatically submit scanned IDs.
 */
export const ManualIdentifierEntryModalBody = ({ existingUserIdentifiers, onCancel, onSubmit }: IProps) => {
	const labels = TranslationEn.customers.customerHeader.assignPassModal.manualIdentifierEntry;
	const [inputValue, setInputValue] = useState('');
	const [error, setError] = useState(false);

	const validateInputAndSubmit = () => {
		if (inputValue.length < MIN_USER_IDENTIFIER_LENGTH) {
			setError(true);
			return;
		}
		setError(false);
		onSubmit(inputValue);
	};

	return (
		<DialogPrimaryLayout
			title={labels.title}
			description={labels.bodyCopy}
			footerDescription={null}
			primaryButton={labels.assignButton}
			onPrimaryAction={validateInputAndSubmit}
			secondaryButton={labels.cancelButton}
			onSecondaryAction={onCancel}
		>
			<CenteredLayout>
				<Input
					type="text"
					value={inputValue}
					onChange={e => setInputValue(e.target.value)}
					minLength={MIN_USER_IDENTIFIER_LENGTH}
					error={error ? labels.minLengthError(MIN_USER_IDENTIFIER_LENGTH) : undefined}
					sizer={SizeEnum.MEDIUM}
				/>

				{existingUserIdentifiers.length > 0 && (
					<Fragment>
						<ShortDetailLayout
							color={ETypographyColor.secondary}
							icon={<Conflict className="h-5 w-5" />}
							css={existingPassesLabelCss}
						>
							{labels.existingPassesInfo}
						</ShortDetailLayout>
						<GridLayout maxColumns={2} css={passesBodyTwoColGridCss}>
							<div>
								<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
									{labels.passIdTH}
								</Typography>
								{existingUserIdentifiers.map(identifier => (
									<ul key={identifier.id}>
										<li>
											<Typography type={ETypography.body2} color={ETypographyColor.primary}>
												{truncateStringMiddleEllipsis(
													identifier.identifier,
													PASS_TRUNCATE_LENGTH_START,
													PASS_TRUNCATE_LENGTH_END
												)}
											</Typography>
										</li>
									</ul>
								))}
							</div>
							<div>
								<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
									{labels.startDateTH}
								</Typography>
								{existingUserIdentifiers.map(identifier => (
									<ul key={identifier.id}>
										<li>
											<Typography type={ETypography.body2} color={ETypographyColor.primary}>
												{identifier.validFrom
													? dayjs(identifier.validFrom).format(EDateTimeFormats.DAY_FORMAT)
													: labels.validFromAlways}
											</Typography>
										</li>
									</ul>
								))}
							</div>
						</GridLayout>
					</Fragment>
				)}
			</CenteredLayout>
		</DialogPrimaryLayout>
	);
};
