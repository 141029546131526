/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import React, { Fragment, ReactNode } from 'react';
import { ETypography, ETypographyColor, IColors, Icons } from '../../types/theme';
import { PaymentMethodTypeEnum } from '../../types/entities/payment';
import { Checkbox } from '../../components/Checkbox';
import { Typography } from '../Typography';
import { Tag } from '../../components/Tag';
import { useColors } from '../../hooks/useColors';
import { Pricify } from '../../lib/price';
import { BnIcon } from '../../components/icons_v2';
import { IPayingUser } from '../../molecules/PaymentMethodPicker';
import { capitalizeFirstLetter, clip, formatUserName } from '../../lib/stringUtils';

const NAME_MAX_LENGTH = 20;

const containerCss = (colors: IColors, selected = false, error = false, invoiceOwner = false) => css`
	display: flex;
	height: 36px;
	width: 100%;
	align-items: center;
	background: ${colors.bg_background_secondary};
	border-radius: 3px;
	cursor: pointer;
	${selected ? `border: 1px solid ${colors.bg_text_secondary};` : `border: 1px solid ${colors.bg_border_primary});`};
	${error && `border: 1px solid ${colors.tx_error}`};
	label {
		height: 100%;
		width: ${!invoiceOwner && 72}px;
		margin-top: 15px;
		padding-left: ${!invoiceOwner && 38}px;
		.S {
			height: 22px;
			width: 22px;
			margin-left: 16px;
			svg {
				height: 22px;
				width: auto;
				margin-top: -1px;
				color: ${`${selected ? colors.tx_text_secondary : colors.tx_text_teriaty}}`};
			}
		}
		.checkmark {
			left: 18px !important;
		}
	}
`;

const detailsCss = css`
	display: flex;
	justify-content: space-between;
	width: 100%;
	line-height: 22px;
	padding-right: 16px;
	margin-left: 24px;
`;

const publicInvoiceCss = css`
	display: flex;
	gap: 10px;
	width: 100%;
`;

const iconCss = (colors: IColors) => css`
	width: 20px;
	height: 20px;
	color: ${colors.tx_text_secondary};
`;

const redTextCss = (colors: IColors) => css`
	margin-top: 4px;
	color: ${colors.tx_error};
`;

const wrapperCss = (colors: IColors) => css`
	svg {
		margin-right: 8px;
		width: 16px;
		height: 16px;
		color: ${colors.bg_text_teriaty};
	}
	align-self: flex-start;
`;

const infoContainerCss = css`
	margin-top: 6px;
	text-align: left;
	margin-bottom: 2px;
`;

export enum EPaymentIcons {
	cash = 'cash',
	check = 'check_p',
	other = 'cash',
	balance = 'bill_account',
	ach_credit_transfer = 'bank',
	card = 'credit_card',
	'gift-card' = 'check_p',
}

export enum EPaymentMethodLabel {
	cash = 'Cash',
	check = 'Check',
	other = 'Other payment',
	balance = 'Credit balance',
	ach_credit_transfer = 'ACH',
	card = 'Credit card',
	'gift-card' = 'Gift Card',
}
export interface PaymentMethodProps {
	id: number;
	selected: boolean;
	type: PaymentMethodTypeEnum;
	icon?: Icons;
	primaryText?: string;
	secondaryText?: string;
	amountTag?: number;
	displayTag?: boolean;
	customErrorAmount?: number;
	error?: string;
	info?: string;
	onSelect?: (id: number, amount?: number) => void;
	isPublicInvoice?: boolean;
	payingUser?: IPayingUser;
}

export const PaymentMethod = ({
	id,
	selected,
	type,
	primaryText,
	secondaryText,
	icon,
	amountTag,
	displayTag,
	customErrorAmount,
	error,
	info,
	onSelect = () => {},
	isPublicInvoice = false,
	payingUser,
}: PaymentMethodProps) => {
	const { colors } = useColors();
	const amountText = amountTag && displayTag ? Pricify(amountTag) : '';
	const pmIcon = icon ?? EPaymentIcons[type];

	/**
	 * Click on the amount tag, sends amunt as well on select
	 */
	const onTagClick = () => onSelect(id, amountTag);

	/**
	 * Click on the entire div
	 */
	const onMethodClick = () => onSelect(id);

	const errorAmount = Pricify(customErrorAmount ?? amountTag);

	return (
		<div data-aid="index-PaymentMethod">
			<div key={id} data-testid="pm-container" css={containerCss(colors, selected, Boolean(error), isPublicInvoice)}>
				<Checkbox checked={selected} icon={!isPublicInvoice && pmIcon} setChecked={onMethodClick} />

				<div data-testid="details-container" css={detailsCss} onClick={onMethodClick}>
					{isPublicInvoice ? (
						<div css={publicInvoiceCss}>
							<Typography color={ETypographyColor.primary} type={ETypography.body2}>
								{clip(formatUserName(payingUser?.firstName, payingUser?.lastName), NAME_MAX_LENGTH)}
							</Typography>
							<BnIcon icon={pmIcon} css={iconCss} />
							<Typography color={ETypographyColor.primary} type={ETypography.body2}>
								{primaryText ?? EPaymentMethodLabel[type]}
							</Typography>
							{secondaryText && (
								<Typography color={ETypographyColor.primary} type={ETypography.body2}>
									{secondaryText.trim()}
								</Typography>
							)}
						</div>
					) : (
						<Fragment>
							<Typography color={ETypographyColor.primary} type={ETypography.body2}>
								{primaryText ?? EPaymentMethodLabel[type]}
							</Typography>
							{secondaryText && (
								<Typography color={ETypographyColor.primary} type={ETypography.body2}>
									{secondaryText.trim()}
								</Typography>
							)}
						</Fragment>
					)}
					{!!amountText && (
						<div
							data-testid="amount-tag"
							onClick={e => {
								e.stopPropagation();
								onTagClick();
							}}
						>
							<Tag title={amountText} color="tertiary" padding="4px 6px" fontWeight={400} />
						</div>
					)}
				</div>
			</div>
			{error && (
				<Typography color={ETypographyColor.primary} type={ETypography.caption}>
					<span css={redTextCss(colors)}>
						{errorAmount}
						{error}
					</span>
				</Typography>
			)}
			{info && (
				<div css={infoContainerCss}>
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						<div css={wrapperCss(colors)}>
							<BnIcon icon={Icons.info} />
						</div>
						<div>{info}</div>
					</Typography>
				</div>
			)}
		</div>
	);
};
