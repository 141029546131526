/** @jsx jsx */
import React, { Fragment, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { useToggle } from '@app/react/hooks/useToggle';
import { RadioModal } from '@app/react/components/shared/RadioModal';
import { TranslationEn } from '@assets/i18n/en';
import { BnIcon, Button, Icons, ModalWindow, Popup, SelectOptions } from '@bondsports/utils';
import { useCustomer } from '../hooks/useCustomer';
import { LinkedMember } from '../../familyPage/AddMemberFlow/LinkedMember';
import { LinkedMemberTypeEnum } from '../../familyPage/AddMemberFlow/types';
import { CustomerAccountModalBody } from '../../modals/CustomerAccountModalBody';
import { Dialog } from 'ui';
import { AssignPassModalManager } from '../../modals/assignPass/AssignPassModalManager';

enum CustomerActionsEnum {
	LINK_ACCOUNT = 'linkToAccount',
	UNLINK_FROM_ACCOUNT = 'unlinkFromAccount',
	ASSIGN_PASS = 'assignPass'
}

enum RadiosEnum {
	NEW_FAMILY_ACCOUNT = 'newFamilyAccount',
	EXISTING_FAMILY_ACCOUNT = 'existingFamilyAccount',
}

interface IProps {
	organizationId: number;
	customerId: number;
	isLinkedMember: boolean;
}

export const MoreMenuWrapper = ({organizationId, customerId, isLinkedMember }: IProps) => {
	const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
	const [isCustomerAccountModalShowing, customerAccountToggle] = useToggle();
	const [isLinkedMemberErrorModalShowing, linkedMemberErrorToggle] = useToggle();
	const [isAssignPassOpen, setAssignPassOpen] = useState(false);
	const { goToCreateFamilyAccount } = useCustomer();

	const labels = TranslationEn.customers.customerHeader;

	const actionOptions = Object.values(CustomerActionsEnum).map(value => {
		return {
			label: labels.moreMenuOptions[value],
			value: value,
			isDisabled: value === CustomerActionsEnum.UNLINK_FROM_ACCOUNT,
		};
	});

	const handleReservationActions = (optionClicked: CustomerActionsEnum) => {
		switch (optionClicked) {
			case CustomerActionsEnum.LINK_ACCOUNT:
				setIsMoreOptionsOpen(false);
				if (isLinkedMember) {
					linkedMemberErrorToggle();
				} else {
					customerAccountToggle();
				}
				break;
			case CustomerActionsEnum.ASSIGN_PASS:
				setIsMoreOptionsOpen(false);
				setAssignPassOpen(true);
			default:
				break;
		}
	};

	const radios = [
		{
			value: RadiosEnum.NEW_FAMILY_ACCOUNT,
			label: labels.customerAccountModal.radios.newFamilyAccount,
		},

		{
			value: RadiosEnum.EXISTING_FAMILY_ACCOUNT,
			label: labels.customerAccountModal.radios.existingFamilyAccount,
			disabled: true,
		},
	];

	const handleGoToCreateFamilyAccount = () => goToCreateFamilyAccount(customerId);

	return (
		<Fragment>
			<Popup
				unsetWidth
				fullWidth={false}
				trigger={isMoreOptionsOpen}
				onOpenChange={v => setIsMoreOptionsOpen(v)}
				body={<SelectOptions options={actionOptions} onSelect={handleReservationActions} />}
			>
				<Button data-aid="button-MoreMenuWrapper-more" theme="basic" sizer="XS">
					<BnIcon icon={Icons.more} />
				</Button>
			</Popup>
			<ModalWindow isShowing={isCustomerAccountModalShowing} toggle={customerAccountToggle}>
				<CustomerAccountModalBody
					goToCreateFamilyAccount={handleGoToCreateFamilyAccount}
					handleBack={customerAccountToggle}
				/>
			</ModalWindow>
			<ModalWindow isShowing={isLinkedMemberErrorModalShowing} toggle={linkedMemberErrorToggle} padding={0}>
				<LinkedMember linkedMemberType={LinkedMemberTypeEnum.OTHER_FAMILY} toggle={linkedMemberErrorToggle} />
			</ModalWindow>
			<Dialog open={isAssignPassOpen} onOpenChange={setAssignPassOpen}>
				<AssignPassModalManager
					organizationId={organizationId}
					customerId={customerId}
					isOpen={isAssignPassOpen}
					setOpen={setAssignPassOpen} />
			</Dialog>
		</Fragment>
	);
};
