import { IBasicError, IErrorArr } from '@app/react/types/errors';
import { environment } from '../../../../environments/environment';
import { network } from '../network';
import {
	CreatePromoCodesDto,
	GetOrganizationPromoCodesDto,
	PaginationResultDto,
	PromoCodeDto,
	UpdatePromoCodesDto,
} from '@bondsports/types';
import { buildQueryString } from './utils';

const ITEMS_PER_PAGE_DEFAULT = 8;

const v4Api = `${environment.CS_URLS.API_ROOT_V4}`;

const baseUrl = (organizationId: number) => `${v4Api}/discounts/organization/${organizationId}/promo-codes`;

const getOrgPromoCodes = async ({
	organizationId,
	queryData,
}: {
	organizationId: number;
	queryData: GetOrganizationPromoCodesDto;
}): Promise<PaginationResultDto<PromoCodeDto> | IBasicError> => {
	const queryString = buildQueryString({
		...queryData,
		search: queryData.search ? encodeURIComponent(queryData.search) : '',
		itemsPerPage: queryData.itemsPerPage ? queryData.itemsPerPage : ITEMS_PER_PAGE_DEFAULT,
	});
	const response = await network.get(`${baseUrl(organizationId)}${queryString}`);
	return response;
};

const createPromoCodes = async (
	organizationId: number,
	data: CreatePromoCodesDto
): Promise<PromoCodeDto[] | IBasicError | IErrorArr> => {
	const response = await network.post(baseUrl(organizationId), data);
	return response;
};

const editPromoCodes = async (
	organizationId: number,
	data: UpdatePromoCodesDto
): Promise<PromoCodeDto[] | IBasicError | IErrorArr> => {
	const response = await network.put(baseUrl(organizationId), data);
	return response;
};

async function deletePromoCodes(organizationId: number, ids: number[]) {
	const queryString = buildQueryString({ ids });
	const response = await network.delete(`${baseUrl(organizationId)}${queryString}`, {});
	return response;
}

const getPromoCodeById = async ({
	organizationId,
	promoCodeId,
}: {
	organizationId: number;
	promoCodeId: number;
}): Promise<PromoCodeDto | IBasicError> => {
	const response = await network.get(`${baseUrl(organizationId)}/${promoCodeId}`);
	return response;
};

export const promoCodesApi = { getOrgPromoCodes, createPromoCodes, editPromoCodes, deletePromoCodes, getPromoCodeById };
