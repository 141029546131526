/** @jsx jsx */
import * as React from 'react';
import { css, jsx } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { EventWithAttendanceDto } from '@bondsports/types';
import { useMemo } from 'react';
import {
	flexCol,
	flexRowCss,
	fullWidthCss,
	gapCss,
	justifyContentCss,
	marginTop,
	paddingBottomCss,
	separatorCss,
} from '@app/react/styles/utils';
import { CHARACHERS, ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import {
	calculateTimeDifference,
	convertTimestampFromTimezoneToTimezone,
	DateTimeFormats,
	DEFAULT_TIMEZONE,
	formatDateTime,
	getUnitPrefix,
	isInFuture,
	isToday,
	subtractFromDateDays,
	TimeUnit,
} from '@bondsports/date-time';
import { AttendancePanel } from './AttendancePanel';
import { EllipsisWithTooltipContainer } from '../../../../shared/EllipsisWithTooltipContainer';
import { textAlignCss } from '../../../activities/styles';
import { TCustomer } from '../../../../../types/customers';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';

dayjs.extend(durationPlugin);

const rowContainerCss = (isLast: boolean, isFirstItem: boolean) => css`
	display: flex;
	width: 100%;
	flex-direction: row;
	padding-top: 15px;
	${isFirstItem ? '' : separatorCss('top')};
	${isLast ? paddingBottomCss(10) : paddingBottomCss(18)};
`;

const midHightRowCss = css`
	align-self: center;
`;

const formatDateForEvent = (startDate: string): string => {
	const eventDate = new Date(startDate);
	if (isToday(eventDate)) {
		return TranslationEn.time.today;
	}
	if (isToday(subtractFromDateDays(eventDate, 1))) {
		return TranslationEn.time.tomorrow;
	}
	return formatDateTime(startDate, DateTimeFormats.MMM_DD);
};

const differenceInHours = (startDate: string, endDate: string): string => {
	const labels = TranslationEn.time;
	const minutes = calculateTimeDifference(startDate, endDate, TimeUnit.MINUTE);
	const duration = dayjs.duration(minutes, 'minutes'); // Create a duration based on minutes
	const seconds = Math.floor(duration.asSeconds() % 60);
	const fullMinutes = Math.floor(duration.asMinutes() % 60);
	const fullHours = Math.floor(duration.asHours() % 24);
	const fullDays = Math.floor(duration.asDays() % 7);
	const fullWeeks = Math.floor(duration.asWeeks() % 4);
	const fullMonths = Math.floor(duration.asMonths());
	let parts: string[] = [];
	if (fullMonths > 0) parts.push(labels.suffix(fullMonths, getUnitPrefix(TimeUnit.MONTH, labels.units)));
	if (fullWeeks > 0) parts.push(labels.suffix(fullWeeks, getUnitPrefix(TimeUnit.WEEK, labels.units)));
	if (fullDays > 0) parts.push(labels.suffix(fullDays, getUnitPrefix(TimeUnit.DAY, labels.units)));
	if (fullHours > 0) parts.push(labels.suffix(fullHours, getUnitPrefix(TimeUnit.HOUR, labels.units)));
	if (fullMinutes > 0) parts.push(labels.suffix(fullMinutes, getUnitPrefix(TimeUnit.MINUTE, labels.units)));
	if (seconds > 0 || parts.length === 0)
		parts.push(labels.suffix(seconds, getUnitPrefix(TimeUnit.SECOND, labels.units)));
	return labels.breackts(parts, labels.separator);
};

const getEventText = (event): string => {
	const eventStartDate = convertTimestampFromTimezoneToTimezone(
		event.startDate,
		DEFAULT_TIMEZONE,
		event.timezone,
		DateTimeFormats.YYYY_MM_DD_T_HH_MM_SS
	);
	const eventEndDate = convertTimestampFromTimezoneToTimezone(
		event.endDate,
		DEFAULT_TIMEZONE,
		event.timezone,
		DateTimeFormats.YYYY_MM_DD_T_HH_MM_SS
	);

	const day = formatDateForEvent(eventStartDate);
	const time = formatDateTime(eventStartDate, DateTimeFormats.H12_AMPM_UPPERCASE);
	const diff = differenceInHours(eventStartDate, eventEndDate);
	const isSpaceExist = event.resources?.length > 0;

	return isSpaceExist
		? `${day} ${CHARACHERS.DASH} ${time} ${diff} ${CHARACHERS.DASH}`
		: `${day} ${CHARACHERS.DASH} ${time} ${diff}`;
};

const getSpaceNames = (event: EventWithAttendanceDto): string =>
	event?.resources
		?.map(resource => resource.name)
		.filter(name => name.trim() !== '')
		.sort((a, b) => (a > b ? 1 : -1))
		.join(', ')
		.trim();

interface Props {
	customerState: TCustomer;
	event: EventWithAttendanceDto;
	isFirstItem: boolean;
	isLastItem: boolean;
}

export const EventRow = ({ event, customerState, isLastItem, isFirstItem }: Props) => {
	const eventText = useMemo(() => getEventText(event), [event]);
	const spaceNames = useMemo(() => getSpaceNames(event), [event]);

	const textColor: ETypographyColor = useMemo(
		() => (isInFuture(event.endDate) ? ETypographyColor.primary : ETypographyColor.secondary),
		[event.endDate]
	);

	const displayColor = textColor || ETypographyColor.primary;

	return (
		<div
			css={[rowContainerCss(isLastItem, isFirstItem), justifyContentCss('space-between')]}
			data-index={event.id}
			datatype="row"
			data-testid="event"
			key={`event-check-${event.id}`}
		>
			<div datatype="content" data-testid="event-name" css={[flexCol, justifyContentCss('start')]}>
				<div css={[flexRowCss, justifyContentCss('space-between'), fullWidthCss, gapCss(8)]}>
					{event.parentSession && event.parentSession.programId && event.parentSession.id ? (
						<a
							href={eventUrl(event.parentSession.programId, event.parentId)}
							css={[flexRowCss, justifyContentCss('space-between'), fullWidthCss, gapCss(16)]}
						>
							<EllipsisWithTooltipContainer
								color={textColor}
								type={ETypography.body1Link}
								text={event.title}
								placement="top-end"
							/>
						</a>
					) : (
						<EllipsisWithTooltipContainer
							color={textColor}
							type={ETypography.body1Link}
							text={event.title}
							placement="top-end"
						/>
					)}
				</div>
				<div
					datatype="content"
					data-testid="event-body"
					css={[flexRowCss, fullWidthCss, justifyContentCss('start'), gapCss(8), marginTop(8)]}
				>
					<div css={[justifyContentCss('start'), textAlignCss('start')]}>
						<Typography color={displayColor} type={ETypography.body2}>
							{eventText}
						</Typography>
					</div>
					<div css={[justifyContentCss('start'), textAlignCss('start')]}>
							<EllipsisWithTooltipContainer
								color={displayColor}
								type={ETypography.body2}
								text={spaceNames}
								placement="top-end"
							/>
					</div>
				</div>
			</div>
			<div
				datatype="content"
				data-testid="event-action"
				css={[flexCol, justifyContentCss('start'), midHightRowCss]}
			>
				<AttendancePanel event={event} customerState={customerState} />
			</div>
		</div>
	);
};

function eventUrl(programId: number, parentId: number): string {
	return `/client/activities#/program/clinics/${programId}/sessions/${parentId}/tab/schedule`;
}
