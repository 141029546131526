import { CalculateFeeResult } from '@app/react/hooks/purchase/types';
import { IRawNote } from '@app/react/types/notes';
import { ILineItem } from '@app/react/types/orders';
import { EPaymentMethod, IPayingUser } from '@app/react/types/payment';
import { EntitlementGroup, FutureInstallment, ItemDiscountDto, PaymentStatusEnum } from '@bondsports/types';
import { RCOrganization } from '@rcenter/core';

interface InvoiceToPayment {
	paidAmount: number;
	invoiceId: number;
}

interface IInvoiceTaxes {
	organizationId: number;
	invoiceId: number;
	taxPrecent: number;
	isTaxInclusive: boolean;
	currency: string;
	price: number;
}

export interface IInvoice {
	id: number;
	createdAt: Date;
	updatedAt: Date;
	price: number;
	payingUserId?: number;
	invoiceToPayments: InvoiceToPayment[];
	lineItems: ILineItem[];
	paymentStatus: PaymentStatusEnum;
	paymentMethodId?: string;
	paymentType?: EPaymentMethod;
	paidAmount: number;
	isScheduled?: boolean;
	invoiceId: string;
	mainInvoiceId: number;
	isRefunded: boolean;
	isVoided: boolean;
	invoiceNotes?: IRawNote[];
	paymentsFees?: CalculateFeeResult[];
	futurePrice?: number;
	isPublic?: boolean;
	owner?: IPayingUser;
	totalFeesAmount?: number;
	taxes?: IInvoiceTaxes[];
	discounts?: ItemDiscountDto[];
}

export interface IInvoiceTemplateState {
	invoice: IInvoice;
	entitlementGroups: EntitlementGroup[];
	organization: RCOrganization;
	scheduledPayments: FutureInstallment[];
}
export enum EScheduledChargeStatus {
	FUTURE = 'future',
	SUCCEDEDED = 'succeeded',
	CANCELED = 'canceled', // todo - canceled fix typo BOND-8043
	PENDING = 'pending',
}

export const scheduledChargeStatusWithoutTreansactionFee = [EScheduledChargeStatus.CANCELED];
export const scheduledChargeStatusWithTreansactionFee = [
	EScheduledChargeStatus.FUTURE,
	EScheduledChargeStatus.PENDING,
	EScheduledChargeStatus.SUCCEDEDED,
];

export enum EDiscountApplicant {
	INVOICE = 'invoice',
	LINE_ITEM = 'lineItem',
}

export interface ITaxType {
	[x: number]: number;
}
