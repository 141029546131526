import { IPromoCodeState } from '.';
import { IOption } from '../../../types';
import { IUseSelectInitialData } from '../../../types/components/select';

import { FetchDataCallback } from '../../../types/fetchData';

export enum StateEnum {
	LOADING = 'loading',
	ERROR = 'error',
	APPLIED = 'applied',
	NO_CODES = 'noCodes',
	CODES = 'codes',
}

export interface IPromoCodeInitialState extends IUseSelectInitialData {
	privateNote?: string;
	publicNote?: string;
	selectedPromoCode?: IOption;
}

export interface PromoCodeSelectProps {
	onChange: (promoCodeState: Partial<IPromoCodeState>) => void;
	initialState: IPromoCodeInitialState;
	fetchData: FetchDataCallback<IOption, IGetPromoCodesFilters>;
}

export interface IGetPromoCodesFilters {
	search?: string;
}
