import { css } from '@emotion/react';
import { IColors } from '../types';
import { horizontalHourHeight, spaceHeight, spaceWidth, verticalSpaceHeight } from './atoms/styles';

export const cssProvider = (colors: IColors, height = spaceWidth, monthly?: boolean) => css`
	height: ${height}px;
	flex-grow: 1;
	${monthly && 'margin: 10px;'}

	.Droppable {
		height: 100%;
	}
`;

export const rootContainer = css`
	justify-content: space-between;
	display: flex;
	flex-grow: 1;
	height: 100%;
`;

export const renderHoursContainer = css`
	flex-grow: 1;
	margin-left: 1rem;
	margin-top: ${verticalSpaceHeight}px;
	display: flex;
	flex-direction: column;
	min-height: calc(100% - 90px);
`;

export const renderSpacesHorizontalView = (height = 1000) => css`
	${renderHoursContainer}
	margin-top: ${horizontalHourHeight}px;
	display: flex;
	flex-direction: column;
	height: calc(100% - 44px);
	min-height: ${height}px;
`;

export const renderHoursSeparator = (horizontal?: boolean) => css`
	height: ${horizontal ? horizontalHourHeight : verticalSpaceHeight}px;
	width: ${horizontal ? 150 : 55}px;
	background: #f3f7fa;
	position: fixed;
	margin-top: -${horizontal ? horizontalHourHeight : verticalSpaceHeight}px;
	margin-left: -3px;
	z-index: 2;
`;

export const dndProviderContainer = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background-color: white;

	overflow: auto;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		height: 6px;
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${colors.bg_text_teriaty};
		border-radius: 20px;
		&:hover {
			background-color: ${colors.bg_text_primary};
		}
	}
`;

export const headerContainer = (width: number = spaceWidth, horizontal?: boolean) => css`
	display: flex;
	position: fixed;
	width: ${width}px;
	${!horizontal && 'background: white;'};
	z-index: 21;
`;

export const headerContent = (horizontal?: boolean) => css`
	background: ${horizontal ? '#F3F7FA' : 'white'};
	overflow: hidden;
	z-index: 20;
	width: 300px;
	display: flex;
	flex-grow: 1;
`;

export const headerComponentContainer = (children = 1, spaces = 1, monthly?: boolean) => css`
	position: relative;
	min-width: ${children > 1 ? spaceWidth * children + 1 : spaceWidth}px;
	flex-grow: ${children};
	flex-basis: ${Math.ceil((100 / spaces) * children * 10) / 10}%;
	${monthly && `min-width: max(100%, ${spaceWidth * 7}px);`}
`;

export const headerComponentContainerSpace = (height = spaceHeight, children = 1) => css`
	min-height: ${children > 1 ? height + 1 : height}px;

	flex-grow: 1;
	height: 100%;
`;

export const bodyContainer = (horizontal?: boolean, minWidth?: number) => css`
	display: flex;
	height: 100%;
	margin-top: ${horizontal ? horizontalHourHeight : verticalSpaceHeight}px;
	${minWidth && `min-width: ${minWidth}px;`};
`;

export const bodyContent = (colors: IColors, height = 1000, horizontal?: boolean) => css`
	position: relative;
	background: white;
	flex-grow: 1;
	display: flex;
	flex-direction: ${horizontal ? 'column' : 'row'};
	width: 300px;
	height: 100%;
	min-height: max(100%, ${height - 6}px);
`;

export const spaceColumnContainer = (columns = 1, children = 1) => css`
	display: flex;
	min-width: ${children > 1 ? spaceWidth * children + 1 : spaceWidth}px;
	flex-grow: ${children};
	flex-basis: ${Math.ceil((100 / columns) * children * 10) / 10}%;
`;

export const spaceRowContainer = (spaces = 1) => css`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;
