/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { colors } from 'app/react/styles/theme';

const dividerCss = css`
	background: ${colors.borderSeperator};
	height: 1px;
	width: 100%;
	margin-bottom: 16px;
`;
export const Divider = () => <div css={dividerCss} />;
