/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useState, Fragment, useMemo, useEffect } from 'react';

import { useSlot } from '../../../hooks/useSlot';
import { GenericContainer } from '../GenericContainer';
import { MaintainanceList } from '../MaintenanceSlot';
import { ColorCode, IMaintSlot, ISlot, MaintenanceTimingEnum as MaintTime } from '../types';
import { EventSlotBody } from './EventSlotBody';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const EventSlot = ({
	event: state,
	isDragging,
	isConflict = false,
	isInternal,
	colorCode,
}: {
	event: ISlot;
	isDragging?: boolean;
	isConflict?: boolean;
	isInternal: boolean;
	colorCode?: ColorCode;
}) => {
	const { connectedMaints } = state;
	const [isMaintBefore, setIsMaintBefore] = useState(false);
	const [isMaintAfter, setIsMaintAfter] = useState(false);
	const [isMaintAtBeginning, setIsMaintAtBeginning] = useState(false);
	const [isMaintAtTheEnd, setIsMaintAtTheEnd] = useState(false);
	const [maintAfterHeight, setMaintAfterHeight] = useState(0);
	const [maintEndHeight, setMaintEndHeight] = useState(0);
	const { isShort, isDraft } = useSlot({
		state,
		isConflict,
	});

	const setMaintPxHeight = (pxHeight: number, isAfter: boolean) => {
		isAfter ? setMaintAfterHeight(pxHeight) : setMaintEndHeight(pxHeight);
	};

	const getMaintsOfSameTiming = (connectedMaints: IMaintSlot[], maintTiming: number) => {
		return connectedMaints?.filter((maint: IMaintSlot) => +maint.maintenanceTiming === maintTiming);
	};

	const calcRelativeHeight = (parentTime: number, innerMaintTime: number) => (innerMaintTime / parentTime) * 100;
	const calcDurationDiff = (endTime: string, startTime: string) => {
		return dayjs(endTime, 'HH:mm:ss').diff(dayjs(startTime, 'HH:mm:ss'), 'minutes');
	};

	const parentEventDuration = calcDurationDiff(
		state?.overallEndTime ?? state?.endTime ?? '',
		state?.overallStartTime ?? state?.endTime ?? ''
	);

	const calcMaintHeight = (maint: IMaintSlot) => {
		const maintDuration = calcDurationDiff(maint?.endTime, maint?.startTime);
		const maintSlotHeight = calcRelativeHeight(parentEventDuration, maintDuration);
		return maintSlotHeight || 0;
	};

	const [maintsBefore, maintsAfter, maintsAtBeginning, maintsAtEnd, responsiveGapFromTop] = useMemo(() => {
		const maintsBefore = getMaintsOfSameTiming(connectedMaints, MaintTime.BEFORE);
		const maintsAfter = getMaintsOfSameTiming(connectedMaints, MaintTime.AFTER);
		const maintsAtBeginning = getMaintsOfSameTiming(connectedMaints, MaintTime.AT_THE_BEGINING);
		const maintsAtEnd = getMaintsOfSameTiming(connectedMaints, MaintTime.AT_THE_END);
		const responsiveGapFromTop = calcMaintHeight(maintsBefore?.[0]);

		return [maintsBefore, maintsAfter, maintsAtBeginning, maintsAtEnd, responsiveGapFromTop];
	}, [connectedMaints]);

	const isMaintSettersHandler = () => {
		if (maintsBefore?.length) {
			setIsMaintBefore(true);
		}
		if (maintsAfter?.length) {
			setIsMaintAfter(true);
		}
		if (maintsAtBeginning?.length) {
			setIsMaintAtBeginning(true);
		}
		if (maintsAtEnd?.length) {
			setIsMaintAtTheEnd(true);
		}
	};

	useEffect(() => {
		isMaintSettersHandler();
	}, [connectedMaints]);

	return (
		<Fragment>
			{isMaintBefore && (
				<MaintainanceList
					maintSlots={maintsBefore}
					maintAtEndHeight={null}
					isTrailing={true}
					calcHeight={calcMaintHeight}
					colorCode={colorCode}
				/>
			)}
			<GenericContainer
				isDraft={isDraft}
				isDaily
				isShort={isDragging ? false : isShort}
				isNesting={isMaintAtBeginning}
				colorCode={colorCode}
			>
				<EventSlotBody
					event={state}
					isShort={isShort}
					isConflict={isConflict}
					isMaintAtBeginning={isMaintAtBeginning}
					isMaintAtTheEnd={isMaintAtTheEnd}
					maintsAtBeginning={maintsAtBeginning}
					maintsAtEnd={maintsAtEnd}
					isInternal={isInternal}
					colorCode={colorCode}
					calcHeight={calcMaintHeight}
					gapFromTop={responsiveGapFromTop}
					endAndAfterOverallPxHeight={maintAfterHeight + maintEndHeight}
					maintEndHeight={maintAfterHeight}
					getMaintRefHeight={setMaintPxHeight}
				/>
			</GenericContainer>
			{isMaintAfter && (
				<MaintainanceList
					maintSlots={maintsAfter}
					maintAtEndHeight={{ height: maintAfterHeight }}
					isTrailing={true}
					calcHeight={calcMaintHeight}
					colorCode={colorCode}
					setHeightFromRef={setMaintPxHeight}
				/>
			)}
		</Fragment>
	);
};
