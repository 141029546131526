/** @jsxRuntime classic */
/* * @jsx jsx */
import { SerializedStyles, jsx } from '@emotion/react';
import React, { ReactNode } from 'react';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types';
import { centeredContainerCss } from '../../styles/utils';

interface ErrorProps {
	children: ReactNode;
	typographyType?: ETypography;
	style?: SerializedStyles;
	otherStyles?: SerializedStyles;
}

export const ErrorComponent: React.FC<ErrorProps> = ({
	children,
	typographyType = ETypography.caption,
	style = centeredContainerCss,
	otherStyles,
}) => {
	return (
		<div css={[style, otherStyles]} data-aid="error-fething-data">
			<Typography type={typographyType} color={ETypographyColor.red}>
				{children}
			</Typography>
		</div>
	);
};
