/** @jsx jsx */
import React, { Fragment, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { RCGenderEnum } from '@rcenter/core';
import { Button, Icons } from '@bondsports/utils';
import { ColorPickerBlock } from '../../components/shared/formBlocks/ColorPickerBlock';
import { RightSide, mainCon, mainContainer, detailsPartCss, formLine } from '../../pages/customers/DetailsPage/styles';
import { FrameContainer } from '../../components/customers/utils/FrameContainer';
import { FormInput } from '../../components/shared/FormControls/FormInput';
import { FormSelect } from '../../components/shared/FormControls/FormSelect';
import { FormDateInput } from '../../components/shared/FormControls/FormDateInput';
import { TranslationEn } from '@assets/i18n/en';
import { ContactDetails } from './blocks/ContactDetails';
import { widthCss } from '../../styles/utils';
import { EmergencyContact } from './blocks/EmergencyContact';
import { cantactDetailsValidation } from './familyAccount/lib';

export const containerCss = css`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
`;

interface CustomerDetailsFormProps {
  initialValues: any;
  onCancel: () => void; 
  onSubmit: (values: any) => void;
  onDirtyChange: (isDirty: boolean) => void;
  isDependentCustomer: boolean;
}

export const CustomerDetailsForm = ({ initialValues, onCancel, onSubmit, onDirtyChange, isDependentCustomer }: CustomerDetailsFormProps) => {

  return (
    <Fragment>
      <div data-aid="editCustomerDetails" css={containerCss}>
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
            clear: (args, state, { changeValue }) => {
              changeValue(state, args[0], () => undefined);
            },
            onSelect: (args, state, { changeValue }) => {
              changeValue(state, args[0], () => args[1]);
            },
          }}
          initialValues={initialValues}
          validate={cantactDetailsValidation}
          render={({ form, handleSubmit, pristine }) => {
            const { initialValues, values } = form.getState();
            useEffect(() => {
              onDirtyChange(!pristine);
            }, [pristine]);

            return (
              <form
                autoComplete="off"
                style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                {/* <MiddleWhiteContainer width={600} margin="0" height="unset" isTransparent={true}> */}
                <div css={mainCon} style={{ marginTop: '-1rem' }}>
                  <div css={mainContainer}>
                    <FrameContainer title={TranslationEn.customers.detailsPage.personalDetails}>
                      <div css={detailsPartCss}>
                        <div css={formLine}>
                          <div css={RightSide}>
                            <FormInput
                              label={TranslationEn.customers.detailsPage.firstName}
                              required={initialValues.firstName}
                              name="firstName"
                              sizer="S"
                              disableTrailing={true}
                            />
                          </div>
                          <FormInput
                            label={TranslationEn.customers.detailsPage.lastName}
                            required={initialValues.lastName}
                            name="lastName"
                            sizer="S"
                            disableTrailing={true}
                          />
                        </div>

                        <div css={formLine}>
                          <div css={RightSide}>
                            <FormSelect
                              options={[
                                {
                                  label: TranslationEn.gender[RCGenderEnum.MALE],
                                  value: RCGenderEnum.MALE,
                                },
                                {
                                  label: TranslationEn.gender[RCGenderEnum.FEMALE],
                                  value: RCGenderEnum.FEMALE,
                                },
                                {
                                  label: TranslationEn.gender[RCGenderEnum.OTHER],
                                  value: RCGenderEnum.OTHER,
                                },
                              ]}
                              placeholder=""
                              name="gender"
                              onClear={form.mutators.clear}
                              onSelecting={form.mutators.onSelect}
                              required={initialValues.gender}
                              label={TranslationEn.customers.detailsPage.gender}
                            />
                          </div>
                          <FormDateInput
                            rightIcon={Icons.calendar}
                            required
                            name="birthDate"
                            enablePast={true}
                            sizer="S"
                            isBirthday
                            label={TranslationEn.customers.detailsPage.dob}
                          />
                        </div>
                        <div css={[formLine, widthCss(50)]}>
                          <div css={RightSide}>
                            <ColorPickerBlock
                              label={TranslationEn.customers.detailsPage.selectColorSet}
                              formProp={form}
                            />
                          </div>
                        </div>
                      </div>
                    </FrameContainer>
                    <ContactDetails
                      isDisplayEmailNote={!isDependentCustomer}
                      isDependentCustomer={isDependentCustomer}
                    />
                    {/* TODO: remove formvalues after moving to FormWrapper */}
                    <EmergencyContact formvalues={values} />
                    <FormSpy
                      subscription={{ valid: true }}
                      render={props => {
                        return <FormFooter valid={props.valid} onCancel={onCancel} />;
                      }}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        />
      </div>
    </Fragment>
  )
};

const footerContainer = css`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

const FormFooter = ({ valid, onCancel }: { valid: boolean; onCancel: () => void }) => {
	const submit = () => {};
	return (
		<div css={footerContainer}>
			<div>
				<Button data-aid="button-editCustomerDetails-cancel" theme="basic" type="button" sizer="M" onClick={onCancel}>
					{TranslationEn.customers.detailsPage.cancel}
				</Button>
			</div>
			<div>
				<Button data-aid="button-editCustomerDetails-save" disabled={!valid} onClick={submit} theme="primary" sizer="M" type="submit">
					{TranslationEn.memberships.selectPage.save}
				</Button>
			</div>
		</div>
	);
};
