/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { paginationCss } from './styles';

export const Pagination = ({ page, count, shape, renderItem, ...props }: any) => {
	let list = [
		renderItem({
			key: -1,
			className: 'pagination-item pagination-arrow',
			disabled: page === 1,
			page: page - 1 < 1 ? 1 : page - 1,
			label: () => (
				<svg
					data-aid="pagination-item-icon"
					className="pagination-item-icon"
					focusable="false"
					viewBox="0 0 24 24"
					aria-hidden="true"
				>
					<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
				</svg>
			),
		}),
	];
	if (count > 7) {
		list.push(
			renderItem({
				key: 1,
				className: 1 === page ? 'pagination-item selected' : 'pagination-item',
				page: 1,
				label: () => 1,
			})
		);
		if (page < 5) {
			//last
			for (let i = 1; i < 5; i++) {
				list.push(
					renderItem({
						key: i + 1,
						className: i + 1 === page ? 'pagination-item selected' : 'pagination-item',
						page: i + 1,
						label: () => i + 1,
					})
				);
			}
			list.push(
				renderItem({
					key: 6,
					className: 'pagination-item pagination-arrow',
					disabled: true,
					page: 0,
					label: () => '...',
				})
			);
		} else if (page >= 5 && page <= count - 4) {
			//first+last
			list.push(
				renderItem({
					key: 2,
					className: 'pagination-item pagination-arrow',
					disabled: true,
					page: 0,
					label: () => '...',
				})
			);
			let k = 3;
			for (let i = -1; i < 2; i++) {
				list.push(
					renderItem({
						key: k,
						className: page + i === page ? 'pagination-item selected' : 'pagination-item',
						page: page + i,
						label: () => page + i,
					})
				);
				k += 1;
			}
			list.push(
				renderItem({
					key: 6,
					className: 'pagination-item pagination-arrow',
					disabled: true,
					page: 0,
					label: () => '...',
				})
			);
		} else if (page > count - 4) {
			//first
			list.push(
				renderItem({
					key: 2,
					className: 'pagination-item pagination-arrow',
					disabled: true,
					page: 0,
					label: () => '...',
				})
			);
			let k = 3;
			for (let i = count - 4; i < count; i++) {
				list.push(
					renderItem({
						key: k,
						className: i === page ? 'pagination-item selected' : 'pagination-item',
						page: i,
						label: () => i,
					})
				);
				k += 1;
			}
		}
		list.push(
			renderItem({
				key: 7,
				className: count === page ? 'pagination-item selected' : 'pagination-item',
				page: count,
				label: () => count,
			})
		);
	} else {
		for (let i = 0; i < count; i++) {
			list.push(
				renderItem({
					key: i,
					className: i + 1 === page ? 'pagination-item selected' : 'pagination-item',
					page: i + 1,
					label: () => i + 1,
				})
			);
		}
	}
	list.push(
		renderItem({
			key: 8,
			className: 'pagination-item pagination-arrow',
			disabled: count === page,
			page: page + 1 > count ? count : page + 1,
			label: () => (
				<svg
					data-aid="pagination-item-icon2"
					className="pagination-item-icon"
					focusable="false"
					viewBox="0 0 24 24"
					aria-hidden="true"
				>
					<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
				</svg>
			),
		})
	);

	return <div css={paginationCss}>{list}</div>;
};

export const PaginationItem = (props: any) => {
	return (
		<div
			data-aid="paginationItem"
			className={props.disabled ? props.className + ' arrow-disabled' : props.className}
			onClick={props.onClick}
			// css={paginationItemCss}
		>
			{props.disabled ? props.label() : <props.component to={props.to}>{props.label()}</props.component>}
		</div>
	);
};
