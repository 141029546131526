/** @jsx jsx */
import { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { jsx } from '@emotion/react';
import { environment } from "environments/environment";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import { MediaDto } from "@bondsports/types";

interface CloudinaryUploadControllerProps {
  setMedia: Dispatch<SetStateAction<MediaDto>>;
  sources: string[];
  allowMultiple: boolean;
  allowCropping: boolean;
  maxImageSize: number;
  imageFormats: string[];
  // Options available for the CloudinaryUploadController:
  //  - cloudName (string): The Cloudinary bucket name to use as the destination.
  //  - uploadPreset (string): The Cloudinary upload configuration to use.
  options: {};
  children?: ReactNode;
}

export const CloudinaryUploadController: FC<CloudinaryUploadControllerProps> = ({ setMedia, allowMultiple, allowCropping, sources, maxImageSize, imageFormats, options, children }) => {
  const [cloudName] = options['cloudName'] ?? useState(environment.CLOUDINARY_BUCKET);
  const [uploadPreset] = options['uploadPreset'] ?? useState(environment.CLOUDINARY_UPLOAD_PRESET_WITH_CROPPING);

  const [uwConfig] = useState({
    cloudName,
    uploadPreset,
    //NB: the uploadPreset must have cropping enabled as an incoming transformation.
    // For more info, see https://support.cloudinary.com/hc/en-us/articles/203062071-How-to-crop-images-via-the-Upload-Widget
    cropping: allowCropping,
    croppingAspectRatio: 1.0,
    sources: sources,
    multiple: allowMultiple,
    clientAllowedFormats: imageFormats,
    maxImageFileSize: maxImageSize,
    theme: "white",
    styles: {
      palette: {
        window: "#FFFFFF",
        windowBorder: "#7C9CB4",
        tabIcon: "#0D4774",
        menuIcons: "#7C9CB4",
        textDark: "#0D4774",
        textLight: "#FFFFFF",
        link: "#F7B500",
        action: "#FF620C",
        inactiveTabIcon: "#7C9CB4",
        error: "#F44235",
        inProgress: "#0078FF",
        complete: "#20B832",
        sourceBg: "#F3F7FA"
      },
      frame: {
        background: "#0D477499"
      },
      fonts: {
        "'Montserrat', sans-serif": "https://fonts.googleapis.com/css?family=Montserrat",
      }
    },
    showPoweredBy: false,
  });

  return (
    <CloudinaryUploadWidget uwConfig={uwConfig} setMedia={setMedia} children={children} />
  );
  
}