/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { Tag } from '../../../components/Tag';
import { oldThemeColors as colors } from '../../../styles/theme';

const labels = {
	invoice: 'Payment Plan',
	membership: 'Membership',
};
enum EStatusColorMapper {
	invoice = 'purple',
	membership = 'pink',
}

export const cellCss = (isAction: boolean, isLast?: boolean, svgWidth = 20) => css`
	margin-right: 4px;
	cursor: ${isAction ? 'pointer' : 'default'};
	color: ${colors.brandPrimary};
	width: ${svgWidth}px;
	${isLast ? 'padding: 6px 0px;' : ''}

	.TableCell-paddingCheckbox {
		padding: 0 0 0 8px;
	}
`;

export const ResourceType = ({ column, row }: { column: any; row: any }) => {
	const value = row[column.id];
	const title = labels[value];
	return (
		<div css={cellCss(column.action, false)}>
			<Tag title={title} color={EStatusColorMapper[row[column.id]] || 'blue'} />
		</div>
	);
};