import React from 'react';
import { AlertDialog, AlertDialogPrimaryLayout, Conflict } from 'ui';

interface ILabels {
	title: string;
	description: string;
	primaryButton: string;
	secondaryButton: string;
}

interface Props {
	open: boolean;
	labels: ILabels;
	onPrimaryAction: () => void;
	onSecondaryAction: () => void;
}
export const WarningAlert = ({ open, labels, onPrimaryAction, onSecondaryAction }: Props) => {
	return (
		<div data-testid="warning-alert">
			<AlertDialog open={open}>
				<AlertDialogPrimaryLayout
					icon={<Conflict className="h-2 w-2 text-error" />}
					title={labels.title}
					primaryButton={labels.primaryButton}
					onPrimaryAction={onPrimaryAction}
					onSecondaryAction={onSecondaryAction}
					secondaryButton={labels.secondaryButton}
				>
					{labels.description}
				</AlertDialogPrimaryLayout>
			</AlertDialog>
		</div>
	);
};
