import { IFilterObject } from '@app/react/types/notes';
import {
	ActionSourcePlatformEnum,
	CreateNoteDto,
	FuturePaymentStatusEnum,
	PaymentMethodTypeEnum,
	PaymentStatusEnum,
	PlatformsEnum,
	SubPaymentMethodTypeEnum,
	UpdateConnectedResourcePaymentMethodDto,
	UpdateNoteContentDto,
} from '@bondsports/types';
import { environment } from '../../../../environments/environment';
import {
	CalculateFeeResult,
	ICancelScheduled,
	IChargeScheduled,
	TChargeScheduled,
	IPayProps,
	IRefundProps,
	IScheduleProps,
	ISendPaymentRequest,
	ISendReceiptRequest,
	ISetDiscountProps,
	PublishInvoiceDto,
	TPurchaseData,
} from '../../hooks/purchase/types';
import { ERoutePaths as EPath } from '../../types/navigation';
import { BookingDto } from '../../types/NewReservation';
import { IPurchaseRes } from '../../types/orders';
import { IPostDiscountData, IRefundRequest, IRemoveDiscountData } from '../../types/payment';
import { auth } from '../auth';
import { network } from '../network';
import { buildQueryString } from '../urlUtils';
import { IGetResourcesFilters } from '@app/react/components/customers/ManagePaymentMethods/utils';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

async function getPaymentSecret(organizationId: number, userId: number) {
	const response = await network.get(`${v4APIUrl}/payment/organization/${organizationId}/${userId}/clientSecret`);

	return response.client_secret;
}

/**
 * Get allowed payment methods for this user + fees
 * @param organizationId requesting organization
 * @param userId purchasing customer's userId
 * @param paymentMethodType optional payment method type filter
 */
async function getCustomerUserPaymentMethods(
	organizationId: number,
	userId: number,
	paymentMethodType?: PaymentMethodTypeEnum
) {
	return network.get(
		`${v4APIUrl}/payment/organization/${organizationId}/${userId}/options?platform=${
			ActionSourcePlatformEnum.BACKOFFICE
		}&${paymentMethodType ? `paymentMethodTypes=${paymentMethodType}` : ''}`
	);
}

async function refund(data: IRefundProps) {
	return network.post(`${v4APIUrl}/payment/invoice//refund`, data);
}

/**
 * Refund invoice (Refund v2)
 * @param req Refund request object
 * @param organizationId Organization id
 */
async function refundInvoice(req: IRefundRequest, organizationId: number) {
	return network.post(`${v4APIUrl}/payment/organization/${organizationId}/invoice/refund`, req);
}

async function payPartialBalance(data: IPayProps) {
	return network.post(`${v4APIUrl}/purchase/partial-payment`, network.addPlatformToBody(data));
}

async function schedulePayments(data: IScheduleProps) {
	return network.post(`${v4APIUrl}/payment/schedule`, data);
}

async function getScheduledPayments(organizationId: number, invoiceId: number, userId: number) {
	return network.get(
		`${v4APIUrl}/payment/organization/${organizationId}/user/${userId}/invoice/${invoiceId}/schedule/`
	);
}

async function getFutureScheduledPayments(
	organizationId: number,
	invoiceId: number,
	userId: number,
	page = 1,
	itemsPerPage = 4
) {
	const formattedQuery = buildQueryString({
		page,
		itemsPerPage,
		statuses: [FuturePaymentStatusEnum.FUTURE, FuturePaymentStatusEnum.FAILED, PaymentStatusEnum.RETRY_IN_PROGRESS],
	});
	return network.get(
		`${v4APIUrl}/payment/organization/${organizationId}/user/${userId}/invoice/${invoiceId}/schedule/active${formattedQuery}`
	);
}

async function setDiscount(data: ISetDiscountProps) {
	return network.post(`${v4APIUrl}/customers/invoice/discount`, data);
}

async function chargeScheduled(data: IChargeScheduled): Promise<IPurchaseRes> {
	return network.post(`${v4APIUrl}/purchase/charge-scheduled-remaining`, network.addPlatformToBody(data));
}

async function chargeSelectedScheduled({ query, body }: TChargeScheduled): Promise<IPurchaseRes> {
	return network.post(
		`${v4APIUrl}/purchase/organization/${query.organizationId}/user/${query.userId}/invoice/${query.invoiceId}/charge-scheduled/`,
		body
	);
}

async function cancelScheduledPayments({ paymentsIds }: ICancelScheduled) {
	return network.deleteMethod(`${v4APIUrl}/payment/schedule`, network.addPlatformToBody({ paymentsIds }));
}

async function getStripeToken() {
	return network.get(`${v4APIUrl}/payment/stripe-connection-token`);
}

async function createPaymentIntent(data) {
	return network.post(`${v4APIUrl}/payment/organization/${data.organizationId}/create-payment-intent`, data);
}

async function purchase(data: TPurchaseData, organizationId: number): Promise<IPurchaseRes> {
	// There are situations where invoiceId in the data object defaults to 0 instead of null
	// This is a temporary fix until we find the root cause
	const invoiceId = (data as BookingDto).invoiceId > 0 ? (data as BookingDto).invoiceId : null;
	const fixedData = { ...data, invoiceId };
	return network.post(`${v4APIUrl}/purchase/organization/${organizationId}`, network.addPlatformToBody(fixedData));
}

async function rentalPurchase(data: BookingDto, organizationId: number, facilityId: number): Promise<IPurchaseRes> {
	return network.post(`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/book`, data);
}

async function sendReceipt(organizationId: number, data: ISendReceiptRequest) {
	return network.post(`${v4APIUrl}/payment/organization/${organizationId}/send-receipt`, data);
}

async function sendPaymentRequest(organizationId: number, isPaid: boolean, body: ISendPaymentRequest) {
	return network.post(
		`${v4APIUrl}/${EPath.PAYMENT}/${EPath.ORGANIZATION}/${organizationId}/${EPath.SEND_REQUEST_PAYMENT}/${isPaid}`,
		body
	);
}
async function getConnectedResourcesByFilters(
	userId: number,
	organizationId: number,
	paymentMethodIds?: string[],
	filters?: IGetResourcesFilters
) {
	const queryString = buildQueryString({
		paymentMethodIds,
		...filters,
	});

	return await network.get(
		`${v4APIUrl}/payment/payment-methods/organization/${organizationId}/user/${userId}/connected-resources${queryString}`
	);
}

async function updateConnectedResourcesPaymentMethod(
	userId: number,
	organizationId: number,
	connectedResources: UpdateConnectedResourcePaymentMethodDto[]
) {
	return await network.put(
		`${v4APIUrl}/payment/payment-methods/organization/${organizationId}/user/${userId}/connected-resources`,
		connectedResources
	);
}

async function getPaymentMethodsConnctedResourcesByFilters(
	organizationId: number,
	userId: number,
	page = 1 as number,
	itemsPerPage = 10,
	paymentMethodIds = [] as string[],
	resourceTypes?: number[],
	search?: string
) {
	const searchParam = search ? `?search=${encodeURIComponent(search)}` : '';

	const queryString = buildQueryString({
		paymentMethodIds,
		searchParam,
		page,
		itemsPerPage,
		resourceTypes,
	});

	return await network.get(
		`${v4APIUrl}/payment/payment-methods/organization/${organizationId}/user/${userId}/connected-resources${queryString}`
	);
}

async function getPaymentMethodsConnctedResources(
	organizationId: number,
	userId: number,
	paymentMethodIds = [] as string[],
	page = 1 as number,
	itemsPerPage = 10 as number,
	search?: string
) {
	const searchParam = search ? `?nameSearch=${encodeURIComponent(search)}` : '';

	const queryString = buildQueryString({
		paymentMethodIds,
		page,
		itemsPerPage,
	});

	return await network.get(
		`${v4APIUrl}/payment/payment-methods/organization/${organizationId}/user/${userId}/connected-resources${queryString}`
	);
}

async function setInvoiceToPublic(invoiceId: number, body: PublishInvoiceDto) {
	return network.post(`${v4APIUrl}/invoices/${invoiceId}/publish`, body);
}

async function getRecalcFee(
	organizationId: number,
	paymentMethodType: PaymentMethodTypeEnum,
	amount: number,
	cardFunding?: SubPaymentMethodTypeEnum
): Promise<CalculateFeeResult> {
	const headers = auth.getAuthHeaders();
	const subPaymentMethodType = cardFunding ? `&subPaymentMethodType=${cardFunding}` : '';

	return network.get(
		// eslint-disable-next-line max-len
		`${v4APIUrl}/fees/organization/${organizationId}/calc-fee?platform=${PlatformsEnum.BO}&amount=${amount}&paymentMethodType=${paymentMethodType}${subPaymentMethodType}`,
		headers
	);
}

async function getRecalcFees(
	organizationId: number,
	paymentMethodType: PaymentMethodTypeEnum,
	amounts: number[],
	splitsNumber: number,
	cardFunding?: SubPaymentMethodTypeEnum
): Promise<CalculateFeeResult[]> {
	const headers = auth.getAuthHeaders();
	const baseUrl = `${v4APIUrl}/fees/organization/${organizationId}/calc-fees?platform=${PlatformsEnum.BO}`;
	const paymentMethodTypeString = `&paymentMethodType=${paymentMethodType}`;
	const subPaymentMethodTypeString = cardFunding ? `&subPaymentMethodType=${cardFunding}` : '';
	const amountsString = amounts.length > 1 ? `&amounts=${amounts.join(',')}` : `&amount=${amounts[0]}`;
	const splitString = splitsNumber > 1 ? `&splitsNumber=${splitsNumber}` : ``;

	return network.get(
		`${baseUrl}${amountsString}${splitString}${paymentMethodTypeString}${subPaymentMethodTypeString}`,
		headers
	);
}

function createInvoiceNote(organizationId: number, invoiceId: number, note: CreateNoteDto): Promise<any> {
	return network.post(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/invoice/${invoiceId}/notes`,
		note
	);
}

function getInvoiceNotes(organizationId: number, invoiceId: number, filter: IFilterObject | boolean): Promise<any> {
	return network.get(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/invoice/${invoiceId}/notes?filter=${filter}`
	);
}

function updateInvoiceNote(organizationId: number, invoiceId: number, note: UpdateNoteContentDto): Promise<any> {
	return network.put(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/invoice/${invoiceId}/notes/${note.id}`,
		note
	);
}

function deleteInvoiceNote(organizationId: number, invoiceId: number, noteId: number) {
	return network.deleteMethod(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/invoice/${invoiceId}/notes/${noteId}`,
		{}
	);
}

function createPaymentNote(organizationId: number, paymentId: number, note: CreateNoteDto): Promise<any> {
	return network.post(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/payment/${paymentId}/notes`,
		note
	);
}

function getPaymentNotes(organizationId: number, paymentId: number, filter: IFilterObject): Promise<any> {
	return network.get(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/payment/${paymentId}/notes?filter=${filter}`
	);
}

function updatePaymentNote(organizationId: number, paymentId: number, note: UpdateNoteContentDto): Promise<any> {
	return network.put(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/payment/${paymentId}/notes/${note.id}`,
		note
	);
}

function deletePaymentNote(organizationId: number, paymentId: number, noteId: number) {
	return network.deleteMethod(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/payment/${paymentId}/notes/${noteId}`,
		{}
	);
}

const postDiscounts = async (organizationId: number, invoiceId: number, data: IPostDiscountData) => {
	const response = await network.post(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/invoice/${invoiceId}/discounts`,
		data
	);
	return response;
};

const createUserPaymentMethod = async (userId: number, paymentMethodId: string) => {
	const response = await network.post(`${v4APIUrl}/payment/user/${userId}/methods/${paymentMethodId}`, {});
	return response;
};

const removeDiscounts = async (organizationId: number, invoiceId: number, data: IRemoveDiscountData) => {
	const response = await network.post(
		`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/invoice/${invoiceId}/remove-discounts`,
		data
	);
	return response;
};

export const paymentApi = {
	getPaymentSecret,
	payPartialBalance,
	schedulePayments,
	getScheduledPayments,
	setDiscount,
	getStripeToken,
	createPaymentIntent,
	cancelScheduledPayments,
	purchase,
	refund,
	rentalPurchase,
	sendReceipt,
	refundInvoice,
	sendPaymentRequest,
	getPaymentMethodsConnctedResources,
	getConnectedResourcesByFilters,
	updateConnectedResourcesPaymentMethod,
	getPaymentMethodsConnctedResourcesByFilters,
	getCustomerUserPaymentMethods,
	getRecalcFee,
	getRecalcFees,
	getInvoiceNotes,
	setInvoiceToPublic,
	postDiscounts,
	removeDiscounts,
	getFutureScheduledPayments,
	chargeSelectedScheduled,
	createInvoiceNote,
	updateInvoiceNote,
	deleteInvoiceNote,
	createPaymentNote,
	getPaymentNotes,
	updatePaymentNote,
	deletePaymentNote,
	createUserPaymentMethod,
};
