import { useTheme } from '@emotion/react';
import React, { ReactNode } from 'react';
import { PickerItemComp } from './style';

export interface IProps {
	label: string | ReactNode;
	value: string | number;
	isActive: boolean;
	tabIndex: number;
	isOutline?: boolean;
	handleSelect: (val: string | number) => void;
	disabled?: boolean;
}
export const PickerItem = ({ label, value, isActive, handleSelect, isOutline = false, disabled }: IProps) => {
	const colors = useTheme();

	return (
		<PickerItemComp
			data-value={label}
			onClick={disabled ? undefined : () => handleSelect(value)}
			className={isActive ? 'active' : ''}
			theme={{ ...colors }}
			isOutline={isOutline}
			tabIndex={0}
			onKeyDown={e => {
				if (e.key === ' ') {
					e.preventDefault();
					handleSelect(value);
				}
			}}
			disabled={disabled}
		>
			{label}
		</PickerItemComp>
	);
};
