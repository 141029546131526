import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { setOpacity } from '../../styles/utils';

export const StyledButton = styled.button<any>(
	({ theme: colors, disabledBackground }: { theme: IColors, disabledBackground: string }) => `
  padding: 0.6rem 0.75rem;
  box-sizing: border-box;
  border:none;
  border-radius: 3px;
  background: transparent;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content:center;

  &:focus {
    outline: none;
  }

  &:disabled{
    cursor:default;

  }


  &.primary {
    background: linear-gradient(270deg, ${colors.bg_system_gradient} 0%,${colors.bg_system} 100%);
    color: ${colors.tx_white};
    &:hover {
      box-shadow: 0px 2px 16px 8px ${setOpacity(colors.bg_system || '', 0.2)};
    }
    &:disabled {
      background: ${colors.bg_background_primary};
      color: ${colors.tx_text_disabled};
      &:hover {
        box-shadow: none;
      }
    }
  }

  &.secondary {
    color: ${colors.bg_text_secondary};
    background: transparent;
    border: 1px solid ${colors.bg_text_teriaty};
    box-shadow: none;
    &:hover {
      border: 1px solid ${colors.bg_text_secondary};
    }
    &:disabled {
      color: ${colors.bg_text_disabled};
      border: 1px solid ${colors.bg_text_disabled};
    }
  }

  &.invert {
    color: ${colors.tx_white};
    background: transparent;
    border: 1px solid ${colors.tx_white};
  }

  &.basic {
    color: ${colors.tx_text_secondary};
    font-weight: 500;
    text-transform: none;
    &:hover{
      color:${colors.tx_text_primary};
    }
    &.isActive{
      color:${colors.tx_text_primary};
    }
    &:disabled{
      color: ${colors.tx_text_secondary};
      background: ${disabledBackground};
    }
  }

  &.L {
    padding: 14px 16px;
    font-size: 16px;
    line-height: 20px;
  }
  &.M {
    padding: 10px 12px;
    font-size: 16px;
    line-height: 20px;
  }
  &.S {
    padding: 9.5px 10px;
    font-size: 14px;
    line-height: 17px;
  }
  &.XS {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 14.67px;
  }


  &.isProcessing{
    padding-left: 0px !important;
    padding-right: 0px !important;
    cursor:default !important;
    &:hover {
      box-shadow: none !important;
    }
  }

  &.basicLine {
    padding: 0px;
    color: ${colors.tx_text_secondary};
    font-weight: 500;
    text-transform: none;
    text-decoration-line: underline;
    &:hover{
      color:${colors.tx_text_primary};
    }
    &.isActive{
      color:${colors.tx_text_primary};
    }
    &:disabled{
      color: ${colors.tx_text_disabled};
    }
  }
`
);
