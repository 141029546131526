/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, memo, useCallback, useState, useMemo, FC } from 'react';
import { css, jsx } from '@emotion/react';
import { SectionContainer } from '../sectionContainer';
import { Table } from '../../table';
import { calculateColumns, extractInlineIcon, subRowsColumns } from './lib';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useDetectPrint } from '../../../hooks/useIsPrint';
import { Summary } from '../summary';
import { SummaryLine } from '../summary/summaryLine';
import { Pricify } from '../../../lib/price';
import { EInvoiceVariant, PaymentStatusEnum } from '../../../organisms/invoiceTemplate/types';
import {
	BACKOFFICE_VARIANTS,
	DiscountOnTypeEnum,
	DiscountTypeEnum,
	EInvoiceAction,
	EInvoicingTabs,
	PAYMENT_VARIANTS,
} from '../types';
import { Toggle } from '../../../components/Toggle';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { flexCss, marginRightCss } from '../../../styles/utils';
import { Column, Row } from '../../../atoms/layoutPrimitives';
import { BnIcon } from '../../../components/icons_v2';
import { hrCss, inlineIconCss, sublineCss, textColCss } from '../../../organisms/invoiceTemplate/styles';

const voidToggleCss = css`
	${flexCss};
	justify-content: space-around;
	margin-inside: 8px;
`;

interface DetailsProps {
	labels: any;
	isLoading: boolean;
	state: any;
	additional: any;
	activeTab: string;
	variant: EInvoiceVariant;
	setShowVoid: (show: boolean) => void;
	showVoidSwitch?: boolean;
	showVoid?: boolean;
	onRemoveDiscount?: (action: EInvoiceAction, val: any) => void;
	discountVisibility: { promoCode: boolean; customDiscount: boolean };
}

export const Details: FC<DetailsProps> = memo(
	({ labels, isLoading, state, additional, activeTab, variant, setShowVoid, showVoid, onRemoveDiscount, discountVisibility }) => {
		const { isMobile } = useWindowSize();
		const isPrint = useDetectPrint();
		const paymentVariant = PAYMENT_VARIANTS.includes(variant);
		const thermalPrint = isPrint && isMobile;

		const getFinalTotal = (subRows: any[]) => {
			const subRowsCount = subRows.length;
			const lastElementTotal = subRows[subRows.length - 1]?.total;

			return lastElementTotal > 0 ? lastElementTotal : subRowsCount > 1 ? subRows[subRowsCount - 2]?.total : null;
		};

		const [rows, columns, subRowColumns] = useMemo(() => {
			return [
				additional.rows,
				calculateColumns(labels.details?.table.columns, isLoading, isMobile, variant, discountVisibility),
				subRowsColumns(isMobile, variant, handleRemoveDiscount, discountVisibility)
			];
		}, [state, additional, labels, isMobile, isLoading, discountVisibility]);

		const showHideVoided = (checked: boolean) => {
			setShowVoid(checked);
		};

		if (activeTab === EInvoicingTabs.SCHEDULE && !isPrint) {
			return <Fragment />;
		}

		const handleRemoveDiscount = (discountGroupId: string) => {
			onRemoveDiscount && onRemoveDiscount(EInvoiceAction.REMOVE_DISCOUNT, discountGroupId);
		};

		const VoidToggle = (
			<div css={voidToggleCss} data-aid="index-details">
				<div css={marginRightCss(6)}>
					<Toggle checked={showVoid} setPressed={showHideVoided} />
				</div>
				<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
					{labels.details.voidToggle}
				</Typography>
			</div>
		);

		const SummaryComponent = () => {
			return (
				<Summary gap={BACKOFFICE_VARIANTS.includes(variant) ? 8 : 12} padding="16px">
					<SummaryLine
						label={labels?.details?.summary?.subtotal}
						value={Pricify(state.invoice.meta?.subtotal, undefined, undefined, true)}
						isLoading={isLoading}
						variant={variant}
					/>
					{Boolean(state.invoice.meta?.discounts?.length) && (
						<Column gap={'4px'} fullWidth>
							<SummaryLine
								label={`${labels?.details?.summary?.discounts}`}
								value={Pricify(state.invoice.meta?.totalDiscountAmount, undefined, undefined, true)}
								isLoading={isLoading}
								variant={variant}
							/>

							{state.invoice.meta?.discounts?.map((discount: any, idx: number) => {
								const label = `${discount?.name} ${discount?.precent > 0 ? `(${discount?.precent}%)` : ''}`.trim();
								const value =
									BACKOFFICE_VARIANTS.includes(variant) &&
									(discount?.isInvoice || discount?.type === DiscountTypeEnum.FIXED) ? (
										<Row gap="4px" styles={sublineCss}>
											<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
												{Pricify(discount?.discountAmount, undefined, undefined, true)}
											</Typography>
											{!discount.isLineItemDiscount && (discountVisibility.customDiscount || discountVisibility.promoCode) ? (
												<BnIcon
													onClick={() => handleRemoveDiscount(discount?.actionId)}
													icon={Icons.indications_cancelled}
												/>
											) : (
												<Fragment />
											)}
										</Row>
									) : (
										Pricify(discount?.discountAmount, undefined, undefined, true)
									);
								return (
									<SummaryLine
										key={idx}
										label={label}
										value={value}
										isLoading={isLoading}
										variant={variant}
										isSubline
									/>
								);
							})}
						</Column>
					)}
					{state.invoice.meta?.tax?.excl && (
						<SummaryLine
							label={`${labels?.details?.summary?.tax} (${state.invoice.meta?.tax?.excl?.precent}%)`}
							value={Pricify(state.invoice.meta?.tax?.excl?.price, undefined, undefined, true)}
							isLoading={isLoading}
							variant={variant}
						/>
					)}
					{state.invoice.meta?.tax?.incl && (
						<SummaryLine
							label={`${labels?.details?.summary?.taxInc} (${state.invoice.meta?.tax?.incl?.precent}%)`}
							value={Pricify(state.invoice.meta?.tax?.incl?.price, undefined, undefined, true)}
							isLoading={isLoading}
							variant={variant}
						/>
					)}
					<SummaryLine
						label={labels.details?.summary?.total(paymentVariant)}
						value={Pricify(state.invoice.meta?.total, undefined, undefined, true)}
						isLoading={isLoading}
						variant={variant}
						isMain={!thermalPrint}
					/>
				</Summary>
			);
		};

		const showVoidToggleSwitch = state?.invoice?.lineItems?.some(
			(lineItem: any) => lineItem.paymentStatus === PaymentStatusEnum.VOID
		);
		return (
			<Fragment>
				<SectionContainer
					title={labels.details.title(paymentVariant)}
					labelRowComponent={showVoidToggleSwitch && VoidToggle}
					className="no-print"
				>
					<Table<any>
						columns={columns}
						rows={rows}
						pagination={false}
						isLoading={isLoading}
						footer={<SummaryComponent />}
						isHover={false}
						maxHeight={-1}
						subRowsColumns={subRowColumns}
						removeRowSeparator
					/>
				</SectionContainer>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						width: 100%;
						gap: 2rem;
					`}
					className="print"
				>
					{rows?.map((row: any, idx: number) => {
						const finalTotal: string = getFinalTotal(row.subRows);
						return (
							<Column key={idx} gap="0.5rem">
								<Row fullWidth justify="space-between">
									<Column align="start" gap="0">
										<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
											{row.item?.name ?? 'Item'}
										</Typography>
										{Boolean(row.item?.description) && (
											<Typography color={ETypographyColor.primary} type={ETypography.caption}>
												{row.item.description}
											</Typography>
										)}
									</Column>
									<Typography color={ETypographyColor.primary} type={ETypography.caption}>
										{Pricify(row.price, undefined, undefined, true)}
									</Typography>
								</Row>
								{row.subRows?.length > 0 ? (
									row.subRows.map((sr: any, idx: number) => {
										const extracted = extractInlineIcon(sr.text);
										const text = extracted.val;
										const inStringIconString = extracted.inStringIconString;
										return (
											<Row
												key={`sr-key-${idx}`}
												fullWidth
												justify="space-between"
												styles={css`
													padding-left: 1rem;
												`}
											>
												<div css={textColCss('0')}>
													{Boolean(inStringIconString) ? (
														<div css={inlineIconCss}>{/*<BnIcon icon={Icons[inStringIconString as string]} />*/}</div>
													) : (
														<Fragment />
													)}
													<Typography color={ETypographyColor.primary} type={ETypography.caption}>
														{text}
													</Typography>
												</div>
												<Typography color={ETypographyColor.primary} type={ETypography.caption}>
													{Boolean(sr.total) ? sr.price : ''}
												</Typography>
											</Row>
										);
									})
								) : (
									<Fragment />
								)}
								<Row
									key={`sr-key-${idx}`}
									fullWidth
									justify="space-between"
									styles={css`
										padding-left: 1rem;
									`}
								>
									<Typography color={ETypographyColor.primary} type={ETypography.body2}>
										{`Quantity x${row.quantity ?? '1'}`}
									</Typography>
									<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
										{finalTotal ?? Pricify(row.total, undefined, undefined, true)}
									</Typography>
								</Row>
							</Column>
						);
					})}
					<hr css={hrCss} />
				</div>
			</Fragment>
		);
	}
);
